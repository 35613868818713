import { getRegisterCommand } from '../../core/websocket/commands';
import { getGuid, getRegisterStepField } from '../utils/fields-storage';
import { LEGAL_PERSON, NATURAL_PERSON, RO_CI_IDENTITY_TYPE } from '../components/steps/RegisterStep2';
import { ADDRESS_DIFFER, NOTIFICATION_DOMICILE } from '../components/forms/RegisterLocationForm';
import {
    REGISTER_STEP_10_NAME, REGISTER_STEP_11_NAME, REGISTER_STEP_12_NAME, REGISTER_STEP_13_NAME, REGISTER_STEP_2_NAME,
    REGISTER_STEP_5_NAME, REGISTER_STEP_6_NAME, REGISTER_STEP_7_NAME, REGISTER_STEP_8_NAME, REGISTER_STEP_9_NAME,
    REGISTER_STEP_5, REGISTER_STEP_14_NAME,
} from '../utils/register-steps';
import {
    CI_FIELD, CNP_FIELD, COMPANY_NAME_FIELD, EMAIL_FIELD, FIRST_NAME_FIELD, LAST_NAME_FIELD,
    PHONE_FIELD, SELECTED_IDENTITY_FIELD, SELECTED_TYPE_PERSON_FIELD, EMITTED_BY_FIELD, EMITTED_DATE_FIELD,
    EXPIRE_DATE_FIELD, BIRTH_DATE_FIELD, BIRTH_PLACE_FIELD, ADDRESS_FIELD, COUNTY_FIELD, CITY_FIELD, COUNTRY_FIELD,
    CITIZENSHIP_FIELD, NATIONALITY_FIELD, ORIGIN_FIELD, ADDRESS_DIFFER_FIELD, RESIDENCY_ADDRESS_FIELD, RESIDENCY_CITY_FIELD,
    RESIDENCY_COUNTY_FIELD, RESIDENCY_COUNTRY_FIELD, RESIDENCY_NOTIFICATION_FIELD, BANK_NAME_FIELD, CURRENCY_FIELD,
    IBAN_FIELD, USERNAME_FIELD, PASSWORD_FIELD, REPETEAD_PASSWORD_FIELD, PERSON_NONRESIDENT_FIELD, PUBLIC_PERSON_FIELD,
    REAL_BENEFICIARY_DIFFER_FIELD, PUBLIC_FUNCTION_FIELD, FUNDS_SOURCE_FIELD, REAL_BENEFICIARY_FIELD, REAL_BENEFICIARY_QUESTION_FIELD,
    REAL_BENEFICIARY_FUNDS_SOURCE_FIELD, BENEFICIARY_AGREEMENT, OCCUPATION_FIELD, OWN_ACTIVITY_FIELD, EMPLOYER_FIELD,
    DOMAIN_FIELD, SPECULATIVE_FIELD, EDUCATION_LEVEL_FIELD, ANNUAL_INCOME_FIELD, ESTIMATE_PERCENTAGE_FIELD, EXPERIENCE_FIELD,
    RISK_FIELD, INVESTMENT_FIELD, PRODUCT1_FIELD, PRODUCT2_FIELD, PRODUCT3_FIELD, PRODUCT4_FIELD, PRODUCT5_FIELD,
    PRODUCT6_FIELD, PRODUCT7_FIELD, FISCAL_CODE_FIELD, NEW_COUNTRY_FIELD, NEW_FISCAL_CODE_FIELD, USA_CITIZEN_FIELD,
    NEW_COUNTRY_2_FIELD, NEW_FISCAL_CODE_2_FIELD, INTERNATIONAL_PHONE_COUNTRY_NUMBER_FIELD, PERSON_COMPENSABLE_FIELD,
} from '../reducers/features/form-structures';
import {
    NEXT_STEP_ENTRY_NAME, RISK_ENTRY_NAME, EXPERIENCE_ENTRY_NAME, ESTIMATE_PERCENTAGE_ENTRY_NAME, INVESTMENT_ENTRY_NAME,
    ADDRESS_ENTRY_NAME, BENEFICIARY_AGREEMENT_ENTRY_NAME, ADDRESS_DIFFER_ENTRY_NAME, ANNUAL_INCOME_ENTRY_NAME, ANAF_ENTRY_NAME,
    BANK_NAME_ENTRY_NAME, BIRTH_DATE_ENTRY_NAME, BIRTH_PLACE_ENTRY_NAME, CI_ENTRY_NAME, CITIZENSHIP_ENTRY_NAME,
    CITY_ENTRY_NAME, CNP_ENTRY_NAME, EDUCATION_LEVEL_ENTRY_NAME, COMPANY_NAME_ENTRY_NAME, COUNTRY_ENTRY_NAME, COUNTY_ENTRY_NAME,
    CURRENCY_ENTRY_NAME, DOMAIN_ENTRY_NAME, EMAIL_ENTRY_NAME, EMITTED_BY_ENTRY_NAME, EMPLOYER_ENTRY_NAME, FUNDS_SOURCE_ENTRY_NAME,
    EXPIRE_DATE_ENTRY_NAME, FINAL_AGREEMENT_2_ENTRY_NAME, FINAL_AGREEMENT_ENTRY_NAME, FIRST_NAME_ENTRY_NAME, IBAN_ENTRY_NAME,
    RESIDENCY_ADDRESS_ENTRY_NAME, EMMITED_DATE_ENTRY_NAME, FUNDS_SOURCE_TIT_ENTRY_NAME, INTERNATIONAL_PHONE_COUNTRY_ENTRY_NAME,
    LAST_NAME_ENTRY_NAME, NATIONALITY_ENTRY_NAME, OCCUPATION_ENTRY_NAME, ORIGIN_ENTRY_NAME, PASSPORT_IDENTITY_TYPE_ENTRY_NAME,
    PASSWORD_ENTRY_NAME, PERSON_NONRESIDENT_ENTRY_NAME, PHONE_ENTRY_NAME, PUBLIC_FUNCTION_ENTRY_NAME, PUBLIC_PERSON_ENTRY_NAME,
    REAL_BENEFICIARY_DIFFER_ENTRY_NAME, REPETEAD_PASSWORD_ENTRY_NAME, REAL_BENEFICIARY_ENTRY_NAME, REAL_BENEFICIARY_FUNDS_SOURCE_ENTRY_NAME,
    RESIDENCY_NOTIFICATION_ENTRY_NAME, REAL_BENEFICIARY_QUESTION_ENTRY_NAME, RESIDENCY_CITY_ENTRY_NAME, RESIDENCY_COUNTRY_ENTRY_NAME,
    RO_CI_IDENTITY_TYPE_ENTRY_NAME, RESIDENCY_COUNTY_ENTRY_NAME, SELECTED_IDENTITY_ENTRY_NAME, SELECTED_TYPE_PERSON_ENTRY_NAME,
    TRANSACTION_SCOPE_ENTRY_NAME, USERNAME_ENTRY_NAME, LAST_STEP_ENTRY_NAME, GET_NEW_GUID_ENTRY_NAME, IS_FIRST_TIME_ENTRY_NAME,
    ACCEPT_MAILS_ENTRY_NAME, USERNAME_CHECK_ENTRY_NAME, ACCOUNT_TYPE_ENTRY_NAME, PRODUCT_INVEST_ENTRY_NAME, CURRENT_STEP_ENTRY_NAME,
    FINAL_EMAIL_ENTRY_NAME, FILES_ENTRY_NAME, FISCAL_COUNTRY_1_ENTRY_NAME, FISCAL_CODE_1_ENTRY_NAME, FISCAL_COUNTRY_3_ENTRY_NAME,
    FISCAL_COUNTRY_2_ENTRY_NAME, FISCAL_CODE_3_ENTRY_NAME, FISCAL_CODE_2_ENTRY_NAME, USA_CITIZEN_ENTRY_NAME, FISCAL_COUNTRY_CODE_ENTRY_NAME,
    COUNTRIES_ENTRY_NAME, DMONITOR_ENTRY_NAME, PERSON_COMPENSABLE_ENTRY_NAME,
} from './database-entries';

const extractInputValueByName = (inputs, name) => {
    const foundInput = inputs.find((input) => input.name === name);
    const value = foundInput ? foundInput.value : '';
    if (name === COUNTY_FIELD) {
        return decorateCountyValue(value);
    }
    if (name === ADDRESS_FIELD) {
        return decorateAddressValue(value);
    }

    return value;
};

const extractInputVisibilityByName = (inputs, name) => {
    const foundInput = inputs.find((input) => input.name === name);
    return foundInput ? foundInput.isVisible : true;
};

const decorateCountyValue = (county) => {
    const regexSectorPattern = /sec\.?\s*(\d+)/i;
    const match = county.match(regexSectorPattern);
    let sectorString = null;
    if (match) {
        const sectorNumber = match[1];
        sectorString = `Sector ${sectorNumber}`;
    }

    return sectorString || county;
};

const decorateAddressValue = (address) => {
    const regex = /(((mun|municipiu|municipiul|sec|sector|jud|judet|oras)[\\. ]+?)([^ ]+))/gi;
    return address.replace(regex, '').trim();
};

export const getStep2Command = (inputs) => {
    const selectedTypePerson = extractInputValueByName(inputs, SELECTED_TYPE_PERSON_FIELD);
    const identityType = extractInputValueByName(inputs, SELECTED_IDENTITY_FIELD);
    const companyName = extractInputValueByName(inputs, COMPANY_NAME_FIELD);
    const guid = getGuid();

    const getNaturalPersonPayload = () => (
        identityType === RO_CI_IDENTITY_TYPE ? RO_CI_IDENTITY_TYPE_ENTRY_NAME : PASSPORT_IDENTITY_TYPE_ENTRY_NAME
    );

    let phoneNumber = null;
    const phone = extractInputValueByName(inputs, PHONE_FIELD);
    const phonePrefix = extractInputValueByName(inputs, INTERNATIONAL_PHONE_COUNTRY_NUMBER_FIELD);
    if (phonePrefix) {
        phoneNumber = `+${phonePrefix} ${phone}`;
    } else {
        phoneNumber = phone;
    }

    const commandPayload = {
        [SELECTED_TYPE_PERSON_ENTRY_NAME]: selectedTypePerson === NATURAL_PERSON ? 'F' : 'J',
        [EMAIL_ENTRY_NAME]: extractInputValueByName(inputs, EMAIL_FIELD),
        [PHONE_ENTRY_NAME]: phoneNumber,
        [INTERNATIONAL_PHONE_COUNTRY_ENTRY_NAME]: extractInputValueByName(inputs, INTERNATIONAL_PHONE_COUNTRY_NUMBER_FIELD) || '',
        [COMPANY_NAME_ENTRY_NAME]: selectedTypePerson === LEGAL_PERSON ? companyName : '',
        [SELECTED_IDENTITY_ENTRY_NAME]: selectedTypePerson === NATURAL_PERSON ? getNaturalPersonPayload() : '',
        [FINAL_AGREEMENT_ENTRY_NAME]: 'on',
        [FINAL_AGREEMENT_2_ENTRY_NAME]: selectedTypePerson === NATURAL_PERSON ? 1 : 0,
        [LAST_STEP_ENTRY_NAME]: REGISTER_STEP_2_NAME,
        [GET_NEW_GUID_ENTRY_NAME]: guid ? 'nu' : 'da',
        [IS_FIRST_TIME_ENTRY_NAME]: !!guid,
        guid,
    };
    if (selectedTypePerson === LEGAL_PERSON) {
        commandPayload[LAST_NAME_ENTRY_NAME] = companyName;
        commandPayload[FIRST_NAME_ENTRY_NAME] = 'PJ';
    }

    return getRegisterCommand(commandPayload);
};

export const getStep3Command = () => {
    const guid = getGuid();
    const commandPayload = {
        [ACCEPT_MAILS_ENTRY_NAME]: '1',
        guid,
    };

    return getRegisterCommand(commandPayload);
};

export const getStep5Command = (inputs) => {
    const guid = getGuid();
    const commandPayload = {
        [LAST_NAME_ENTRY_NAME]: extractInputValueByName(inputs, LAST_NAME_FIELD),
        [FIRST_NAME_ENTRY_NAME]: extractInputValueByName(inputs, FIRST_NAME_FIELD),
        [CNP_ENTRY_NAME]: extractInputValueByName(inputs, CNP_FIELD),
        [CI_ENTRY_NAME]: extractInputValueByName(inputs, CI_FIELD),
        [EMITTED_BY_ENTRY_NAME]: extractInputValueByName(inputs, EMITTED_BY_FIELD),
        [EMMITED_DATE_ENTRY_NAME]: extractInputValueByName(inputs, EMITTED_DATE_FIELD),
        [EXPIRE_DATE_ENTRY_NAME]: extractInputValueByName(inputs, EXPIRE_DATE_FIELD),
        [BIRTH_DATE_ENTRY_NAME]: extractInputValueByName(inputs, BIRTH_DATE_FIELD),
        [BIRTH_PLACE_ENTRY_NAME]: extractInputValueByName(inputs, BIRTH_PLACE_FIELD),
        [ADDRESS_ENTRY_NAME]: extractInputValueByName(inputs, ADDRESS_FIELD),
        [COUNTY_ENTRY_NAME]: extractInputValueByName(inputs, COUNTY_FIELD),
        [CITY_ENTRY_NAME]: extractInputValueByName(inputs, CITY_FIELD),
        [COUNTRY_ENTRY_NAME]: extractInputValueByName(inputs, COUNTRY_FIELD).toUpperCase(),
        [LAST_STEP_ENTRY_NAME]: REGISTER_STEP_5_NAME,
        guid,
    };

    return getRegisterCommand(commandPayload);
};

export const getStep6Command = (inputs) => {
    const addressDiffer = extractInputValueByName(inputs, ADDRESS_DIFFER_FIELD);
    const residencyAddress = extractInputValueByName(inputs, RESIDENCY_ADDRESS_FIELD);
    const residencyCity = extractInputValueByName(inputs, RESIDENCY_CITY_FIELD);
    const residencyCounty = extractInputValueByName(inputs, RESIDENCY_COUNTY_FIELD);
    const residencyCountry = extractInputValueByName(inputs, RESIDENCY_COUNTRY_FIELD);
    const residencyNotification = extractInputValueByName(inputs, RESIDENCY_NOTIFICATION_FIELD);
    const isNotificationFieldVisible = extractInputVisibilityByName(inputs, RESIDENCY_NOTIFICATION_FIELD);
    const guid = getGuid();

    const commandPayload = {
        [CITIZENSHIP_ENTRY_NAME]: extractInputValueByName(inputs, CITIZENSHIP_FIELD).toUpperCase(),
        [NATIONALITY_ENTRY_NAME]: extractInputValueByName(inputs, NATIONALITY_FIELD).toUpperCase(),
        [ORIGIN_ENTRY_NAME]: extractInputValueByName(inputs, ORIGIN_FIELD).toUpperCase(),
        [ADDRESS_DIFFER_ENTRY_NAME]: addressDiffer === ADDRESS_DIFFER ? 'D' : 'N',
        [LAST_STEP_ENTRY_NAME]: REGISTER_STEP_6_NAME,
        guid,
    };
    if (isNotificationFieldVisible) {
        commandPayload[RESIDENCY_NOTIFICATION_ENTRY_NAME] = residencyNotification === NOTIFICATION_DOMICILE ? 'Domiciliu' : 'Resedinta';
    } else {
        commandPayload[RESIDENCY_NOTIFICATION_ENTRY_NAME] = 'Domiciliu';
    }

    if (addressDiffer === ADDRESS_DIFFER) {
        const decoratedCommandPayload = {
            ...commandPayload,
            [RESIDENCY_ADDRESS_ENTRY_NAME]: residencyAddress,
            [RESIDENCY_CITY_ENTRY_NAME]: residencyCity,
            [RESIDENCY_COUNTY_ENTRY_NAME]: residencyCounty,
            [RESIDENCY_COUNTRY_ENTRY_NAME]: residencyCountry.toUpperCase(),
        };

        return getRegisterCommand(decoratedCommandPayload);
    }

    return getRegisterCommand(commandPayload);
};

export const getStep7Command = (inputs) => {
    const guid = getGuid();
    const commandPayload = {
        [BANK_NAME_ENTRY_NAME]: extractInputValueByName(inputs, BANK_NAME_FIELD),
        [CURRENCY_ENTRY_NAME]: extractInputValueByName(inputs, CURRENCY_FIELD),
        [IBAN_ENTRY_NAME]: extractInputValueByName(inputs, IBAN_FIELD),
        [LAST_STEP_ENTRY_NAME]: REGISTER_STEP_7_NAME,
        guid,
    };

    return getRegisterCommand(commandPayload);
};

export const getUsernameCheckCommand = (username) => {
    const guid = getGuid();
    const commandPayload = {
        [USERNAME_CHECK_ENTRY_NAME]: username,
        guid,
    };

    return getRegisterCommand(commandPayload);
};

export const getStep8Command = (inputs) => {
    const guid = getGuid();
    const commandPayload = {
        [USERNAME_ENTRY_NAME]: extractInputValueByName(inputs, USERNAME_FIELD),
        [PASSWORD_ENTRY_NAME]: extractInputValueByName(inputs, PASSWORD_FIELD),
        [REPETEAD_PASSWORD_ENTRY_NAME]: extractInputValueByName(inputs, REPETEAD_PASSWORD_FIELD),
        [ACCOUNT_TYPE_ENTRY_NAME]: 'SA',
        [LAST_STEP_ENTRY_NAME]: REGISTER_STEP_8_NAME,
        guid,
    };

    return getRegisterCommand(commandPayload);
};

export const getStep9Command = (inputs, dmonitorResponse) => {
    const realBeneficiaryDiffer = extractInputValueByName(inputs, REAL_BENEFICIARY_DIFFER_FIELD);
    const realBeneficiary = extractInputValueByName(inputs, REAL_BENEFICIARY_FIELD);
    const guid = getGuid();
    const commandPayload = {
        [PERSON_NONRESIDENT_ENTRY_NAME]: extractInputValueByName(inputs, PERSON_NONRESIDENT_FIELD) ? '0' : '1',
        [PUBLIC_PERSON_ENTRY_NAME]: extractInputValueByName(inputs, PUBLIC_PERSON_FIELD) ? '1' : '0',
        [PUBLIC_FUNCTION_ENTRY_NAME]: extractInputValueByName(inputs, PUBLIC_FUNCTION_FIELD) || '',
        [FUNDS_SOURCE_ENTRY_NAME]: extractInputValueByName(inputs, FUNDS_SOURCE_FIELD) || '',
        [REAL_BENEFICIARY_DIFFER_ENTRY_NAME]: realBeneficiaryDiffer ? '0' : '1',
        [REAL_BENEFICIARY_ENTRY_NAME]: !realBeneficiaryDiffer ? 'DA' : realBeneficiary,
        [REAL_BENEFICIARY_QUESTION_ENTRY_NAME]: extractInputValueByName(inputs, REAL_BENEFICIARY_QUESTION_FIELD) ? '1' : '0',
        [REAL_BENEFICIARY_FUNDS_SOURCE_ENTRY_NAME]: extractInputValueByName(inputs, REAL_BENEFICIARY_FUNDS_SOURCE_FIELD),
        [BENEFICIARY_AGREEMENT_ENTRY_NAME]: extractInputValueByName(inputs, BENEFICIARY_AGREEMENT) ? 'on' : '',
        [PERSON_COMPENSABLE_ENTRY_NAME]: extractInputValueByName(inputs, PERSON_COMPENSABLE_FIELD) ? '1' : '0',
        [DMONITOR_ENTRY_NAME]: JSON.stringify(dmonitorResponse),
        [LAST_STEP_ENTRY_NAME]: REGISTER_STEP_9_NAME,
        guid,
    };

    return getRegisterCommand(commandPayload);
};

export const getStep10Command = (inputs) => {
    const guid = getGuid();
    const employer = extractInputValueByName(inputs, EMPLOYER_FIELD) || extractInputValueByName(inputs, OWN_ACTIVITY_FIELD);
    const commandPayload = {
        [OCCUPATION_ENTRY_NAME]: extractInputValueByName(inputs, OCCUPATION_FIELD),
        [EMPLOYER_ENTRY_NAME]: employer || 'Nu se aplica',
        [DOMAIN_ENTRY_NAME]: extractInputValueByName(inputs, DOMAIN_FIELD) || 'Nu se aplica',
        [LAST_STEP_ENTRY_NAME]: REGISTER_STEP_10_NAME,
        guid,
    };

    return getRegisterCommand(commandPayload);
};

export const getStep11Command = (inputs) => {
    let transactionsScope = '';
    if (extractInputValueByName(inputs, SPECULATIVE_FIELD)) {
        transactionsScope += '0';
    }
    if (extractInputValueByName(inputs, INVESTMENT_FIELD)) {
        transactionsScope += '1';
    }
    const guid = getGuid();
    const commandPayload = {
        [EDUCATION_LEVEL_ENTRY_NAME]: extractInputValueByName(inputs, EDUCATION_LEVEL_FIELD) - 1,
        [ANNUAL_INCOME_ENTRY_NAME]: extractInputValueByName(inputs, ANNUAL_INCOME_FIELD) - 1,
        [FUNDS_SOURCE_TIT_ENTRY_NAME]: extractInputValueByName(inputs, FUNDS_SOURCE_FIELD),
        [TRANSACTION_SCOPE_ENTRY_NAME]: transactionsScope,
        [LAST_STEP_ENTRY_NAME]: REGISTER_STEP_11_NAME,
        guid,
    };

    return getRegisterCommand(commandPayload);
};

export const getStep12Command = (inputs) => {
    const productInvest = constructProductInvest(inputs);
    const guid = getGuid();
    const commandPayload = {
        [ESTIMATE_PERCENTAGE_ENTRY_NAME]: extractInputValueByName(inputs, ESTIMATE_PERCENTAGE_FIELD) - 1,
        [EXPERIENCE_ENTRY_NAME]: extractInputValueByName(inputs, EXPERIENCE_FIELD) - 1,
        [RISK_ENTRY_NAME]: extractInputValueByName(inputs, RISK_FIELD) - 1,
        [INVESTMENT_ENTRY_NAME]: extractInputValueByName(inputs, INVESTMENT_FIELD) - 1,
        [PRODUCT_INVEST_ENTRY_NAME]: productInvest,
        [LAST_STEP_ENTRY_NAME]: REGISTER_STEP_12_NAME,
        decizii: '',
        aflatdevg: '',
        guid,
    };

    return getRegisterCommand(commandPayload);
};

const constructProductInvest = (inputs) => {
    let productInvest = '';
    const product1 = extractInputValueByName(inputs, PRODUCT1_FIELD);
    const product2 = extractInputValueByName(inputs, PRODUCT2_FIELD);
    const product3 = extractInputValueByName(inputs, PRODUCT3_FIELD);
    const product4 = extractInputValueByName(inputs, PRODUCT4_FIELD);
    const product5 = extractInputValueByName(inputs, PRODUCT5_FIELD);
    const product6 = extractInputValueByName(inputs, PRODUCT6_FIELD);
    const product7 = extractInputValueByName(inputs, PRODUCT7_FIELD);

    productInvest += product1 ? '0' : '';
    productInvest += product2 ? 'I' : '';
    productInvest += product3 ? '1' : '';
    productInvest += product4 ? '8' : '';
    productInvest += product6 ? '9' : '';
    productInvest += product7 ? '4' : '';
    productInvest += product5 ? 'E' : '';

    return productInvest;
};

export const getStep13Command = (inputs) => {
    const country = extractInputValueByName(inputs, COUNTRY_FIELD);
    const newCountryVisibility = extractInputVisibilityByName(inputs, NEW_COUNTRY_FIELD);
    const newFiscalCodeVisibility = extractInputVisibilityByName(inputs, NEW_FISCAL_CODE_FIELD);
    const newCountry2Visibility = extractInputVisibilityByName(inputs, NEW_COUNTRY_2_FIELD);
    const newFiscalCode2Visibility = extractInputVisibilityByName(inputs, NEW_FISCAL_CODE_2_FIELD);
    const guid = getGuid();

    let cnpFieldValue = null;
    const cnpField = getRegisterStepField(REGISTER_STEP_5, CNP_FIELD);
    if (cnpField) {
        cnpFieldValue = cnpField.value;
    }

    const commandPayload = {
        [FISCAL_COUNTRY_1_ENTRY_NAME]: country.toUpperCase(),
        [FISCAL_CODE_1_ENTRY_NAME]: extractInputValueByName(inputs, FISCAL_CODE_FIELD),
        [FISCAL_COUNTRY_2_ENTRY_NAME]: newCountryVisibility ? extractInputValueByName(inputs, NEW_COUNTRY_FIELD).toUpperCase() : '',
        [FISCAL_CODE_2_ENTRY_NAME]: newFiscalCodeVisibility ? extractInputValueByName(inputs, NEW_FISCAL_CODE_FIELD) : '',
        [FISCAL_COUNTRY_3_ENTRY_NAME]: newCountry2Visibility ? extractInputValueByName(inputs, NEW_COUNTRY_2_FIELD).toUpperCase() : '',
        [FISCAL_CODE_3_ENTRY_NAME]: newFiscalCode2Visibility ? extractInputValueByName(inputs, NEW_FISCAL_CODE_2_FIELD) : '',
        [USA_CITIZEN_ENTRY_NAME]: extractInputValueByName(inputs, USA_CITIZEN_FIELD) === 1 ? '1' : '0',
        [ANAF_ENTRY_NAME]: cnpFieldValue || '',
        [FISCAL_COUNTRY_CODE_ENTRY_NAME]: country.toUpperCase(),
        [LAST_STEP_ENTRY_NAME]: REGISTER_STEP_13_NAME,
        LipsaFiscID_Option: '',
        LipsaFiscID_Obs: '',
        NonUS_NascutUS_Option: '',
        NonUS_NascutUS_Obs: '',
        guid,
    };

    return getRegisterCommand(commandPayload);
};

export const getStep14Command = () => {
    const guid = getGuid();
    const commandPayload = {
        [FILES_ENTRY_NAME]: 'da',
        [LAST_STEP_ENTRY_NAME]: REGISTER_STEP_14_NAME,
        guid,
    };

    return getRegisterCommand(commandPayload);
};

export const getStep15Command = () => {
    const guid = getGuid();
    const commandPayload = {
        [FINAL_EMAIL_ENTRY_NAME]: 'da',
        [LAST_STEP_ENTRY_NAME]: REGISTER_STEP_14_NAME,
        guid,
    };

    return getRegisterCommand(commandPayload);
};

export const getLastStepCommand = () => {
    const guid = getGuid();
    const commandPayload = {
        [LAST_STEP_ENTRY_NAME]: REGISTER_STEP_14_NAME,
        guid,
    };

    return getRegisterCommand(commandPayload);
};

export const getNewGuidCommand = () => {
    const commandPayload = { [GET_NEW_GUID_ENTRY_NAME]: 'da' };
    return getRegisterCommand(commandPayload);
};

export const getNewStepCommand = () => {
    const guid = getGuid();
    const commandPayload = {
        [NEXT_STEP_ENTRY_NAME]: '?',
        guid,
    };

    return getRegisterCommand(commandPayload);
};

export const getCurrentStepCommand = (currentStep) => {
    const guid = getGuid();
    const commandPayload = {
        [CURRENT_STEP_ENTRY_NAME]: currentStep,
        guid,
    };

    return getRegisterCommand(commandPayload);
};

const MAIL_SHORT_NAME = 'DescCont_Intern_Blocaj';
export const getSendBlockedMailCommand = () => {
    const guid = getGuid();
    const commandPayload = {
        trimiteEmail: MAIL_SHORT_NAME,
        guid,
    };

    return getRegisterCommand(commandPayload);
};

export const getCountriesCommand = () => {
    const commandPayload = {
        [COUNTRIES_ENTRY_NAME]: 'da',
    };

    return getRegisterCommand(commandPayload);
};
