import React from 'react';
import { FormattedMessage } from 'react-intl';

import TextInput from './core/TextInput';
import FileUpload from './core/FileUpload';
import RadioWithThreeOptions from './core/RadioWithThreeOptions';
import { setRegisterStepFieldValue, setRegisterStepFileValue } from '../../actions/register';
import { REGISTER_STEP_7 } from '../../utils/register-steps';
import { ACCOUNT_IN_USD, ACCOUNTS_IN_EUR, ACCOUNTS_IN_RON } from '../../utils/forms-options';
import { BANK_FILE_FIELD, BANK_FILENAME, BANK_NAME_FIELD, CURRENCY_FIELD, IBAN_FIELD } from '../../reducers/features/form-structures';
import { fontSize } from '../../../core/styles/generic.styles';

import BANK_SVG from '../../../core/assets/svg/bank.svg';

const EUR_CURRENCY = 'EUR';
const USD_CURRENCY = 'USD';
const RON_CURRENCY = 'RON';

const RegisterBankDetailsForm = ({
    inputs, dispatch, loader, setLoader, errorMessage, setErrorMessage, uploadedFiles, onFileUpload, validatorCallback,
}) => (
    <>
        <TextInput
            label={<FormattedMessage id='register_page_step_seven_bank_name' />}
            controlId={inputs[BANK_NAME_FIELD].name}
            value={inputs[BANK_NAME_FIELD].value}
            isInvalid={!inputs[BANK_NAME_FIELD].isValid}
            onInputChange={(e) => dispatch(setRegisterStepFieldValue(REGISTER_STEP_7, BANK_NAME_FIELD, e.target.value))}
        />
        <RadioWithThreeOptions
            formLabel={<FormattedMessage id='register_page_step_seven_currency' />}
            formName={inputs[CURRENCY_FIELD].name}
            formInvalid={!inputs[CURRENCY_FIELD].isValid}
            checkedOption={inputs[CURRENCY_FIELD].value}
            firstOptionId={RON_CURRENCY} firstOptionLabel={RON_CURRENCY} firstOptionValue={RON_CURRENCY}
            onFirstOptionChange={() => dispatch(setRegisterStepFieldValue(REGISTER_STEP_7, CURRENCY_FIELD, RON_CURRENCY))}
            secondOptionId={EUR_CURRENCY} secondOptionLabel={EUR_CURRENCY} secondOptionValue={EUR_CURRENCY}
            onSecondOptionChange={() => dispatch(setRegisterStepFieldValue(REGISTER_STEP_7, CURRENCY_FIELD, EUR_CURRENCY))}
            thirdOptionId={USD_CURRENCY} thirdOptionLabel={USD_CURRENCY} thirdOptionValue={USD_CURRENCY}
            onThirdOptionChange={() => dispatch(setRegisterStepFieldValue(REGISTER_STEP_7, CURRENCY_FIELD, USD_CURRENCY))}
        />
        <TextInput
            label={<FormattedMessage id='register_page_step_seven_iban' />}
            controlId={inputs[IBAN_FIELD].name}
            value={inputs[IBAN_FIELD].value}
            isInvalid={!inputs[IBAN_FIELD].isValid}
            onInputChange={(e) => dispatch(setRegisterStepFieldValue(REGISTER_STEP_7, IBAN_FIELD, e.target.value))}
            onBlur={(e) => dispatch(setRegisterStepFieldValue(REGISTER_STEP_7, IBAN_FIELD, e.target.value.replace(/\s+/g, '')))}
        />
        <FileUpload
            imgSrc={BANK_SVG}
            titleLabel={<FormattedMessage id='register_page_step_fourteen_bank' />}
            isInvalid={!uploadedFiles.some((file) => file.includes(BANK_FILENAME)) && !inputs[BANK_FILE_FIELD].isValid}
            errorMessage={errorMessage}
            successMessage={uploadedFiles.some((file) => file.includes(BANK_FILENAME)) && BANK_FILENAME}
            loader={loader}
            handleFileChange={(e) => {
                dispatch(setRegisterStepFileValue(REGISTER_STEP_7, BANK_FILE_FIELD, e.target.files[0]));
                onFileUpload(e.target.files[0], setErrorMessage, setLoader, validatorCallback(BANK_FILE_FIELD), BANK_FILENAME);
            }}
        />
        <span style={fontSize(14)}>
            <p><FormattedMessage id='register_page_step_seven_footer_1' /></p>
            <FormattedMessage id='register_page_step_seven_footer_2' /> {RON_CURRENCY}:
            <br/>{ACCOUNTS_IN_RON}<br/><br/>
            <FormattedMessage id='register_page_step_seven_footer_2' /> {EUR_CURRENCY}:
            <br/>{ACCOUNTS_IN_EUR}<br/><br/>
            <FormattedMessage id='register_page_step_seven_footer_2' /> {USD_CURRENCY}:
            <br/>{ACCOUNT_IN_USD}
        </span>
    </>
);

export default RegisterBankDetailsForm;
