import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import selectIsOverlayerOpened from '../../../core/selectors/overlayer';
import { closeOverlayer } from '../../../core/actions/overlayer';

export const REGISTER_BANK_STATEMENT_OVERLAYER_NAME = 'REGISTER_BANK_STATEMENT';

const RegisterBankStatementOverlayer = () => {
    const dispatch = useDispatch();
    const isOpen = useSelector((state) => selectIsOverlayerOpened(state, REGISTER_BANK_STATEMENT_OVERLAYER_NAME));
    const onClose = () => dispatch(closeOverlayer(REGISTER_BANK_STATEMENT_OVERLAYER_NAME));

    return (
        <Modal show={isOpen} onHide={onClose} size={'lg'}>
            <Modal.Header closeButton />
            <Modal.Body>
                <FormattedMessage id='register_overlayer_statement_title' />
            </Modal.Body>
        </Modal>
    );
};

export default RegisterBankStatementOverlayer;
