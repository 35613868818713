import React from 'react';
import { useSelector } from 'react-redux';
import { Button, ProgressBar } from 'react-bootstrap';

import { selectRegisterStep } from '../selectors/register';
import { REGISTER_STEPS } from '../utils/register-steps';
import { contentTitleFormat } from '../containers/RegisterPage.styles';
import { textMainColor } from '../../core/styles/generic.styles';

const RegisterStepContent = ({
    formTitle, formSubtitle, content, prevBtnText, nextBtnText, onGoNextClick, onGoPrevClick, showDesc, setShowDesc, nextBtnDisabled,
}) => {
    const step = useSelector(selectRegisterStep);

    const onHelpClick = () => {
        setShowDesc(!showDesc);
    };

    return (
        <div className="col-md-6">
            <div className="register-step-container__col2">
                <p onClick={onHelpClick} className="d-md-none register-step-container__col2__showhelp">?</p>
                <p style={{ ...contentTitleFormat, opacity: 0.6 }} >{formTitle}</p>
                <ProgressBar className="register__progress" variant='success' now={(step / REGISTER_STEPS) * 100 - 5} />
                <p>{formSubtitle}</p>
                {content}
                {renderNavButtons(onGoPrevClick, onGoNextClick, prevBtnText, nextBtnText, nextBtnDisabled)}
            </div>
        </div>
    );
};

const renderNavButtons = (onGoPrevClick, onGoNextClick, prevBtnText, nextBtnText, nextBtnDisabled) => (
    <>
        {onGoPrevClick
            ? <div className='d-flex justify-content-between mt-5 align-items-center'>
                <span className="fs-5 ps-4" style={textMainColor} onClick={onGoPrevClick}>{prevBtnText}</span>
                <Button variant='success' onClick={onGoNextClick} disabled={nextBtnDisabled}>{nextBtnText}</Button>
            </div>
            : <Button style={{ width: '100%' }} disabled={nextBtnDisabled} variant='success' onClick={onGoNextClick}>{nextBtnText}</Button>
        }
    </>
);

export default RegisterStepContent;
