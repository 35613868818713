import React from 'react';
import { useDispatch } from 'react-redux';

import { initWebSocket } from '../engine';

const WebSocket = () => {
    const dispatch = useDispatch();
    if (!window.websocket || typeof window.websocket !== 'object') {
        initWebSocket(dispatch);
    }

    return <React.Fragment />;
};

export default WebSocket;
