import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import RegisterStep from '../RegisterStep';
import RegisterBankDetailsForm from '../forms/RegisterBankDetailsForm';
import validateStep from '../../validators/step-validator';
import usePageTitle from '../../../core/hoc/use-page-title';
import {
    getUploadedFiles, resetRegisterStepValidity, setRegisterStepFields, setRegisterStepFieldValidity, setUploadedFiles, uploadFile,
} from '../../actions/register';
import { selectRegisterStepFormData, selectUploadedFiles } from '../../selectors/register';
import { sendMessage } from '../../../core/websocket/engine';
import { getCurrentStepCommand, getStep7Command } from '../../decorators/payloads';
import { getRegisterStepFields, saveCurrentStep, saveRegisterStepFields } from '../../utils/fields-storage';
import { getFlow } from '../../utils/ocr-storage';
import { REGISTER_STEP_7_NAME, REGISTER_STEP_7 } from '../../utils/register-steps';
import { NEW_OCR_FLOW } from '../../services/ocr-flow-decider';
import { BANK_FILENAME } from '../../reducers/features/form-structures';

const RegisterStep7 = ({ goToNextStep, goToPrevStep }) => {
    const [errorMessage, setErrorMessage] = useState(null);
    const [loader, setLoader] = useState(false);

    const dispatch = useDispatch();
    const inputs = useSelector((state) => selectRegisterStepFormData(state, REGISTER_STEP_7));
    const uploadedFiles = useSelector(selectUploadedFiles);

    const flow = getFlow();
    const version = flow === NEW_OCR_FLOW ? 'v2' : 'v1';
    usePageTitle(`Deschidere cont - Pas 7 | ${version}`);

    useEffect(() => {
        saveCurrentStep(REGISTER_STEP_7_NAME);
        sendMessage(getCurrentStepCommand(REGISTER_STEP_7_NAME), dispatch);
        dispatch(getUploadedFiles());
        dispatch(setRegisterStepFields(REGISTER_STEP_7, getRegisterStepFields(REGISTER_STEP_7)));
    }, []);

    const onSubmit = () => {
        if (loader) {
            setErrorMessage('Te rugam asteapta pana se incarca documentele.');
            return;
        }

        const isValid = validateStep(dispatch, REGISTER_STEP_7, inputs);
        if (isValid && uploadedFiles.some((file) => file.includes(BANK_FILENAME)) && !errorMessage) {
            sendMessage(getStep7Command(Object.values(inputs)), dispatch);
            saveRegisterStepFields(inputs, REGISTER_STEP_7);
            goToNextStep();
        }
    };

    const validatorCallback = (fileField) => (isValid) => (
        dispatch(setRegisterStepFieldValidity(REGISTER_STEP_7, fileField, isValid))
    );

    const onFileUpload = (file, setError, setLoad, validator, fileName = null) => {
        if (file) {
            const onSuccessCallback = () => dispatch(setUploadedFiles([...uploadedFiles, fileName]));
            dispatch(uploadFile(file, setError, setLoad, validator, fileName, onSuccessCallback, dispatch));
        }
    };

    const onBack = () => {
        dispatch(resetRegisterStepValidity(REGISTER_STEP_7));
        goToPrevStep();
    };

    return (
        <RegisterStep
            title={<FormattedMessage id='register_page_step_seven_title' />}
            formTitle={<FormattedMessage id='register_page_step_five_form_title' />}
            formSubtitle={<></>}
            prevBtnText={<FormattedMessage id='prev' />}
            nextBtnText={<FormattedMessage id='next' />}
            onGoNextClick={onSubmit}
            onGoPrevClick={onBack}
        >
            <>
                <p><FormattedMessage id='register_page_step_seven_desc_1' /></p>
                <p><FormattedMessage id='register_page_step_seven_desc_2' /></p>
                <p><FormattedMessage id='register_page_step_seven_desc_3' /></p>
                <p><FormattedMessage id='register_page_step_seven_desc_4' /></p>
            </>
            <>
                <RegisterBankDetailsForm
                    inputs={inputs} dispatch={dispatch} loader={loader} setLoader={setLoader}
                    errorMessage={errorMessage} setErrorMessage={setErrorMessage} uploadedFiles={uploadedFiles}
                    onFileUpload={onFileUpload} validatorCallback={validatorCallback}
                />
            </>
        </RegisterStep>
    );
};

export default RegisterStep7;
