import React from 'react';
import { Form } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';

const NumberInput = ({ controlId, label, isInvalid, value, onInputChange, isLoading, style = {} }) => (
    <Form.Group className="mb-3" style={style} controlId={controlId}>
        <Form.Label>{label}</Form.Label>
        <Form.Control
            isInvalid={isInvalid}
            type="tel"
            value={value}
            onInput={onInputChange}
        />
        {isLoading && <Spinner animation="border" role="status" size='sm' />}
    </Form.Group>
);

export default NumberInput;
