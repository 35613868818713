import { isArray } from 'lodash';

import valuesFallbackFactory from '../services/values-fallback-factory';
import { getFallbackRetries, saveFallbackRetries, getGuid } from '../utils/fields-storage';
import { getFileExtension } from '../utils/helpers';

const BASE_URL = 'https://portal.tradeville.ro';
const UPLOAD_FILE_PATH = 'urcafis/desccont';

export const FILES_REQUEST_NAME = 'files';

export const fetchUploadedFiles = (setUploadedFilesCallback) => {
    const formData = new FormData();
    const guid = getGuid();
    formData.set('guid', guid);

    // eslint-disable-next-line no-undef
    const xhr = new XMLHttpRequest();
    xhr.open('POST', `${BASE_URL}/${UPLOAD_FILE_PATH}`, true);
    xhr.onload = () => {
        if (xhr.status === 200) {
            const rsp = JSON.parse(xhr.response || '{}');
            if (rsp.fisiere && isArray(rsp.fisiere) && rsp.fisiere.length > 0) {
                setUploadedFilesCallback(rsp.fisiere);
            }
        }
    };

    xhr.send(formData);
};

export const doUploadFile = (
    file,
    fileName = null,
    setErrorMessage = () => {},
    setLoader = () => {},
    validator = () => {},
    onSuccessCallback = () => {},
    dispatch = () => {},
) => {
    setLoader(true);
    const formData = new FormData();
    const guid = getGuid();
    formData.set('guid', guid);

    setTimeout(() => {
        setLoader(false);
    }, 5000);

    const constructedFile = constructFile(file, fileName);
    if (constructedFile.size > 21e6) {
        validator(false);
        setErrorMessage('Fisier prea mare - maxim 20MB!');
        setLoader(false);
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        return;
    }

    formData.append('file', constructedFile);

    // eslint-disable-next-line no-undef
    const xhr = new XMLHttpRequest();
    xhr.open('POST', `${BASE_URL}/${UPLOAD_FILE_PATH}`, true);
    xhr.onload = () => {
        if (xhr.status === 200) {
            const rsp = JSON.parse(xhr.response || '{}');
            if (rsp && rsp.eroare) {
                if (rsp.eroare.includes('GUID')) {
                    const fallbackRetries = getFallbackRetries();
                    if (fallbackRetries >= 10) {
                        validator(false);
                        setLoader(false);
                        setErrorMessage(rsp.eroare);
                        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                        return;
                    }

                    saveFallbackRetries(fallbackRetries ? fallbackRetries + 1 : 1);
                    valuesFallbackFactory(dispatch);
                    setTimeout(() => doUploadFile(file, fileName, setErrorMessage, setLoader, validator, onSuccessCallback, dispatch), 500);
                    return;
                }

                validator(false);
                setErrorMessage(rsp.eroare);
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            } else {
                validator(true);
                setErrorMessage(null);
                onSuccessCallback();
            }
        } else {
            validator(false);
        }
        setLoader(false);
    };
    xhr.send(formData);
};

const constructFile = (file, fileName) => {
    if (fileName) {
        const newFileName = `${fileName}.${getFileExtension(file.name)}`;
        // eslint-disable-next-line no-undef
        return new File([file], newFileName, { type: file.type });
    }

    return file;
};
