export const isLocalStorageAvailable = () => {
    try {
        const testKey = '@test';
        localStorage.setItem(testKey, '1');
        localStorage.removeItem(testKey);
        return true;
    } catch (error) {
        return false;
    }
};

export default isLocalStorageAvailable;
