import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { FaInfoCircle } from 'react-icons/fa';

import RegisterStep from '../RegisterStep';
import RegisterDocumentsOverlayer, { REGISTER_DOCUMENTS_OVERLAYER_NAME } from '../overlayers/RegisterDocumentsOverlayer';
import FileUpload from '../forms/core/FileUpload';
import validateStep from '../../validators/step-validator';
import usePageTitle from '../../../core/hoc/use-page-title';
import { selectRegisterStepFormData } from '../../selectors/register';
import { resetRegisterStepValidity, setRegisterStepFieldValidity, setRegisterStepFileValue, uploadFile } from '../../actions/register';
import { sendMessage } from '../../../core/websocket/engine';
import { saveCurrentStep } from '../../utils/fields-storage';
import { openOverlayer } from '../../../core/actions/overlayer';
import { getCurrentStepCommand, getStep14Command } from '../../decorators/payloads';
import { getFlow } from '../../utils/ocr-storage';
import { boldFont, marginRight } from '../../../core/styles/generic.styles';
import { REGISTER_STEP_16_NAME, REGISTER_STEP_16 } from '../../utils/register-steps';
import { LEGAL_FILES_FIELD } from '../../reducers/features/form-structures';
import { NEW_OCR_FLOW } from '../../services/ocr-flow-decider';

const RegisterStep16 = ({ goToNextStep, goToPrevStep }) => {
    const [errorMessage, setErrorMessage] = useState(false);
    const [loader, setLoader] = useState(false);

    const dispatch = useDispatch();
    const inputs = useSelector((state) => selectRegisterStepFormData(state, REGISTER_STEP_16));

    const flow = getFlow();
    const version = flow === NEW_OCR_FLOW ? 'v2' : 'v1';
    usePageTitle(`Deschidere cont - Pas 16 | ${version}`);

    useEffect(() => {
        saveCurrentStep(REGISTER_STEP_16_NAME);
        sendMessage(getCurrentStepCommand(REGISTER_STEP_16_NAME), dispatch);
    }, []);

    const onSubmit = () => {
        if (loader) {
            setErrorMessage('Te rugam asteapta pana se incarca documentele.');
            return;
        }

        const isValid = validateStep(dispatch, REGISTER_STEP_16, inputs);
        if (isValid && !errorMessage) {
            sendMessage(getStep14Command(), dispatch);
            goToNextStep();
        }
    };

    const onBack = () => {
        dispatch(resetRegisterStepValidity(REGISTER_STEP_16));
        goToPrevStep();
    };

    const validatorCallback = (isValid) => (
        dispatch(setRegisterStepFieldValidity(REGISTER_STEP_16, LEGAL_FILES_FIELD, isValid))
    );

    const onFilesUpload = (files, setError) => {
        [...files].forEach((file) => {
            dispatch(uploadFile(file, setError, setLoader, validatorCallback, null, () => {}, dispatch));
        });
    };

    return (
        <RegisterStep
            title={<FormattedMessage id='register_page_step_sixteen_title' />}
            formTitle={<FormattedMessage id='register_page_step_sixteen_form_title' />}
            formSubtitle={<></>}
            prevBtnText={<FormattedMessage id='prev' />}
            nextBtnText={<FormattedMessage id='next' />}
            nextBtnDisabled={loader}
            onGoNextClick={onSubmit}
            onGoPrevClick={onBack}
        >
            <>
                <p style={boldFont}><FormattedMessage id='register_page_step_sixteen_desc_1' /></p>
                <a
                    target='_blank' rel="noreferrer"
                    href='https://mbl.tradeville.ro/Documents/Home/ContractePJ/F1&F2_bilingv_Persoane_Juridice.pdf'
                >
                    <p><FormattedMessage id='register_page_step_sixteen_desc_2' /></p>
                </a>
                <a target='_blank' href='https://public.tradeville.ro/Documents/Home/ContractePJ/Contract.pdf' rel="noreferrer">
                    <p><FormattedMessage id='register_page_step_two_checkbox_2_part_2' /></p>
                </a>
                <a
                    target='_blank' rel="noreferrer"
                    href='https://mbl.tradeville.ro/Documents/Home/ContractePJ/DeclBR_activitatePJ.pdf'
                >
                    <p><FormattedMessage id='register_page_step_sixteen_desc_7' /></p>
                </a>
                <a
                    target='_blank' rel="noreferrer"
                    href='https://mbl.tradeville.ro/Documents/Home/ContractePJ/Decl_Fiscala_PJ.pdf'
                >
                    <p><FormattedMessage id='register_page_step_sixteen_desc_8' /></p>
                </a>
                <p onClick={() => dispatch(openOverlayer(REGISTER_DOCUMENTS_OVERLAYER_NAME))}>
                    <FaInfoCircle style={marginRight(5)} size={18} />
                    <FormattedMessage id='register_page_step_sixteen_desc_9' />
                </p>
            </>
            <>
                <FileUpload
                    titleLabel={<FormattedMessage id='register_page_step_sixteen_item' />}
                    isInvalid={!inputs[LEGAL_FILES_FIELD].isValid}
                    errorMessage={errorMessage}
                    loader={loader}
                    handleFileChange={(e) => {
                        dispatch(setRegisterStepFileValue(REGISTER_STEP_16, LEGAL_FILES_FIELD, e.target.files));
                        onFilesUpload(e.target.files, setErrorMessage);
                    }}
                    multiple
                />
                <RegisterDocumentsOverlayer />
            </>
        </RegisterStep>
    );
};

export default RegisterStep16;
