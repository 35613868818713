import { CLOSE_OVERLAYER, OPEN_OVERLAYER } from '../actions/overlayer';

const INITIAL_STATE = {
    isOpen: false,
};

const overlayerReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case OPEN_OVERLAYER:
            return { ...state, isOpen: true };
        case CLOSE_OVERLAYER:
            return { ...state, isOpen: false };
        default:
            return state;
    }
};

export default overlayerReducer;
