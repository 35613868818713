import { BACKGROUND_GRAY_COLOR, WHITE } from '../../core/utils/colors';

export const iframeContainer = {
    backgroundColor: WHITE,
    padding: 20,
    textAlign: 'center',
    border: '1px solid #ccc',
    borderRadius: 10,
    width: '100%',
};

export const titleFormat = {
    fontWeight: 'bold',
    fontSize: 40,
    lineHeight: 1.2,
    marginBottom: '1.5rem',
};

export const contentTitleFormat = {
    fontWeight: 'bold',
    fontSize: 20,
};

export const productCheckboxContainer = {
    padding: 0,
    paddingTop: 10,
    paddingLeft: 10,
    paddingBottom: 10,
};

export const checkboxContainer = {
    border: '1px solid #adadad',
    borderRadius: 5,
    padding: 10,
    width: '48%',
    marginBottom: 15,
    cursor: 'pointer',
};

export const uploadFileItem = {
    paddingTop: 25,
    paddingBottom: 25,
    paddingLeft: 35,
    paddingRight: 35,
    border: '1px solid #ccc',
    borderWidth: 1,
    borderRadius: 5,
    marginBottom: 15,
};

export const fullPageContentContainer = {
    marginTop: 30,
    padding: 30,
    width: '100%',
    backgroundColor: WHITE,
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
};

export const finalStepContainer = {
    backgroundColor: BACKGROUND_GRAY_COLOR,
    padding: 50,
    border: '1px solid #ccc',
    borderRadius: 10,
    marginBottom: 50,
    marginTop: 80,
};

export const finalStepTitle = {
    fontSize: 40,
    textAlign: 'center',
    fontWeight: 'bold',
};

export const finalStepAction = {
    backgroundColor: WHITE,
    textDecoration: 'none',
    color: 'inherit',
    border: '1px solid #ccc',
    borderRadius: 10,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    height: 150,
    marginRight: 30,
};
