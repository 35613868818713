import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import RegisterStep1 from '../components/steps/RegisterStep1';
import RegisterStep2 from '../components/steps/RegisterStep2';
import RegisterStep3 from '../components/steps/RegisterStep3';
import RegisterStep4 from '../components/steps/RegisterStep4';
import RegisterStep5 from '../components/steps/RegisterStep5';
import RegisterStep6 from '../components/steps/RegisterStep6';
import RegisterStep7 from '../components/steps/RegisterStep7';
import RegisterStep8 from '../components/steps/RegisterStep8';
import RegisterStep9 from '../components/steps/RegisterStep9';
import RegisterStep10 from '../components/steps/RegisterStep10';
import RegisterStep11 from '../components/steps/RegisterStep11';
import RegisterStep12 from '../components/steps/RegisterStep12';
import RegisterStep13 from '../components/steps/RegisterStep13';
import RegisterStep14 from '../components/steps/RegisterStep14';
import RegisterStep15 from '../components/steps/RegisterStep15';
import RegisterStep16 from '../components/steps/RegisterStep16';
import RegisterStep17 from '../components/steps/RegisterStep17';
import getQueryParam, { appendQueryParam, generateRandomString, removeQueryParam } from '../../core/utils/url';
import getRegisterCommand from '../../core/websocket/commands';
import { selectRegisterStep } from '../selectors/register';
import { setRegisterStep } from '../actions/register';
import { sendMessage } from '../../core/websocket/engine';
import { getCountriesCommand, getNewGuidCommand, getNewStepCommand } from '../decorators/payloads';
import { getFlow, getTrackingId, removeTrackingId, saveTrackingId } from '../utils/ocr-storage';
import { setFlow } from '../actions/ocr';
import { getCurrentStep, getGuid, hasCountries, saveGuid } from '../utils/fields-storage';
import {
    REGISTER_STEP_1, REGISTER_STEP_2, REGISTER_STEP_3, REGISTER_STEP_4, REGISTER_STEP_5, REGISTER_STEP_6, REGISTER_STEP_7,
    REGISTER_STEP_8, REGISTER_STEP_9, REGISTER_STEP_10, REGISTER_STEP_11, REGISTER_STEP_12, REGISTER_STEP_13,
    REGISTER_STEP_14, REGISTER_STEP_15, REGISTER_STEP_16, REGISTER_STEP_17, getStepFromName, REGISTER_STEP_LIVENESS,
} from '../utils/register-steps';

const RegisterPage = () => {
    const dispatch = useDispatch();
    const step = useSelector(selectRegisterStep);

    useEffect(() => {
        if (getQueryParam(window.location.href, 'new')) {
            localStorage.clear();
            removeQueryParam('new');
            return;
        }

        const savedFlow = getFlow();
        if (savedFlow) {
            dispatch(setFlow(savedFlow));
        }

        const trackingId = getTrackingId();
        const urlTrackingId = getQueryParam(window.location.href, 'trackingId');
        if (urlTrackingId) {
            if (!trackingId) {
                saveTrackingId(urlTrackingId);
            } else if (trackingId && urlTrackingId !== trackingId) {
                removeTrackingId();
                saveTrackingId(urlTrackingId);
            }
        } else if (trackingId) {
            appendQueryParam('trackingId', trackingId);
        } else {
            const randomTrackingId = generateRandomString(10);
            saveTrackingId(randomTrackingId);
            appendQueryParam('trackingId', randomTrackingId);
        }

        setTimeout(() => {
            if (!hasCountries()) {
                sendMessage(getCountriesCommand(), dispatch);
            }
            const url = window.location.href;
            const guidUrlParam = getQueryParam(url, 'guid');
            if (guidUrlParam) {
                saveGuid(guidUrlParam);
                sendMessage(getRegisterCommand({ guid: guidUrlParam }), dispatch);
            } else {
                const savedGuid = getGuid();
                if (!savedGuid) {
                    sendMessage(getNewGuidCommand(), dispatch);
                }
            }
        }, 1000);

        // setTimeout(() => {
        //     if (getQueryParam(window.location.href, 'files')) {
        //         dispatch(setRegisterStep(REGISTER_STEP_FILES));
        //     }
        // }, 1);
    }, []);

    useEffect(() => {
        const currentStep = getCurrentStep();
        if (currentStep) {
            dispatch(setRegisterStep(getStepFromName(currentStep)));
        }

        const intervalId = setInterval(() => {
            sendMessage(getNewStepCommand(), dispatch);
        }, 2000);

        return () => clearInterval(intervalId);
    }, []);

    const goToNextStep = () => {
        dispatch(setRegisterStep(step + 1));
    };

    const goToPrevStep = () => {
        dispatch(setRegisterStep(step - 1));
    };

    const goToStep = (value) => () => {
        dispatch(setRegisterStep(value));
    };

    const displayRegisterStep = (currentStep) => {
        switch (currentStep) {
            case REGISTER_STEP_2:
                return <RegisterStep2 goToNextStep={goToNextStep} goToPrevStep={goToPrevStep} goToStep={goToStep} />;
            case REGISTER_STEP_3:
                return <RegisterStep3 goToNextStep={goToNextStep} goToStep={goToStep} />;
            case REGISTER_STEP_4:
                return <RegisterStep4 goToStep={goToStep} goToNextStep={goToNextStep} goToPrevStep={goToStep(REGISTER_STEP_2)} />;
            case REGISTER_STEP_5:
                return <RegisterStep5 goToNextStep={goToNextStep} goToStep={goToStep} goToPrevStep={goToPrevStep} />;
            case REGISTER_STEP_6:
                return <RegisterStep6 goToNextStep={goToNextStep} goToPrevStep={goToPrevStep} />;
            case REGISTER_STEP_LIVENESS:
                return <RegisterStep4 liveness goToStep={goToStep} goToNextStep={goToNextStep} goToPrevStep={goToPrevStep} />;
            case REGISTER_STEP_7:
                return <RegisterStep7 goToNextStep={goToNextStep} goToPrevStep={goToPrevStep} />;
            case REGISTER_STEP_8:
                return <RegisterStep8 goToNextStep={goToNextStep} goToPrevStep={goToPrevStep} />;
            case REGISTER_STEP_9:
                return <RegisterStep9 goToNextStep={goToNextStep} goToPrevStep={goToPrevStep} />;
            case REGISTER_STEP_10:
                return <RegisterStep10 goToNextStep={goToNextStep} goToPrevStep={goToPrevStep} />;
            case REGISTER_STEP_11:
                return <RegisterStep11 goToNextStep={goToNextStep} goToPrevStep={goToPrevStep} />;
            case REGISTER_STEP_12:
                return <RegisterStep12 goToNextStep={goToNextStep} goToPrevStep={goToPrevStep} />;
            case REGISTER_STEP_13:
                return <RegisterStep13 goToNextStep={goToNextStep} goToPrevStep={goToPrevStep} />;
            case REGISTER_STEP_14:
                return <RegisterStep14 goToNextStep={goToNextStep} goToPrevStep={goToPrevStep} />;
            case REGISTER_STEP_15:
                return <RegisterStep15 goToNextStep={goToNextStep} goToPrevStep={goToPrevStep} />;
            case REGISTER_STEP_16:
                return <RegisterStep16 goToNextStep={goToNextStep} goToPrevStep={goToStep(REGISTER_STEP_2)} />;
            case REGISTER_STEP_17:
                return <RegisterStep17 goToNextStep={goToStep(REGISTER_STEP_15)} goToPrevStep={goToPrevStep} />;
            // case REGISTER_STEP_FILES:
            //     return <RegisterStepFiles goToNextStep={goToStep(REGISTER_STEP_15)} goToPrevStep={goToPrevStep} />;
            case REGISTER_STEP_1:
            default:
                return <RegisterStep1 goToNextStep={goToNextStep} />;
        }
    };

    return (
        <div className="container">
            {displayRegisterStep(step)}
        </div>
    );
};

export default RegisterPage;
