import React from 'react';
import { FormattedMessage } from 'react-intl';

import RadioWithTwoOptions from './core/RadioWithTwoOptions';
import Select from './core/Select';
import TextInput from './core/TextInput';
import { setRegisterStepFieldsVisibility, setRegisterStepFieldValue } from '../../actions/register';
import { getCountries } from '../../utils/fields-storage';
import { REGISTER_STEP_6 } from '../../utils/register-steps';
import {
    ADDRESS_DIFFER_FIELD, CITIZENSHIP_FIELD, NATIONALITY_FIELD, ORIGIN_FIELD, RESIDENCY_ADDRESS_FIELD, RESIDENCY_CITY_FIELD,
    RESIDENCY_COUNTRY_FIELD, RESIDENCY_COUNTY_FIELD, RESIDENCY_NOTIFICATION_FIELD,
} from '../../reducers/features/form-structures';
import { fontSize } from '../../../core/styles/generic.styles';

export const ADDRESS_DIFFER = 1;
export const ADDRESS_SAME = 0;
export const NOTIFICATION_DOMICILE = 2;
export const NOTIFICATION_RESIDENCY = 3;

const RegisterLocationForm = ({ dispatch, inputs }) => (
    <div>
        <Select
            formLabel={<FormattedMessage id='register_page_step_six_citizenship' />}
            formControlId={inputs[CITIZENSHIP_FIELD].name}
            selectedValue={inputs[CITIZENSHIP_FIELD].value}
            isInvalid={!inputs[CITIZENSHIP_FIELD].isValid}
            onSelectedValueChange={(e) => (
                dispatch(setRegisterStepFieldValue(REGISTER_STEP_6, CITIZENSHIP_FIELD, e.target.selectedOptions[0].id))
            )}
            options={getCountries()}
        />
        <Select
            formLabel={<FormattedMessage id='register_page_step_six_nationality' />}
            formControlId={inputs[NATIONALITY_FIELD].name}
            isInvalid={!inputs[NATIONALITY_FIELD].isValid}
            selectedValue={inputs[NATIONALITY_FIELD].value}
            onSelectedValueChange={(e) => (
                dispatch(setRegisterStepFieldValue(REGISTER_STEP_6, NATIONALITY_FIELD, e.target.selectedOptions[0].id))
            )}
            options={getCountries()}
        />
        <Select
            formLabel={<FormattedMessage id='register_page_step_six_origin' />}
            formControlId={inputs[ORIGIN_FIELD].name}
            selectedValue={inputs[ORIGIN_FIELD].value}
            isInvalid={!inputs[ORIGIN_FIELD].isValid}
            onSelectedValueChange={(e) => (
                dispatch(setRegisterStepFieldValue(REGISTER_STEP_6, ORIGIN_FIELD, e.target.selectedOptions[0].id))
            )}
            options={getCountries()}
        />

        <RadioWithTwoOptions
            formName={inputs[ADDRESS_DIFFER_FIELD].name}
            formLabel={<FormattedMessage id='register_page_step_six_does_address_differ' />}
            formInvalid={!inputs[ADDRESS_DIFFER_FIELD].isValid}
            checkedOption={inputs[ADDRESS_DIFFER_FIELD].value}
            firstOptionId={ADDRESS_SAME} firstOptionValue={ADDRESS_SAME} firstOptionLabel={<FormattedMessage id='no' />}
            secondOptionId={ADDRESS_DIFFER} secondOptionValue={ADDRESS_DIFFER} secondOptionLabel={<FormattedMessage id='yes' />}
            onFirstOptionChange={() => {
                dispatch(setRegisterStepFieldValue(REGISTER_STEP_6, ADDRESS_DIFFER_FIELD, ADDRESS_SAME));
                dispatch(
                    setRegisterStepFieldsVisibility(
                        REGISTER_STEP_6,
                        [
                            RESIDENCY_ADDRESS_FIELD, RESIDENCY_CITY_FIELD, RESIDENCY_COUNTY_FIELD,
                            RESIDENCY_COUNTRY_FIELD, RESIDENCY_NOTIFICATION_FIELD,
                        ],
                        false,
                    ),
                );
            }}
            onSecondOptionChange={() => {
                dispatch(setRegisterStepFieldValue(REGISTER_STEP_6, ADDRESS_DIFFER_FIELD, ADDRESS_DIFFER));
                dispatch(
                    setRegisterStepFieldsVisibility(
                        REGISTER_STEP_6,
                        [
                            RESIDENCY_ADDRESS_FIELD, RESIDENCY_CITY_FIELD, RESIDENCY_COUNTY_FIELD,
                            RESIDENCY_COUNTRY_FIELD, RESIDENCY_NOTIFICATION_FIELD,
                        ],
                        true,
                    ),
                );
            }}
        />

        {inputs[RESIDENCY_ADDRESS_FIELD].isVisible
                && <div>
                    <TextInput
                        label={<FormattedMessage id='register_page_step_six_address_res' />}
                        controlId={inputs[RESIDENCY_ADDRESS_FIELD].name}
                        value={inputs[RESIDENCY_ADDRESS_FIELD].value}
                        isInvalid={!inputs[RESIDENCY_ADDRESS_FIELD].isValid}
                        onInputChange={(e) => (
                            dispatch(setRegisterStepFieldValue(REGISTER_STEP_6, RESIDENCY_ADDRESS_FIELD, e.target.value))
                        )}
                    />
                    <TextInput
                        label={<FormattedMessage id='register_page_step_five_city' />}
                        controlId={inputs[RESIDENCY_CITY_FIELD].name}
                        value={inputs[RESIDENCY_CITY_FIELD].value}
                        isInvalid={!inputs[RESIDENCY_CITY_FIELD].isValid}
                        onInputChange={(e) => (
                            dispatch(setRegisterStepFieldValue(REGISTER_STEP_6, RESIDENCY_CITY_FIELD, e.target.value))
                        )}
                    />
                    <TextInput
                        label={<FormattedMessage id='register_page_step_five_county' />}
                        controlId={inputs[RESIDENCY_COUNTY_FIELD].name}
                        value={inputs[RESIDENCY_COUNTY_FIELD].value}
                        isInvalid={!inputs[RESIDENCY_COUNTY_FIELD].isValid}
                        onInputChange={(e) => (
                            dispatch(setRegisterStepFieldValue(REGISTER_STEP_6, RESIDENCY_COUNTY_FIELD, e.target.value))
                        )}
                    />
                    <Select
                        formLabel={<FormattedMessage id='register_page_step_five_country' />}
                        formControlId={inputs[RESIDENCY_COUNTRY_FIELD].name}
                        isInvalid={!inputs[RESIDENCY_COUNTRY_FIELD].isValid}
                        selectedValue={inputs[RESIDENCY_COUNTRY_FIELD].value}
                        onSelectedValueChange={(e) => (
                            dispatch(
                                setRegisterStepFieldValue(REGISTER_STEP_6, RESIDENCY_COUNTRY_FIELD, e.target.selectedOptions[0].id),
                            )
                        )}
                        options={getCountries()}
                    />
                    <RadioWithTwoOptions
                        formName='residencyNotifications'
                        formLabel={<FormattedMessage id='register_page_step_six_notif' />}
                        checkedOption={inputs[RESIDENCY_NOTIFICATION_FIELD].value}
                        formInvalid={!inputs[RESIDENCY_NOTIFICATION_FIELD].isValid}
                        firstOptionId={NOTIFICATION_DOMICILE} firstOptionValue={NOTIFICATION_DOMICILE}
                        firstOptionLabel={<FormattedMessage id='register_page_step_six_home_address' />}
                        onFirstOptionChange={() => (
                            dispatch(setRegisterStepFieldValue(REGISTER_STEP_6, RESIDENCY_NOTIFICATION_FIELD, NOTIFICATION_DOMICILE))
                        )}
                        secondOptionValue={NOTIFICATION_RESIDENCY} secondOptionId={NOTIFICATION_RESIDENCY}
                        secondOptionLabel={<FormattedMessage id='register_page_step_six_address_res' />}
                        onSecondOptionChange={() => (
                            dispatch(setRegisterStepFieldValue(REGISTER_STEP_6, RESIDENCY_NOTIFICATION_FIELD, NOTIFICATION_RESIDENCY))
                        )}
                    />
                </div>
        }

        <p style={fontSize(14)}>
            <FormattedMessage id='register_page_step_six_footer' />
            <a target='_blank' href='https://mbl.tradeville.ro/Documents/Home/Contracte/Procura_TDV.pdf' rel="noreferrer">
                <FormattedMessage id='register_page_step_six_footer_action' />
            </a>
        </p>
    </div>
);

export default RegisterLocationForm;
