import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import selectIsOverlayerOpened from '../../../core/selectors/overlayer';
import { closeOverlayer } from '../../../core/actions/overlayer';

export const REGISTER_COMPENSABLE_INFO_OVERLAYER_NAME = 'REGISTER_COMPENSABLE_INFO';

const RegisterCompensableInfoOverlayer = () => {
    const dispatch = useDispatch();
    const isOpen = useSelector((state) => selectIsOverlayerOpened(state, REGISTER_COMPENSABLE_INFO_OVERLAYER_NAME));
    const onClose = () => dispatch(closeOverlayer(REGISTER_COMPENSABLE_INFO_OVERLAYER_NAME));

    return (
        <Modal show={isOpen} onHide={onClose} size={'lg'}>
            <Modal.Header closeButton />
            <Modal.Body>
                <p><FormattedMessage id="register_overlayer_compensable_text_1"/></p>
                <p><FormattedMessage id="register_overlayer_compensable_text_2"/></p>
                <p><FormattedMessage id="register_overlayer_compensable_text_3"/></p>
                <p><FormattedMessage id="register_overlayer_compensable_text_4"/></p>
                <p><FormattedMessage id="register_overlayer_compensable_text_5"/></p>
                <p><FormattedMessage id="register_overlayer_compensable_text_6"/></p>
            </Modal.Body>
        </Modal>
    );
};
export default RegisterCompensableInfoOverlayer;
