let currentStream = null;
let frontCameraStream = null;
let rearCameraStream = null;

export const requestPermission = async () => {
    try {
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            if (navigator.mediaDevices.enumerateDevices) {
                const devices = await navigator.mediaDevices.enumerateDevices();
                const videoDevices = devices.filter((device) => device.kind === 'videoinput');

                const frontCameraDevice = videoDevices.find((device) => device.label.toLowerCase().includes('front'));
                const rearCameraDevice = videoDevices.find((device) => device.label.toLowerCase().includes('back') || !device.label.toLowerCase().includes('front'));
                if (frontCameraDevice) {
                    frontCameraStream = await navigator.mediaDevices.getUserMedia({ video: { deviceId: frontCameraDevice.deviceId } });
                }
                if (rearCameraDevice) {
                    rearCameraStream = await navigator.mediaDevices.getUserMedia({ video: { deviceId: rearCameraDevice.deviceId } });
                }
            }
            if (!rearCameraStream && !frontCameraStream) {
                navigator.mediaDevices
                    .getUserMedia({ video: true })
                    .then((stream) => {
                        currentStream = stream;
                    })
                    .catch(() => {});
            }
        }
    } catch (e) { /* empty */ }
};

export const disableCamera = () => {
    if (currentStream) {
        currentStream.getTracks().forEach((track) => track.stop());
        currentStream = null;
    }
    if (frontCameraStream) {
        frontCameraStream.getTracks().forEach((track) => track.stop());
        frontCameraStream = null;
    }
    if (rearCameraStream) {
        rearCameraStream.getTracks().forEach((track) => track.stop());
        rearCameraStream = null;
    }
};
