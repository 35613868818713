import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import RegisterStep from '../RegisterStep';
import FileUpload from '../forms/core/FileUpload';
import validateStep from '../../validators/step-validator';
import usePageTitle from '../../../core/hoc/use-page-title';
import { selectRegisterStepFormData, selectUploadedFiles } from '../../selectors/register';
import { sendMessage } from '../../../core/websocket/engine';
import { getCurrentStepCommand, getStep14Command } from '../../decorators/payloads';
import { saveCurrentStep } from '../../utils/fields-storage';
import {
    getUploadedFiles, resetRegisterStepValidity, setRegisterStepFieldValidity, setRegisterStepFileValue, setUploadedFiles, uploadFile,
} from '../../actions/register';
import { getFlow } from '../../utils/ocr-storage';
import { NEW_OCR_FLOW } from '../../services/ocr-flow-decider';
import { REGISTER_STEP_17_NAME, REGISTER_STEP_17 } from '../../utils/register-steps';
import {
    BANK_FILE_FIELD, BANK_JURIDIC_FILENAME, BENEFICIARY_FILE_FIELD, BENEFICIARY_FILENAME, CONSTITUTIVE_FILE_FIELD,
    CONSTITUTIVE_FILENAME, CUI_FILE_FIELD, CUI_FILENAME, LEI_CODE_FILE_FIELD, LEI_CODE_FILENAME, ONRC_FILE_FIELD,
    ONRC_FILENAME, OTHER_FILES_FIELD,
} from '../../reducers/features/form-structures';
import { boldFont } from '../../../core/styles/generic.styles';

const RegisterStep17 = ({ goToNextStep, goToPrevStep }) => {
    const [errorMessage1, setErrorMessage1] = useState(null);
    const [errorMessage2, setErrorMessage2] = useState(null);
    const [errorMessage3, setErrorMessage3] = useState(null);
    const [errorMessage4, setErrorMessage4] = useState(null);
    const [errorMessage5, setErrorMessage5] = useState(null);
    const [errorMessage6, setErrorMessage6] = useState(null);
    const [errorMessage7, setErrorMessage7] = useState(null);
    const [loader1, setLoader1] = useState(false);
    const [loader2, setLoader2] = useState(false);
    const [loader3, setLoader3] = useState(false);
    const [loader4, setLoader4] = useState(false);
    const [loader5, setLoader5] = useState(false);
    const [loader6, setLoader6] = useState(false);
    const [loader7, setLoader7] = useState(false);

    const dispatch = useDispatch();
    const inputs = useSelector((state) => selectRegisterStepFormData(state, REGISTER_STEP_17));
    const uploadedFiles = useSelector(selectUploadedFiles);

    const flow = getFlow();
    const version = flow === NEW_OCR_FLOW ? 'v2' : 'v1';
    usePageTitle(`Deschidere cont - Pas 17 | ${version}`);

    useEffect(() => {
        saveCurrentStep(REGISTER_STEP_17_NAME);
        sendMessage(getCurrentStepCommand(REGISTER_STEP_17_NAME), dispatch);
        dispatch(getUploadedFiles());
    }, []);

    const onSubmit = () => {
        if (loader1) {
            setErrorMessage1('Te rugam asteapta pana se incarca documentele.');
            return;
        }
        if (loader2) {
            setErrorMessage2('Te rugam asteapta pana se incarca documentele.');
            return;
        }
        if (loader3) {
            setErrorMessage3('Te rugam asteapta pana se incarca documentele.');
            return;
        }
        if (loader4) {
            setErrorMessage4('Te rugam asteapta pana se incarca documentele.');
            return;
        }
        if (loader5) {
            setErrorMessage5('Te rugam asteapta pana se incarca documentele.');
            return;
        }
        if (loader6) {
            setErrorMessage6('Te rugam asteapta pana se incarca documentele.');
            return;
        }
        if (loader7) {
            setErrorMessage7('Te rugam asteapta pana se incarca documentele.');
            return;
        }

        const hasAlreadyAllFiles = () => (
            uploadedFiles.some((file) => file.includes(CUI_FILENAME))
            && uploadedFiles.some((file) => file.includes(BANK_JURIDIC_FILENAME))
            && uploadedFiles.some((file) => file.includes(CONSTITUTIVE_FILENAME))
            && uploadedFiles.some((file) => file.includes(ONRC_FILENAME))
            && uploadedFiles.some((file) => file.includes(BENEFICIARY_FILENAME))
            && uploadedFiles.some((file) => file.includes(LEI_CODE_FILENAME))
        );

        const hasNoErrorMessages = () => (
            !errorMessage1 && !errorMessage2 && !errorMessage3 && !errorMessage4 && !errorMessage5 && !errorMessage6 && !errorMessage7
        );

        const isValid = validateStep(dispatch, REGISTER_STEP_17, inputs);
        if ((isValid || hasAlreadyAllFiles()) && hasNoErrorMessages()) {
            sendMessage(getStep14Command(), dispatch);
            goToNextStep();
        }
    };

    const onBack = () => {
        dispatch(resetRegisterStepValidity(REGISTER_STEP_17));
        goToPrevStep();
    };

    const validatorCallback = (fileField) => (isValid) => (
        dispatch(setRegisterStepFieldValidity(REGISTER_STEP_17, fileField, isValid))
    );

    const onFileUpload = (file, setError, setLoader, validator, fileName = null) => {
        if (file) {
            const onSuccessCallback = () => dispatch(setUploadedFiles([...uploadedFiles, fileName]));
            dispatch(uploadFile(file, setError, setLoader, validator, fileName, onSuccessCallback, dispatch));
        }
    };

    const onFilesUpload = (files, setError, setLoader, validator, fileName = null) => {
        [...files].forEach((file) => {
            dispatch(uploadFile(file, setError, setLoader, validator, fileName, () => {}, dispatch));
        });
    };

    return (
        <RegisterStep
            title={<FormattedMessage id='register_page_step_seventeen_title' />}
            formTitle={<FormattedMessage id='register_page_step_seventeen_form_title' />}
            formSubtitle={<></>}
            prevBtnText={<FormattedMessage id='prev' />}
            nextBtnText={<FormattedMessage id='next' />}
            nextBtnDisabled={loader1 || loader2 || loader3 || loader4 || loader5 || loader6 || loader7}
            onGoNextClick={onSubmit}
            onGoPrevClick={onBack}
        >
            <>
                <p style={boldFont}><FormattedMessage id='register_page_step_seventeen_desc_1' /></p>
                <ol>
                    <li><FormattedMessage id='register_page_step_seventeen_desc_2' /></li>
                    <li><FormattedMessage id='register_page_step_seventeen_desc_3' /></li>
                    <li><FormattedMessage id='register_page_step_seventeen_desc_4' /></li>
                    <li><FormattedMessage id='register_page_step_seventeen_desc_5' /></li>
                    <li><FormattedMessage id='register_page_step_seventeen_desc_6' /></li>
                    <li><FormattedMessage id='register_page_step_seventeen_desc_7' /></li>
                </ol>
                <FormattedMessage id='register_page_step_seventeen_desc_8' />
                <ul className='mt-3'>
                    <li><FormattedMessage id='register_page_step_seventeen_desc_9' /></li>
                    <li><FormattedMessage id='register_page_step_seventeen_desc_10' /></li>
                </ul>
            </>
            <>
                <FileUpload
                    titleLabel={<FormattedMessage id='register_page_step_seventeen_item_1' />}
                    isInvalid={!uploadedFiles.some((file) => file.includes(CUI_FILENAME)) && !inputs[CUI_FILE_FIELD].isValid}
                    errorMessage={errorMessage1}
                    successMessage={uploadedFiles.some((file) => file.includes(CUI_FILENAME)) && CUI_FILENAME}
                    loader={loader1}
                    handleFileChange={(e) => {
                        dispatch(setRegisterStepFileValue(REGISTER_STEP_17, CUI_FILE_FIELD, e.target.files[0]));
                        onFileUpload(e.target.files[0], setErrorMessage1, setLoader1, validatorCallback(CUI_FILE_FIELD), CUI_FILENAME);
                    }}
                />
                <FileUpload
                    titleLabel={<FormattedMessage id='register_page_step_seventeen_item_2' />}
                    isInvalid={!uploadedFiles.some((file) => file.includes(BANK_JURIDIC_FILENAME)) && !inputs[BANK_FILE_FIELD].isValid}
                    errorMessage={errorMessage2}
                    successMessage={uploadedFiles.some((file) => file.includes(BANK_JURIDIC_FILENAME)) && BANK_JURIDIC_FILENAME}
                    loader={loader2}
                    handleFileChange={(e) => {
                        dispatch(setRegisterStepFileValue(REGISTER_STEP_17, BANK_FILE_FIELD, e.target.files[0]));
                        onFileUpload(
                            e.target.files[0],
                            setErrorMessage2,
                            setLoader2,
                            validatorCallback(BANK_FILE_FIELD),
                            BANK_JURIDIC_FILENAME,
                        );
                    }}
                />
                <FileUpload
                    titleLabel={<FormattedMessage id='register_page_step_seventeen_item_3' />}
                    isInvalid={!uploadedFiles.some((file) => file.includes(CONSTITUTIVE_FILENAME))
                        && !inputs[CONSTITUTIVE_FILE_FIELD].isValid}
                    errorMessage={errorMessage3}
                    successMessage={uploadedFiles.some((file) => file.includes(CONSTITUTIVE_FILENAME)) && CONSTITUTIVE_FILENAME}
                    loader={loader3}
                    handleFileChange={(e) => {
                        dispatch(setRegisterStepFileValue(REGISTER_STEP_17, CONSTITUTIVE_FILE_FIELD, e.target.files[0]));
                        onFileUpload(
                            e.target.files[0],
                            setErrorMessage3,
                            setLoader3,
                            validatorCallback(CONSTITUTIVE_FILE_FIELD),
                            CONSTITUTIVE_FILENAME,
                        );
                    }}
                />
                <FileUpload
                    titleLabel={<FormattedMessage id='register_page_step_seventeen_item_4' />}
                    isInvalid={!uploadedFiles.some((file) => file.includes(ONRC_FILENAME)) && !inputs[ONRC_FILE_FIELD].isValid}
                    errorMessage={errorMessage4}
                    successMessage={uploadedFiles.some((file) => file.includes(ONRC_FILENAME)) && ONRC_FILENAME}
                    loader={loader4}
                    handleFileChange={(e) => {
                        dispatch(setRegisterStepFileValue(REGISTER_STEP_17, ONRC_FILE_FIELD, e.target.files[0]));
                        onFileUpload(e.target.files[0], setErrorMessage4, setLoader4, validatorCallback(ONRC_FILE_FIELD), ONRC_FILENAME);
                    }}
                />
                <FileUpload
                    titleLabel={<FormattedMessage id='register_page_step_seventeen_item_5' />}
                    isInvalid={!uploadedFiles.some((file) => file.includes(BENEFICIARY_FILENAME))
                        && !inputs[BENEFICIARY_FILE_FIELD].isValid}
                    errorMessage={errorMessage5}
                    successMessage={uploadedFiles.some((file) => file.includes(BENEFICIARY_FILENAME)) && BENEFICIARY_FILENAME}
                    loader={loader5}
                    handleFileChange={(e) => {
                        dispatch(setRegisterStepFileValue(REGISTER_STEP_17, BENEFICIARY_FILE_FIELD, e.target.files[0]));
                        onFileUpload(
                            e.target.files[0],
                            setErrorMessage5,
                            setLoader5,
                            validatorCallback(BENEFICIARY_FILE_FIELD),
                            BENEFICIARY_FILENAME,
                        );
                    }}
                />
                <FileUpload
                    titleLabel={<FormattedMessage id='register_page_step_seventeen_item_6' />}
                    isInvalid={!uploadedFiles.some((file) => file.includes(LEI_CODE_FILENAME)) && !inputs[LEI_CODE_FILE_FIELD].isValid}
                    errorMessage={errorMessage6}
                    successMessage={uploadedFiles.some((file) => file.includes(LEI_CODE_FILENAME)) && LEI_CODE_FILENAME}
                    loader={loader6}
                    handleFileChange={(e) => {
                        dispatch(setRegisterStepFileValue(REGISTER_STEP_17, LEI_CODE_FILE_FIELD, e.target.files[0]));
                        onFileUpload(
                            e.target.files[0],
                            setErrorMessage6,
                            setLoader6,
                            validatorCallback(LEI_CODE_FILE_FIELD),
                            LEI_CODE_FILENAME,
                        );
                    }}
                />
                <FileUpload
                    titleLabel={<FormattedMessage id='register_page_step_seventeen_other' />}
                    isInvalid={!inputs[OTHER_FILES_FIELD].isValid}
                    errorMessage={errorMessage7}
                    loader={loader7}
                    handleFileChange={(e) => {
                        dispatch(setRegisterStepFileValue(REGISTER_STEP_17, OTHER_FILES_FIELD, e.target.files));
                        onFilesUpload(e.target.files, setErrorMessage7, setLoader7, validatorCallback(OTHER_FILES_FIELD));
                    }}
                    multiple
                />
            </>
        </RegisterStep>
    );
};

export default RegisterStep17;
