import React from 'react';
import ReactDOM from 'react-dom';

import Root from './core/containers/Root';
import filterOutTranslationErrors from './core/utils/console-filter';
import './core/services/cookies-bar';

import './index.scss';
import './cookies.scss';

filterOutTranslationErrors();
const runApp = () => {
    ReactDOM.render(<Root />, document.getElementById('root'));
};

runApp();
