import { setRegisterStepFieldErrorMessage, setRegisterStepFieldsValidity, setRegisterStepFieldValidity } from '../actions/register';
import { hasPassedProcess } from '../utils/ocr-storage';
import {
    validateCheckbox, validateRadio, validateText, validateEmail, validateDate, validateSelect, validateCnp,
    validatePhoneNumber, validateFile, validateFiles, validatePassword, validateExpireDate, validateBirthDate,
    validateBankName, validateEmittedDate, validateEmittedDateAndExpireDateCompatibility, validatePasswordMatch,
    validateCheckboxGroup, validateIban,
} from './validators';

import { REGISTER_STEP_14 } from '../utils/register-steps';
import {
    CHECKBOX_MANDATORY, RADIO, TEXT, EMAIL, SELECT, DATE, CNP, PHONE, FILE, FILES, PASSWORD, EXPIRE_DATE, IBAN,
    BIRTH_DATE, BANK, EMITTED_DATE, CHECKBOX_INVESTMENTS_MANDATORY_GROUP, CHECKBOX_PRODUCTS_MANDATORY_GROUP,
} from './types';
import {
    EXPIRE_DATE_FIELD, INVESTMENT_FIELD, PASSWORD_FIELD, REPETEAD_PASSWORD_FIELD, SPECULATIVE_FIELD,
    PRODUCT1_FIELD, PRODUCT2_FIELD, PRODUCT3_FIELD, PRODUCT4_FIELD, PRODUCT5_FIELD, PRODUCT6_FIELD, PRODUCT7_FIELD,
    ID_FILE_FIELD, SELFIE_FILE_FIELD, INTERNATIONAL_PHONE_COUNTRY_FIELD, COUNTRY_FIELD,
} from '../reducers/features/form-structures';

export default (dispatch, step, inputs) => {
    let canGoForward = true;

    Object.values(inputs).map((input) => {
        switch (input.validator) {
            case CHECKBOX_MANDATORY:
                if (input.isVisible === false) {
                    break;
                }

                if (!validateCheckbox(input.value)) {
                    dispatch(setRegisterStepFieldValidity(step, input.name, false));
                    canGoForward = false;
                }
                break;
            case CHECKBOX_INVESTMENTS_MANDATORY_GROUP:
                if (input.isVisible === false) {
                    break;
                }

                if (!validateCheckboxGroup([inputs[SPECULATIVE_FIELD].value, inputs[INVESTMENT_FIELD].value])) {
                    dispatch(setRegisterStepFieldsValidity(step, [inputs[SPECULATIVE_FIELD].name, inputs[INVESTMENT_FIELD].name], false));
                    canGoForward = false;
                }
                break;
            case CHECKBOX_PRODUCTS_MANDATORY_GROUP:
                if (input.isVisible === false) {
                    break;
                }

                // eslint-disable-next-line no-case-declarations
                const products = [
                    inputs[PRODUCT1_FIELD].value, inputs[PRODUCT2_FIELD].value, inputs[PRODUCT3_FIELD].value,
                    inputs[PRODUCT4_FIELD].value, inputs[PRODUCT5_FIELD].value, inputs[PRODUCT6_FIELD].value,
                    inputs[PRODUCT7_FIELD].value,
                ];
                if (!validateCheckboxGroup(products)) {
                    // eslint-disable-next-line no-case-declarations
                    const productsNames = [
                        inputs[PRODUCT1_FIELD].name, inputs[PRODUCT2_FIELD].name, inputs[PRODUCT3_FIELD].name,
                        inputs[PRODUCT4_FIELD].name, inputs[PRODUCT5_FIELD].name, inputs[PRODUCT6_FIELD].name,
                        inputs[PRODUCT7_FIELD].name,
                    ];
                    dispatch(setRegisterStepFieldsValidity(step, productsNames, false));
                    canGoForward = false;
                }
                break;
            case FILE:
                if (input.isVisible === false || input.isMandatory === false) {
                    break;
                }
                if (step === REGISTER_STEP_14 && hasPassedProcess()) {
                    if (input.name === ID_FILE_FIELD || input.name === SELFIE_FILE_FIELD) {
                        break;
                    }
                }

                if (!validateFile(input.value)) {
                    dispatch(setRegisterStepFieldValidity(step, input.name, false));
                    canGoForward = false;
                }
                break;
            case FILES:
                if (input.isVisible === false || input.isMandatory === false) {
                    break;
                }

                if (!validateFiles(input.value)) {
                    dispatch(setRegisterStepFieldValidity(step, input.name, false));
                    canGoForward = false;
                }
                break;
            case CNP:
                if (input.isVisible === false) {
                    break;
                }

                if (inputs[COUNTRY_FIELD].value === 'RO' && !validateCnp(input.value)) {
                    dispatch(setRegisterStepFieldValidity(step, input.name, false));
                    canGoForward = false;
                }
                break;
            case PHONE:
                if (input.isVisible === false || inputs[INTERNATIONAL_PHONE_COUNTRY_FIELD].value) {
                    break;
                }

                if (!validatePhoneNumber(input.value)) {
                    dispatch(setRegisterStepFieldValidity(step, input.name, false));
                    canGoForward = false;
                }
                break;
            case RADIO:
                if (input.isVisible === false) {
                    break;
                }

                if (!validateRadio(input.value)) {
                    dispatch(setRegisterStepFieldValidity(step, input.name, false));
                    canGoForward = false;
                }
                break;
            case TEXT:
                if (input.isVisible === false) {
                    break;
                }
                if (!validateText(input.value)) {
                    dispatch(setRegisterStepFieldValidity(step, input.name, false));
                    canGoForward = false;
                }
                break;
            case EMAIL:
                if (input.isVisible === false) {
                    break;
                }

                if (!validateEmail(input.value)) {
                    dispatch(setRegisterStepFieldValidity(step, input.name, false));
                    canGoForward = false;
                }
                break;
            case BANK:
                if (input.isVisible === false) {
                    break;
                }

                if (!validateBankName(input.value)) {
                    dispatch(setRegisterStepFieldValidity(step, input.name, false));
                    canGoForward = false;
                }
                break;
            case IBAN:
                if (input.isVisible === false) {
                    break;
                }

                if (!validateIban(input.value)) {
                    dispatch(setRegisterStepFieldValidity(step, input.name, false));
                    canGoForward = false;
                }
                break;
            case PASSWORD:
                if (input.isVisible === false) {
                    break;
                }

                if (!validatePassword(input.value)) {
                    dispatch(setRegisterStepFieldValidity(step, input.name, false));
                    dispatch(setRegisterStepFieldErrorMessage(step, input.name, 'register_page_step_eight_password_too_short'));
                    canGoForward = false;
                }
                if (!validatePasswordMatch(inputs[PASSWORD_FIELD].value, inputs[REPETEAD_PASSWORD_FIELD].value)) {
                    dispatch(setRegisterStepFieldValidity(step, input.name, false));
                    dispatch(setRegisterStepFieldErrorMessage(step, input.name, 'register_page_step_eight_passwords_dont_match'));
                    canGoForward = false;
                }
                break;
            case EMITTED_DATE:
                if (input.isVisible === false) {
                    break;
                }
                if (!validateEmittedDate(input.value)
                    || !validateEmittedDateAndExpireDateCompatibility(input.value, inputs[EXPIRE_DATE_FIELD].value)
                ) {
                    dispatch(setRegisterStepFieldValidity(step, input.name, false));
                    canGoForward = false;
                }
                break;
            case EXPIRE_DATE:
                if (input.isVisible === false) {
                    break;
                }

                if (!validateExpireDate(input.value)) {
                    dispatch(setRegisterStepFieldValidity(step, input.name, false));
                    canGoForward = false;
                }
                break;
            case BIRTH_DATE:
                if (input.isVisible === false) {
                    break;
                }

                if (!validateBirthDate(input.value)) {
                    dispatch(setRegisterStepFieldValidity(step, input.name, false));
                    canGoForward = false;
                }
                break;
            case DATE:
                if (input.isVisible === false) {
                    break;
                }

                if (!validateDate(input.value)) {
                    dispatch(setRegisterStepFieldValidity(step, input.name, false));
                    canGoForward = false;
                }
                break;
            case SELECT:
                if (input.isVisible === false) {
                    break;
                }

                if (!validateSelect(input.value)) {
                    dispatch(setRegisterStepFieldValidity(step, input.name, false));
                    canGoForward = false;
                }
                break;
            default:
                return true;
        }

        return true;
    });

    return canGoForward;
};
