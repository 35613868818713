import { useEffect } from 'react';

const usePageTitle = (title, withoutDataLayer = false) => {
    useEffect(() => {
        const prevTitle = document.title;
        document.title = title;
        if (!withoutDataLayer) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'Pagina_virtuala_desc',
                pageTitle: title,
            });
        }

        return () => {
            document.title = prevTitle;
        };
    }, [title]);
};

export default usePageTitle;
