import { uniq } from 'lodash/array';

import {
    WEBSOCKET_CONNECTION_ERROR, WEBSOCKET_CONNECTION_COMPLETED, WEBSOCKET_CONNECTING,
    ADD_RESPONSE_TO_STACK, ADD_COMMAND_TO_STACK, SET_LAST_RESPONSE,
} from '../actions/websocket';

export const INITIAL_UI_STATE = {
    isConnecting: false,
    isConnected: false,
    isError: false,
};

export const websocketUIReducer = (state = INITIAL_UI_STATE, action) => {
    switch (action.type) {
        case WEBSOCKET_CONNECTING:
            return { ...state, isConnecting: true, isConnected: false, isError: false };
        case WEBSOCKET_CONNECTION_COMPLETED:
            return { ...state, isConnecting: false, isConnected: true, isError: false };
        case WEBSOCKET_CONNECTION_ERROR:
            return { ...state, isConnecting: false, isConnected: false, isError: true };
        default:
            return state;
    }
};

export const INITIAL_DATA_STATE = {
    commandsStack: [],
    responsesStack: [],
    lastResponse: null,
};

export const websocketDataReducer = (state = INITIAL_DATA_STATE, action) => {
    switch (action.type) {
        case ADD_RESPONSE_TO_STACK:
            return { ...state, responsesStack: uniq([...state.responsesStack, { ...action.payload }]) };
        case ADD_COMMAND_TO_STACK:
            return { ...state, commandsStack: uniq([...state.commandsStack, { ...action.payload }]) };
        case SET_LAST_RESPONSE:
            return { ...state, lastResponse: { ...action.payload } };
        default:
            return state;
    }
};
