import React from 'react';
import { FormattedMessage } from 'react-intl';

import TwoCheckboxesRow from './core/TwoCheckboxesRow';
import Select from './core/Select';
import { setRegisterStepFieldsValidity, setRegisterStepFieldValue } from '../../actions/register';
import { ANNUAL_INCOME_VALUES, EDUCATION_LEVEL_VALUES, FUNDS_SOURCE_VALUES } from '../../utils/forms-options';
import {
    ANNUAL_INCOME_FIELD, INVESTMENT_FIELD, SPECULATIVE_FIELD, EDUCATION_LEVEL_FIELD, FUNDS_SOURCE_FIELD,
} from '../../reducers/features/form-structures';
import { REGISTER_STEP_11 } from '../../utils/register-steps';

const RegisterFinancialForm = ({ dispatch, inputs }) => (
    <>
        <TwoCheckboxesRow
            label={<FormattedMessage id='register_page_step_eleven_purpose' />} formControlId='purpose'
            firstInputControlId={inputs[SPECULATIVE_FIELD].name}
            firstInputValue={inputs[SPECULATIVE_FIELD].value}
            firstInputIsInvalid={!inputs[SPECULATIVE_FIELD].isValid}
            firstInputLabel={<FormattedMessage id='register_page_step_eleven_purpose_speculative' />}
            onFirstInputChange={(e) => {
                dispatch(setRegisterStepFieldValue(REGISTER_STEP_11, SPECULATIVE_FIELD, e.target.checked));
                dispatch(setRegisterStepFieldsValidity(REGISTER_STEP_11, [SPECULATIVE_FIELD, INVESTMENT_FIELD], true));
            }}
            secondInputValue={inputs[INVESTMENT_FIELD].value}
            secondInputControlId={inputs[INVESTMENT_FIELD].name}
            secondInputIsInvalid={!inputs[INVESTMENT_FIELD].isValid}
            secondInputLabel={<FormattedMessage id='register_page_step_eleven_purpose_investment' />}
            onSecondInputChange={(e) => {
                dispatch(setRegisterStepFieldValue(REGISTER_STEP_11, INVESTMENT_FIELD, e.target.checked));
                dispatch(setRegisterStepFieldsValidity(REGISTER_STEP_11, [SPECULATIVE_FIELD, INVESTMENT_FIELD], true));
            }}
        />
        <Select
            formLabel={<FormattedMessage id='register_page_step_eleven_education_level' />}
            formControlId={inputs[EDUCATION_LEVEL_FIELD].name}
            isInvalid={!inputs[EDUCATION_LEVEL_FIELD].isValid}
            selectedValue={inputs[EDUCATION_LEVEL_FIELD].value}
            options={EDUCATION_LEVEL_VALUES}
            onSelectedValueChange={(e) => (
                dispatch(setRegisterStepFieldValue(REGISTER_STEP_11, EDUCATION_LEVEL_FIELD, e.target.selectedOptions[0].id))
            )}
        />
        <Select
            formLabel={<FormattedMessage id='register_page_step_eleven_annual_income' />}
            formControlId={inputs[ANNUAL_INCOME_FIELD].name}
            isInvalid={!inputs[ANNUAL_INCOME_FIELD].isValid}
            selectedValue={inputs[ANNUAL_INCOME_FIELD].value}
            options={ANNUAL_INCOME_VALUES}
            onSelectedValueChange={(e) => (
                dispatch(setRegisterStepFieldValue(REGISTER_STEP_11, ANNUAL_INCOME_FIELD, e.target.selectedOptions[0].id))
            )}
        />
        <Select
            formLabel={<FormattedMessage id='register_page_step_eleven_funds_source' />}
            formControlId={inputs[FUNDS_SOURCE_FIELD].name}
            selectedValue={inputs[FUNDS_SOURCE_FIELD].value}
            isInvalid={!inputs[FUNDS_SOURCE_FIELD].isValid}
            options={FUNDS_SOURCE_VALUES}
            onSelectedValueChange={(e) => (
                dispatch(setRegisterStepFieldValue(REGISTER_STEP_11, FUNDS_SOURCE_FIELD, e.target.selectedOptions[0].value))
            )}
        />
    </>
);

export default RegisterFinancialForm;
