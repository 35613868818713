import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { BiCheckCircle } from 'react-icons/bi';
import { Image } from 'react-bootstrap';

import usePageTitle from '../../../core/hoc/use-page-title';
import { sendMessage } from '../../../core/websocket/engine';
import { getCurrentStepCommand, getLastStepCommand, getStep15Command } from '../../decorators/payloads';
import { resetAllData, saveCurrentStep } from '../../utils/fields-storage';
import { getFlow } from '../../utils/ocr-storage';
import getQueryParam, { appendQueryParam } from '../../../core/utils/url';
import { finalStepAction, finalStepContainer, finalStepTitle } from '../../containers/RegisterPage.styles';
import { boldFont, displayFlexRow, marginRight } from '../../../core/styles/generic.styles';
import { GREEN_FOOTER_COLOR, LIGHT_GREEN } from '../../../core/utils/colors';
import { REGISTER_STEP_15_NAME } from '../../utils/register-steps';
import { NEW_OCR_FLOW } from '../../services/ocr-flow-decider';

import HOME_SVG from '../../../core/assets/svg/home.svg';
import GRADUATION_SVG from '../../../core/assets/svg/graduation-hat.svg';
import DEMO_SVG from '../../../core/assets/svg/demo.svg';
import TEACHER_SVG from '../../../core/assets/svg/teacher.svg';

const RegisterStep15 = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (localStorage.getItem('finished')) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                first_time: 'false',
            });
        } else {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                first_time: 'true',
            });
            localStorage.setItem('finished', 'true');
        }
    }, []);

    const flow = getFlow();
    const version = flow === NEW_OCR_FLOW ? 'v2' : 'v1';
    usePageTitle(`Deschidere cont - Pas 15 | ${version}`);

    useEffect(() => {
        const urlTrackingProcessFinished = getQueryParam(window.location.href, 'processFinished');
        if (!urlTrackingProcessFinished) {
            appendQueryParam('processFinished', 'true');
        }
        saveCurrentStep(REGISTER_STEP_15_NAME);
        sendMessage(getCurrentStepCommand(REGISTER_STEP_15_NAME), dispatch);
        sendMessage(getStep15Command(), dispatch);
        sendMessage(getLastStepCommand(), dispatch);
    }, []);

    const [isHovered1, setIsHovered1] = useState(false);
    const [isHovered2, setIsHovered2] = useState(false);
    const [isHovered3, setIsHovered3] = useState(false);
    const [isHovered4, setIsHovered4] = useState(false);

    const handleMouseEnter = (element) => () => {
        switch (element) {
            case 1:
                setIsHovered1(true);
                break;
            case 2:
                setIsHovered2(true);
                break;
            case 3:
                setIsHovered3(true);
                break;
            case 4:
                setIsHovered4(true);
                break;
            default:
                break;
        }
    };

    const handleMouseLeave = (element) => () => {
        switch (element) {
            case 1:
                setIsHovered1(false);
                break;
            case 2:
                setIsHovered2(false);
                break;
            case 3:
                setIsHovered3(false);
                break;
            case 4:
                setIsHovered4(false);
                break;
            default:
                break;
        }
    };

    return (
        <div>
            <div style={finalStepContainer}>
                <p style={finalStepTitle}>
                    <BiCheckCircle color={GREEN_FOOTER_COLOR} style={marginRight(15)} />
                    <FormattedMessage id='register_page_step_fifteen_title' />
                </p>
                <p><FormattedMessage id='register_page_step_fifteen_desc_1' /></p>
                <p><FormattedMessage id='register_page_step_fifteen_desc_2' /></p>
                <p><FormattedMessage id='register_page_step_fifteen_desc_3' /></p>
            </div>
            <div className="final-step-links" style={displayFlexRow}>
                <a
                    href='https://tradeville.ro/' target='_blank'
                    style={isHovered1 ? { ...finalStepAction, backgroundColor: LIGHT_GREEN } : finalStepAction}
                    onMouseEnter={handleMouseEnter(1)} onMouseLeave={handleMouseLeave(1)} rel="noreferrer"
                >
                    <Image src={HOME_SVG} height={75} width={75} />
                    <p style={{ ...boldFont, marginTop: 10 }}><FormattedMessage id='register_page_step_fifteen_op_1' /></p>
                </a>
                <a
                    href='https://www.tradeville.ro/landing/educatie' target='_blank'
                    style={isHovered2 ? { ...finalStepAction, backgroundColor: LIGHT_GREEN } : finalStepAction}
                    onMouseEnter={handleMouseEnter(2)} onMouseLeave={handleMouseLeave(2)} rel="noreferrer"
                >
                    <Image src={GRADUATION_SVG} height={75} width={75} />
                    <p style={{ ...boldFont, marginTop: 10 }}><FormattedMessage id='register_page_step_fifteen_op_2' /></p>
                </a>
                <a
                    href='https://www.tradeville.ro/actiuni/demo-actiuni' target='_blank'
                    style={isHovered3 ? { ...finalStepAction, backgroundColor: LIGHT_GREEN } : finalStepAction}
                    onMouseEnter={handleMouseEnter(3)} onMouseLeave={handleMouseLeave(3)} rel="noreferrer"
                >
                    <Image src={DEMO_SVG} height={75} width={75} />
                    <p style={{ ...boldFont, marginTop: 10 }}><FormattedMessage id='register_page_step_fifteen_op_3' /></p>
                </a>
                <a
                    href='https://ulise.ro/etfs' target='_blank'
                    style={isHovered4
                        ? { ...finalStepAction, marginRight: 0, backgroundColor: LIGHT_GREEN }
                        : { ...finalStepAction, marginRight: 0 }
                    }
                    onMouseEnter={handleMouseEnter(4)} onMouseLeave={handleMouseLeave(4)} rel="noreferrer"
                >
                    <Image src={TEACHER_SVG} height={75} width={75} />
                    <p style={{ ...boldFont, marginTop: 10 }}><FormattedMessage id='register_page_step_fifteen_op_4' /></p>
                </a>
            </div>
            <br/><br/>
            <span style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => { resetAllData(); window.location.reload(); }}>
                <FormattedMessage id='reset_form' />
            </span>
        </div>
    );
};

export default RegisterStep15;
