import { HTTP_REQUEST_COMPLETED, HTTP_REQUEST_ERROR, HTTP_REQUEST_STARTED } from '../actions/http';

const INITIAL_STATE = {
    isFetching: false,
    isError: false,
};

const httpReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case HTTP_REQUEST_STARTED:
            return { ...state, isFetching: true, isError: false };
        case HTTP_REQUEST_ERROR:
            return { ...state, isError: true };
        case HTTP_REQUEST_COMPLETED:
            return { ...state, isFetching: false };
        default:
            return state;
    }
};

export default httpReducer;
