import React from 'react';
import { IntlProvider } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import UnloggedMenuBar from '../components/UnloggedMenuBar';
import Footer from '../components/Footer';
import { localeSelector } from '../selectors/i18n';
import { setLocale, DEFAULT_LOCALE } from '../actions/i18n';

const I18n = (WrappedComponent, translations) => (props) => {
    const dispatch = useDispatch();
    const locale = useSelector(localeSelector);
    const onLocaleSet = (newLocale) => dispatch(setLocale(newLocale));

    return (
        <IntlProvider locale={locale} defaultLocale={DEFAULT_LOCALE} messages={translations[locale]}>
            <UnloggedMenuBar setLocale={onLocaleSet} />
            <WrappedComponent {...props}/>
            <Footer />
        </IntlProvider>
    );
};

export default I18n;
