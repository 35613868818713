import { SET_INSTANCE_INFO, SET_OLD_SESSION_INFO, SET_FLOW, SET_TOKEN_INFO, SET_WORKFLOW_RESULTS } from '../actions/ocr';

const INITIAL_UI_STATE = {
    currentStep: 0,
    flow: null,
};

export const ocrUiReducer = (state = INITIAL_UI_STATE, action) => {
    switch (action.type) {
        case SET_FLOW:
            return { ...state, flow: action.flow };
        default:
            return state;
    }
};

const INITIAL_DATA_STATE = {
    tokenInfo: {
        token: null,
        refreshToken: null,
        expireDate: null,
    },
    instanceInfo: {
        instanceId: null,
        workflowId: null,
        uniqueGUID: null,
        businessKey: null,
        correlationKeys: null,
    },
    sessionInfo: {
        hasAutomatedSignature: null,
        idExternalRequest: null,
        idExternalUser: null,
        idInternalRequest: null,
        idInternalUser: null,
        idRequest: null,
        idStep: null,
        idWFInstance: null,
        pageNumberSignature: null,
    },
    workflowResults: {
        instanceResult: null,
        consentsResults: null,
        selfieResult: null,
        livenessResult: null,
        idCardResult: null,
        otpResult: null,
    },
};

export const ocrDataReducer = (state = INITIAL_DATA_STATE, action) => {
    switch (action.type) {
        case SET_TOKEN_INFO:
            return { ...state, tokenInfo: { token: action.token, refreshToken: action.refreshToken, expireDate: action.expireDate } };
        case SET_INSTANCE_INFO:
            return {
                ...state,
                instanceInfo: {
                    instanceId: action.instanceId,
                    workflowId: action.workflowId,
                    uniqueGUID: action.uniqueGUID,
                    businessKey: action.businessKey,
                    correlationKeys: action.correlationKeys,
                },
            };
        case SET_WORKFLOW_RESULTS:
            return {
                ...state,
                workflowResults: {
                    instanceResult: action.instanceResult,
                    consentsResults: action.consentsResults,
                    selfieResult: action.selfieResult,
                    livenessResult: action.livenessResult,
                    idCardResult: action.idCardResult,
                    otpResult: action.otpResult,
                },
            };
        case SET_OLD_SESSION_INFO:
            return {
                ...state,
                sessionInfo: {
                    hasAutomatedSignature: action.hasAutomatedSignature,
                    idExternalRequest: action.idExternalRequest,
                    idExternalUser: action.idExternalUser,
                    idInternalRequest: action.idInternalRequest,
                    idInternalUser: action.idInternalUser,
                    idRequest: action.idRequest,
                    idStep: action.idStep,
                    idWFInstance: action.idWFInstance,
                    pageNumberSignature: action.pageNumberSignature,
                },
            };
        default:
            return state;
    }
};
