import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { BiCheck } from 'react-icons/bi';
import { Image } from 'react-bootstrap';

import RegisterStep from '../RegisterStep';
import usePageTitle from '../../../core/hoc/use-page-title';
import { getCurrentStepCommand, getStep3Command } from '../../decorators/payloads';
import { sendMessage } from '../../../core/websocket/engine';
import { saveCurrentStep } from '../../utils/fields-storage';
import { getFlow } from '../../utils/ocr-storage';
import { selectRegisterStepFormData } from '../../selectors/register';
import { SELECTED_IDENTITY_FIELD } from '../../reducers/features/form-structures';
import { PASSPORT_IDENTITY_TYPE } from './RegisterStep2';
import { displayFlexAlignCenter, displayFlexJustifyCenter, marginRight } from '../../../core/styles/generic.styles';
import { REGISTER_STEP_2, REGISTER_STEP_3_NAME, REGISTER_STEP_5 } from '../../utils/register-steps';
import { NEW_OCR_FLOW } from '../../services/ocr-flow-decider';
import { WHITE } from '../../../core/utils/colors';

import NOTIFICATIONS_IMAGE_SOURCE from '../../../core/assets/img/notifications.jpg';

const RegisterStep3 = ({ goToNextStep, goToStep }) => {
    const dispatch = useDispatch();
    const inputs = useSelector((state) => selectRegisterStepFormData(state, REGISTER_STEP_2));
    const selectedIdentity = inputs[SELECTED_IDENTITY_FIELD].value;
    const isPassportSelected = selectedIdentity === PASSPORT_IDENTITY_TYPE;

    const flow = getFlow();
    const version = flow === NEW_OCR_FLOW ? 'v2' : 'v1';
    usePageTitle(`Deschidere cont - Pas 3 | ${version}`);

    useEffect(() => {
        saveCurrentStep(REGISTER_STEP_3_NAME);
        sendMessage(getCurrentStepCommand(REGISTER_STEP_3_NAME), dispatch);
    }, []);

    const onAcceptPress = () => {
        sendMessage(getStep3Command(), dispatch);
        if (isPassportSelected) {
            goToStep(REGISTER_STEP_5)();
        } else {
            goToNextStep();
        }
    };

    const onDeclinePress = () => {
        if (isPassportSelected) {
            goToStep(REGISTER_STEP_5)();
        } else {
            goToNextStep();
        }
    };

    return (
        <RegisterStep
            title={<FormattedMessage id='register_page_step_three_title' />}
            formTitle={<FormattedMessage id='register_page_step_three_form_title' />}
            formSubtitle={<></>}
            prevBtnText={<FormattedMessage id='decline_notifications' />}
            nextBtnText={<FormattedMessage id='accept_notifications' />}
            onGoNextClick={onAcceptPress}
            onGoPrevClick={onDeclinePress}
        >
            <>
                <p style={displayFlexAlignCenter}>
                    <span className="btn btn-success rounded-circle" style={marginRight(10)}>
                        <BiCheck color={WHITE} />
                    </span>
                    <FormattedMessage id='register_page_step_three_item_1' />
                </p>
                <p style={displayFlexAlignCenter}>
                    <span className="btn btn-success rounded-circle" style={marginRight(10)}>
                        <BiCheck color={WHITE} />
                    </span>
                    <FormattedMessage id='register_page_step_three_item_2' />
                </p>
                <p style={displayFlexAlignCenter}>
                    <span className="btn btn-success rounded-circle" style={marginRight(10)}>
                        <BiCheck color={WHITE} />
                    </span>
                    <FormattedMessage id='register_page_step_three_item_3' />
                </p>
            </>
            <div style={displayFlexJustifyCenter}>
                <Image src={NOTIFICATIONS_IMAGE_SOURCE} style={{ width: '100%', maxWidth: 304 }} />
            </div>
        </RegisterStep>
    );
};

export default RegisterStep3;
