import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import getQueryParam from '../../core/utils/url';
import { requestPermission, disableCamera } from '../../core/utils/camera';
import { getRequestsIds, getTrackingId } from '../utils/ocr-storage';
import { getSessionResult, getSessionStatus } from '../actions/ocr';
import { iframeContainer } from '../containers/RegisterPage.styles';

const OldOcrIframe = ({ liveness }) => {
    const type = liveness ? 'liveness' : 'idcard';
    const [canGoNext, setCanGoNext] = useState(false);
    const { idExternalRequest, idInternalRequest } = getRequestsIds();
    const dispatch = useDispatch();

    const trackingId = getTrackingId();
    const urlTrackingId = getQueryParam(window.location.href, 'trackingId');
    useEffect(() => {
        requestPermission();

        return () => {
            disableCamera();
        };
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            dispatch(getSessionStatus(type));
        }, 5000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    const handleMessage = (event) => {
        if (event.data && event.data.info === 'OK') {
            if (event.origin.includes('4apply')) {
                if (event.data.methodType === 'IDScan') {
                    setCanGoNext(true);
                }
            }
        }
    };

    useEffect(() => {
        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    const onNextClick = () => {
        setCanGoNext(false);
        dispatch(getSessionResult(type));
    };

    return (
        <div style={{ ...iframeContainer, paddingLeft: 0, paddingRight: 0 }}>
            <iframe
                id="verification-iframe"
                width="95%"
                style={{ height: '80vh' }}
                sandbox="allow-same-origin allow-scripts"
                data-hj-allow-iframe="true"
                allow="camera; microphone; display-capture; autoplay; clipboard-write;"
                src={`https://tradeville.4apply.com/${type}?IdExternalRequest=${idExternalRequest}&idInternalRequest=${idInternalRequest}&trackingId=${trackingId || urlTrackingId}`}
            />
            {canGoNext && <Button variant='success' onClick={onNextClick}><FormattedMessage id='next' /></Button>}
        </div>
    );
};

export default OldOcrIframe;
