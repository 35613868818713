import React from 'react';
import { Form } from 'react-bootstrap';

import { width } from '../../../../core/styles/generic.styles';

const DateInput = ({ disabled = false, controlId, label, value, isInvalid, onChange }) => (
    <Form.Group style={width(100)} className="mb-3" controlId={controlId}>
        <Form.Label>{label}</Form.Label>
        <Form.Control disabled={disabled} type="date" value={value} isInvalid={isInvalid} onInput={onChange} />
    </Form.Group>
);

export default DateInput;
