import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import RegisterStep from '../RegisterStep';
import RegisterBasicInfoForm from '../forms/RegisterBasicInfoForm';
import validateStep from '../../validators/step-validator';
import usePageTitle from '../../../core/hoc/use-page-title';
import { selectRegisterStepFormData } from '../../selectors/register';
import { getStringBetweenParentheses } from '../../utils/helpers';
import { getCurrentStepCommand, getStep2Command } from '../../decorators/payloads';
import { sendMessage } from '../../../core/websocket/engine';
import { getRegisterStepFields, saveCurrentStep, saveRegisterStepFields } from '../../utils/fields-storage';
import { getFlow } from '../../utils/ocr-storage';
import {
    resetRegisterStepValidity, setRegisterStepFields, setRegisterStepFieldValue, setRegisterStepFieldVisibility,
} from '../../actions/register';
import { REGISTER_STEP_16, REGISTER_STEP_2_NAME, REGISTER_STEP_2, REGISTER_STEP_1 } from '../../utils/register-steps';
import {
    COMPANY_NAME_FIELD, EMAIL_FIELD, FINAL_AGREEMENT_FIELD, FINAL_AGREEMENT_2_FIELD, INTERNATIONAL_PHONE_COUNTRY_FIELD,
    INTERNATIONAL_PHONE_FIELD, PHONE_FIELD, SELECTED_IDENTITY_FIELD, SELECTED_TYPE_PERSON_FIELD, INTERNATIONAL_PHONE_COUNTRY_NUMBER_FIELD,
} from '../../reducers/features/form-structures';
import { NEW_OCR_FLOW } from '../../services/ocr-flow-decider';

export const NATURAL_PERSON = 1;
export const LEGAL_PERSON = 2;
export const RO_CI_IDENTITY_TYPE = 3;
export const PASSPORT_IDENTITY_TYPE = 4;

const RegisterStep2 = ({ goToPrevStep, goToNextStep, goToStep }) => {
    const dispatch = useDispatch();
    const inputs = useSelector((state) => selectRegisterStepFormData(state, REGISTER_STEP_2));

    const flow = getFlow();
    const version = flow === NEW_OCR_FLOW ? 'v2' : 'v1';
    usePageTitle(`Deschidere cont - Pas 2 | ${version}`);

    useEffect(() => {
        saveCurrentStep(REGISTER_STEP_2_NAME);
        sendMessage(getCurrentStepCommand(REGISTER_STEP_2_NAME), dispatch);
        dispatch(setRegisterStepFields(REGISTER_STEP_2, getRegisterStepFields(REGISTER_STEP_2)));
        const type = inputs[SELECTED_TYPE_PERSON_FIELD].value;
        setIdentityTypeVisibility(type, false);
    }, []);

    const onGoNextClick = () => {
        const type = inputs[SELECTED_TYPE_PERSON_FIELD].value;
        const isValid = validateStep(dispatch, REGISTER_STEP_2, inputs);
        if (isValid) {
            sendMessage(getStep2Command(Object.values(inputs)), dispatch);
            saveRegisterStepFields(inputs, REGISTER_STEP_2);
        }

        return type === LEGAL_PERSON ? (isValid && (goToStep(REGISTER_STEP_16)())) : (isValid && goToNextStep());
    };

    const setIdentityTypeVisibility = (type, withReset) => {
        if (type === LEGAL_PERSON) {
            dispatch(setRegisterStepFieldVisibility(REGISTER_STEP_2, COMPANY_NAME_FIELD, true));
            dispatch(setRegisterStepFieldVisibility(REGISTER_STEP_2, SELECTED_IDENTITY_FIELD, false));
            if (withReset) {
                dispatch(setRegisterStepFieldValue(REGISTER_STEP_2, SELECTED_IDENTITY_FIELD, null));
            }
        } else {
            dispatch(setRegisterStepFieldVisibility(REGISTER_STEP_2, COMPANY_NAME_FIELD, false));
            dispatch(setRegisterStepFieldVisibility(REGISTER_STEP_2, SELECTED_IDENTITY_FIELD, true));
            if (withReset) {
                dispatch(setRegisterStepFieldValue(REGISTER_STEP_2, COMPANY_NAME_FIELD, null));
            }
        }
    };

    const onGoPrevClick = () => {
        saveCurrentStep(REGISTER_STEP_1);
        dispatch(resetRegisterStepValidity(REGISTER_STEP_2));
        goToPrevStep();
    };

    const onSelectedPersonTypeChange = (value) => {
        dispatch(setRegisterStepFieldValue(REGISTER_STEP_2, SELECTED_TYPE_PERSON_FIELD, value));
        setIdentityTypeVisibility(value, true);
    };

    const onInternationalPhoneCheck = (e) => {
        const value = e.target.checked;
        dispatch(setRegisterStepFieldValue(REGISTER_STEP_2, INTERNATIONAL_PHONE_FIELD, value));

        if (value) {
            dispatch(setRegisterStepFieldVisibility(REGISTER_STEP_2, INTERNATIONAL_PHONE_COUNTRY_FIELD, true));
        } else {
            dispatch(setRegisterStepFieldVisibility(REGISTER_STEP_2, INTERNATIONAL_PHONE_COUNTRY_FIELD, false));
            dispatch(setRegisterStepFieldValue(REGISTER_STEP_2, INTERNATIONAL_PHONE_COUNTRY_FIELD, null));
            dispatch(setRegisterStepFieldValue(REGISTER_STEP_2, INTERNATIONAL_PHONE_COUNTRY_NUMBER_FIELD, null));
        }
    };

    const onSelectCountry = (e) => {
        const selectedId = e.target.selectedOptions[0].id;
        const selectedValue = e.target.selectedOptions[0].value;

        dispatch(setRegisterStepFieldValue(REGISTER_STEP_2, INTERNATIONAL_PHONE_COUNTRY_FIELD, selectedId));
        dispatch(
            setRegisterStepFieldValue(
                REGISTER_STEP_2,
                INTERNATIONAL_PHONE_COUNTRY_NUMBER_FIELD,
                getStringBetweenParentheses(selectedValue),
            ),
        );
    };

    return (
        <RegisterStep
            title={<FormattedMessage id='register_page_step_two_title' />}
            formTitle={<FormattedMessage id='register_page_step_two_form_title' />}
            formSubtitle={<></>}
            prevBtnText={<FormattedMessage id='prev' />}
            nextBtnText={<FormattedMessage id='next' />}
            onGoNextClick={onGoNextClick}
            onGoPrevClick={onGoPrevClick}
        >
            <>
                <p><FormattedMessage id='register_page_step_two_desc_1' /></p>
                <p><span style={{ color: 'red' }}><FormattedMessage id='attention' /></span><FormattedMessage id='register_page_step_two_desc_2' /></p>
            </>
            <>
                <RegisterBasicInfoForm
                    inputs={inputs}
                    handleSelectedPerson={onSelectedPersonTypeChange}
                    onInternationalPhoneCheck={onInternationalPhoneCheck}
                    onSelectCountry={onSelectCountry}
                    setEmail={(value) => dispatch(setRegisterStepFieldValue(REGISTER_STEP_2, EMAIL_FIELD, value))}
                    setPhone={(value) => dispatch(setRegisterStepFieldValue(REGISTER_STEP_2, PHONE_FIELD, value))}
                    handleIdentityType={(value) => (
                        dispatch(setRegisterStepFieldValue(REGISTER_STEP_2, SELECTED_IDENTITY_FIELD, value))
                    )}
                    setCompanyName={(value) => (
                        dispatch(setRegisterStepFieldValue(REGISTER_STEP_2, COMPANY_NAME_FIELD, value))
                    )}
                    onFinalAgreementClick={(value) => (
                        dispatch(setRegisterStepFieldValue(REGISTER_STEP_2, FINAL_AGREEMENT_FIELD, value))
                    )}
                    onFinalAgreement2Click={(value) => (
                        dispatch(setRegisterStepFieldValue(REGISTER_STEP_2, FINAL_AGREEMENT_2_FIELD, value))
                    )}
                />
            </>
        </RegisterStep>
    );
};

export default RegisterStep2;
