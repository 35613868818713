import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { requestPermission, disableCamera } from '../../core/utils/camera';
import { getWorkflowVariables } from '../actions/ocr';
import { getTrackingId } from '../utils/ocr-storage';
import { iframeContainer } from '../containers/RegisterPage.styles';

const OcrIframe = ({ instanceId }) => {
    const dispatch = useDispatch();
    const trackingId = getTrackingId();

    useEffect(() => {
        requestPermission();

        return () => {
            disableCamera();
        };
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            dispatch(getWorkflowVariables(true));
        }, 10000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    const handleMessage = (event) => {
        if (event.origin.includes('4apply')) {
            if (event.data && event.data.info === 'OK') {
                console.log(event.data);
            }
        }
    };

    useEffect(() => {
        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    return (
        <div style={{ ...iframeContainer, paddingLeft: 0, paddingRight: 0 }}>
            <iframe
                id="verification-iframe"
                width="95%"
                style={{ height: '80vh' }}
                sandbox="allow-same-origin allow-scripts"
                data-hj-allow-iframe="true"
                allow="camera; microphone; display-capture; autoplay; clipboard-write;"
                src={`https://websdk.4apply.com/workflow-tradeville?wfInstanceId=${instanceId}&trackingId=${trackingId}`}
            />
        </div>
    );
};

export default OcrIframe;
