const registerStep1Translations = {
    en: {
        register_page_step_one_title: 'Welcome to TradeVille!',
        register_page_step_one_subtitle_part_1: 'Find out ',
        register_page_step_one_subtitle_part_2: 'what you need ',
        register_page_step_one_subtitle_part_3: 'and ',
        register_page_step_one_subtitle_part_4: 'how to ',
        register_page_step_one_subtitle_part_5: 'open an online trading account.',
        register_page_step_one_desc_1: 'We know that going through this step takes a little time out of your already busy schedule, but we\'ll help you open your account as quickly as possible.',
        register_page_step_one_desc_2: 'After each step, the data you complete is automatically saved in your browser and you can continue your session.',
        register_page_step_one_form_title: 'Fill-in-your data',
        register_page_step_one_form_subtitle: 'Before you go any further you should read the ',
        register_page_step_one_form_subtitle_action_text: 'presentation document',
        register_page_step_one_form_checkbox_1: 'By checking this box, I agree that I have read the presentation document, I understand it, I am fully aware of it and I have no reservations about it.',
        register_page_step_one_form_checkbox_2: 'Being aware that false statements are punishable under the applicable legal provisions, I declare on my own behalf that I am not a suspect or defendant and have not been convicted in criminal proceedings for the offence of money laundering, financing of terrorism or any other financial offence provided for by special laws.',
        register_page_step_one_helper_1: 'Your identity card;',
        register_page_step_one_helper_2: 'A bank statement showing the details of your account;',
        register_page_step_one_helper_3: 'A webcam;',
        register_page_step_one_helper_4: 'A mobile phone.',
        register_page_step_one_helper_5: 'Fill in the online account opening form (about 10 minutes);',
        register_page_step_one_helper_6: 'Upload the required documents at the end;',
        register_page_step_one_helper_7: 'You will receive the login details on the provided email address (within one business day).',
        reset_form: 'Reset form',
    },
    ro: {
        register_page_step_one_title: 'Bine ati venit la TradeVille!',
        register_page_step_one_subtitle_part_1: 'Aflati ',
        register_page_step_one_subtitle_part_2: 'de ce aveti nevoie ',
        register_page_step_one_subtitle_part_3: 'si ',
        register_page_step_one_subtitle_part_4: 'ce pasi veti urma ',
        register_page_step_one_subtitle_part_5: 'pentru a va deschide un cont online de tranzactionare.',
        register_page_step_one_desc_1: 'Stim ca parcurgerea acestor pasi cere timp din programul dumneavoastra deja aglomerat, dar va vom ajuta sa va deschideti contul cat mai repede si usor posibil.',
        register_page_step_one_desc_2: 'Dupa fiecare pas, datele completate sunt salvate automat in browser si puteti oricand continua sesiunea.',
        register_page_step_one_form_title: 'Completati-va datele',
        register_page_step_one_form_subtitle: 'Inainte de a merge mai departe va rugam sa cititi ',
        register_page_step_one_form_subtitle_action_text: 'documentul de prezentare',
        register_page_step_one_form_checkbox_1: 'Prin bifarea casutei declar ca am citit documentul de prezentare, l-am inteles, am luat cunostinta in mod complet de acesta si nu am nicio rezerva in legatura cu acesta.',
        register_page_step_one_form_checkbox_2: 'Cunoscand faptul ca falsul in declaratii se pedepseste conform dispozitiilor legale aplicabile, declar pe propria raspundere ca nu am calitatea de inculpat si nu am fost condamnat in dosare pentru penale savarsirea infractiunii de spalare a banilor, finantare a terorismului sau a altor infractiuni financiare prevazute de legi speciale.',
        register_page_step_one_helper_1: 'Actul dumneavoastra de identitate;',
        register_page_step_one_helper_2: 'Un extras de cont bancar care sa contina datele dumneavoastra;',
        register_page_step_one_helper_3: 'Un webcam;',
        register_page_step_one_helper_4: 'Telefonul mobil.',
        register_page_step_one_helper_5: 'Completati online formularul de deschidere cont (aproximativ 10 minute);',
        register_page_step_one_helper_6: 'Incarcati documentele solicitate la final;',
        register_page_step_one_helper_7: 'Primiti datele de logare pe adresa de e-mail furnizata (pana la o zi lucratoare).',
        reset_form: 'Resetare formular',
    },
};

const registerStep2Translations = {
    en: {
        register_page_step_two_title: 'Account holder',
        register_page_step_two_desc_1: 'According to the legal provisions in force, TradeVille is obliged to verify and register the identity of any person who wishes to open a trading account.',
        register_page_step_two_desc_2: ' the contract conclusion takes place electronically; so make sure that you have correctly filled in your e-mail address and telephone number, otherwise you will not be able to complete the account opening.',
        register_page_step_two_form_title: 'Fill in your details',
        register_page_step_two_form_checkbox_1: 'Natural person',
        register_page_step_two_form_checkbox_2: 'Legal person ',
        register_page_step_two_international_phone: 'International number',
        register_page_step_two_checkbox: 'By submitting this form, I express my consent for the conclusion of a distance contract with TradeVille S.A., operated via the Internet, and express my consent to the use of remote communication means.',
        register_page_step_two_checkbox_2_part_1: 'By checking the box, I expressly agree to the conclusion of the ',
        register_page_step_two_checkbox_2_part_2: 'Financial Investment Services Agreement',
        register_page_step_two_checkbox_2_part_3: ', declare that I have read it in its entirety, and agree with each of its provisions, and adopt all the aspects declared in the account opening and all the obligations contained in the Agreement and its annexes.',
        register_page_step_two_documents: 'Identity document used for identification',
        register_page_step_two_documents_option_1: 'RO Identity Card',
        register_page_step_two_documents_option_2: 'CI Electronic ID / Passport / Other',
        register_page_step_two_company_name: 'Company Name',
    },
    ro: {
        register_page_step_two_title: 'Titularul de cont',
        register_page_step_two_desc_1: 'Conform prevederilor legale in vigoare, TradeVille are obligatia sa verifice si sa inregistreze identitatea oricarei persoane care doreste sa-si deschida un cont de tranzactionare.',
        register_page_step_two_desc_2: ' incheierea contractului are loc electronic; va rugam asigurati-va ca ati completat corect adresa de e-mail si numarul de telefon, pentru ca altfel nu veti putea finaliza deschiderea de cont.',
        register_page_step_two_form_title: 'Completati-va datele',
        register_page_step_two_form_checkbox_1: 'Persoana Fizica',
        register_page_step_two_form_checkbox_2: 'Persoana Juridica',
        register_page_step_two_international_phone: 'Numar international',
        register_page_step_two_checkbox: 'Prin bifarea casutei imi exprim expres consimtamantul privind demararea incheierii unui contract la distanta cu societatea TradeVille S.A., operat prin internet si imi exprim expres acordul prealabil pentru utilizarea mijloacelor de comunicare la distanta.',
        register_page_step_two_checkbox_2_part_1: 'Prin bifarea casutei, imi exprim acordul expres pentru incheierea ',
        register_page_step_two_checkbox_2_part_2: 'Contractului de prestari servicii de investitii financiare',
        register_page_step_two_checkbox_2_part_3: ', declar ca l-am citit in integralitate si sunt de acord cu fiecare dintre prevederile acestuia si imi insusesc toate aspectele declarate in deschiderea de cont si toate obligatiile din cuprinsul Contractului si al anexelor la Contract.',
        register_page_step_two_documents: 'Actul de identitate folosit pentru identificare',
        register_page_step_two_documents_option_1: 'Carte de identitate RO',
        register_page_step_two_documents_option_2: 'CI electronic / Pasaport / Altele',
        register_page_step_two_company_name: 'Nume Companie',
    },
};

const registerStep3Translations = {
    en: {
        register_page_step_three_title: 'Get your daily dose of financial inspiration',
        register_page_step_three_item_1: 'Receive our daily email newsletters - popular and easy-to-read Romanian financial analyses. Concise, noise-free news.',
        register_page_step_three_item_2: 'Free access to all our analyses reports, even for the special editions.',
        register_page_step_three_item_3: 'Stay up-to-date, straight from your phone, with the news that move the stock market.',
        register_page_step_three_form_title: 'Subscribe now!',
    },
    ro: {
        register_page_step_three_title: 'Luati-va doza zilnica de inspiratie financiara',
        register_page_step_three_item_1: 'Primiti zilnic pe email newsletterele noastre - analize financiare din Romania, populare si usor de citit. Stiri compacte, fara zgomot.',
        register_page_step_three_item_2: 'Aveti acces gratuit la toate rapoartele noastre de analiza, chiar si la editiile speciale.',
        register_page_step_three_item_3: 'Ramaneti la curent, direct de pe telefon, cu stirile care misca bursele.',
        register_page_step_three_form_title: 'Abonati-va!',
    },
};

const registerStep4Translations = {
    en: {
        register_page_step_four_title: 'Identity validation',
        register_page_step_four_subtitle_1: 'For the capture of data from your identity card and the video identification step you need a webcam.',
        register_page_step_four_subtitle_2: 'If it is more convenient for you, you can continue with these steps on your mobile phone.',
        register_page_step_four_continue_on_mobile: 'Continue on mobie',
        register_page_step_four_decline_webcam: 'I don\'t want to use the webcam',
        registe_page_step_four_transfer_on_mobile: 'Transfer on mobile',
    },
    ro: {
        register_page_step_four_title: 'Validare identitate',
        register_page_step_four_subtitle_1: 'Pentru preluarea datelor din actul de identitate si pasul pentru identificare video veti avea nevoie de o camera web.',
        register_page_step_four_subtitle_2: 'Daca este mai comod pentru dumneavoastra, puteti continua cu acesti pasi pe telefonul mobil.',
        register_page_step_four_continue_on_mobile: 'Continuati pe mobil',
        register_page_step_four_decline_webcam: 'Nu vreau sa folosesc camera web',
        registe_page_step_four_transfer_on_mobile: 'Transfera pe mobil',
    },
};

const registerStep5Translations = {
    en: {
        register_page_step_five_title: 'Identification data',
        register_page_step_five_subtitle: 'Personal data and Address',
        register_page_step_five_desc: 'As aforementioned, we have an obligation to know our customers very well. In this step you will need to provide us with your personal data and address. If you have chosen live identification, you will find your personal data completed. Just please check them.',
        register_page_step_five_form_title: 'Complete your details',
        register_page_step_five_form_title_v2: 'Verify your details',
        register_page_step_five_first_name: 'Surname',
        register_page_step_five_last_name: 'Name',
        register_page_step_five_cnp: 'PIN',
        register_page_step_five_series: 'IC series and number',
        register_page_step_five_number: 'Document number',
        register_page_step_five_emitted_by: 'Issued by',
        register_page_step_five_emitted_by_passport: 'Authority',
        register_page_step_five_emitted_date: 'Valid from',
        register_page_step_five_expire_date: 'Until',
        register_page_step_five_birth_day: 'Date of birth',
        register_page_step_five_birth_place: 'Place of birth',
        register_page_step_five_address: 'Address',
        register_page_step_five_county: 'County',
        register_page_step_five_city: 'Locality',
        register_page_step_five_country: 'Country',
    },
    ro: {
        register_page_step_five_title: 'Date de identificare',
        register_page_step_five_subtitle: 'Date personale si adresa',
        register_page_step_five_desc: 'Cum spuneam, avem obligatia legala sa ne cunoastem foarte bine clientii. In acest pas avem nevoie sa ne furnizati datele personale si adresa. In cazul in care ati ales identificarea live, gasiti aici datele dumneavoastra personale precompletate. Va rugam doar sa le verificati.',
        register_page_step_five_form_title: 'Completati-va datele',
        register_page_step_five_form_title_v2: 'Verificare date',
        register_page_step_five_first_name: 'Prenume',
        register_page_step_five_last_name: 'Nume',
        register_page_step_five_cnp: 'CNP',
        register_page_step_five_series: 'Serie si numar CI',
        register_page_step_five_number: 'Numar document',
        register_page_step_five_emitted_by: 'Emis de',
        register_page_step_five_emitted_by_passport: 'Emis de',
        register_page_step_five_emitted_date: 'Valabil de la',
        register_page_step_five_expire_date: 'Pana la',
        register_page_step_five_birth_day: 'Data nasterii',
        register_page_step_five_birth_place: 'Locul nasterii',
        register_page_step_five_address: 'Adresa',
        register_page_step_five_county: 'Judet',
        register_page_step_five_city: 'Oras',
        register_page_step_five_country: 'Tara',
    },
};

const registerStep6Translations = {
    en: {
        register_page_step_six_desc: 'As part of our Know Your Customer (KYC) process, we are obliged to have information about the identity and address of our customers.',
        register_page_step_six_citizenship: 'Citizenship',
        register_page_step_six_nationality: 'Nationality',
        register_page_step_six_origin: 'Country of origin',
        register_page_step_six_does_address_differ: 'Is your residence address (where you currently live) different from your home address?',
        register_page_step_six_notif: 'You want to receive notifications at the address of:',
        register_page_step_six_home_address: 'Home address',
        register_page_step_six_address_res: 'Residence address',
        register_page_step_six_footer: 'To authorise other persons on your account, use this form of ',
        register_page_step_six_footer_action: 'power of attorney.',
    },
    ro: {
        register_page_step_six_desc: 'Ca parte a procesului nostru de cunoastere a clientului (KYC) suntem obligati sa avem informatii despre identitatea si adresa clientilor nostri.',
        register_page_step_six_citizenship: 'Cetatenie',
        register_page_step_six_nationality: 'Nationalitate',
        register_page_step_six_origin: 'Tara de origine',
        register_page_step_six_does_address_differ: 'Adresa de resedinta (unde locuiti in prezent) difera de adresa de domiciliu?',
        register_page_step_six_notif: 'Doriti sa primiti notificari la adresa de:',
        register_page_step_six_home_address: 'Domiciliu',
        register_page_step_six_address_res: 'Adresa resedinta',
        register_page_step_six_footer: 'Pentru a autoriza si alte persoane pe cont, folositi acest ',
        register_page_step_six_footer_action: 'model de procura.',
    },
};

const registerStep7Translations = {
    en: {
        register_page_step_seven_title: 'Bank Information',
        register_page_step_seven_desc_1: 'The data filled in must be those related to the account statement of which you are the holder and which you will upload at the end.',
        register_page_step_seven_desc_2: 'The account now registered will be used for supplies and withdrawals.',
        register_page_step_seven_desc_3: 'You will only be able to make transactions through a bank account declared in relation to us.',
        register_page_step_seven_desc_4: 'To add other bank accounts you will load the statement in the Portal platform. The important thing is to be the holder of these accounts.',
        register_page_step_seven_bank_name: 'Name of the Bank',
        register_page_step_seven_currency: 'Account Currency',
        register_page_step_seven_iban: 'IBAN of the account',
        register_page_step_seven_footer_1: 'Note: TradeVille has accounts opened at the following banks (bank transfers between accounts opened at the same bank are faster and cheaper):',
        register_page_step_seven_footer_2: 'Accounts in ',
    },
    ro: {
        register_page_step_seven_title: 'Date Bancare',
        register_page_step_seven_desc_1: 'Datele completate trebuie sa fie cele aferente extrasului de cont al carui titular sunteti si pe care il veti incarca la final',
        register_page_step_seven_desc_2: 'Contul inregistrat acum va putea fi folosit pentru alimentari si retrageri.',
        register_page_step_seven_desc_3: 'Veti putea face operatiuni doar printr-un cont bancar declarat in relatie cu noi.',
        register_page_step_seven_desc_4: 'Pentru a adauga si alte conturi bancare veti incarca extrasul in platforma Portal. Important este sa fiti titularul acestora.',
        register_page_step_seven_bank_name: 'Numele Bancii',
        register_page_step_seven_currency: 'Moneda Contului',
        register_page_step_seven_iban: 'Contul IBAN',
        register_page_step_seven_footer_1: 'Nota: TradeVille are conturi deschise la urmatoarele banci (transferurile bancare intre conturi deschise la aceeasi banca sunt mai rapide si mai ieftine):',
        register_page_step_seven_footer_2: 'Conturi in ',
    },
};

const registerStep8Translations = {
    en: {
        register_page_step_eight_title: 'Access data',
        register_page_step_eight_desc_1: 'Please choose a username and password to access your trading account.',
        register_page_step_eight_desc_2: 'It is important to note that the username cannot be changed.',
        register_page_step_eight_desc_3: 'If you already have an active account, please use the same login details.',
        register_page_step_eight_form_title: 'Choose your login details',
        register_page_step_eight_username: 'Username',
        register_page_step_eight_password: 'Password ',
        register_page_step_eight_password_too_short: 'Password must be at least 8 characters long',
        register_page_step_eight_passwords_dont_match: 'Passwords don\'t match',
        register_page_step_eight_repetead_password: 'Password Confirmation',
        register_page_step_eigth_agreement_1: 'I agree to ',
        register_page_step_eigth_agreement_action_1: 'the terms of the TradeVille contract ',
        register_page_step_eigth_agreement_action_2: ' the TradeVille Fees and Charges Annex',
    },
    ro: {
        register_page_step_eight_title: 'Date acces',
        register_page_step_eight_desc_1: 'Va rugam sa va alegeti un nume de utilizator si o parola pentru a accesa contul de tranzactionare.',
        register_page_step_eight_desc_2: 'Este important de retinut ca utilizatorul nu poate fi schimbat.',
        register_page_step_eight_desc_3: 'Daca aveti deja un cont activ, va rugam folositi aceleasi coordonate de autentificare.',
        register_page_step_eight_form_title: 'Alegeti-va datele de acces in cont',
        register_page_step_eight_username: 'Username',
        register_page_step_eight_password: 'Parola',
        register_page_step_eight_password_too_short: 'Parola trebuie sa aiba cel putin 8 caractere',
        register_page_step_eight_passwords_dont_match: 'Parolele nu se potrivesc',
        register_page_step_eight_repetead_password: 'Confirma Parola',
        register_page_step_eigth_agreement_1: 'Sunt de acord cu ',
        register_page_step_eigth_agreement_action_1: 'termenii contractului TradeVille ',
        register_page_step_eigth_agreement_action_2: ' Anexa de Comisioane si Taxe TradeVille',
    },
};

const registerStep9Translations = {
    en: {
        register_page_step_nine_title: 'Legal Frameworks',
        register_page_step_nine_desc_1: 'Why are they necessary?',
        register_page_step_nine_desc_2: 'If you fall into one of the categories below, according to the legal provisions in force, you are obliged to notify TradeVille when opening a trading account.',
        register_page_step_nine_final_agreement_1: 'I have read and understood what are the categories of ',
        register_page_step_nine_final_agreement_2: 'publicly exposed persons, the notion of real beneficiary and resident/non-resident, compensable investors',
        register_page_step_nine_first_question: 'Do you fall into the "non-resident persons" category?',
        register_page_step_nine_second_question: 'Do you fall into the category of "publicly exposed person" or are you a family member of a "publicly exposed person" or a person known to be a close associate of a "publicly exposed person"?',
        register_page_step_nine_third_question: 'Is the beneficial owner of the account different from the account holder?',
        register_page_step_nine_function: 'Public position held',
        register_page_step_nine_compensable_question: 'Do you fall into any of the categories exempted from compensation according to the scheme administered by the Investor’s Compensation Fund?',
        register_page_step_nine_funds: 'Source of funds and account holder\'s wealth (publicly exposed person)',
        register_page_step_nine_real_ben: 'Fill in the full name of the beneficiary owner',
        register_page_step_nine_real_ben_question_1: 'The beneficial owner is a "publicly exposed person" or is a family member of a "publicly exposed person" or a person known to be a close associate of a "publicly exposed person"?',
    },
    ro: {
        register_page_step_nine_title: 'Incadrari Legale',
        register_page_step_nine_desc_1: 'De ce sunt necesare?',
        register_page_step_nine_desc_2: 'In cazul in care va incadrati in una dintre categoriile de mai jos, conform prevederilor legale in vigoare, aveti obligatia de a anunta TradeVille la deschiderea unui cont de tranzactionare.',
        register_page_step_nine_final_agreement_1: 'Am citit si inteles care sunt categoriile de ',
        register_page_step_nine_final_agreement_2: 'persoane expuse public, notiunea de beneficiar real si de rezident/nerezident, investitori compensabili',
        register_page_step_nine_first_question: 'Va incadrati in categoria "persoanelor nerezidente"?',
        register_page_step_nine_second_question: 'Va incadrati in categoria de "persoane expuse public" sau detineti calitatea de membru al familiei "persoanei expuse public" ori de persoana cunoscuta ca asociat apropiat al unei "persoane expuse public"?',
        register_page_step_nine_third_question: 'Beneficiarul real al contului este diferit de titularul de cont?',
        register_page_step_nine_function: 'Functia publica detinuta',
        register_page_step_nine_compensable_question: 'Va incadrati in vreuna din categoriile exceptate de la compensare potrivit schemei administrate de Fondul de Compensare a Investitorilor?',
        register_page_step_nine_funds: 'Sursa fondurilor si averii titularului de cont (persoana expusa public)',
        register_page_step_nine_real_ben: 'Completati numele si prenumele beneficiarului real',
        register_page_step_nine_real_ben_question_1: 'Beneficiarul real este o "persoana expusa public" sau detine calitatea de membru al familiei unei "persoane expuse public" ori de persoana cunoscuta ca asociat apropiat al unei "persoane expuse public"?',
        register_page_step_nine_real_ben_select: 'Sursa fondurilor si averii beneficiarului real (persoana expusa public)',
    },
};

const registerStep10Translations = {
    en: {
        register_page_step_ten_title: 'Socio-demographic profile',
        register_page_step_ten_desc: 'To provide you with the best experience, we need information about your employer. This information will never be used to contact your employer.',
        register_page_step_ten_employer: 'Name of employer',
        register_page_step_ten_occupation: 'Your occupation/profession',
        register_page_step_ten_domain: 'Field of activity',
        register_page_step_ten_own_activity: 'The nature of own activity',
    },
    ro: {
        register_page_step_ten_title: 'Profil socio-demografic',
        register_page_step_ten_desc: 'Pentru a va oferi cea mai buna experienta, avem nevoie de informatii in legatura cu angajatorul dumneavoastra. Aceste informatii nu vor fi niciodata folosite pentru a contacta angajatorul.',
        register_page_step_ten_employer: 'Numele angajatorului',
        register_page_step_ten_occupation: 'Ocupatia/Profesia dumneavoastra',
        register_page_step_ten_domain: 'Domeniul de activitate',
        register_page_step_ten_own_activity: 'Natura activitatii proprii',
    },
};

const registerStep11Translations = {
    en: {
        register_page_step_eleven_desc: 'In order to give you the best trading experience we need some information from you.',
        register_page_step_eleven_purpose: 'Purpose and nature of transactions',
        register_page_step_eleven_purpose_speculative: 'Speculative',
        register_page_step_eleven_purpose_investment: 'Investment',
        register_page_step_eleven_education_level: 'Education Level',
        register_page_step_eleven_annual_income: 'Annual family income',
        register_page_step_eleven_funds_source: 'Main source of funds to be used in the business relationship',
    },
    ro: {
        register_page_step_eleven_desc: 'Pentru a va oferi o cat mai buna experienta de tranzactionare avem nevoie de cateva informatii de la dumneavoastra.',
        register_page_step_eleven_purpose: 'Scopul si natura tranzactiilor',
        register_page_step_eleven_purpose_speculative: 'Speculativ',
        register_page_step_eleven_purpose_investment: 'Investitional',
        register_page_step_eleven_education_level: 'Nivelul Studiilor',
        register_page_step_eleven_annual_income: 'Venitul anual al familiei',
        register_page_step_eleven_funds_source: 'Sursa principala a fondurilor ce urmeaza sa fie utilizate in derularea relatiei de afaceri',

    },
};

const registerStep12Translations = {
    en: {
        register_page_step_twelve_title: 'Investment Profile',
        register_page_step_twelve_desc: 'In order to ensure that we offer you the most suitable trading products and services, please answer the attached questions about your previous experiences.',
        register_page_step_twelve_revenue: 'Percentage of revenue allocated to investments',
        register_page_step_twelve_estimate: 'Estimated investment maintenance',
        register_page_step_twelve_risk: 'Level of risk accepted',
        register_page_step_twelve_experience: 'Investment experience',
        register_page_step_twelve_products_experience: 'Investment products of interest to you',
    },
    ro: {
        register_page_step_twelve_title: 'Profil Investitional',
        register_page_step_twelve_desc: 'Pentru a ne asigura ca va oferim cele mai potrivite produse si servicii de tranzactionare va rugam sa raspundeti la intrebarile alaturate referitoare la experientele anterioare.',
        register_page_step_twelve_revenue: 'Procent din venituri alocat pentru investitii',
        register_page_step_twelve_estimate: 'Estimare mentinere investitii',
        register_page_step_twelve_risk: 'Nivel de risc acceptat',
        register_page_step_twelve_experience: 'Experienta investitionala',
        register_page_step_twelve_products_experience: 'Produse de investitii ce prezinta interes pentru dumneavoastra',
    },
};

const registerStep13Translations = {
    en: {
        register_page_step_thirteen_title: 'Tax residence information',
        register_page_step_thirteen_desc: 'If you want to declare a tax residence outside Romania you will have to send us documents proving your address abroad. Otherwise you will be registered as a Romanian tax resident and you will be taxed in Romania for withholding tax instruments.',
        register_page_step_thirteen_desc_2: 'If you are resident for tax purposes in a country other than Romania, in order to avoid withholding tax on trading gains (1% of gains on holdings over 1 year or 3% for holdings under 1 year), you must submit your Tax Residence Certificate valid for the current year. The certificate must be presented in original/electronically released, issued by the competent authority of the country where you pay taxes (other than Romania), together with a certified translation into Romanian if the document is not issued in English/Bilingual English and Romanian. On the basis of the Tax Residence Certificate valid for the current year (in original/electronically issued), you will benefit from the provisions of the double taxation conventions signed between the Member States until 28 February of the following year for which the Tax Residence Certificate has been issued. By this date, you must submit a new Tax Residence Certificate, in original/electronically issued, valid for the following year. The declaration of tax residence in a foreign country in the absence of the Tax Residence Certificate (original/electronically signed) entails the obligation of reporting the client to ANAF as a Person who pays taxes and contributions in Romania.',
        register_page_step_thirteen_countries: 'Countries where the client has tax obligations',
        register_page_step_thirteen_fiscal_code: 'Fiscal Code (PIN, SSN, etc)',
        register_page_step_thirteen_new_country: 'Add a new country',
        register_page_step_thirteen_remove_country: 'Remove country',
        register_page_step_thirteen_usa: 'USA Citizen',
        register_page_step_thirteen_usa_yes: 'Yes, I am a citizen',
        register_page_step_thirteen_usa_no: 'No, I am not a citizen',
        register_page_step_thirteen_usa_disclaimer: '*U.S. Citizen - an individual who is a U.S. citizen or U.S. resident alien as defined in the U.S. Internal Revenue Code',
        register_page_step_thirteen_footer: 'I declare that I have examined and completed the data on this form to the best of my knowledge, and that they are true, correct and complete. In particular, I certify the matters set out in ',
        register_page_step_thirteen_footer_action: 'this declaration.',
        register_page_step_thirteen_footer_action_usa: 'If you are a "U.S. Person" please complete the tax residence in the U.S., along with SSN.',
    },
    ro: {
        register_page_step_thirteen_title: 'Informatii privind rezidenta fiscala',
        register_page_step_thirteen_desc: 'Daca vreti sa declarati o rezidenta fiscala in afara Romaniei va trebui sa ne trimiteti documente care sa dovedeasca adresa din strainatate. Altfel veti fi inregistrat ca rezident fiscal roman si vi se va retine impozit in Romania pentru instrumentele cu retinere la sursa.',
        register_page_step_thirteen_desc_2: 'Daca sunteti rezident fiscal in alt stat decat Romania, pentru a evita retinerea la sursa a impozitului pe castigul din tranzactionare (1% din castiguri pe detinerile de peste 1 an sau 3% pentru detinerile de sub 1 an) este necesar sa prezentati Certificatul de Rezidenta Fiscala valabil pentru anul in curs. Acesta trebuie prezentat in original/eliberat electronic, emis de autoritatea competenta din tara in care platiti impozitele (alta decat Romania), alaturi de traducerea legalizata in limba romana, daca documentul nu este emis in engleza/bilingv engleza. In baza Certificatului de Rezidenta Fiscala valabil pentru anul in curs (in original/eliberat electronic) veti beneficia de prevederile Conventiilor pentru evitarea dublei impuneri semnate intre state pana la 28 februarie a anului urmator pentru care Certificatul de rezidenta fiscala a fost eliberat, urmand ca pana la aceasta data sa prezentati un nou Certificat de rezidenta fiscala, in original/eliberat electronic, valabil pentru anul urmator. Declararea rezidentei fiscale in strainatate in lipsa Certificatului de Rezidenta Fiscala (original/semnat electronic) atrage obligatia de raportare a clientului catre ANAF in calitate de persoana care plateste impozite si taxe pe teritoriul Romaniei.',
        register_page_step_thirteen_countries: 'Tarile in care clientul are obligatii fiscale',
        register_page_step_thirteen_fiscal_code: 'Cod fiscal (CNP, SSN, etc)',
        register_page_step_thirteen_new_country: 'Adauga o noua tara',
        register_page_step_thirteen_remove_country: 'Sterge tara',
        register_page_step_thirteen_usa: 'Cetatean SUA',
        register_page_step_thirteen_usa_yes: 'Da, sunt cetatean',
        register_page_step_thirteen_usa_no: 'Nu, nu sunt cetatean',
        register_page_step_thirteen_usa_disclaimer: '*Cetatean SUA - o persoana fizica care este cetatean SUA sau strain rezident SUA, in conformitate cu definitiile din US Internal Revenue Code',
        register_page_step_thirteen_footer: 'Declar ca am examinat si completat datele de pe acest formular in deplina cunostinta, iar acestea sunt adevarate, corecte si complete. In special, certific aspectele inscrise in ',
        register_page_step_thirteen_footer_action: 'aceasta declaratie.',
        register_page_step_thirteen_footer_action_usa: 'In conditiile in care sunteti "Persoana SUA" va rugam sa completati rezidenta fiscala in SUA, alaturi de SSN.',
    },
};

const registerStep14Translations = {
    en: {
        register_page_step_fourteen_title: 'Upload Documents',
        register_page_step_fourteen_form_title: 'Upload the documents below',
        register_page_step_fourteen_desc_1: 'To complete the online account opening process please upload the required documents',
        register_page_step_fourteen_desc_3: 'Once you have signed, we will send you the account activation confirmation by email.',
        register_page_step_fourteen_id: 'Identity Card',
        register_page_step_fourteen_bank: 'Bank statement',
        register_page_step_fourteen_selfie: 'Selfie with your identity card',
        register_page_step_fourteen_other: 'Other Files - optional (tax residency certificate, proof of residence, etc.)',
        register_page_step_fourteen_choose: 'Choose file',
    },
    ro: {
        register_page_step_fourteen_title: 'Incarcati Documentele',
        register_page_step_fourteen_form_title: 'Incarcati documentele mai jos',
        register_page_step_fourteen_desc_1: 'Pentru a finaliza procesul de deschidere online a contului va rugam sa incarcati documentele solicitate',
        register_page_step_fourteen_desc_3: 'Dupa ce ati semnat, va vom trimite pe mail confirmarea de activare a contului.',
        register_page_step_fourteen_id: 'Act Identitate',
        register_page_step_fourteen_bank: 'Extras cont bancar',
        register_page_step_fourteen_selfie: 'Selfie cu actul de identitate',
        register_page_step_fourteen_other: 'Alte Fisiere - optional (certificat de rezidenta fiscala, dovada domiciliu, etc.)',
        register_page_step_fourteen_choose: 'Alege fisier',
    },
};

const registerStep15Translations = {
    en: {
        register_page_step_fifteen_title: 'Registration was successfully submitted',
        register_page_step_fifteen_desc_1: 'As soon as we verify the data, if everything is in order, you will receive an account activation email.',
        register_page_step_fifteen_desc_2: 'We aim to start processing the documents you have sent us immediately. However, due to the very high number of requests during this period, the opening of your trading account may take a little longer than we would like.',
        register_page_step_fifteen_desc_3: 'We remind you that opening an account does not commit you to anything, and TradeVille does not charge you an account opening or administration fee.',
        register_page_step_fifteen_op_1: 'TradeVille',
        register_page_step_fifteen_op_2: 'School of investors',
        register_page_step_fifteen_op_3: 'Demo BVB',
        register_page_step_fifteen_op_4: 'Overview of ETFs',
    },
    ro: {
        register_page_step_fifteen_title: 'Inregistrarea a fost trimisa cu succes',
        register_page_step_fifteen_desc_1: 'Imediat ce verificam datele, daca acestea sunt in ordine, veti primi un e-mail de activare a contului.',
        register_page_step_fifteen_desc_2: 'Ne propunem sa incepem imediat procesarea documentelor pe care ni le-ati trimis. Totusi, dat fiind numarul foarte mare de solicitari din aceasta perioada, deschiderea contului dumneavoastra de tranzactionare ar putea dura mai mult decat ne dorim.',
        register_page_step_fifteen_desc_3: 'Va reamintim ca deschiderea de cont nu va angajeaza cu nimic, iar TradeVille nu va percepe taxa de deschidere cont sau de administrare.',
        register_page_step_fifteen_op_1: 'TradeVille',
        register_page_step_fifteen_op_2: 'Scoala de investitori',
        register_page_step_fifteen_op_3: 'Demo BVB',
        register_page_step_fifteen_op_4: 'Prezentare ETF-uri',
    },
};

const registerStep16Translations = {
    en: {
        register_page_step_sixteen_title: 'Account opening documents',
        register_page_step_sixteen_form_title: 'Upload the documents below',
        register_page_step_sixteen_desc_1: 'Download, complete and sign the files below. Then upload the files in the attached section. You can upload one or more pdfs.',
        register_page_step_sixteen_desc_2: 'Account Application Form',
        register_page_step_sixteen_desc_3: 'StartradeRO Transaction Contract',
        register_page_step_sixteen_desc_4: 'STARTRADE RO - Fees and Commissions',
        register_page_step_sixteen_desc_5: 'StartradeINTL Transaction Contract',
        register_page_step_sixteen_desc_6: 'STARTRADE INTL - Fees and Commissions',
        register_page_step_sixteen_desc_7: 'Real beneficiary declaration and the activity carried out',
        register_page_step_sixteen_desc_8: 'Fiscal Information Declaration',
        register_page_step_sixteen_desc_9: 'To complete the account opening, in the next step you will have to upload a series of documents.',
        register_page_step_sixteen_item: 'Completed files',
        register_page_step_sixteen_choose: 'Choose files',
    },
    ro: {
        register_page_step_sixteen_title: 'Documente deschidere cont',
        register_page_step_sixteen_form_title: 'Incarcati documentele mai jos',
        register_page_step_sixteen_desc_1: 'Descarcati, completati si semnati fisierele de mai jos. Apoi incarcati fisierele in sectiunea alaturata. Puteti incarca unul sau mai multe pdf-uri.',
        register_page_step_sixteen_desc_2: 'Formular deschidere cont',
        register_page_step_sixteen_desc_3: 'Contract de Tranzactionare StartradeRO',
        register_page_step_sixteen_desc_4: 'Taxe si comisioane StartradeRO',
        register_page_step_sixteen_desc_5: 'Contract de Tranzactionare StartradeINTL',
        register_page_step_sixteen_desc_6: 'Taxe si comisioane StartradeINTL',
        register_page_step_sixteen_desc_7: 'Declaratie beneficiar real si activitatea desfasurata',
        register_page_step_sixteen_desc_8: 'Declaratie privind Informatiile Fiscale',
        register_page_step_sixteen_desc_9: 'Pentru a finaliza deschiderea de cont, la pasul urmator va trebui sa incarcati o serie de documente.',
        register_page_step_sixteen_item: 'Fisiere Completate',
        register_page_step_sixteen_choose: 'Alege Fisiere',
    },
};

const registerStep17Translations = {
    en: {
        register_page_step_seventeen_title: 'Account opening documents',
        register_page_step_seventeen_form_title: 'Upload the documents below',
        register_page_step_seventeen_desc_1: 'Upload the following documents',
        register_page_step_seventeen_desc_2: 'Copy of the "Unique Company Registration Certificate" (CUI);',
        register_page_step_seventeen_desc_3: 'A bank statement related to the declared account (not older than 6 months);',
        register_page_step_seventeen_desc_4: 'Copy of the company\'s Articles of Incorporation / the company contract and the company\'s statutes. If it is not clear from the articles of incorporation of the company who is the representative or administrator of the company with full powers, then the documents certifying this shall be provided (eg: AGM decisions, additional documents);',
        register_page_step_seventeen_desc_5: 'Copy of the "Assessment Certificate issued by ONRC" (not older than 1 month);',
        register_page_step_seventeen_desc_6: 'Copies of the identity documents of the legal representative (administrator or authorized representative) and the real beneficiaries.',
        register_page_step_seventeen_desc_7: 'LEI code (issued by the Central Depository or another authorized entity).',
        register_page_step_seventeen_desc_8: 'Optional',
        register_page_step_seventeen_desc_9: 'Documents from which it can be seen that they are classified as professional clients under the effect of Law 126/2018 on the markets of financial instruments, if applicable.',
        register_page_step_seventeen_desc_10: 'Power of attorney for the representative appointed in relation to TradeVille from the company, if he is not its legal representative;',
        register_page_step_seventeen_item_1: '1. Copy of the tax identification number',
        register_page_step_seventeen_item_2: '2. Bank statement',
        register_page_step_seventeen_item_3: '3. Copy of the constitutive document',
        register_page_step_seventeen_item_4: '4. ONRC (National Trade Register Office) certificate',
        register_page_step_seventeen_item_5: '5. Representative and beneficiary documents',
        register_page_step_seventeen_item_6: '6. LEI Code',
        register_page_step_seventeen_other: '7. Other Files',
        register_page_step_seventeen_choose: 'Choose file',
    },
    ro: {
        register_page_step_seventeen_title: 'Documente deschidere cont',
        register_page_step_seventeen_form_title: 'Incarcati documentele mai jos',
        register_page_step_seventeen_desc_1: 'Incarcati urmatoarele documente',
        register_page_step_seventeen_desc_2: 'Copia "Certificatului unic de inregistrare al societatii" (CUI);',
        register_page_step_seventeen_desc_3: 'Un extras bancar aferent contului declarat (nu mai vechi de 6 luni);',
        register_page_step_seventeen_desc_4: 'Copia dupa Actului constitutiv al societatii /contractul de societate si statutul societatii. Daca din actul constitutiv al societatii nu reiese clar cine este reprezentantul sau administratorul societatii cu puteri depline, atunci se vor furniza documentele care atesta acest lucru (ex: hotarari AGA, acte aditionale);',
        register_page_step_seventeen_desc_5: 'Copia "Certificatului constatator emis de ONRC" (nu mai vechi de 1 luna);',
        register_page_step_seventeen_desc_6: 'Copii dupa documentele de identitate ale reprezentantului legal (administrator sau imputernicit) si a beneficiarilor reali.',
        register_page_step_seventeen_desc_7: 'Codul LEI (emis de catre Depozitarul Central sau alta entitate autorizata).',
        register_page_step_seventeen_desc_8: 'Optional',
        register_page_step_seventeen_desc_9: 'Documente din care sa reiasa incadrarea in categoria clientilor profesionali prin efectul Legii 126/2018 privind pietele de instrumente financiare, daca este cazul.',
        register_page_step_seventeen_desc_10: 'Procura de imputernicire a reprezentantului desemnat in relatia cu TradeVille din partea societatii, in cazul in care acesta nu este reprezentantul legal al acesteia;',
        register_page_step_seventeen_item_1: '1. Copie CUI',
        register_page_step_seventeen_item_2: '2. Extras cont bancar',
        register_page_step_seventeen_item_3: '3. Copie act constitutiv',
        register_page_step_seventeen_item_4: '4. Certificat constatator ONRC',
        register_page_step_seventeen_item_5: '5. Documente reprezentant si beneficiar',
        register_page_step_seventeen_item_6: '6. Cod LEI',
        register_page_step_seventeen_other: '7. Alte Fisiere',
        register_page_step_seventeen_choose: 'Alege Fisier',
    },
};

const professionsTranslations = {
    en: {
        profession_1: 'Manager, director, administrator',
        profession_2: 'In the financial-banking field, financial analyst, actuary',
        profession_3: 'Auditor, tax consultant, accountant, lawyer, broker',
        profession_4: 'IT&C specialist (system/database administrator, system engineer/architect, etc.)',
        profession_5: 'Lawyer, notary, legal advisor',
        profession_6: 'Diplomat (ambassador, chargÃ© d\'affaires, consul, etc.)',
        profession_7: 'Doctor, assistant/medical technician, pharmacist/pharmacy assistant, paramedic',
        profession_8: 'Engineer/Technician, production operator, inspector',
        profession_9: 'Mechanic, plumber, electrician, bricklayer, construction worker, etc',
        profession_10: 'Professor, university assistant, lecturer, referent, teacher',
        profession_11: 'Public servant',
        profession_12: 'Consultant, project manager',
        profession_13: 'Stock market agent, capital market specialist, risk capital agent',
        profession_14: 'Marketing specialist, PR, spokesperson, secretary',
        profession_15: 'Cook, pastry chef, waiter, cleaning employee, receptionist, predefined tourist guide',
        profession_16: 'Farmer/Fisherman (horticulturist, beekeeper, forester, woodcutter, animal breeder, etc)',
        profession_17: 'Sales agent, customer relations, commercial attachÃ©, buyer',
        profession_18: 'Transporter (Driver, delivery person, logistics manager)',
        profession_19: 'Navigator, Captain, Commander, Pilot/Copilot, Flight Attendant',
        profession_20: 'Architect, designer, interior designer',
        profession_21: 'HR Specialist, Human Resources Inspector, Payroll',
        profession_22: 'Financial analyst, actuary',
        profession_23: 'Marketing, communication, PR',
        profession_24: 'MApN officer/employee, MAI',
        profession_25: 'Artist (actor, musician, etc.)',
        profession_26: 'Athlete/coach',
        profession_27: 'Student, elev/Student, pupil',
        profession_28: 'Entrepreneur, freelancer',
        profession_29: 'Retired',
        profession_30: 'No occupation (homemaker, unemployed, etc.)',
    },
    ro: {
        profession_1: 'Manager, director, administrator',
        profession_2: 'Economist, specialist in dom. financiar-bancar, analist financiar, actuar',
        profession_3: 'Auditor, consultant fiscal, contabil, jurist, broker',
        profession_4: 'Specialist IT&C (administrator sistem/baza de date, inginer/arhitect sistem, etc)',
        profession_5: 'Avocat, notar, consilier juridic',
        profession_6: 'Diplomat (ambasador, insarcinat cu afaceri, consul, etc)',
        profession_7: 'Medic, asistent/tehnician medical, farmacist/asistent farmacie, ambulantier',
        profession_8: 'Inginer/Tehnician, operator productie, inspector',
        profession_9: 'Mecanic, instalator, electrician, muncitor zidarie, santier, etc',
        profession_10: 'Profesor, asistent universitar, lector, referend, invatator',
        profession_11: 'Functionar public',
        profession_12: 'Consultant, manager proiect',
        profession_13: 'Agent bursa, specialist pentru piata de capital, agent capital de risc',
        profession_14: 'Specialist marketing, PR, purtator de cuvant, secretar',
        profession_15: 'Bucatar, patiser, chelner, angajat in curatenie, receptioner, ghid turistic',
        profession_16: 'Agricultor/Pescar (horticultor, apicultor, silvicultor, taietor de lemne, crescator de animale, etc)',
        profession_17: 'Agent vanzari, relatii clienti, atasat comercial, cumparator',
        profession_18: 'Transportator (Sofer, livrator, responsabil logistica)',
        profession_19: 'Nagivator, Capitan, Comandant, Pilot/Copilot, Insotitor de bord',
        profession_20: 'Arhitect, proiectant, designer interior',
        profession_21: 'HR Specialist, Inspector resurse umane, Salarizare',
        profession_22: 'Analist financiar, actuar',
        profession_23: 'Marketing, comunicare, PR',
        profession_24: 'Ofiter/angajat MApN, MAI',
        profession_25: 'Artist (actor, muzician, etc)',
        profession_26: 'Sportiv/antrenor',
        profession_27: 'Student, elev/Student, pupil',
        profession_28: 'Antreprenor, liber profesionist',
        profession_29: 'Pensionar',
        profession_30: 'Fara ocupatie (casnic, somer etc)',
    },
};

const domainTranslations = {
    en: {
        domain_1: 'Constructions/real estate',
        domain_2: 'Pharmaceutical/health',
        domain_3: 'Education',
        domain_4: 'Banking',
        domain_5: 'IT/Software, telecommunications',
        domain_6: 'Industry',
        domain_7: 'Architecture',
        domain_8: 'Agriculture, Marine and Fishing',
        domain_9: 'Trade',
        domain_10: 'Land/sea transport, Logistics and Tourism',
        domain_11: 'Hospitality and Public Food',
        domain_12: 'Cultural and artistic',
        domain_13: 'Sport activities',
        domain_14: 'Legal, notarial services',
        domain_15: 'Accounting, audit, tax consulting services',
        domain_16: 'Public institution/authority, political activities, public procurement',
        domain_17: 'Capital market',
        domain_18: 'Diplomacy',
        domain_19: 'Romania\'s national defense sector',
        domain_20: 'Human resources / Marketing, Communication and PR',
        domain_21: 'Casino, gambling',
        domain_22: 'Exchange office',
        domain_23: 'Trade in precious metals',
        domain_24: 'Arms trade and the defense sector, from outside Romania',
        domain_25: 'NGO (association / foundation / other fiduciary street)',
        domain_26: 'Courier, distribution, transport of goods and people',
        domain_27: 'Extractive industry',
        domain_28: 'Not applicable',
    },
    ro: {
        domain_1: 'Constructii/imobiliare',
        domain_2: 'Farmaceutic/sanatate',
        domain_3: 'Educatie',
        domain_4: 'Financiar-bancar',
        domain_5: 'IT/Software, telecomunicatii',
        domain_6: 'Industrie',
        domain_7: 'Arhitectura',
        domain_8: 'Agricultura, Marina si Pescuit',
        domain_9: 'Comert',
        domain_10: 'Transport terestru/naval, Logistica si Turism',
        domain_11: 'Hotelarie si Alimentatie publica',
        domain_12: 'Cultural-artistic',
        domain_13: 'Activitati sportive',
        domain_14: 'Servicii legale, notariale',
        domain_15: 'Servicii contabilitate, audit, consultanta fiscala',
        domain_16: 'Institutie/autoritate publica, activitati politice,achizitii publice',
        domain_17: 'Piata de capital',
        domain_18: 'Diplomatie',
        domain_19: 'Sectorul apararii nationale a Romaniei',
        domain_20: 'Resurse umane /Marketing, Comunicare si PR',
        domain_21: 'Cazinou, jocuri de noroc',
        domain_22: 'Casa de schimb valutar',
        domain_23: 'Comert cu metale pretioase',
        domain_24: 'Comert cu arme si sectorul apararii, din afara RO',
        domain_25: 'ONG (asociatie / fundatie / alta str. fiduciara)',
        domain_26: 'Curierat, distributie, transport marfuri si pers.',
        domain_27: 'Industrie extractiva',
        domain_28: 'Nu se aplica',
    },
};

const ownActivityTranslations = {
    en: {
        own_activity_1: 'Authorized person',
        own_activity_2: 'Microenterprise',
        own_activity_3: 'Association/Family business',
        own_activity_4: 'Independent activities',
    },
    ro: {
        own_activity_1: 'Persoana fizica autorizata',
        own_activity_2: 'Microinterprindere',
        own_activity_3: 'Asociatie/Interprindere familiala',
        own_activity_4: 'Activitati independente',
    },
};

const educationLevelTranslations = {
    en: {
        education_level_1: '10 classes',
        education_level_2: 'High school',
        education_level_3: 'Post-secondary school',
        education_level_4: 'University',
        education_level_5: 'Postgraduate',
    },
    ro: {
        education_level_1: '10 clase',
        education_level_2: 'Liceu',
        education_level_3: 'Scoala postliceala',
        education_level_4: 'Universitare',
        education_level_5: 'Postuniversitare',
    },
};

const annualIncomeTranslations = {
    en: {
        annual_income_1: 'under 6.000 EUR',
        annual_income_2: '6.001 - 12.000 EUR',
        annual_income_3: '12.001 - 24.000 EUR',
        annual_income_4: '24.001 - 48.000 EUR',
        annual_income_5: '48.001 - 120.000 EUR',
        annual_income_6: 'over 120.000 EUR',
    },
    ro: {
        annual_income_1: 'sub 6.000 EUR',
        annual_income_2: '6.001 - 12.000 EUR',
        annual_income_3: '12.001 - 24.000 EUR',
        annual_income_4: '24.001 - 48.000 EUR',
        annual_income_5: '48.001 - 120.000 EUR',
        annual_income_6: 'peste 120.000 EUR',
    },
};

const estimateTranslations = {
    en: {
        estimate_1: 'under 10%',
        estimate_2: '10% - 30%',
        estimate_3: '30% - 50%',
        estimate_4: '50% - 70%',
        estimate_5: '70% - 90%',
        estimate_6: 'over 90%',
    },
    ro: {
        estimate_1: 'sub 10%',
        estimate_2: '10% - 30%',
        estimate_3: '30% - 50%',
        estimate_4: '50% - 70%',
        estimate_5: '70% - 90%',
        estimate_6: 'peste 90%',
    },
};

const experienceTranslations = {
    en: {
        experience_1: '1 - 3 months',
        experience_2: '3 - 6 months',
        experience_3: '6 - 12 months',
        experience_4: '1 - 3 months',
        experience_5: 'over 3 years',
    },
    ro: {
        experience_1: '1 - 3 luni',
        experience_2: '3 - 6 luni',
        experience_3: '6 - 12 luni',
        experience_4: '1 - 3 ani',
        experience_5: 'peste 3 ani',
    },
};

const riskTranslations = {
    en: {
        risk_1: 'Low',
        risk_2: 'Medium',
        risk_3: 'Speculative',
    },
    ro: {
        risk_1: 'Scazut',
        risk_2: 'Mediu',
        risk_3: 'Speculativ',
    },
};

const internationalPhoneCountriesTranslations = {
    en: {
        international_phone_1: 'Afghanistan (+93)',
        international_phone_2: 'South Africa (+27)',
        international_phone_3: 'Albania (+355)',
        international_phone_4: 'Algeria (+213)',
        international_phone_5: 'Andorra (+376)',
        international_phone_6: 'Angola (+244)',
        international_phone_7: 'Anguilla (+1264)',
        international_phone_8: 'Antigua and Barbuda (+1268)',
        international_phone_9: 'Netherlands Antilles (+599)',
        international_phone_10: 'Saudi Arabia (+966)',
        international_phone_11: 'Argentina (+54)',
        international_phone_12: 'Armenia (+374)',
        international_phone_13: 'Aruba (+297)',
        international_phone_14: 'Australia (+61)',
        international_phone_15: 'Austria (+43)',
        international_phone_16: 'Azerbaijan (+994)',
        international_phone_17: 'Bahamas (+1242)',
        international_phone_18: 'Bahrain (+973)',
        international_phone_19: 'Bangladesh (+880)',
        international_phone_20: 'Barbados (+1246)',
        international_phone_21: 'Belarus (+375)',
        international_phone_22: 'Belgium (+32)',
        international_phone_23: 'Belize (+501)',
        international_phone_24: 'Benin (+229)',
        international_phone_25: 'Bermuda (+1441)',
        international_phone_26: 'Bhutan (+975)',
        international_phone_27: 'Bolivia (+591)',
        international_phone_28: 'Bosnia and Herzegovina (+387)',
        international_phone_29: 'Botswana (+267)',
        international_phone_30: 'Brazil (+55)',
        international_phone_31: 'Brunei (+673)',
        international_phone_32: 'Bulgaria (+359)',
        international_phone_33: 'Burkina Faso (+226)',
        international_phone_34: 'Burundi (+257)',
        international_phone_35: 'Cambodia (+855)',
        international_phone_36: 'Cameroon (+237)',
        international_phone_37: 'Canada (+1)',
        international_phone_38: 'Cape Verde (+238)',
        international_phone_39: 'Cayman Islands (+1345)',
        international_phone_40: 'Chile (+56)',
        international_phone_41: 'China (+86)',
        international_phone_42: 'Cyprus (+357)',
        international_phone_43: 'Colombia (+57)',
        international_phone_44: 'Comoros (+269)',
        international_phone_45: 'Congo (+242)',
        international_phone_46: 'North Korea (+850)',
        international_phone_47: 'South Korea (+82)',
        international_phone_48: 'Costa Rica (+506)',
        international_phone_49: 'Ivory Coast (+225)',
        international_phone_50: 'Croatia (+385)',
        international_phone_51: 'Cuba (+53)',
        international_phone_52: 'Denmark (+45)',
        international_phone_53: 'Dominica (+1767)',
        international_phone_54: 'Ecuador (+593)',
        international_phone_55: 'Egypt (+20)',
        international_phone_56: 'El Salvador (+503)',
        international_phone_57: 'United Arab Emirates (+971)',
        international_phone_58: 'Eritrea (+291)',
        international_phone_59: 'Estonia (+372)',
        international_phone_60: 'Ethiopia (+251)',
        international_phone_61: 'Fiji (+679)',
        international_phone_62: 'Philippines (+63)',
        international_phone_63: 'Finland (+358)',
        international_phone_64: 'France (+33)',
        international_phone_65: 'Gabon (+241)',
        international_phone_66: 'Gambia (+220)',
        international_phone_67: 'Georgia (+995)',
        international_phone_68: 'Germany (+49)',
        international_phone_69: 'Ghana (+233)',
        international_phone_70: 'Gibraltar (+350)',
        international_phone_71: 'Greece (+30)',
        international_phone_72: 'Grenada (+1473)',
        international_phone_73: 'Greenland (+299)',
        international_phone_74: 'Guadeloupe (+590)',
        international_phone_75: 'Guam (+1671)',
        international_phone_76: 'Guatemala (+502)',
        international_phone_77: 'Guernsey (+44)',
        international_phone_78: 'Guinea (+224)',
        international_phone_79: 'Equatorial Guinea (+240)',
        international_phone_80: 'Guinea-Bissau (+245)',
        international_phone_81: 'Guyana (+592)',
        international_phone_82: 'French Guiana (+594)',
        international_phone_83: 'Haiti (+509)',
        international_phone_84: 'Honduras (+504)',
        international_phone_85: 'India (+91)',
        international_phone_86: 'Indonesia (+62)',
        international_phone_87: 'Jersey (+44)',
        international_phone_88: 'Kazakhstan (+7)',
        international_phone_89: 'Kenya (+254)',
        international_phone_90: 'Kiribati (+686)',
        international_phone_91: 'Kuwait (+965)',
        international_phone_92: 'Kyrgyzstan (+996)',
        international_phone_93: 'Laos (+856)',
        international_phone_94: 'Lesotho (+266)',
        international_phone_95: 'Latvia (+371)',
        international_phone_96: 'Lebanon (+961)',
        international_phone_97: 'Liberia (+231)',
        international_phone_98: 'Libya (+218)',
        international_phone_99: 'Liechtenstein (+423)',
        international_phone_100: 'Lithuania (+370)',
        international_phone_101: 'Luxembourg (+352)',
        international_phone_102: 'Macao (+853)',
        international_phone_103: 'Macedonia (+389)',
        international_phone_104: 'Madagascar (+261)',
        international_phone_105: 'Malawi (+265)',
        international_phone_106: 'Malaysia (+60)',
        international_phone_107: 'Maldives (+960)',
        international_phone_108: 'Mali (+223)',
        international_phone_109: 'Malta (+356)',
        international_phone_110: 'Morocco (+212)',
        international_phone_111: 'Martinique (+596)',
        international_phone_112: 'Mauritania (+222)',
        international_phone_113: 'Mauritius (+230)',
        international_phone_114: 'Mayotte (+262)',
        international_phone_115: 'Moldova (+373)',
        international_phone_116: 'Monaco (+377)',
        international_phone_117: 'Mongolia (+976)',
        international_phone_118: 'Montenegro (+382)',
        international_phone_119: 'Mozambique (+258)',
        international_phone_120: 'Myanmar (+95)',
        international_phone_121: 'Mexico (+52)',
        international_phone_122: 'Namibia (+264)',
        international_phone_123: 'Nauru (+674)',
        international_phone_124: 'Nepal (+977)',
        international_phone_125: 'Nicaragua (+505)',
        international_phone_126: 'Niger (+227)',
        international_phone_127: 'Nigeria (+234)',
        international_phone_128: 'Niue (+683)',
        international_phone_129: 'Norway (+47)',
        international_phone_130: 'New Caledonia (+687)',
        international_phone_131: 'New Zealand (+64)',
        international_phone_132: 'Netherlands (+31)',
        international_phone_133: 'Oman (+968)',
        international_phone_134: 'Pakistan (+92)',
        international_phone_135: 'Palau (+680)',
        international_phone_136: 'Palestine (+970)',
        international_phone_137: 'Panama (+507)',
        international_phone_138: 'Papua New Guinea (+675)',
        international_phone_139: 'Paraguay (+595)',
        international_phone_140: 'Peru (+51)',
        international_phone_141: 'French Polynesia (+689)',
        international_phone_142: 'Poland (+48)',
        international_phone_143: 'Portugal (+351)',
        international_phone_144: 'Puerto Rico (+1787)',
        international_phone_145: 'Qatar (+974)',
        international_phone_146: 'Central African Republic (+236)',
        international_phone_147: 'Czech Republic (+420)',
        international_phone_148: 'Dominican Republic (+1849)',
        international_phone_149: 'Moldova (+373)',
        international_phone_150: 'China (+86)',
        international_phone_151: 'Tanzania (+255)',
        international_phone_152: 'Reunion (+262)',
        international_phone_153: 'Romania (+40)',
        international_phone_154: 'Russia (+7)',
        international_phone_155: 'Rwanda (+250)',
        international_phone_156: 'Western Sahara (+212)',
        international_phone_157: 'Saint Kitts and Nevis (+1869)',
        international_phone_158: 'Saint Lucia (+1758)',
        international_phone_159: 'Saint Pierre and Miquelon (+508)',
        international_phone_160: 'Saint Vincent and the Grenadines (+1784)',
        international_phone_161: 'Samoa (+685)',
        international_phone_162: 'American Samoa (+1684)',
        international_phone_163: 'San Marino (+378)',
        international_phone_164: 'Sao Tome and Principe (+239)',
        international_phone_165: 'Senegal (+221)',
        international_phone_166: 'Serbia (+381)',
        international_phone_167: 'Seychelles (+248)',
        international_phone_168: 'Sierra Leone (+232)',
        international_phone_169: 'Singapore (+65)',
        international_phone_170: 'Slovakia (+421)',
        international_phone_171: 'Slovenia (+386)',
        international_phone_172: 'Solomon Islands (+677)',
        international_phone_173: 'Somalia (+252)',
        international_phone_174: 'Sri Lanka (+94)',
        international_phone_175: 'United States (+1)',
        international_phone_176: 'Sudan (+249)',
        international_phone_177: 'South Sudan (+211)',
        international_phone_178: 'Suriname (+597)',
        international_phone_179: 'Svalbard and Jan Mayen (+47)',
        international_phone_180: 'Swaziland (+268)',
        international_phone_181: 'Sweden (+46)',
        international_phone_182: 'Switzerland (+41)',
        international_phone_183: 'Syria (+963)',
        international_phone_184: 'Tajikistan (+992)',
        international_phone_185: 'Taiwan (+886)',
        international_phone_186: 'Tanzania (+255)',
        international_phone_187: 'Thailand (+66)',
        international_phone_188: 'East Timor (+670)',
        international_phone_189: 'Togo (+228)',
        international_phone_190: 'Tokelau (+690)',
        international_phone_191: 'Tonga (+676)',
        international_phone_192: 'Trinidad and Tobago (+1868)',
        international_phone_193: 'Tunisia (+216)',
        international_phone_194: 'Turkey (+90)',
        international_phone_195: 'Turkmenistan (+993)',
        international_phone_196: 'Turks and Caicos Islands (+1649)',
        international_phone_197: 'Tuvalu (+688)',
        international_phone_198: 'Ukraine (+380)',
        international_phone_199: 'Uganda (+256)',
        international_phone_200: 'Hungary (+36)',
        international_phone_201: 'Uruguay (+598)',
        international_phone_202: 'Uzbekistan (+998)',
        international_phone_203: 'Vanuatu (+678)',
        international_phone_204: 'Vatican City (+39)',
        international_phone_205: 'Venezuela (+58)',
        international_phone_206: 'Vietnam (+84)',
        international_phone_207: 'Wallis and Futuna (+681)',
        international_phone_208: 'Yemen (+967)',
        international_phone_209: 'Zambia (+260)',
        international_phone_210: 'Zimbabwe (+263)',
    },
    ro: {
        international_phone_1: 'Afganistan (+93)',
        international_phone_2: 'Africa de Sud (+27)',
        international_phone_3: 'Albania (+355)',
        international_phone_4: 'Algeria (+213)',
        international_phone_5: 'Andorra (+376)',
        international_phone_6: 'Angola (+244)',
        international_phone_7: 'Anguilla (+1264)',
        international_phone_8: 'Antigua si Barbuda (+1268)',
        international_phone_9: 'Antilele Olandeze (+599)',
        international_phone_10: 'Arabia Saudita (+966)',
        international_phone_11: 'Argentina (+54)',
        international_phone_12: 'Armenia (+374)',
        international_phone_13: 'Aruba (+297)',
        international_phone_14: 'Australia (+61)',
        international_phone_15: 'Austria (+43)',
        international_phone_16: 'Azerbaidjan (+994)',
        international_phone_17: 'Bahamas (+1242)',
        international_phone_18: 'Bahrain (+973)',
        international_phone_19: 'Bangladesh (+880)',
        international_phone_20: 'Barbados (+1246)',
        international_phone_21: 'Belarus (+375)',
        international_phone_22: 'Belgia (+32)',
        international_phone_23: 'Belize (+501)',
        international_phone_24: 'Benin (+229)',
        international_phone_25: 'Bermuda (+1441)',
        international_phone_26: 'Bhutan (+975)',
        international_phone_27: 'Bolivia (+591)',
        international_phone_28: 'Bosnia si Hertegovina (+387)',
        international_phone_29: 'Botswana (+267)',
        international_phone_30: 'Brazilia (+55)',
        international_phone_31: 'Brunei (+673)',
        international_phone_32: 'Bulgaria (+359)',
        international_phone_33: 'Burkina Faso (+226)',
        international_phone_34: 'Burundi (+257)',
        international_phone_35: 'Cambodgia (+855)',
        international_phone_36: 'Camerun (+237)',
        international_phone_37: 'Canada (+1)',
        international_phone_38: 'Capul Verde (+238)',
        international_phone_39: 'Cayman (+1345)',
        international_phone_40: 'Chile (+56)',
        international_phone_41: 'China (+86)',
        international_phone_42: 'Cipru (+357)',
        international_phone_43: 'Columbia (+57)',
        international_phone_44: 'Comore (+269)',
        international_phone_45: 'Congo (+242)',
        international_phone_46: 'Coreea de Nord (+850)',
        international_phone_47: 'Coreea de Sud (+82)',
        international_phone_48: 'Costa Rica (+506)',
        international_phone_49: 'Cote d\'Ivoire (+225)',
        international_phone_50: 'Croația (+385)',
        international_phone_51: 'Cuba (+53)',
        international_phone_52: 'Danemarca (+45)',
        international_phone_53: 'Dominica (+1767)',
        international_phone_54: 'Ecuador (+593)',
        international_phone_55: 'Egipt (+20)',
        international_phone_56: 'El Salvador (+503)',
        international_phone_57: 'Emiratele Arabe Unite (+971)',
        international_phone_58: 'Eritreea (+291)',
        international_phone_59: 'Estonia (+372)',
        international_phone_60: 'Etiopia (+251)',
        international_phone_61: 'Fiji (+679)',
        international_phone_62: 'Filipine (+63)',
        international_phone_63: 'Finlanda (+358)',
        international_phone_64: 'Franta (+33)',
        international_phone_65: 'Gabon (+241)',
        international_phone_66: 'Gambia (+220)',
        international_phone_67: 'Georgia (+995)',
        international_phone_68: 'Germania (+49)',
        international_phone_69: 'Ghana (+233)',
        international_phone_70: 'Gibraltar (+350)',
        international_phone_71: 'Grecia (+30)',
        international_phone_72: 'Grenada (+1473)',
        international_phone_73: 'Groenlanda (+299)',
        international_phone_74: 'Guadelupa (+590)',
        international_phone_75: 'Guam (+1671)',
        international_phone_76: 'Guatemala (+502)',
        international_phone_77: 'Guernsey (+44)',
        international_phone_78: 'Guinea (+224)',
        international_phone_79: 'Guinea Ecuatorială (+240)',
        international_phone_80: 'Guinea-Bissau (+245)',
        international_phone_81: 'Guyana (+592)',
        international_phone_82: 'Guyana Franceză (+594)',
        international_phone_83: 'Haiti (+509)',
        international_phone_84: 'Honduras (+504)',
        international_phone_85: 'India (+91)',
        international_phone_86: 'Indonezia (+62)',
        international_phone_87: 'Jersey (+44)',
        international_phone_88: 'Kazahstan (+7)',
        international_phone_89: 'Kenya (+254)',
        international_phone_90: 'Kiribati (+686)',
        international_phone_91: 'Kuweit (+965)',
        international_phone_92: 'Kârgâzstan (+996)',
        international_phone_93: 'Laos (+856)',
        international_phone_94: 'Lesotho (+266)',
        international_phone_95: 'Letonia (+371)',
        international_phone_96: 'Liban (+961)',
        international_phone_97: 'Liberia (+231)',
        international_phone_98: 'Libia (+218)',
        international_phone_99: 'Liechtenstein (+423)',
        international_phone_100: 'Lituania (+370)',
        international_phone_101: 'Luxemburg (+352)',
        international_phone_102: 'Macao (+853)',
        international_phone_103: 'Macedonia (+389)',
        international_phone_104: 'Madagascar (+261)',
        international_phone_105: 'Malawi (+265)',
        international_phone_106: 'Malaezia (+60)',
        international_phone_107: 'Maldive (+960)',
        international_phone_108: 'Mali (+223)',
        international_phone_109: 'Malta (+356)',
        international_phone_110: 'Maroc (+212)',
        international_phone_111: 'Martinica (+596)',
        international_phone_112: 'Mauritania (+222)',
        international_phone_113: 'Mauritius (+230)',
        international_phone_114: 'Mayotte (+262)',
        international_phone_115: 'Moldova (+373)',
        international_phone_116: 'Monaco (+377)',
        international_phone_117: 'Mongolia (+976)',
        international_phone_118: 'Muntenegru (+382)',
        international_phone_119: 'Mozambic (+258)',
        international_phone_120: 'Myanmar (+95)',
        international_phone_121: 'Mexic (+52)',
        international_phone_122: 'Namibia (+264)',
        international_phone_123: 'Nauru (+674)',
        international_phone_124: 'Nepal (+977)',
        international_phone_125: 'Nicaragua (+505)',
        international_phone_126: 'Niger (+227)',
        international_phone_127: 'Nigeria (+234)',
        international_phone_128: 'Niue (+683)',
        international_phone_129: 'Norvegia (+47)',
        international_phone_130: 'Noua Caledonie (+687)',
        international_phone_131: 'Noua Zeelandă (+64)',
        international_phone_132: 'Olanda (+31)',
        international_phone_133: 'Oman (+968)',
        international_phone_134: 'Pakistan (+92)',
        international_phone_135: 'Palau (+680)',
        international_phone_136: 'Palestina (+970)',
        international_phone_137: 'Panama (+507)',
        international_phone_138: 'Papua Noua Guinee (+675)',
        international_phone_139: 'Paraguay (+595)',
        international_phone_140: 'Peru (+51)',
        international_phone_141: 'Polinezia Franceză (+689)',
        international_phone_142: 'Polonia (+48)',
        international_phone_143: 'Portugalia (+351)',
        international_phone_144: 'Puerto Rico (+1787)',
        international_phone_145: 'Qatar (+974)',
        international_phone_146: 'Republica Centrafricană (+236)',
        international_phone_147: 'Republica Cehă (+420)',
        international_phone_148: 'Republica Dominicană (+1849)',
        international_phone_149: 'Republica Moldova (+373)',
        international_phone_150: 'Republica Populară Chineză (+86)',
        international_phone_151: 'Republica Unită Tanzania (+255)',
        international_phone_152: 'Reunion (+262)',
        international_phone_153: 'România (+40)',
        international_phone_154: 'Rusia (+7)',
        international_phone_155: 'Rwanda (+250)',
        international_phone_156: 'Sahara Occidentală (+212)',
        international_phone_157: 'Saint Kitts și Nevis (+1869)',
        international_phone_158: 'Saint Lucia (+1758)',
        international_phone_159: 'Saint Pierre și Miquelon (+508)',
        international_phone_160: 'Saint Vincent și Grenadine (+1784)',
        international_phone_161: 'Samoa (+685)',
        international_phone_162: 'Samoa Americană (+1684)',
        international_phone_163: 'San Marino (+378)',
        international_phone_164: 'Sao Tome și Principe (+239)',
        international_phone_165: 'Senegal (+221)',
        international_phone_166: 'Serbia (+381)',
        international_phone_167: 'Seychelles (+248)',
        international_phone_168: 'Sierra Leone (+232)',
        international_phone_169: 'Singapore (+65)',
        international_phone_170: 'Slovacia (+421)',
        international_phone_171: 'Slovenia (+386)',
        international_phone_172: 'Solomon (+677)',
        international_phone_173: 'Somalia (+252)',
        international_phone_174: 'Sri Lanka (+94)',
        international_phone_175: 'SUA (+1)',
        international_phone_176: 'Sudan (+249)',
        international_phone_177: 'Sudanul de Sud (+211)',
        international_phone_178: 'Surinam (+597)',
        international_phone_179: 'Svalbard și Jan Mayen (+47)',
        international_phone_180: 'Swaziland (+268)',
        international_phone_181: 'Suedia (+46)',
        international_phone_182: 'Elveția (+41)',
        international_phone_183: 'Siria (+963)',
        international_phone_184: 'Tadjikistan (+992)',
        international_phone_185: 'Taiwan (+886)',
        international_phone_186: 'Tanzania (+255)',
        international_phone_187: 'Thailanda (+66)',
        international_phone_188: 'Timorul de Est (+670)',
        international_phone_189: 'Togo (+228)',
        international_phone_190: 'Tokelau (+690)',
        international_phone_191: 'Tonga (+676)',
        international_phone_192: 'Trinidad și Tobago (+1868)',
        international_phone_193: 'Tunisia (+216)',
        international_phone_194: 'Turcia (+90)',
        international_phone_195: 'Turkmenistan (+993)',
        international_phone_196: 'Insulele Turks și Caicos (+1649)',
        international_phone_197: 'Tuvalu (+688)',
        international_phone_198: 'Ucraina (+380)',
        international_phone_199: 'Uganda (+256)',
        international_phone_200: 'Ungaria (+36)',
        international_phone_201: 'Uruguay (+598)',
        international_phone_202: 'Uzbekistan (+998)',
        international_phone_203: 'Vanuatu (+678)',
        international_phone_204: 'Vatican (+39)',
        international_phone_205: 'Venezuela (+58)',
        international_phone_206: 'Vietnam (+84)',
        international_phone_207: 'Wallis și Futuna (+681)',
        international_phone_208: 'Yemen (+967)',
        international_phone_209: 'Zambia (+260)',
        international_phone_210: 'Zimbabwe (+263)',
    },
};

const investmentTranslations = {
    en: {
        investment_1: 'under 6 months',
        investment_2: '6 - 12 months',
        investment_3: '1 - 3 months',
        investment_4: 'over 3 years',
    },
    ro: {
        investment_1: 'sub 6 luni',
        investment_2: '6 - 12 luni',
        investment_3: '1 - 3 ani',
        investment_4: 'peste 3 ani',
    },
};

const productTranslations = {
    en: {
        product_1: 'Romanian shares',
        product_2: 'International shares',
        product_3: 'Bonds',
        product_4: 'CFDs (indexes, stocks, commodities, ETFs, etc)',
        product_5: 'ETFs',
        product_6: 'CFD FOREX',
        product_7: 'Investment funds',
    },
    ro: {
        product_1: 'Actiuni romanesti',
        product_2: 'Actiuni internationale',
        product_3: 'Obligatiuni',
        product_4: 'CFD (indici, actiuni, marfuri, etf-uri, etc)',
        product_5: 'ETF-uri',
        product_6: 'CFD FOREX',
        product_7: 'Fonduri de investitii',
    },
};

const fundsTranslations = {
    en: {
        funds_1: 'Salaries and other salary rights (bonuses, profit-sharing)',
        funds_2: 'Income from authorized self-employed activites',
        funds_3: 'Dividend/interest income',
        funds_4: 'Income from capital market investments',
        funds_5: 'Income from pensions / allowances',
        funds_6: 'Income from grants / allowances (including from parents)',
        funds_7: 'Income from the sale / rental of movable or immovable property',
        funds_8: 'Income from intellectual property rights or copyright',
        funds_9: 'Bank loans and credits',
        funds_10: 'Donations / sponsorships',
        funds_11: 'Legacies',
        funds_12: 'Savings',
    },
    ro: {
        funds_1: 'Salarii si alte drepturi salariale (premii, participare la profit, profit, etc.)',
        funds_2: 'Venituri din activitati autorizate desfasurate pe cont propriu',
        funds_3: 'Venituri din dividende/dobanzi',
        funds_4: 'Venituri din investitii pe piata de capital',
        funds_5: 'Venituri din pensii, indemnizatii, somaj, ajutor social',
        funds_6: 'Venituri din burse, alocatii (inclusiv de la parinti)',
        funds_7: 'Venituri din vanzarea/inchirierea de bunuri mobile sau imobile',
        funds_8: 'Venituri din drepturi de proprietate intelectuala sau de autor',
        funds_9: 'Imprumuturi si credite bancare',
        funds_10: 'Donatii/sponsorizari',
        funds_11: 'Mosteniri',
        funds_12: 'Economii',

    },
};

const overlayersTranslations = {
    en: {
        register_overlayer_not_working_title: 'Make sure you have enabled "Cookies" in your browser.',
        register_overlayer_selfie_title: 'Send a selfie like in the following model:',
        register_overlayer_statement_title: 'Please attach an account statement issued by a credit institution (commercial bank) of which you are the owner and which contains your first and last name, IBAN code, currency. Account statements from Revolut, Orange Money are not accepted in this step (they can be attached to your account after you have made a top-up from a bank account).',
        register_overlayer_countries: 'The information in this form is required by TradeVille SA in order to comply with its obligations under Law no. 233/2015, Law no. 207/2015, Law no. 70/2016 and MFP Order no. 1939/2016, which implements in Romania the US FATCA Agreement - Foreign Accounting and Tax Compliance Act, OECD - Standard for the Automatic Exchange of Financial Account Information in Tax Matters and Directive 2011/16/EU regarding the mandatory automatic exchange of information in tax matters. The country in which the individual has tax obligations is the country of tax residency (as determined in accordance with local laws). In the U.S., an individual is resident for tax purposes if he or she is a U.S. citizen, if he or she was born in the U.S. and does not have a Certificate of Loss of Citizenship, if he or she has a domicile or permanent residence in the U.S., if he or she is a foreign national who has a substantial presence in the U.S, in accordance with the US Internal Revenue Code.',
        register_overlayer_countries_tax_declaration_1: '1. I am the beneficial owner of all income on this account (or I am authorized to sign for the natural person holding this account).',
        register_overlayer_countries_tax_declaration_2: '2. I am not resident for tax purposes in any other country not mentioned on this form.',
        register_overlayer_countries_tax_declaration_3: '3. I understand and accept that the data provided by this Declaration represent personal data, having an identification function, and I acknowledge and confirm their storage, processing and transmission by TradeVille S.A. to the competent Romanian tax authorities, the latter transferring them to the competent tax authorities of the countries with which Romania has committed itself through a legal instrument of international law to such an exchange of information.',
        register_overlayer_countries_tax_declaration_4: '4. I freely, informally, expressly and unequivocally consent that I am aware that my personal data will be stored by TradeVille SA according to the company\'s internal procedures and I freely, informally, expressly and unequivocally consent that I am aware of the provisions of Law no.677 /2001 for the protection of individuals with regard to the processing of personal data and the free movement of such data with regard to the right of access, the right of intervention, the right of opposition, the right not to be subject to an individual decision, the right to apply to the courts, as well as that they can be exercised by a written request addressed to the company, drawn up in accordance with the provisions of Law no.677/2001.',
        register_overlayer_countries_tax_declaration_note_1: 'Note: If the client is (1) a non-resident of Romania or (2) a citizen or resident for tax purposes of the United States of America, or (3) if the information provided in this form is incorrect or incomplete, his tax identification number (or similar) and/or information regarding his accounts with TradeVille SA, may be reported to a relevant tax authority anywhere in the world in accordance with the OECD Common Reporting Standard for Automatic Exchange of Financial Account Information or the US Foreign Accounting and Tax Compliance Act (FATCA).',
        register_overlayer_countries_tax_declaration_note_2: 'Note: TradeVille SA does not provide tax advice and you are responsible for ensuring that the information you provide to us regarding your tax situation is true and up to date at all times, substantial presence in the U.S. in accordance with the provisions of the U.S. Internal Revenue Code',
        register_overlayer_problems_title: 'Help',
        register_overlayer_problems_desc_part_1: 'Facing difficulties?',
        register_overlayer_problems_desc_part_2: 'Call ',
        register_overlayer_problems_desc_part_3: ' or write to ',
        register_overlayer_compensable_text_1: 'The following belong to this category:',
        register_overlayer_compensable_text_2: 'a) directors, administrators and members with direct responsibility from among the participants in the Fund, persons whose responsibility is the preparation of the audit of the participants in the Fund;',
        register_overlayer_compensable_text_3: 'b) shareholders of participants in the Fund having holdings of at least 5% of the share capital;',
        register_overlayer_compensable_text_4: 'c) investors with similar status to those mentioned in letter a) and b) within other companies in the same group as the Fund participants;',
        register_overlayer_compensable_text_5: 'd) spouses, relatives and relatives of the first degree, as well as persons acting on behalf of the investors mentioned in letters a)-c);',
        register_overlayer_compensable_text_6: 'e) persons who have a responsibility for or have obtained benefits from certain situations in relation to a participant in the Fund that led to the financial difficulties of the participant or were likely to lead to the deterioration of his financial situation.',
    },
    ro: {
        register_overlayer_not_working_title: 'Asigurati-va ca ati activat "Coookies" din browser.',
        register_overlayer_selfie_title: 'Trimiteti un selfie ca in modelul urmator:',
        register_overlayer_statement_title: 'Va rugam sa atasati un extras de cont emis de o institutie de credit (banca comerciala), al carui titular sunteti si care sa contina nume si prenume, cod IBAN, valuta. Nu sunt acceptate in acest pas extrase de cont de la Revolut, Orange Money (acestea pot fi atasate contului dumneavoastra dupa ce ati facut o alimentare dintr-un cont bancar).',
        register_overlayer_countries: 'Informatiile din prezentul formular sunt solicitate de SSIF TradeVille SA pentru respectarea obligatiilor ce ii revin in aplicarea Legii nr. 233/2015, Legii nr. 207/2015, Legii nr. 70/2016 si a Ordinului MFP nr. 1939/2016, care implementeaza in Romania Acordul FATCA SUA - Foreign Accounting and Tax Compliance Act, OECD - Standard for the Automatic Exchange of Financial Account Information in Tax Matters si Directiva 2011/16/UE in ceea ce priveste schimbul automat obligatoriu de informatii in domeniul fiscal. Tara in care persoana fizica are obligatii fiscale este tara de rezidenta fiscala (asa cum este determinat in conformitate cu legile locale). In SUA o persoana fizica este rezidenta in scopuri fiscale daca este cetatean SUA, daca s-a nascut in SUA si nu are un Certificat de Pierdere a Cetateniei, daca are domiciliul sau resedinta permanenta in SUA, daca este strain care are prezenta substantiala in SUA, in conformitate cu prevederile US Internal Revenue Code.',
        register_overlayer_countries_tax_declaration_1: '1. Sunt beneficiarul real al tuturor veniturilor aferente acestui cont (sau sunt autorizat sa semnez pentru persoana fizica detinatoare a acestui cont).',
        register_overlayer_countries_tax_declaration_2: '2. Nu sunt rezident fiscal in alte tari care nu sunt mentionate in acest formular.',
        register_overlayer_countries_tax_declaration_3: '3. Inteleg si accept faptul ca datele furnizate prin prezenta Declaratie reprezinta date cu caracter personal, avand functie de identificare, si recunosc si confirm stocarea, prelucrarea si transmiterea lor de catre S.S.I.F. TradeVille S.A. catre autoritatile fiscale romane competente, acestea din urma transferandu-le catre autoritatile fiscale competente din statele cu care Romania s-a angajat printr-un instrument juridic de drept international la un astfel de schimb de informatii.',
        register_overlayer_countries_tax_declaration_4: '4. Consimt in mod liber, informat, expres si neechivoc ca am luat la cunostinta faptul ca datele cu caracter personal vor fi stocate de SSIF TradeVille SA conform procedurilor interne ale societatii si consimt in mod liber, informat, expres si neechivoc ca am luat la cunostinta despre prevederile Legii nr.677/2001 pentru protectia persoanelor cu privire la prelucrarea datelor cu caracter personal si libera circulatie a acestor date cu privire la dreptul de acces, dreptul de interventie, dreptul de opozitie, dreptul de a nu fi supus unei decizii individuale, dreptul de a se adresa justitiei, precum si ca acestea pot fi exercitate printr-o cerere scriss adresata societatii, intocmita in conformitate cu dispozitiile Legii nr.677/2001.',
        register_overlayer_countries_tax_declaration_note_1: 'Nota: In cazul in care clientul este (1) nerezident in Romania sau (2) cetatean sau rezident in scopuri fiscale al Statelor Unite ale Americii, sau (3) in cazul in care informatiile furnizate in acest formular sunt incorecte sau incomplete, numarul sau de identificare fiscala (sau similar) si/sau informatiile cu privire la conturile sale cu SSIF TradeVille, ar putea fi raportate unei autoritati fiscale relevante oriunde in lume, in conformitate cu Standardul Comun de Raportare pentru Schimbul Automat de Informatii de Cont Financiar al OECD sau FATCA - Foreign Accounting and Tax Compliance Act al SUA.',
        register_overlayer_countries_tax_declaration_note_2: 'Nota: SSIF TradeVille nu ofera consultanta fiscala si sunteti responsabil pentru asigurarea faptului ca informatiile pe care ni le furnizati in legatura cu situatia dvs. fiscala sunt adevarate si actualizate in orice moment. prezenta substantiala in SUA, in conformitate cu prevederile US Internal Revenue Code',
        register_overlayer_problems_title: 'Ajutor',
        register_overlayer_problems_desc_part_1: 'Aveti dificultati?',
        register_overlayer_problems_desc_part_2: 'Sunati la ',
        register_overlayer_problems_desc_part_3: ' sau scrieti la ',
        register_overlayer_compensable_text_1: 'Din aceasta categorie fac parte:',
        register_overlayer_compensable_text_2: 'a) directori, administratori şi membri cu răspundere directă din cadrul participanţilor la Fond, persoane a căror responsabilitate este întocmirea auditului participanţilor la Fond;',
        register_overlayer_compensable_text_3: 'b) acţionarii participanţilor la Fond având deţineri de cel puţin 5% din capitalul social;',
        register_overlayer_compensable_text_4: 'c) investitori cu statut similar celor menţionaţi la lit. a) şi b) în cadrul altor societăţi din acelaşi grup cu participanţii la Fond;',
        register_overlayer_compensable_text_5: 'd) soţi, rude şi afini de gradul I inclusiv, precum şi persoane care acţionează în numele investitorilor menţionaţi la lit. a)-c);',
        register_overlayer_compensable_text_6: 'e) persoane care au o responsabilitate pentru sau au obţinut beneficii de pe urma anumitor situaţii în legătură cu un participant la Fond care au condus la dificultăţile financiare ale participantului sau au fost de natură să conducă la deteriorarea situaţiei financiare a acestuia.',
    },
};

const registerPageTranslations = {
    en: {
        ...registerStep1Translations.en,
        ...registerStep2Translations.en,
        ...registerStep3Translations.en,
        ...registerStep4Translations.en,
        ...registerStep5Translations.en,
        ...registerStep6Translations.en,
        ...registerStep7Translations.en,
        ...registerStep8Translations.en,
        ...registerStep9Translations.en,
        ...registerStep10Translations.en,
        ...registerStep11Translations.en,
        ...registerStep12Translations.en,
        ...registerStep13Translations.en,
        ...registerStep14Translations.en,
        ...registerStep15Translations.en,
        ...registerStep16Translations.en,
        ...registerStep17Translations.en,
        ...professionsTranslations.en,
        ...domainTranslations.en,
        ...ownActivityTranslations.en,
        ...educationLevelTranslations.en,
        ...annualIncomeTranslations.en,
        ...estimateTranslations.en,
        ...experienceTranslations.en,
        ...riskTranslations.en,
        ...investmentTranslations.en,
        ...productTranslations.en,
        ...fundsTranslations.en,
        ...overlayersTranslations.en,
        ...internationalPhoneCountriesTranslations.en,
    },
    ro: {
        ...registerStep1Translations.ro,
        ...registerStep2Translations.ro,
        ...registerStep3Translations.ro,
        ...registerStep4Translations.ro,
        ...registerStep5Translations.ro,
        ...registerStep6Translations.ro,
        ...registerStep7Translations.ro,
        ...registerStep8Translations.ro,
        ...registerStep9Translations.ro,
        ...registerStep10Translations.ro,
        ...registerStep11Translations.ro,
        ...registerStep12Translations.ro,
        ...registerStep13Translations.ro,
        ...registerStep14Translations.ro,
        ...registerStep15Translations.ro,
        ...registerStep16Translations.ro,
        ...registerStep17Translations.ro,
        ...professionsTranslations.ro,
        ...domainTranslations.ro,
        ...ownActivityTranslations.ro,
        ...educationLevelTranslations.ro,
        ...annualIncomeTranslations.ro,
        ...estimateTranslations.ro,
        ...experienceTranslations.ro,
        ...riskTranslations.ro,
        ...investmentTranslations.ro,
        ...productTranslations.ro,
        ...fundsTranslations.ro,
        ...overlayersTranslations.ro,
        ...internationalPhoneCountriesTranslations.ro,
    },
};

export default registerPageTranslations;
