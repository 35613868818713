import React from 'react';
import { Form, Image } from 'react-bootstrap';
import { FaCheckCircle } from 'react-icons/fa';

import Loader from '../../../../core/components/Loader';
import { uploadFileItem } from '../../../containers/RegisterPage.styles';
import { displayFlexRow, fontSize, marginRight } from '../../../../core/styles/generic.styles';
import { GREEN_FOOTER_COLOR } from '../../../../core/utils/colors';

import MAIL_SVG from '../../../../core/assets/svg/mail.svg';

const FileUpload = ({ titleLabel, isInvalid, loader, handleFileChange, imgSrc, errorMessage, successMessage, multiple = false }) => (
    <div style={uploadFileItem}>
        <div style={{ ...displayFlexRow, alignItems: 'center' }}>
            {imgSrc && <Image src={imgSrc} width={32} height={32} style={marginRight(25)} />}
            <span style={fontSize(22)}>{titleLabel}</span>
        </div>
        <div className='mt-3'>
            <Form.Group controlId='formFile'>
                <Form.Control isInvalid={isInvalid} type="file" onChange={handleFileChange} multiple={multiple} />
            </Form.Group>
            <br />
            {loader && <Loader />}
            {!isInvalid && successMessage && <div style={{ flexDirection: 'row', display: 'flex' }}>
                <Image style={{ marginRight: 10 }} src={MAIL_SVG} height={25} width={25} />
                <p style={{ marginTop: 5, marginRight: 10 }}>{successMessage}</p>
                <FaCheckCircle style={{ marginTop: 5, backgroundColor: GREEN_FOOTER_COLOR, borderRadius: '50%', color: '#fff' }} size={18} />
            </div>}
            {errorMessage && <p style={{ color: 'red', marginTop: 5 }}>{errorMessage}</p>}
        </div>
    </div>
);

export default FileUpload;
