import isLocalStorageAvailable from '../../core/utils/storage';
import { isLessThanHoursApart, isLessThanMinutesApart } from './helpers';

const OCR_TOKEN_ADDRESS = '@ocrToken';
const OCR_REFRESH_TOKEN_ADDRESS = '@ocrRefreshToken';
const OCR_TOKEN_EXPIRE_DATE_ADDRESS = '@ocrTokenExpireDate';
export const getOcrTokenInfo = () => {
    if (isLocalStorageAvailable()) {
        const ocrToken = localStorage.getItem(OCR_TOKEN_ADDRESS);
        const ocrRefreshToken = localStorage.getItem(OCR_REFRESH_TOKEN_ADDRESS);
        const ocrTokenExpireDate = localStorage.getItem(OCR_TOKEN_EXPIRE_DATE_ADDRESS);
        return { ocrToken, ocrRefreshToken, ocrTokenExpireDate };
    }

    return { ocrToken: null, ocrRefreshToken: null, ocrTokenExpireDate: null };
};
export const saveOcrTokenInfo = (token, refreshToken, expireDate) => {
    if (isLocalStorageAvailable()) {
        localStorage.setItem(OCR_TOKEN_ADDRESS, token);
        localStorage.setItem(OCR_REFRESH_TOKEN_ADDRESS, refreshToken);
        localStorage.setItem(OCR_TOKEN_EXPIRE_DATE_ADDRESS, expireDate);
    }
};
export const removeOcrTokenInfo = () => {
    if (isLocalStorageAvailable()) {
        localStorage.removeItem(OCR_TOKEN_ADDRESS);
        localStorage.removeItem(OCR_REFRESH_TOKEN_ADDRESS);
        localStorage.removeItem(OCR_TOKEN_EXPIRE_DATE_ADDRESS);
    }
};

const OCR_OLD_TOKEN_ADDRESS = '@ocrOldToken';
const OCR_OLD_REFRESH_TOKEN_ADDRESS = '@ocrOldRefreshToken';
const OCR_OLD_TOKEN_EXPIRE_DATE_ADDRESS = '@ocrOldTokenExpireDate';
export const getOcrOldTokenInfo = () => {
    if (isLocalStorageAvailable()) {
        const ocrToken = localStorage.getItem(OCR_OLD_TOKEN_ADDRESS);
        const ocrRefreshToken = localStorage.getItem(OCR_OLD_REFRESH_TOKEN_ADDRESS);
        const ocrTokenExpireDate = localStorage.getItem(OCR_OLD_TOKEN_EXPIRE_DATE_ADDRESS);
        return { ocrToken, ocrRefreshToken, ocrTokenExpireDate };
    }

    return { ocrToken: null, ocrRefreshToken: null, ocrTokenExpireDate: null };
};
export const saveOcrOldTokenInfo = (token, refreshToken, expireDate) => {
    if (isLocalStorageAvailable()) {
        localStorage.setItem(OCR_OLD_TOKEN_ADDRESS, token);
        localStorage.setItem(OCR_OLD_REFRESH_TOKEN_ADDRESS, refreshToken);
        localStorage.setItem(OCR_OLD_TOKEN_EXPIRE_DATE_ADDRESS, expireDate);
    }
};
export const removeOcrOldTokenInfo = () => {
    if (isLocalStorageAvailable()) {
        localStorage.removeItem(OCR_OLD_TOKEN_ADDRESS);
        localStorage.removeItem(OCR_OLD_REFRESH_TOKEN_ADDRESS);
        localStorage.removeItem(OCR_OLD_TOKEN_EXPIRE_DATE_ADDRESS);
    }
};

export const hasOcrTokenExpired = (expireDate) => {
    const formattedExpireDate = new Date(expireDate);
    return formattedExpireDate.getTime() <= Date.now();
};

const INSTANCE_INFO_SAVE_DATE_ADDRESS = '@instanceInfoSaveDate';
const INSTANCE_ID_ADDRESS = '@instanceId';
const WORKFLOW_ID_ADDRESS = '@workflowId';
const UNIQUE_GUID_ADDRESS = '@unqiueGUID';
const BUSINESS_KEY_ADDRESS = '@businessKey';
const CORRELATION_KEYS_ADDRESS = '@correlationKeys';
export const getInstanceInfo = () => {
    if (isLocalStorageAvailable()) {
        const saveDate = localStorage.getItem(INSTANCE_INFO_SAVE_DATE_ADDRESS);
        if (!saveDate || !isLessThanMinutesApart(saveDate, new Date(), 30)) {
            removeInstanceInfo();
            return { instanceId: null, workflowId: null, uniqueGUID: null, businessKey: null, correlationKeys: null };
        }

        const instanceId = localStorage.getItem(INSTANCE_ID_ADDRESS);
        const workflowId = localStorage.getItem(WORKFLOW_ID_ADDRESS);
        const uniqueGUID = localStorage.getItem(UNIQUE_GUID_ADDRESS);
        const businessKey = localStorage.getItem(BUSINESS_KEY_ADDRESS);
        const correlationKeys = localStorage.getItem(CORRELATION_KEYS_ADDRESS);

        return { instanceId, workflowId, uniqueGUID, businessKey, correlationKeys };
    }

    return { instanceId: null, workflowId: null, uniqueGUID: null, businessKey: null, correlationKeys: null };
};
export const saveInstanceInfo = (instanceId, workflowId, uniqueGUID, businessKey, correlationKeys) => {
    if (isLocalStorageAvailable()) {
        localStorage.setItem(INSTANCE_INFO_SAVE_DATE_ADDRESS, new Date().getTime());
        localStorage.setItem(INSTANCE_ID_ADDRESS, instanceId);
        localStorage.setItem(WORKFLOW_ID_ADDRESS, workflowId);
        localStorage.setItem(UNIQUE_GUID_ADDRESS, uniqueGUID);
        localStorage.setItem(BUSINESS_KEY_ADDRESS, businessKey);
        localStorage.setItem(CORRELATION_KEYS_ADDRESS, correlationKeys);
    }
};
export const removeInstanceInfo = () => {
    if (isLocalStorageAvailable()) {
        localStorage.removeItem(INSTANCE_INFO_SAVE_DATE_ADDRESS);
        localStorage.removeItem(INSTANCE_ID_ADDRESS);
        localStorage.removeItem(WORKFLOW_ID_ADDRESS);
        localStorage.removeItem(UNIQUE_GUID_ADDRESS);
        localStorage.removeItem(BUSINESS_KEY_ADDRESS);
        localStorage.removeItem(CORRELATION_KEYS_ADDRESS);
    }
};

export const hasInstanceInfo = () => {
    const { instanceId, workflowId, uniqueGUID, businessKey, correlationKeys } = getInstanceInfo();
    return instanceId && workflowId && uniqueGUID && businessKey && correlationKeys;
};

const INSTANCE_ID_FOR_FILE_UPLOAD_ADDRESS = '@instanceIdForFileUpload';
export const saveInstanceIdForFileUpload = (instanceId) => {
    if (isLocalStorageAvailable()) {
        localStorage.setItem(INSTANCE_ID_FOR_FILE_UPLOAD_ADDRESS, instanceId);
    }
};
export const getInstanceIdForFileUpload = () => {
    if (isLocalStorageAvailable()) {
        return localStorage.getItem(INSTANCE_ID_FOR_FILE_UPLOAD_ADDRESS);
    }

    return null;
};
export const removeInstanceIdForFileUpload = () => {
    if (isLocalStorageAvailable()) {
        localStorage.removeItem(INSTANCE_ID_FOR_FILE_UPLOAD_ADDRESS);
    }
};

const REQUESTS_IDS_SAVE_DATE_ADDRESS = '@requestIdsSaveDate';
const ID_EXTERNAL_REQUEST_ADDRESS = '@idExternalRequest';
const ID_INTERNAL_REQUEST_ADDRESS = '@idInternalRequest';
export const getRequestsIds = () => {
    if (isLocalStorageAvailable()) {
        const saveDate = localStorage.getItem(REQUESTS_IDS_SAVE_DATE_ADDRESS);
        if (!saveDate || !isLessThanHoursApart(saveDate, new Date(), 2)) {
            removeRequestsIds();
            return { idExternalRequest: null, idInternalRequest: null };
        }

        const idExternalRequest = localStorage.getItem(ID_EXTERNAL_REQUEST_ADDRESS);
        const idInternalRequest = localStorage.getItem(ID_INTERNAL_REQUEST_ADDRESS);
        return { idExternalRequest, idInternalRequest };
    }

    return { idExternalRequest: null, idInternalRequest: null };
};
export const saveRequestsIds = (idExternalRequest, idInternalRequest) => {
    if (isLocalStorageAvailable()) {
        localStorage.setItem(REQUESTS_IDS_SAVE_DATE_ADDRESS, new Date().getTime());
        localStorage.setItem(ID_EXTERNAL_REQUEST_ADDRESS, idExternalRequest);
        localStorage.setItem(ID_INTERNAL_REQUEST_ADDRESS, idInternalRequest);
    }
};
export const removeRequestsIds = () => {
    if (isLocalStorageAvailable()) {
        localStorage.removeItem(REQUESTS_IDS_SAVE_DATE_ADDRESS);
        localStorage.removeItem(ID_EXTERNAL_REQUEST_ADDRESS);
        localStorage.removeItem(ID_INTERNAL_REQUEST_ADDRESS);
    }
};

const ID_FILE_ADDRESS = '@idFile';
export const saveIdFile = (idFile) => {
    if (isLocalStorageAvailable()) {
        localStorage.setItem(ID_FILE_ADDRESS, idFile);
    }
};
export const getIdFile = () => {
    if (isLocalStorageAvailable()) {
        return localStorage.getItem(ID_FILE_ADDRESS);
    }

    return null;
};
export const removeIdFile = () => {
    if (isLocalStorageAvailable()) {
        localStorage.removeItem(ID_FILE_ADDRESS);
    }
};

const PROCESS_PASSED_ADDRESS = '@processPassed';
export const OLD_OCR_TYPE = 'oldOcr';
export const NEW_OCR_TYPE = 'newOcr';
export const saveProcessPassed = (type) => {
    if (isLocalStorageAvailable()) {
        localStorage.setItem(`${PROCESS_PASSED_ADDRESS}${type}`, new Date().getTime());
    }
};

export const hasPassedProcess = (type = null) => {
    if (isLocalStorageAvailable()) {
        const oldProcessPassed = localStorage.getItem(`${PROCESS_PASSED_ADDRESS}${OLD_OCR_TYPE}`);
        const newProcessPassed = localStorage.getItem(`${PROCESS_PASSED_ADDRESS}${NEW_OCR_TYPE}`);
        if (type === OLD_OCR_TYPE) {
            if (!isLessThanHoursApart(oldProcessPassed, new Date(), 90)) {
                removeProcessPassed(type);
                return false;
            }

            return oldProcessPassed;
        }
        if (type === NEW_OCR_TYPE) {
            if (!isLessThanHoursApart(newProcessPassed, new Date(), 90)) {
                removeProcessPassed(type);
                return false;
            }

            return newProcessPassed;
        }

        if (oldProcessPassed) {
            if (!isLessThanHoursApart(oldProcessPassed, new Date(), 90)) {
                removeProcessPassed(OLD_OCR_TYPE);
                return false;
            }

            return oldProcessPassed;
        }
        if (newProcessPassed) {
            if (!isLessThanHoursApart(newProcessPassed, new Date(), 90)) {
                removeProcessPassed(NEW_OCR_TYPE);
                return false;
            }

            return newProcessPassed;
        }
    }

    return false;
};

export const removeProcessPassed = (type) => {
    if (isLocalStorageAvailable()) {
        localStorage.removeItem(`${PROCESS_PASSED_ADDRESS}${type}`);
    }
};

export const saveTrackingId = (trackingId) => {
    if (isLocalStorageAvailable()) {
        localStorage.setItem('@trackingId', trackingId);
        localStorage.setItem('@trackingIdSaveDate', new Date().getTime());
    }
};

export const getTrackingId = () => {
    if (isLocalStorageAvailable()) {
        const saveDate = localStorage.getItem('@trackingIdSaveDate');
        if (!saveDate || !isLessThanHoursApart(saveDate, new Date(), 24)) {
            removeTrackingId();
            return null;
        }

        return localStorage.getItem('@trackingId');
    }

    return null;
};

export const removeTrackingId = () => {
    if (isLocalStorageAvailable()) {
        localStorage.removeItem('@trackingId');
        localStorage.removeItem('@trackingIdSaveDate');
    }
};

export const saveFlow = (flow) => {
    if (isLocalStorageAvailable()) {
        localStorage.setItem('@ocrFlow', flow);
    }
};

export const getFlow = () => {
    if (isLocalStorageAvailable()) {
        return Number(localStorage.getItem('@ocrFlow'));
    }

    return null;
};

export const removeFlow = () => {
    if (isLocalStorageAvailable()) {
        localStorage.removeItem('@ocrFlow');
    }
};

export const removeAllOcrData = () => {
    removeOcrTokenInfo();
    removeOcrOldTokenInfo();
    removeInstanceInfo();
    removeInstanceIdForFileUpload();
    removeRequestsIds();
    removeProcessPassed();
};
