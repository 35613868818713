import React from 'react';
import { FormattedMessage } from 'react-intl';

import Checkbox from './core/Checkbox';
import Select from './core/Select';
import { setRegisterStepFieldsValidity, setRegisterStepFieldValue } from '../../actions/register';
import { EXPERIENCE_VALUES, ESTIMATE_VALUES, INVESTMENT_VALUES, RISK_VALUES } from '../../utils/forms-options';
import {
    ESTIMATE_PERCENTAGE_FIELD, EXPERIENCE_FIELD, INVESTMENT_FIELD, PRODUCT1_FIELD, PRODUCT2_FIELD,
    PRODUCT3_FIELD, PRODUCT4_FIELD, PRODUCT5_FIELD, PRODUCT6_FIELD, PRODUCT7_FIELD, RISK_FIELD,
} from '../../reducers/features/form-structures';
import { REGISTER_STEP_12 } from '../../utils/register-steps';
import { productCheckboxContainer } from '../../containers/RegisterPage.styles';

const RegisterInvestmentProfileForm = ({ inputs, dispatch }) => {
    const productsNames = [
        inputs[PRODUCT1_FIELD].name, inputs[PRODUCT2_FIELD].name, inputs[PRODUCT3_FIELD].name,
        inputs[PRODUCT4_FIELD].name, inputs[PRODUCT5_FIELD].name, inputs[PRODUCT6_FIELD].name,
        inputs[PRODUCT7_FIELD].name,
    ];

    return (
        <>
            <Select
                formLabel={<FormattedMessage id='register_page_step_twelve_revenue' />}
                formControlId={inputs[ESTIMATE_PERCENTAGE_FIELD].name}
                isInvalid={!inputs[ESTIMATE_PERCENTAGE_FIELD].isValid}
                selectedValue={inputs[ESTIMATE_PERCENTAGE_FIELD].value}
                options={ESTIMATE_VALUES}
                onSelectedValueChange={(e) => (
                    dispatch(
                        setRegisterStepFieldValue(REGISTER_STEP_12, ESTIMATE_PERCENTAGE_FIELD, e.target.selectedOptions[0].id),
                    )
                )}
            />
            <Select
                formLabel={<FormattedMessage id='register_page_step_twelve_estimate' />}
                formControlId={inputs[EXPERIENCE_FIELD].name}
                isInvalid={!inputs[EXPERIENCE_FIELD].isValid}
                selectedValue={inputs[EXPERIENCE_FIELD].value}
                options={EXPERIENCE_VALUES}
                onSelectedValueChange={(e) => (
                    dispatch(setRegisterStepFieldValue(REGISTER_STEP_12, EXPERIENCE_FIELD, e.target.selectedOptions[0].id))
                )}
            />
            <Select
                formLabel={<FormattedMessage id='register_page_step_twelve_risk' />}
                isInvalid={!inputs[RISK_FIELD].isValid}
                formControlId={inputs[RISK_FIELD].name}
                selectedValue={inputs[RISK_FIELD].value}
                options={RISK_VALUES}
                onSelectedValueChange={(e) => (
                    dispatch(setRegisterStepFieldValue(REGISTER_STEP_12, RISK_FIELD, e.target.selectedOptions[0].id))
                )}
            />
            <Select
                formLabel={<FormattedMessage id='register_page_step_twelve_experience' />}
                formControlId={inputs[INVESTMENT_FIELD].name}
                isInvalid={!inputs[INVESTMENT_FIELD].isValid}
                selectedValue={inputs[INVESTMENT_FIELD].value}
                options={INVESTMENT_VALUES}
                onSelectedValueChange={(e) => (
                    dispatch(setRegisterStepFieldValue(REGISTER_STEP_12, INVESTMENT_FIELD, e.target.selectedOptions[0].id))
                )}
            />
            <p className="mb-1"><FormattedMessage id='register_page_step_twelve_products_experience' /></p>
            <Checkbox
                controlId={inputs[PRODUCT1_FIELD].name}
                customStyle={productCheckboxContainer}
                isInvalid={!inputs[PRODUCT1_FIELD].isValid}
                label={<FormattedMessage id='product_1' />}
                checked={inputs[PRODUCT1_FIELD].value}
                onChange={() => {
                    dispatch(setRegisterStepFieldValue(REGISTER_STEP_12, PRODUCT1_FIELD, !inputs[PRODUCT1_FIELD].value));
                    dispatch(setRegisterStepFieldsValidity(REGISTER_STEP_12, productsNames, true));
                }}
            />
            <Checkbox
                controlId={inputs[PRODUCT2_FIELD].name}
                customStyle={productCheckboxContainer}
                isInvalid={!inputs[PRODUCT2_FIELD].isValid}
                label={<FormattedMessage id='product_2' />}
                checked={inputs[PRODUCT2_FIELD].value}
                onChange={() => {
                    dispatch(setRegisterStepFieldValue(REGISTER_STEP_12, PRODUCT2_FIELD, !inputs[PRODUCT2_FIELD].value));
                    dispatch(setRegisterStepFieldsValidity(REGISTER_STEP_12, productsNames, true));
                }}
            />
            <Checkbox
                controlId={inputs[PRODUCT3_FIELD].name}
                customStyle={productCheckboxContainer}
                isInvalid={!inputs[PRODUCT3_FIELD].isValid}
                label={<FormattedMessage id='product_3' />}
                checked={inputs[PRODUCT3_FIELD].value}
                onChange={() => {
                    dispatch(setRegisterStepFieldValue(REGISTER_STEP_12, PRODUCT3_FIELD, !inputs[PRODUCT3_FIELD].value));
                    dispatch(setRegisterStepFieldsValidity(REGISTER_STEP_12, productsNames, true));
                }}
            />
            <Checkbox
                controlId={inputs[PRODUCT4_FIELD].name}
                customStyle={productCheckboxContainer}
                isInvalid={!inputs[PRODUCT4_FIELD].isValid}
                label={<FormattedMessage id='product_4' />}
                checked={inputs[PRODUCT4_FIELD].value}
                onChange={() => {
                    dispatch(setRegisterStepFieldValue(REGISTER_STEP_12, PRODUCT4_FIELD, !inputs[PRODUCT4_FIELD].value));
                    dispatch(setRegisterStepFieldsValidity(REGISTER_STEP_12, productsNames, true));
                }}
            />
            <Checkbox
                controlId={inputs[PRODUCT5_FIELD].name}
                customStyle={productCheckboxContainer}
                isInvalid={!inputs[PRODUCT5_FIELD].isValid}
                label={<FormattedMessage id='product_5' />}
                checked={inputs[PRODUCT5_FIELD].value}
                onChange={() => {
                    dispatch(setRegisterStepFieldValue(REGISTER_STEP_12, PRODUCT5_FIELD, !inputs[PRODUCT5_FIELD].value));
                    dispatch(setRegisterStepFieldsValidity(REGISTER_STEP_12, productsNames, true));
                }}
            />
            <Checkbox
                controlId={inputs[PRODUCT6_FIELD].name}
                customStyle={productCheckboxContainer}
                isInvalid={!inputs[PRODUCT6_FIELD].isValid}
                label={<FormattedMessage id='product_6' />}
                checked={inputs[PRODUCT6_FIELD].value}
                onChange={() => {
                    dispatch(setRegisterStepFieldValue(REGISTER_STEP_12, PRODUCT6_FIELD, !inputs[PRODUCT6_FIELD].value));
                    dispatch(setRegisterStepFieldsValidity(REGISTER_STEP_12, productsNames, true));
                }}
            />
            <Checkbox
                controlId={inputs[PRODUCT7_FIELD].name}
                customStyle={productCheckboxContainer}
                isInvalid={!inputs[PRODUCT7_FIELD].isValid}
                label={<FormattedMessage id='product_7' />}
                checked={inputs[PRODUCT7_FIELD].value}
                onChange={() => {
                    dispatch(setRegisterStepFieldValue(REGISTER_STEP_12, PRODUCT7_FIELD, !inputs[PRODUCT7_FIELD].value));
                    dispatch(setRegisterStepFieldsValidity(REGISTER_STEP_12, productsNames, true));
                }}
            />
        </>
    );
};

export default RegisterInvestmentProfileForm;
