import React from 'react';
import { Form } from 'react-bootstrap';

import { width } from '../../../../core/styles/generic.styles';
import { checkboxContainer } from '../../../containers/RegisterPage.styles';

const RadioWithThreeOptions = ({
    formLabel, formName, checkedOption, formInvalid,
    firstOptionLabel, firstOptionId, firstOptionValue, onFirstOptionChange,
    secondOptionLabel, secondOptionId, secondOptionValue, onSecondOptionChange,
    thirdOptionId, thirdOptionLabel, thirdOptionValue, onThirdOptionChange,
}) => (
    <>
        {formLabel}
        <div className='d-flex mt-2'>
            <div style={{ ...checkboxContainer, ...width(33), marginRight: 10 }} onClick={onFirstOptionChange}>
                <Form.Check
                    type="radio"
                    id={firstOptionId}
                    isInvalid={formInvalid}
                    label={firstOptionLabel}
                    name={formName}
                    value={firstOptionValue}
                    checked={checkedOption === firstOptionValue}
                    onChange={onFirstOptionChange}
                />
            </div>
            <div style={{ ...checkboxContainer, ...width(33), marginRight: 10 }} onClick={onSecondOptionChange}>
                <Form.Check
                    type="radio"
                    id={secondOptionId}
                    isInvalid={formInvalid}
                    label={secondOptionLabel}
                    name={formName}
                    value={secondOptionValue}
                    checked={checkedOption === secondOptionValue}
                    onChange={onSecondOptionChange}
                />
            </div>
            <div style={{ ...checkboxContainer, ...width(33) }} onClick={onThirdOptionChange}>
                <Form.Check
                    type="radio"
                    id={thirdOptionId}
                    isInvalid={formInvalid}
                    label={thirdOptionLabel}
                    name={formName}
                    value={thirdOptionValue}
                    checked={checkedOption === thirdOptionValue}
                    onChange={onThirdOptionChange}
                />
            </div>
        </div>
    </>
);

export default RadioWithThreeOptions;
