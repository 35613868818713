export const CHECKBOX_MANDATORY = 'checkboxMandatory';
export const CHECKBOX_OPTIONAL = 'checkboxOptional';
export const CHECKBOX_INVESTMENTS_MANDATORY_GROUP = 'checkboxInvestmentsMandatoryGroup';
export const CHECKBOX_PRODUCTS_MANDATORY_GROUP = 'checkboxProductsMandatoryGroup';
export const RADIO = 'radio';
export const EMAIL = 'email';
export const CNP = 'cnp';
export const TEXT = 'text';
export const BANK = 'bank';
export const IBAN = 'iban';
export const PASSWORD = 'password';
export const EMITTED_DATE = 'emittedDate';
export const EXPIRE_DATE = 'expireDate';
export const BIRTH_DATE = 'birthDate';
export const DATE = 'date';
export const SELECT = 'select';
export const FILE = 'file';
export const FILES = 'files';
export const PHONE = 'phone';
