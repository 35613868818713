import { setRegisterStep, setRegisterStepFieldsFromOcr } from '../actions/register';
import { getSessionResult } from '../actions/ocr';
import { extractBirthdateFromCNP } from '../utils/helpers';
import { handleOldOcrFilesForId, handleOldOcrFilesForLiveness } from './ocr-file-handler';
import { NEW_OCR_TYPE, OLD_OCR_TYPE, saveProcessPassed } from '../utils/ocr-storage';
import { REGISTER_STEP_5, REGISTER_STEP_7 } from '../utils/register-steps';
import { NATIONALITY_VALUES } from '../utils/forms-options';
import {
    CNP_FIELD, BIRTH_DATE_FIELD, BIRTH_PLACE_FIELD, EMITTED_BY_FIELD, EMITTED_DATE_FIELD, FIRST_NAME_FIELD,
    LAST_NAME_FIELD, EXPIRE_DATE_FIELD, ADDRESS_FIELD, COUNTY_FIELD, COUNTRY_FIELD, CITY_FIELD, CI_FIELD,
} from '../reducers/features/form-structures';

const ROOT_PARAM_NAME = 'Workflow_Instance_IdCard';
const RESULTS_PARAM_NAME = 'wf_IdCardOCR_Response';

export const handleOcrResponse = (response, dispatch) => {
    const values = extractDataFromOcrResponse(response);
    dispatch(setRegisterStepFieldsFromOcr(REGISTER_STEP_5, values));
    dispatch(setRegisterStep(REGISTER_STEP_5));
    saveProcessPassed(NEW_OCR_TYPE);
};

export const extractDataFromOcrResponse = (response) => {
    const values = response[ROOT_PARAM_NAME][RESULTS_PARAM_NAME];
    const {
        CNP, BirthDate, EmisDe, FirstName, LastName, Nationality, PlaceOfBirth, Town, ValidityEnd, ValidityStart,
        Address, District, SerialAndNumber,
    } = values;
    const [sectorString, newAddress] = replaceLocationInfo(Address);

    return {
        [CNP_FIELD]: CNP,
        [BIRTH_DATE_FIELD]: BirthDate.split('T')[0],
        [EMITTED_BY_FIELD]: EmisDe,
        [FIRST_NAME_FIELD]: FirstName,
        [LAST_NAME_FIELD]: LastName,
        [COUNTRY_FIELD]: getCountryByNationality(Nationality),
        [BIRTH_PLACE_FIELD]: PlaceOfBirth,
        [CITY_FIELD]: Town,
        [EXPIRE_DATE_FIELD]: ValidityEnd,
        [EMITTED_DATE_FIELD]: ValidityStart,
        [ADDRESS_FIELD]: newAddress || Address,
        [COUNTY_FIELD]: sectorString || District,
        [CI_FIELD]: SerialAndNumber,
        newFlow: true,
    };
};

export const isProcessCompleted = (instanceResult, instanceWorkflow) => (
    (instanceResult && instanceResult.result === 'PASSED' && instanceResult.text === 'Finalized')
    && (instanceWorkflow && instanceWorkflow.result === 'true' && instanceWorkflow.type === 'Instance'
        && instanceWorkflow.text.includes('Completed at'))
);

export const hasProcessExpired = (instanceResult, instanceWorkflow) => (
    (instanceResult && instanceResult.result === 'FAILED' && instanceResult.text.includes('Timeout'))
    && (instanceWorkflow && instanceWorkflow.result === 'false' && instanceWorkflow.type === 'Instance'
        && instanceWorkflow.text.includes('Timeout'))
);

export const hasProcessFailed = (instanceResult, instanceWorkflow) => (
    (instanceResult && instanceResult.result === 'FAILED' && instanceResult.text === 'Failed')
    && (instanceWorkflow && instanceWorkflow.result === 'false' && instanceWorkflow.type === 'Instance'
        && instanceWorkflow.text.includes('Failed at'))
);

export const handleOcrSessionStatusResponse = (response, dispatch, type) => {
    if ((response.IsOCRDone && response.IsLivenessDone) || (response.IsLivenessDone && type === 'liveness')) {
        dispatch(getSessionResult(type));
    }
};

export const handleOldOcrResponse = (token, response, dispatch, type) => {
    if (type === 'liveness') {
        handleOldOcrFilesForLiveness(token, response, dispatch);
        dispatch(setRegisterStep(REGISTER_STEP_7));
        saveProcessPassed(OLD_OCR_TYPE);
        return;
    }

    const values = extractDataFromOldOcrResponse(response);
    dispatch(setRegisterStepFieldsFromOcr(REGISTER_STEP_5, values));
    handleOldOcrFilesForId(token, response);
    dispatch(setRegisterStep(REGISTER_STEP_5));
};

export const extractDataFromOldOcrResponse = (response) => {
    try {
        let values = null;
        if (response[response.length - 1].ClientDetails) {
            values = JSON.parse(response[response.length - 1].ClientDetails);
        } else {
            values = JSON.parse(response[response.length - 1].Details);
        }

        const {
            CNP, EmisDe, FirstName, LastName, Nationality, PlaceOfBirth, Town, ValidityEnd, ValidityStart, Address, District,
            SerialAndNumber,
        } = values;
        const [sectorString, newAddress] = replaceLocationInfo(Address);

        return {
            [CNP_FIELD]: CNP,
            [BIRTH_DATE_FIELD]: extractBirthdateFromCNP(CNP),
            [EMITTED_BY_FIELD]: EmisDe,
            [FIRST_NAME_FIELD]: FirstName,
            [LAST_NAME_FIELD]: LastName,
            [COUNTRY_FIELD]: getCountryByNationality(Nationality),
            [BIRTH_PLACE_FIELD]: PlaceOfBirth,
            [CITY_FIELD]: Town,
            [EXPIRE_DATE_FIELD]: ValidityEnd,
            [EMITTED_DATE_FIELD]: ValidityStart,
            [ADDRESS_FIELD]: newAddress || Address,
            [COUNTY_FIELD]: sectorString || District,
            [CI_FIELD]: SerialAndNumber,
            newFlow: false,
        };
    } catch (e) {
        return {
            [CNP_FIELD]: '',
            [BIRTH_DATE_FIELD]: '',
            [EMITTED_BY_FIELD]: '',
            [FIRST_NAME_FIELD]: '',
            [LAST_NAME_FIELD]: '',
            [COUNTRY_FIELD]: '',
            [BIRTH_PLACE_FIELD]: '',
            [CITY_FIELD]: '',
            [EXPIRE_DATE_FIELD]: '',
            [EMITTED_DATE_FIELD]: '',
            [ADDRESS_FIELD]: '',
            [COUNTY_FIELD]: '',
            [CI_FIELD]: '',
            newFlow: false,
        };
    }
};

const getCountryByNationality = (nationality) => {
    if (nationality.includes('ROU')) {
        return NATIONALITY_VALUES.filter((country) => country.id === 'RO')[0].id;
    }

    return nationality;
};

const replaceLocationInfo = (Address) => {
    const regexSectorPattern = /sec\.?\s*(\d+)/i;
    const match = Address.match(regexSectorPattern);
    let sectorString = null;
    if (match) {
        const sectorNumber = match[1];
        sectorString = `Sector ${sectorNumber}`;
    }

    const regex = /(((mun|municipiu|municipiul|sec|sector|jud|judet|oras)[\\. ]+?)([^ ]+))/gi;
    const newAddress = Address.replace(regex, '').trim();

    return [sectorString, newAddress];
};
