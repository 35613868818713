import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { BiCheck } from 'react-icons/bi';

import selectIsOverlayerOpened from '../../../core/selectors/overlayer';
import { closeOverlayer } from '../../../core/actions/overlayer';
import { MAIN_MENU_COLOR } from '../../../core/utils/colors';
import { displayFlexAlignCenter, marginRight } from '../../../core/styles/generic.styles';

export const REGISTER_HELP_OVERLAYER_NAME = 'REGISTER_HELP';
export const WHAT_YOU_NEED_MODE = 0;
export const WHAT_STEPS_MODE = 1;

const RegisterHelpOverlayer = ({ overlayerMode }) => {
    const dispatch = useDispatch();
    const isOpen = useSelector((state) => selectIsOverlayerOpened(state, REGISTER_HELP_OVERLAYER_NAME));
    const onClose = () => dispatch(closeOverlayer(REGISTER_HELP_OVERLAYER_NAME));

    return (
        <Modal show={isOpen} onHide={onClose} size={'lg'}>
            <Modal.Header closeButton />
            <Modal.Body>
                {overlayerMode === WHAT_YOU_NEED_MODE
                    ? <>
                        <p style={displayFlexAlignCenter}>
                            <BiCheck color={MAIN_MENU_COLOR} style={marginRight(3)} />
                            <FormattedMessage id='register_page_step_one_helper_1' />
                        </p>
                        <p>
                            <BiCheck color={MAIN_MENU_COLOR} style={marginRight(3)} />
                            <FormattedMessage id='register_page_step_one_helper_2' />
                        </p>
                        <p>
                            <BiCheck color={MAIN_MENU_COLOR} style={marginRight(3)} />
                            <FormattedMessage id='register_page_step_one_helper_3' />
                        </p>
                        <p>
                            <BiCheck color={MAIN_MENU_COLOR} style={marginRight(3)} />
                            <FormattedMessage id='register_page_step_one_helper_4' />
                        </p>
                    </>
                    : <>
                        <p style={displayFlexAlignCenter}>
                            <BiCheck color={MAIN_MENU_COLOR} style={marginRight(3)} />
                            <FormattedMessage id='register_page_step_one_helper_5' />
                        </p>
                        <p>
                            <BiCheck color={MAIN_MENU_COLOR} style={marginRight(3)} />
                            <FormattedMessage id='register_page_step_one_helper_6' />
                        </p>
                        <p>
                            <BiCheck color={MAIN_MENU_COLOR} style={marginRight(3)} />
                            <FormattedMessage id='register_page_step_one_helper_7' />
                        </p>
                    </>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={onClose}>
                    <FormattedMessage id='i_understand' />
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default RegisterHelpOverlayer;
