import HttpClient from '../../core/services/http-client';
import { getInstanceInfo, getRequestsIds } from '../utils/ocr-storage';

const API_USERNAME = 'service.runner.tradeville.websdk';
const API_PASSWORD = 'tr@d3v!ll3';
const API_OLD_USERNAME = 'service.runner.tradeville';
const API_OLD_PASSWORD = 'zxcv1234';
const API_CULTURE_ID = 3;
const API_ORGANIZATION_ID = 52;
const API_IS_SERVICE_USER = true;

const API_FLUX_CLIENT = 'TRADEVILLE';
const API_TOKEN_CLIENT = '7ecbfcf7-71bf-4916-9d6b-ff363cfece83';
const API_MESSAGE_START_NAME = 'MSG_START_FO_START';

const GET_TOKEN_URL = 'https://losapiprod-websdk.krunity.com/api/Token';
const START_MESSAGE_EVENT_URL = 'https://losapiprod-websdk.krunity.com/api/WorkflowsEngine/messageStart';
const GET_WORKFLOW_VARIABLES_URL = 'https://losapiprod-websdk.krunity.com/api/workflowsengine/getWorkflowVariableByName';
const GET_FILES_URL = 'https://losapiprod-websdk.krunity.com/api/Files';

const GET_TOKEN_OLD_URL = 'https://losapiprod.krunity.com/api/token';
const INIT_OLD_SESSION_URL = 'https://losapiprod.krunity.com/api/losRequests/initSession';
const GET_SESSION_STATUS_URL = 'https://losapiprod.krunity.com/api/losRequests/getSessionStatus';
const GET_SESSION_RESULT_URL = 'https://losapiprod.krunity.com/api/losRequests/getInstanceResult';
const GET_FILES_OLD_URL = 'https://losapiprod.krunity.com/api/Files';

export const OCR_REQUEST_NAME = 'ocr';

export const fetchToken = async () => {
    const headers = {
        'Content-Type': 'application/json',
    };

    const requestBody = {
        Username: API_USERNAME,
        Password: API_PASSWORD,
        CultureId: API_CULTURE_ID,
        OrganizationId: API_ORGANIZATION_ID,
        IsServiceUser: API_IS_SERVICE_USER,
    };

    return HttpClient.execute(GET_TOKEN_URL, 'POST', headers, requestBody);
};

export const startMessageEvent = async (token, email = null, phoneNumber = null) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `bearer ${token}`,
    };
    const { uniqueGUID } = getInstanceInfo();

    const requestBody = {
        idWorkflow: '1232',
        messageName: API_MESSAGE_START_NAME,
        processVariables: {
            wf_FluxClient: {
                value: API_FLUX_CLIENT,
            },
            wf_TokenClient: {
                value: uniqueGUID || API_TOKEN_CLIENT,
            },
            wf_PhoneNumber: {
                value: phoneNumber || '',
            },
            wf_Email: {
                value: email || '',
            },
            wf_Has_IFRAME: {
                value: 'true',
            },
            wf_Has_OTP: {
                value: 'false',
            },
            wf_Has_CONSENTS: {
                value: 'true',
            },
            wf_CONSENTS_Details: {
                value: [
                    {
                        result: true,
                        isMandatory: true,
                        hTML: 'text acord',
                        agreed: true,
                        hasError: false,
                        errorKey: null,
                        errorMessage: null,
                        innerError: null,
                    },
                ],
            },
        },
    };

    return HttpClient.execute(START_MESSAGE_EVENT_URL, 'POST', headers, requestBody);
};

export const fetchWorkflowVariables = async (token, instanceId, varName) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `bearer ${token}`,
    };
    const url = `${GET_WORKFLOW_VARIABLES_URL}?idWfInstance=${instanceId}&variableKeyName=${varName}&fluxClient=${API_FLUX_CLIENT}&tokenClient=${API_TOKEN_CLIENT}`;

    return HttpClient.execute(url, 'GET', headers);
};

export const fetchFiles = async (token, guid) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `bearer ${token}`,
    };
    const url = `${GET_FILES_URL}?GUID=${guid}`;

    return HttpClient.execute(url, 'GET', headers);
};

export const fetchOldFiles = async (token, guid) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `bearer ${token}`,
    };
    const url = `${GET_FILES_OLD_URL}?GUID=${guid}`;

    return HttpClient.execute(url, 'GET', headers);
};

export const fetchOldToken = async () => {
    const headers = {
        'Content-Type': 'application/json',
    };

    const requestBody = {
        Username: API_OLD_USERNAME,
        Password: API_OLD_PASSWORD,
        CultureId: API_CULTURE_ID,
        OrganizationId: API_ORGANIZATION_ID,
        IsServiceUser: API_IS_SERVICE_USER,
    };

    return HttpClient.execute(GET_TOKEN_OLD_URL, 'POST', headers, requestBody);
};

export const initOldSession = async (token, email = null, phoneNumber = null) => {
    const { idExternalRequest } = getRequestsIds();
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `bearer ${token}`,
    };

    let requestBody = {
        PersonGroup: 'efffe491-70e7-43de-9c02-7794f5c32d23',
        OCR: true,
        Liveness: true,
        PhoneNo: phoneNumber || '',
        Email: email || '',
        DeleteDataPeriod: 10,
    };
    if (idExternalRequest) {
        requestBody = { ...requestBody, IdExternalRequest: idExternalRequest };
    }

    return HttpClient.execute(INIT_OLD_SESSION_URL, 'POST', headers, requestBody);
};

export const fetchSessionStatus = async (token) => {
    const { idExternalRequest, idInternalRequest } = getRequestsIds();
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `bearer ${token}`,
    };

    const requestBody = {
        IdExternalRequest: idExternalRequest,
        IdInternalRequest: idInternalRequest,
    };

    return HttpClient.execute(GET_SESSION_STATUS_URL, 'POST', headers, requestBody);
};

export const fetchSessionResult = async (token) => {
    const { idExternalRequest, idInternalRequest } = getRequestsIds();
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `bearer ${token}`,
    };

    const requestBody = {
        IdExternalRequest: idExternalRequest,
        IdInternalRequest: idInternalRequest,
    };

    return HttpClient.execute(GET_SESSION_RESULT_URL, 'POST', headers, requestBody);
};
