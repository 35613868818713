const filterOutTranslationErrors = () => {
    // eslint-disable-next-line no-console
    const originalConsoleError = console.error;
    // eslint-disable-next-line no-console
    console.error = (...args) => {
        if (/Missing message:/.test(args[0])) {
            return;
        }
        originalConsoleError(...args);
    };
};

export default filterOutTranslationErrors;
