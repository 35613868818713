import { uniq } from 'lodash/array';

import {
    SET_REGISTER_STEP_FIELD_VALIDITY, SET_REGISTER_STEP, SET_REGISTER_STEP_FIELD_VALUE, RESET_REGISTER_STEP_VALIDITY,
    SET_REGISTER_STEP_FIELD_VISIBILITY, SET_UPLOADED_FILES, SET_REGISTER_STEP_FILE_VALUE,
    SET_REGISTER_STEP_FIELD_LOADING, SET_REGISTER_STEP_FIELD_ERROR_MESSAGE, SET_REGISTER_STEP_FIELDS,
    SET_REGISTER_STEP_FIELDS_FROM_OCR, SET_SMS_MESSAGE,
} from '../actions/register';
import {
    REGISTER_STEP_10_INITIAL_STATE, REGISTER_STEP_11_INITIAL_STATE, REGISTER_STEP_12_INITIAL_STATE,
    REGISTER_STEP_13_INITIAL_STATE, REGISTER_STEP_14_INITIAL_STATE, REGISTER_STEP_16_INITIAL_STATE,
    REGISTER_STEP_17_INITIAL_STATE, REGISTER_STEP_1_INITIAL_STATE, REGISTER_STEP_2_INITIAL_STATE,
    REGISTER_STEP_5_INITIAL_STATE, REGISTER_STEP_6_INITIAL_STATE, REGISTER_STEP_7_INITIAL_STATE,
    REGISTER_STEP_8_INITIAL_STATE, REGISTER_STEP_9_INITIAL_STATE,
    FIRST_NAME_FIELD, CITY_FIELD, COUNTRY_FIELD, COUNTY_FIELD, ADDRESS_FIELD, EMITTED_DATE_FIELD, EMITTED_BY_FIELD,
    BIRTH_PLACE_FIELD, BIRTH_DATE_FIELD, EXPIRE_DATE_FIELD, LAST_NAME_FIELD, CNP_FIELD, CI_FIELD,
} from './features/form-structures';
import {
    REGISTER_STEP_1, REGISTER_STEP_2, REGISTER_STEP_5, REGISTER_STEP_6, REGISTER_STEP_7, REGISTER_STEP_8,
    REGISTER_STEP_9, REGISTER_STEP_10, REGISTER_STEP_11, REGISTER_STEP_12, REGISTER_STEP_13,
    REGISTER_STEP_14, REGISTER_STEP_16, REGISTER_STEP_17,
} from '../utils/register-steps';

export const INITIAL_UI_STATE = {
    step: 1,
    smsMessage: null,
    uploadedFiles: [],
};

export const registerUIReducer = (state = INITIAL_UI_STATE, action) => {
    switch (action.type) {
        case SET_REGISTER_STEP:
            return { ...state, step: action.payload };
        case SET_SMS_MESSAGE:
            return { ...state, smsMessage: action.payload };
        case SET_UPLOADED_FILES:
            return { ...state, uploadedFiles: uniq([...state.uploadedFiles, ...action.payload]) };
        default:
            return state;
    }
};

export const INITIAL_DATA_STATE = {
    forms: {
        [REGISTER_STEP_1]: { ...REGISTER_STEP_1_INITIAL_STATE },
        [REGISTER_STEP_2]: { ...REGISTER_STEP_2_INITIAL_STATE },
        [REGISTER_STEP_5]: { ...REGISTER_STEP_5_INITIAL_STATE },
        [REGISTER_STEP_6]: { ...REGISTER_STEP_6_INITIAL_STATE },
        [REGISTER_STEP_7]: { ...REGISTER_STEP_7_INITIAL_STATE },
        [REGISTER_STEP_8]: { ...REGISTER_STEP_8_INITIAL_STATE },
        [REGISTER_STEP_9]: { ...REGISTER_STEP_9_INITIAL_STATE },
        [REGISTER_STEP_10]: { ...REGISTER_STEP_10_INITIAL_STATE },
        [REGISTER_STEP_11]: { ...REGISTER_STEP_11_INITIAL_STATE },
        [REGISTER_STEP_12]: { ...REGISTER_STEP_12_INITIAL_STATE },
        [REGISTER_STEP_13]: { ...REGISTER_STEP_13_INITIAL_STATE },
        [REGISTER_STEP_14]: { ...REGISTER_STEP_14_INITIAL_STATE },
        [REGISTER_STEP_16]: { ...REGISTER_STEP_16_INITIAL_STATE },
        [REGISTER_STEP_17]: { ...REGISTER_STEP_17_INITIAL_STATE },
    },
};

export const registerDataReducer = (state = INITIAL_DATA_STATE, action) => {
    switch (action.type) {
        case SET_REGISTER_STEP_FIELD_VALUE:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    [action.step]: {
                        ...state.forms[action.step],
                        [action.field]: {
                            ...state.forms[action.step][action.field],
                            value: action.value,
                            isValid: true,
                        },
                    },
                },
            };
        case SET_REGISTER_STEP_FIELDS_FROM_OCR:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    [action.step]: {
                        ...state.forms[action.step],
                        [FIRST_NAME_FIELD]: {
                            ...state.forms[action.step][FIRST_NAME_FIELD],
                            value: action.payload[FIRST_NAME_FIELD],
                        },
                        [LAST_NAME_FIELD]: {
                            ...state.forms[action.step][LAST_NAME_FIELD],
                            value: action.payload[LAST_NAME_FIELD],
                        },
                        [CNP_FIELD]: {
                            ...state.forms[action.step][CNP_FIELD],
                            value: action.payload[CNP_FIELD],
                        },
                        [CI_FIELD]: {
                            ...state.forms[action.step][CI_FIELD],
                            value: action.payload[CI_FIELD],
                        },
                        [EMITTED_BY_FIELD]: {
                            ...state.forms[action.step][EMITTED_BY_FIELD],
                            value: action.payload[EMITTED_BY_FIELD],
                        },
                        [EMITTED_DATE_FIELD]: {
                            ...state.forms[action.step][EMITTED_DATE_FIELD],
                            value: action.payload[EMITTED_DATE_FIELD],
                        },
                        [EXPIRE_DATE_FIELD]: {
                            ...state.forms[action.step][EXPIRE_DATE_FIELD],
                            value: action.payload[EXPIRE_DATE_FIELD],
                        },
                        [BIRTH_DATE_FIELD]: {
                            ...state.forms[action.step][BIRTH_DATE_FIELD],
                            value: action.payload[BIRTH_DATE_FIELD],
                        },
                        [BIRTH_PLACE_FIELD]: {
                            ...state.forms[action.step][BIRTH_PLACE_FIELD],
                            value: action.payload[BIRTH_PLACE_FIELD],
                        },
                        [ADDRESS_FIELD]: {
                            ...state.forms[action.step][ADDRESS_FIELD],
                            value: action.payload[ADDRESS_FIELD],
                        },
                        [COUNTY_FIELD]: {
                            ...state.forms[action.step][COUNTY_FIELD],
                            value: action.payload[COUNTY_FIELD],
                        },
                        [CITY_FIELD]: {
                            ...state.forms[action.step][CITY_FIELD],
                            value: action.payload[CITY_FIELD],
                        },
                        [COUNTRY_FIELD]: {
                            ...state.forms[action.step][COUNTRY_FIELD],
                            value: action.payload[COUNTRY_FIELD],
                        },
                        newFlow: action.payload.newFlow || false,
                    },
                },
            };
        case SET_REGISTER_STEP_FIELDS:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    [action.step]: {
                        ...state.forms[action.step],
                        ...action.fields,
                    },
                },
            };
        case SET_REGISTER_STEP_FILE_VALUE:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    [action.step]: {
                        ...state.forms[action.step],
                        [action.field]: {
                            ...state.forms[action.step][action.field],
                            value: {
                                lastModified: action.value ? action.value.lastModified : '',
                                lastModifiedDate: action.value ? action.value.lastModifiedDate : '',
                                name: action.value ? action.value.name : '',
                                size: action.value ? action.value.size : '',
                                type: action.value ? action.value.type : '',
                                webkitRelativePath: action.value ? action.value.webkitRelativePath : '',
                            },
                            isValid: true,
                        },
                    },
                },
            };
        case SET_REGISTER_STEP_FIELD_VALIDITY:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    [action.step]: {
                        ...state.forms[action.step],
                        [action.field]: {
                            ...state.forms[action.step][action.field],
                            isValid: action.validity,
                        },
                    },
                },
            };
        case SET_REGISTER_STEP_FIELD_ERROR_MESSAGE:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    [action.step]: {
                        ...state.forms[action.step],
                        [action.field]: {
                            ...state.forms[action.step][action.field],
                            errorMessage: action.errorMessage,
                        },
                    },
                },
            };
        case SET_REGISTER_STEP_FIELD_LOADING:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    [action.step]: {
                        ...state.forms[action.step],
                        [action.field]: {
                            ...state.forms[action.step][action.field],
                            isLoading: action.loading,
                        },
                    },
                },
            };
        case SET_REGISTER_STEP_FIELD_VISIBILITY:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    [action.step]: {
                        ...state.forms[action.step],
                        [action.field]: {
                            ...state.forms[action.step][action.field],
                            isVisible: action.visibility,
                        },
                    },
                },
            };
        case RESET_REGISTER_STEP_VALIDITY:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    [action.step]: {
                        ...convertNewFieldsArrayToObject(extractNewFieldsToArray(state, action.step, 'isValid', true)),
                    },
                },
            };
        default:
            return state;
    }
};

const extractNewFieldsToArray = (state, step, fieldName, fieldValue) => (
    Object.values(state.forms[step]).map((field) => ({
        ...field,
        [fieldName]: fieldValue,
    }))
);

const convertNewFieldsArrayToObject = (newFields) => (
    newFields.reduce((obj, item) => {
        // eslint-disable-next-line no-param-reassign
        obj[item.name] = item;
        return obj;
    }, {})
);
