import { isArray } from 'lodash';

import getQueryParam from '../utils/url';
import COMMAND_NAMES from './constants';
import { addResponseToStack, setLastResponse } from '../actions/websocket';
import { setRegisterStepFieldLoading, setRegisterStepFieldValidity, setSmsMessage } from '../../security/actions/register';
import { setFieldsFromResponse } from '../../security/decorators/fields-factory';
import { getCurrentStep, getGuid, saveCountries, saveCurrentStep, saveGuid } from '../../security/utils/fields-storage';
import { decideOcrFlowAndSave } from '../../security/services/ocr-flow-decider';
import { REGISTER_STEP_8 } from '../../security/utils/register-steps';
import { USERNAME_FIELD } from '../../security/reducers/features/form-structures';

export const handleMessage = (message, dispatch) => {
    const formattedMessage = JSON.parse(message.data);
    if (shouldAddResponseToStack(formattedMessage)) {
        dispatch(addResponseToStack(formattedMessage));
        dispatch(setLastResponse(formattedMessage));
    }

    switch (formattedMessage.cmd) {
        case COMMAND_NAMES.register:
            if (formattedMessage.data) {
                const url = window.location.href;
                const guidUrlParam = getQueryParam(url, 'guid');
                if (doesResponseContainsData(formattedMessage) && guidUrlParam) {
                    setFieldsFromResponse(formattedMessage.data, dispatch);
                }
                if (formattedMessage.data.guid && isArray(formattedMessage.data.guid)) {
                    const savedGuid = getGuid();
                    const guid = formattedMessage.data.guid[0];
                    const decodedJsonCommand = formattedMessage.prm.json ? JSON.parse(formattedMessage.prm.json) : {};
                    if (hasOnlyGetNewGuidProperty(decodedJsonCommand)) {
                        // const adrFlowPercentage = formattedMessage.data.fluxADR[0];
                        const adrFlowPercentage = 50;
                        decideOcrFlowAndSave(dispatch, adrFlowPercentage);
                    }
                    if (savedGuid !== guid) {
                        saveGuid(guid);
                    }
                }
                if (formattedMessage.data.cod && formattedMessage.data.nume && formattedMessage.prm.json.includes('tarile')) {
                    saveCountries(formattedMessage.data.cod, formattedMessage.data.nume);
                    const guidUrlParamExists = getQueryParam(url, 'guid');
                    if (!guidUrlParamExists) {
                        setTimeout(() => window.location.reload(), 500);
                    }
                }
                if (formattedMessage.data.pasnou && isArray(formattedMessage.data.pasnou)) {
                    const savedCurrentStep = getCurrentStep();
                    const currentStep = formattedMessage.data.pasnou[0];
                    if (savedCurrentStep !== currentStep) {
                        saveCurrentStep(currentStep);
                    }
                }
                if (formattedMessage.data.valid && isArray(formattedMessage.data.valid)) {
                    const isValid = formattedMessage.data.valid[0];
                    dispatch(setRegisterStepFieldLoading(REGISTER_STEP_8, USERNAME_FIELD, false));
                    dispatch(setRegisterStepFieldValidity(REGISTER_STEP_8, USERNAME_FIELD, isValid));
                }
                if (formattedMessage.data.msg && isArray(formattedMessage.data.msg)) {
                    const smsMessage = formattedMessage.data.msg[0];
                    dispatch(setSmsMessage(smsMessage));
                }
            }
            break;
        default:
            break;
    }
};

const shouldAddResponseToStack = (message) => message.data && !message.data.pasnou && !message.data.pascurent;
const doesResponseContainsData = (response) => {
    // eslint-disable-next-line
    if (response.hasOwnProperty('prm') && typeof response.prm === 'object') {
        // eslint-disable-next-line
        if (response.prm.hasOwnProperty('json') && typeof response.prm.json === 'string') {
            try {
                const jsonParsed = JSON.parse(response.prm.json);
                const keys = Object.keys(jsonParsed);
                if (keys.length === 1 && keys[0] === 'guid') {
                    return true;
                }
            } catch (e) {
                return false;
            }
        }
    }

    return false;
};

const hasOnlyGetNewGuidProperty = (obj) => {
    // eslint-disable-next-line no-prototype-builtins
    const hasGetNewGuid = obj.hasOwnProperty('getNewGuid');
    const hasOnlyOneProperty = Object.keys(obj).length === 1;
    return hasGetNewGuid && hasOnlyOneProperty;
};

export default handleMessage;
