import { isArray } from 'lodash';

import { saveCurrentStep, saveRegisterStepField } from '../utils/fields-storage';
import { setRegisterStep, setRegisterStepFieldValue } from '../actions/register';
import { ADDRESS_DIFFER, ADDRESS_SAME, NOTIFICATION_DOMICILE, NOTIFICATION_RESIDENCY } from '../components/forms/RegisterLocationForm';
import { LEGAL_PERSON, NATURAL_PERSON, PASSPORT_IDENTITY_TYPE, RO_CI_IDENTITY_TYPE } from '../components/steps/RegisterStep2';
import {
    REGISTER_STEP_10, REGISTER_STEP_11, REGISTER_STEP_12, REGISTER_STEP_2, REGISTER_STEP_5, REGISTER_STEP_6,
    REGISTER_STEP_7, REGISTER_STEP_8, REGISTER_STEP_9, getStepFromName,
} from '../utils/register-steps';
import {
    ADDRESS_DIFFER_FIELD, ADDRESS_FIELD, ANNUAL_INCOME_FIELD, BANK_NAME_FIELD, BENEFICIARY_AGREEMENT, BIRTH_DATE_FIELD,
    BIRTH_PLACE_FIELD, CI_FIELD, CITIZENSHIP_FIELD, CITY_FIELD, CNP_FIELD, COMPANY_NAME_FIELD, COUNTRY_FIELD, COUNTY_FIELD,
    CURRENCY_FIELD, DOMAIN_FIELD, EDUCATION_LEVEL_FIELD, EMAIL_FIELD, EMITTED_BY_FIELD, EMITTED_DATE_FIELD, EMPLOYER_FIELD,
    ESTIMATE_PERCENTAGE_FIELD, EXPERIENCE_FIELD, EXPIRE_DATE_FIELD, FINAL_AGREEMENT_2_FIELD, FINAL_AGREEMENT_FIELD,
    FIRST_NAME_FIELD, FUNDS_SOURCE_FIELD, IBAN_FIELD, INTERNATIONAL_PHONE_COUNTRY_FIELD, INVESTMENT_FIELD, LAST_NAME_FIELD,
    NATIONALITY_FIELD, OCCUPATION_FIELD, ORIGIN_FIELD, PASSWORD_FIELD, PERSON_NONRESIDENT_FIELD, PHONE_FIELD, PUBLIC_FUNCTION_FIELD,
    PUBLIC_PERSON_FIELD, REAL_BENEFICIARY_DIFFER_FIELD, REAL_BENEFICIARY_FIELD, REAL_BENEFICIARY_FUNDS_SOURCE_FIELD,
    REAL_BENEFICIARY_QUESTION_FIELD, REPETEAD_PASSWORD_FIELD, RESIDENCY_ADDRESS_FIELD, RESIDENCY_CITY_FIELD, RESIDENCY_COUNTRY_FIELD,
    RESIDENCY_COUNTY_FIELD, RESIDENCY_NOTIFICATION_FIELD, RISK_FIELD, SELECTED_IDENTITY_FIELD, SELECTED_TYPE_PERSON_FIELD,
    SPECULATIVE_FIELD, USERNAME_FIELD, PERSON_COMPENSABLE_FIELD,
} from '../reducers/features/form-structures';
import {
    STEP_ENTRY_NAME, RISK_ENTRY_NAME, EXPERIENCE_ENTRY_NAME, ESTIMATE_PERCENTAGE_ENTRY_NAME, INVESTMENT_ENTRY_NAME,
    ADDRESS_ENTRY_NAME, BENEFICIARY_AGREEMENT_ENTRY_NAME, ADDRESS_DIFFER_ENTRY_NAME, ANNUAL_INCOME_ENTRY_NAME,
    BANK_NAME_ENTRY_NAME, BIRTH_DATE_ENTRY_NAME, BIRTH_PLACE_ENTRY_NAME, CI_ENTRY_NAME, CITIZENSHIP_ENTRY_NAME,
    CITY_ENTRY_NAME, CNP_ENTRY_NAME, EDUCATION_LEVEL_ENTRY_NAME, COMPANY_NAME_ENTRY_NAME, COUNTRY_ENTRY_NAME, COUNTY_ENTRY_NAME,
    CURRENCY_ENTRY_NAME, DOMAIN_ENTRY_NAME, EMAIL_ENTRY_NAME, EMITTED_BY_ENTRY_NAME, EMPLOYER_ENTRY_NAME, FUNDS_SOURCE_ENTRY_NAME,
    EXPIRE_DATE_ENTRY_NAME, FINAL_AGREEMENT_2_ENTRY_NAME, FINAL_AGREEMENT_ENTRY_NAME, FIRST_NAME_ENTRY_NAME, IBAN_ENTRY_NAME,
    RESIDENCY_ADDRESS_ENTRY_NAME, EMMITED_DATE_ENTRY_NAME, FUNDS_SOURCE_TIT_ENTRY_NAME, INTERNATIONAL_PHONE_COUNTRY_ENTRY_NAME,
    LAST_NAME_ENTRY_NAME, NATIONALITY_ENTRY_NAME, OCCUPATION_ENTRY_NAME, ORIGIN_ENTRY_NAME, PASSPORT_IDENTITY_TYPE_ENTRY_NAME,
    PASSWORD_ENTRY_NAME, PERSON_NONRESIDENT_ENTRY_NAME, PHONE_ENTRY_NAME, PUBLIC_FUNCTION_ENTRY_NAME, PUBLIC_PERSON_ENTRY_NAME,
    REAL_BENEFICIARY_DIFFER_ENTRY_NAME, REPETEAD_PASSWORD_ENTRY_NAME, REAL_BENEFICIARY_ENTRY_NAME, REAL_BENEFICIARY_FUNDS_SOURCE_ENTRY_NAME,
    RESIDENCY_NOTIFICATION_ENTRY_NAME, REAL_BENEFICIARY_QUESTION_ENTRY_NAME, RESIDENCY_CITY_ENTRY_NAME, RESIDENCY_COUNTRY_ENTRY_NAME,
    RO_CI_IDENTITY_TYPE_ENTRY_NAME, RESIDENCY_COUNTY_ENTRY_NAME, SELECTED_IDENTITY_ENTRY_NAME, SELECTED_TYPE_PERSON_ENTRY_NAME,
    TRANSACTION_SCOPE_ENTRY_NAME, USERNAME_ENTRY_NAME, PERSON_COMPENSABLE_ENTRY_NAME,
} from './database-entries';

export const setFieldsFromResponse = (data, dispatch) => {
    Object.keys(data).forEach((item) => {
        if (data[item][0]) {
            const [fieldName, fieldValue, step] = getFieldStructureByDatabaseEntry(item, data[item][0]);
            if (fieldName !== null && fieldValue !== null) {
                if (fieldName === 'step' && fieldValue) {
                    saveCurrentStep(fieldValue);
                    dispatch(setRegisterStep(getStepFromName(fieldValue)));
                    return;
                }
                if (isArray(fieldName)) {
                    fieldName.forEach((field) => {
                        dispatch(setRegisterStepFieldValue(step, field, fieldValue));
                        saveRegisterStepField(step, field, fieldValue);
                    });

                    return;
                }

                dispatch(setRegisterStepFieldValue(step, fieldName, fieldValue));
                saveRegisterStepField(step, fieldName, fieldValue);
            }
        }
    });
};

export const getFieldStructureByDatabaseEntry = (databaseEntry, databaseValue) => {
    switch (databaseEntry) {
        case STEP_ENTRY_NAME:
            return ['step', databaseValue, 'step'];
        case SELECTED_TYPE_PERSON_ENTRY_NAME:
            return [SELECTED_TYPE_PERSON_FIELD, databaseValue === 'F' ? NATURAL_PERSON : LEGAL_PERSON, REGISTER_STEP_2];
        case EMAIL_ENTRY_NAME:
            return [EMAIL_FIELD, databaseValue, REGISTER_STEP_2];
        case PHONE_ENTRY_NAME:
            return [PHONE_FIELD, databaseValue, REGISTER_STEP_2];
        case INTERNATIONAL_PHONE_COUNTRY_ENTRY_NAME:
            return [INTERNATIONAL_PHONE_COUNTRY_FIELD, databaseValue, REGISTER_STEP_2];
        case COMPANY_NAME_ENTRY_NAME:
            return [COMPANY_NAME_FIELD, databaseValue, REGISTER_STEP_2];
        case SELECTED_IDENTITY_ENTRY_NAME:
            // eslint-disable-next-line no-case-declarations
            let value = null;
            if (databaseValue === RO_CI_IDENTITY_TYPE_ENTRY_NAME) {
                value = RO_CI_IDENTITY_TYPE;
            }
            if (databaseValue === PASSPORT_IDENTITY_TYPE_ENTRY_NAME) {
                value = PASSPORT_IDENTITY_TYPE;
            }

            return [SELECTED_IDENTITY_FIELD, value, REGISTER_STEP_2];
        case FINAL_AGREEMENT_ENTRY_NAME:
            return [FINAL_AGREEMENT_FIELD, true, REGISTER_STEP_2];
        case FINAL_AGREEMENT_2_ENTRY_NAME:
            return [FINAL_AGREEMENT_2_FIELD, true, REGISTER_STEP_2];
        case LAST_NAME_ENTRY_NAME:
            return [LAST_NAME_FIELD, databaseValue, REGISTER_STEP_5];
        case FIRST_NAME_ENTRY_NAME:
            return [FIRST_NAME_FIELD, databaseValue, REGISTER_STEP_5];
        case CNP_ENTRY_NAME:
            return [CNP_FIELD, databaseValue, REGISTER_STEP_5];
        case CI_ENTRY_NAME:
            return [CI_FIELD, databaseValue, REGISTER_STEP_5];
        case EMITTED_BY_ENTRY_NAME:
            return [EMITTED_BY_FIELD, databaseValue, REGISTER_STEP_5];
        case EMMITED_DATE_ENTRY_NAME:
            return [EMITTED_DATE_FIELD, databaseValue, REGISTER_STEP_5];
        case EXPIRE_DATE_ENTRY_NAME:
            return [EXPIRE_DATE_FIELD, databaseValue, REGISTER_STEP_5];
        case BIRTH_DATE_ENTRY_NAME:
            return [BIRTH_DATE_FIELD, databaseValue, REGISTER_STEP_5];
        case BIRTH_PLACE_ENTRY_NAME:
            return [BIRTH_PLACE_FIELD, databaseValue, REGISTER_STEP_5];
        case ADDRESS_ENTRY_NAME:
            return [ADDRESS_FIELD, databaseValue, REGISTER_STEP_5];
        case COUNTY_ENTRY_NAME:
            return [COUNTY_FIELD, databaseValue, REGISTER_STEP_5];
        case CITY_ENTRY_NAME:
            return [CITY_FIELD, databaseValue, REGISTER_STEP_5];
        case COUNTRY_ENTRY_NAME:
            return [COUNTRY_FIELD, databaseValue.toLowerCase(), REGISTER_STEP_5];
        case CITIZENSHIP_ENTRY_NAME:
            return [CITIZENSHIP_FIELD, databaseValue.toLowerCase(), REGISTER_STEP_6];
        case NATIONALITY_ENTRY_NAME:
            return [NATIONALITY_FIELD, databaseValue.toLowerCase(), REGISTER_STEP_6];
        case ORIGIN_ENTRY_NAME:
            return [ORIGIN_FIELD, databaseValue.toLowerCase(), REGISTER_STEP_6];
        case ADDRESS_DIFFER_ENTRY_NAME:
            return [ADDRESS_DIFFER_FIELD, databaseValue === 'D' ? ADDRESS_DIFFER : ADDRESS_SAME, REGISTER_STEP_6];
        case RESIDENCY_ADDRESS_ENTRY_NAME:
            return [RESIDENCY_ADDRESS_FIELD, databaseValue, REGISTER_STEP_6];
        case RESIDENCY_CITY_ENTRY_NAME:
            return [RESIDENCY_CITY_FIELD, databaseValue, REGISTER_STEP_6];
        case RESIDENCY_COUNTY_ENTRY_NAME:
            return [RESIDENCY_COUNTY_FIELD, databaseValue, REGISTER_STEP_6];
        case RESIDENCY_COUNTRY_ENTRY_NAME:
            return [RESIDENCY_COUNTRY_FIELD, databaseValue.toLowerCase(), REGISTER_STEP_6];
        case RESIDENCY_NOTIFICATION_ENTRY_NAME:
            return [RESIDENCY_NOTIFICATION_FIELD, databaseValue === 'Domiciliu' ? NOTIFICATION_DOMICILE : NOTIFICATION_RESIDENCY, REGISTER_STEP_6];
        case BANK_NAME_ENTRY_NAME:
            return [BANK_NAME_FIELD, databaseValue, REGISTER_STEP_7];
        case CURRENCY_ENTRY_NAME:
            return [CURRENCY_FIELD, databaseValue, REGISTER_STEP_7];
        case IBAN_ENTRY_NAME:
            return [IBAN_FIELD, databaseValue, REGISTER_STEP_7];
        case USERNAME_ENTRY_NAME:
            return [USERNAME_FIELD, databaseValue, REGISTER_STEP_8];
        case PASSWORD_ENTRY_NAME:
            return [PASSWORD_FIELD, databaseValue, REGISTER_STEP_8];
        case REPETEAD_PASSWORD_ENTRY_NAME:
            return [REPETEAD_PASSWORD_FIELD, databaseValue, REGISTER_STEP_8];
        case PERSON_NONRESIDENT_ENTRY_NAME:
            return [PERSON_NONRESIDENT_FIELD, databaseValue === 0, REGISTER_STEP_9];
        case PERSON_COMPENSABLE_ENTRY_NAME:
            return [PERSON_COMPENSABLE_FIELD, databaseValue === 1, REGISTER_STEP_9];
        case PUBLIC_PERSON_ENTRY_NAME:
            return [PUBLIC_PERSON_FIELD, databaseValue === 1, REGISTER_STEP_9];
        case PUBLIC_FUNCTION_ENTRY_NAME:
            return [PUBLIC_FUNCTION_FIELD, databaseValue, REGISTER_STEP_9];
        case FUNDS_SOURCE_ENTRY_NAME:
            return [FUNDS_SOURCE_FIELD, databaseValue, REGISTER_STEP_9];
        case REAL_BENEFICIARY_DIFFER_ENTRY_NAME:
            return [REAL_BENEFICIARY_DIFFER_FIELD, databaseValue === 0, REGISTER_STEP_9];
        case REAL_BENEFICIARY_ENTRY_NAME:
            return [REAL_BENEFICIARY_FIELD, databaseValue, REGISTER_STEP_9];
        case REAL_BENEFICIARY_QUESTION_ENTRY_NAME:
            return [REAL_BENEFICIARY_QUESTION_FIELD, databaseValue === '1', REGISTER_STEP_9];
        case REAL_BENEFICIARY_FUNDS_SOURCE_ENTRY_NAME:
            return [REAL_BENEFICIARY_FUNDS_SOURCE_FIELD, databaseValue, REGISTER_STEP_9];
        case BENEFICIARY_AGREEMENT_ENTRY_NAME:
            return [BENEFICIARY_AGREEMENT, databaseValue === 'on', REGISTER_STEP_9];
        case OCCUPATION_ENTRY_NAME:
            return [OCCUPATION_FIELD, databaseValue, REGISTER_STEP_10];
        case EMPLOYER_ENTRY_NAME:
            return [EMPLOYER_FIELD, databaseValue, REGISTER_STEP_10];
        case DOMAIN_ENTRY_NAME:
            return [DOMAIN_FIELD, databaseValue, REGISTER_STEP_10];
        case EDUCATION_LEVEL_ENTRY_NAME:
            return [EDUCATION_LEVEL_FIELD, Number(databaseValue) + 1, REGISTER_STEP_11];
        case ANNUAL_INCOME_ENTRY_NAME:
            return [ANNUAL_INCOME_FIELD, Number(databaseValue) + 1, REGISTER_STEP_11];
        case FUNDS_SOURCE_TIT_ENTRY_NAME:
            return [FUNDS_SOURCE_FIELD, databaseValue, REGISTER_STEP_11];
        case TRANSACTION_SCOPE_ENTRY_NAME:
            // eslint-disable-next-line no-case-declarations
            let shouldSetSpeculative = false;
            // eslint-disable-next-line no-case-declarations
            let shouldSetInvestment = false;
            if (databaseValue.includes('0')) {
                shouldSetSpeculative = true;
            }
            if (databaseValue.includes('1')) {
                shouldSetInvestment = true;
            }

            if (shouldSetInvestment && shouldSetSpeculative) {
                return [[INVESTMENT_FIELD, SPECULATIVE_FIELD], true, REGISTER_STEP_11];
            }
            if (shouldSetInvestment) {
                return [INVESTMENT_FIELD, true, REGISTER_STEP_11];
            }
            if (shouldSetSpeculative) {
                return [SPECULATIVE_FIELD, true, REGISTER_STEP_11];
            }
            break;
        case ESTIMATE_PERCENTAGE_ENTRY_NAME:
            return [ESTIMATE_PERCENTAGE_FIELD, Number(databaseValue) + 1, REGISTER_STEP_12];
        case EXPERIENCE_ENTRY_NAME:
            return [EXPERIENCE_FIELD, Number(databaseValue) + 1, REGISTER_STEP_12];
        case RISK_ENTRY_NAME:
            return [RISK_FIELD, Number(databaseValue) + 1, REGISTER_STEP_12];
        case INVESTMENT_ENTRY_NAME:
            return [INVESTMENT_FIELD, Number(databaseValue) + 1, REGISTER_STEP_12];
        default:
            return [null, null, null];
    }

    return [null, null, null];
};
