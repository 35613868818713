import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';

import RegisterHelpOverlayer, { REGISTER_HELP_OVERLAYER_NAME, WHAT_STEPS_MODE, WHAT_YOU_NEED_MODE } from '../overlayers/RegisterHelpOverlayer';
import RegisterStep from '../RegisterStep';
import Checkbox from '../forms/core/Checkbox';
import validateStep from '../../validators/step-validator';
import usePageTitle from '../../../core/hoc/use-page-title';
import { selectRegisterStepFormData } from '../../selectors/register';
import { setRegisterStepFields, setRegisterStepFieldValue } from '../../actions/register';
import { sendMessage } from '../../../core/websocket/engine';
import { openOverlayer } from '../../../core/actions/overlayer';
import { getCurrentStepCommand } from '../../decorators/payloads';
import { getRegisterStepFields, resetAllData, saveRegisterStepFields } from '../../utils/fields-storage';
import { getFlow } from '../../utils/ocr-storage';
import { REGISTER_STEP_1, REGISTER_STEP_1_NAME } from '../../utils/register-steps';
import { PRESENTATION_DOCUMENT_FIELD, LEGAL_ACKNOWLEDGE_FIELD } from '../../reducers/features/form-structures';
import { boldFont, textMainColor } from '../../../core/styles/generic.styles';
import { NEW_OCR_FLOW } from '../../services/ocr-flow-decider';

const RegisterStep1 = ({ goToNextStep }) => {
    const [overlayerMode, setOverlayerMode] = useState(WHAT_YOU_NEED_MODE);
    const dispatch = useDispatch();
    const inputs = useSelector((state) => selectRegisterStepFormData(state, REGISTER_STEP_1));

    const flow = getFlow();
    const version = flow === NEW_OCR_FLOW ? 'v2' : 'v1';
    usePageTitle(`Deschidere cont - Pas 1 | ${version}`, true);
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'Pagina_virtuala_desc',
                pageTitle: document.title,
            });
        }, 500);

        return () => clearTimeout(timeoutId);
    }, []);

    useEffect(() => {
        sendMessage(getCurrentStepCommand(REGISTER_STEP_1_NAME), dispatch);
        dispatch(setRegisterStepFields(REGISTER_STEP_1, getRegisterStepFields(REGISTER_STEP_1)));
    }, []);

    const onGoNextClick = () => {
        const isValid = validateStep(dispatch, REGISTER_STEP_1, inputs);
        if (isValid) {
            saveRegisterStepFields(inputs, REGISTER_STEP_1);
        }
        return isValid && goToNextStep();
    };

    const onFirstHelperPress = () => {
        setOverlayerMode(WHAT_YOU_NEED_MODE);
        dispatch(openOverlayer(REGISTER_HELP_OVERLAYER_NAME));
    };

    const onSecondHelperPress = () => {
        setOverlayerMode(WHAT_STEPS_MODE);
        dispatch(openOverlayer(REGISTER_HELP_OVERLAYER_NAME));
    };

    return (
        <>
            <RegisterStep
                title={<FormattedMessage id='register_page_step_one_title' />}
                formTitle={<FormattedMessage id='register_page_step_one_form_title' />}
                formSubtitle={<><FormattedMessage id='register_page_step_one_form_subtitle' /> <a href="https://mbl.tradeville.ro/Documents/Home/Document_prezentare.pdf" target="_blank" rel="noreferrer"><FormattedMessage id='register_page_step_one_form_subtitle_action_text' /></a>:</>}
                prevBtnText={<FormattedMessage id='prev' />}
                nextBtnText={<FormattedMessage id='next' />}
                onGoNextClick={onGoNextClick}
            >
                <>
                    <p style={boldFont}>
                        <FormattedMessage id='register_page_step_one_subtitle_part_1' />
                        <span style={textMainColor} onClick={onFirstHelperPress}>
                            <FormattedMessage id='register_page_step_one_subtitle_part_2' />
                        </span>
                        <FormattedMessage id='register_page_step_one_subtitle_part_3' />
                        <span style={textMainColor} onClick={onSecondHelperPress}>
                            <FormattedMessage id='register_page_step_one_subtitle_part_4' />
                        </span>
                        <FormattedMessage id='register_page_step_one_subtitle_part_5' />
                    </p>
                    <p><FormattedMessage id='register_page_step_one_desc_1' /></p>
                    <p><FormattedMessage id='register_page_step_one_desc_2' /></p>
                </>
                <>
                    <Checkbox
                        controlId={PRESENTATION_DOCUMENT_FIELD}
                        isInvalid={!inputs[PRESENTATION_DOCUMENT_FIELD].isValid}
                        checked={inputs[PRESENTATION_DOCUMENT_FIELD].value}
                        label={<FormattedMessage id='register_page_step_one_form_checkbox_1' />}
                        onChange={(e) => (
                            dispatch(setRegisterStepFieldValue(REGISTER_STEP_1, PRESENTATION_DOCUMENT_FIELD, e.target.checked))
                        )}
                    />
                    <Checkbox
                        controlId={LEGAL_ACKNOWLEDGE_FIELD}
                        isInvalid={!inputs[LEGAL_ACKNOWLEDGE_FIELD].isValid}
                        checked={inputs[LEGAL_ACKNOWLEDGE_FIELD].value}
                        label={<FormattedMessage id='register_page_step_one_form_checkbox_2' />}
                        onChange={(e) => (
                            dispatch(setRegisterStepFieldValue(REGISTER_STEP_1, LEGAL_ACKNOWLEDGE_FIELD, e.target.checked))
                        )}
                    />
                    <span style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => { resetAllData(); window.location.reload(); }}>
                        <FormattedMessage id='reset_form' />
                    </span>
                    <br/><br/>
                </>
            </RegisterStep>
            <RegisterHelpOverlayer overlayerMode={overlayerMode} />
        </>
    );
};

export default RegisterStep1;
