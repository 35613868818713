export default {
    en: {
        no: 'No',
        yes: 'Yes',
        yes_comp: 'Yes (I\'m compensable)',
        no_comp: 'No (I\'m not compensable)',
        next: 'Next',
        finish: 'Finish',
        prev: 'Back',
        attention: 'Please note that',
        i_understand: 'I understand',
        error: 'It seems an error occured!',
        try_again: 'Please try again',
        phone: 'Telephone',
        decline_notifications: 'I do not subscribe',
        accept_notifications: 'I subscribe',
        not_working: 'Not working?',
        are_you_sure: 'Are you sure? You will need to start the process again.',
        read: 'read',
        and: 'and',
        select: 'Select',
        footer_title: 'WHY I LOVE ',
        footer_desc_part_1: 'Investment in financial instruments involves specific risks.',
        footer_desc_part_2: 'Past performances do not constitute a reliable indicator of future performance.',
        footer_desc_part_3: 'There is no financial instrument without risk.',
        footer_desc_part_4: 'SSIF Tradeville SA, Calea Vitan nr. 6A, Bl. B, Tronson B, et. 3, Bucuresti, +40 21 318 75 55, help@tradeville.ro.',
        footer_desc_part_5: 'The CNVM authorization 2225/15.07.2003. Regulated by',
    },
    ro: {
        no: 'Nu',
        yes: 'Da',
        yes_comp: 'Da (sunt compensat)',
        no_comp: 'Nu (nu sunt compensat)',
        next: 'Inainte',
        finish: 'Finalizeaza',
        prev: 'Inapoi',
        attention: 'Atentie',
        i_understand: 'Am inteles',
        error: 'A aparut o eroare!',
        try_again: 'Te rog incearca din nou',
        phone: 'Telefon',
        decline_notifications: 'Nu ma abonez',
        accept_notifications: 'Ma abonez',
        not_working: 'Nu functioneaza?',
        are_you_sure: 'Esti sigur? Va trebui sa incepi procesul din nou.',
        read: 'citeste',
        and: 'si',
        select: 'Selectati',
        footer_title: 'DE CE IUBESC ',
        footer_desc_part_1: 'Investitia in instrumente financiare presupune riscuri specifice',
        footer_desc_part_2: 'Performantele anterioare nu reprezinta un indicator fiabil al performantei viitoare',
        footer_desc_part_3: 'Nu exista instrument financiar fara risc',
        footer_desc_part_4: 'SSIF Tradeville SA, Calea Vitan nr. 6A, Bl. B, Tronson B, et. 3, Bucuresti, +40 21 318 75 55, help@tradeville.ro.',
        footer_desc_part_5: 'Autorizatia CNVM 2225/15.07.2003. Reglementata de',
    },
};
