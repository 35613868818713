import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import RegisterStep from '../RegisterStep';
import RegisterLegalForm from '../forms/RegisterLegalForm';
import validateStep from '../../validators/step-validator';
import usePageTitle from '../../../core/hoc/use-page-title';
import { selectRegisterStepFormData } from '../../selectors/register';
import { resetRegisterStepValidity, setRegisterStepFields } from '../../actions/register';
import { sendMessage } from '../../../core/websocket/engine';
import { getCurrentStepCommand, getStep9Command } from '../../decorators/payloads';
import { getFlow } from '../../utils/ocr-storage';
import { getRegisterStepFields, saveCurrentStep, saveRegisterStepFields } from '../../utils/fields-storage';
import { getDmonitorData } from '../../api/dmonitor';
import { REGISTER_STEP_9_NAME, REGISTER_STEP_5, REGISTER_STEP_9 } from '../../utils/register-steps';
import { NEW_OCR_FLOW } from '../../services/ocr-flow-decider';
import { FIRST_NAME_FIELD, LAST_NAME_FIELD } from '../../reducers/features/form-structures';

const RegisterStep9 = ({ goToNextStep, goToPrevStep }) => {
    const dispatch = useDispatch();
    const inputs = useSelector((state) => selectRegisterStepFormData(state, REGISTER_STEP_9));
    const inputsFromStep5 = useSelector((state) => selectRegisterStepFormData(state, REGISTER_STEP_5));

    useEffect(() => {
        dispatch(setRegisterStepFields(REGISTER_STEP_5, getRegisterStepFields(REGISTER_STEP_5)));
    }, []);

    const onSubmit = async () => {
        const isValid = validateStep(dispatch, REGISTER_STEP_9, inputs);
        if (isValid) {
            try {
                const firstName = replaceRomanianDiacritics(inputsFromStep5[FIRST_NAME_FIELD].value);
                const lastName = replaceRomanianDiacritics(inputsFromStep5[LAST_NAME_FIELD].value);
                const response = await getDmonitorData(firstName.toLowerCase(), lastName.toLowerCase());
                const formattedResponse = await response.json();
                sendMessage(getStep9Command(Object.values(inputs), formattedResponse), dispatch);
                saveRegisterStepFields(inputs, REGISTER_STEP_9);
            } catch (e) {
                sendMessage(getStep9Command(Object.values(inputs), ''), dispatch);
                saveRegisterStepFields(inputs, REGISTER_STEP_9);
            }
        }

        return isValid && goToNextStep();
    };

    const flow = getFlow();
    const version = flow === NEW_OCR_FLOW ? 'v2' : 'v1';
    usePageTitle(`Deschidere cont - Pas 9 | ${version}`);

    useEffect(() => {
        saveCurrentStep(REGISTER_STEP_9_NAME);
        sendMessage(getCurrentStepCommand(REGISTER_STEP_9_NAME), dispatch);
        dispatch(setRegisterStepFields(REGISTER_STEP_9, getRegisterStepFields(REGISTER_STEP_9)));
    }, []);

    const onBack = () => {
        dispatch(resetRegisterStepValidity(REGISTER_STEP_9));
        goToPrevStep();
    };

    return (
        <RegisterStep
            title={<FormattedMessage id='register_page_step_nine_title' />}
            formTitle={<FormattedMessage id='register_page_step_five_form_title' />}
            formSubtitle={<></>}
            prevBtnText={<FormattedMessage id='prev' />}
            nextBtnText={<FormattedMessage id='next' />}
            onGoNextClick={onSubmit}
            onGoPrevClick={onBack}
        >
            <>
                <p><FormattedMessage id='register_page_step_nine_desc_1' /></p>
                <p><FormattedMessage id='register_page_step_nine_desc_2' /></p>
            </>
            <>
                <RegisterLegalForm inputs={inputs} dispatch={dispatch} />
            </>
        </RegisterStep>
    );
};

const replaceRomanianDiacritics = (text) => {
    const diacriticsMap = {
        ă: 'a',
        â: 'a',
        î: 'i',
        ș: 's',
        ț: 't',
        Ă: 'A',
        Â: 'A',
        Î: 'I',
        Ș: 'S',
        Ț: 'T',
    };

    return text.replace(/ă|â|î|ș|ț|Ă|Â|Î|Ș|Ț/g, (match) => diacriticsMap[match]);
};

export default RegisterStep9;
