import { hasPassedProcess } from '../../utils/ocr-storage';
import {
    ANNUAL_INCOME_VALUES, CITIZENSHIP_VALUES, DOMAIN_VALUES, EDUCATION_LEVEL_VALUES, ESTIMATE_VALUES,
    EXPERIENCE_VALUES, FUNDS_SOURCE_VALUES, INTERNATIONAL_PHONE_COUNTRIES_VALUES, INVESTMENT_VALUES, NATIONALITY_VALUES,
    OCCUPATION_VALUES, ORIGIN_VALUES, OWN_ACTIVITY_VALUES, RISK_VALUES, ROMANIA_COUNTRY_INDEX,
} from '../../utils/forms-options';
import {
    CHECKBOX_MANDATORY, CNP, EMAIL, RADIO, CHECKBOX_OPTIONAL, FILES, BANK, BIRTH_DATE, EXPIRE_DATE, PASSWORD, IBAN,
    TEXT, FILE, SELECT, PHONE, EMITTED_DATE, CHECKBOX_INVESTMENTS_MANDATORY_GROUP, CHECKBOX_PRODUCTS_MANDATORY_GROUP,
} from '../../validators/types';

export const PRESENTATION_DOCUMENT_FIELD = 'presentationDocument';
export const LEGAL_ACKNOWLEDGE_FIELD = 'legalAcknowledge';
export const REGISTER_STEP_1_INITIAL_STATE = {
    [PRESENTATION_DOCUMENT_FIELD]: {
        validator: CHECKBOX_MANDATORY,
        name: PRESENTATION_DOCUMENT_FIELD,
        value: false,
        isValid: true,
        isLoading: false,
        isVisible: true,
    },
    [LEGAL_ACKNOWLEDGE_FIELD]: {
        validator: CHECKBOX_MANDATORY,
        name: LEGAL_ACKNOWLEDGE_FIELD,
        value: false,
        isValid: true,
        isLoading: false,
        isVisible: true,
    },
};

export const SELECTED_TYPE_PERSON_FIELD = 'selectedTypePerson';
export const EMAIL_FIELD = 'email';
export const PHONE_FIELD = 'phone';
export const INTERNATIONAL_PHONE_FIELD = 'internationalPhone';
export const INTERNATIONAL_PHONE_COUNTRY_FIELD = 'internationalPhoneCountry';
export const INTERNATIONAL_PHONE_COUNTRY_NUMBER_FIELD = 'internationalPhoneCountryNumber';
export const SELECTED_IDENTITY_FIELD = 'selectedIdentity';
export const COMPANY_NAME_FIELD = 'companyName';
export const FINAL_AGREEMENT_FIELD = 'finalAgreement';
export const FINAL_AGREEMENT_2_FIELD = 'finalAgreement2';
export const REGISTER_STEP_2_INITIAL_STATE = {
    [SELECTED_TYPE_PERSON_FIELD]: {
        validator: RADIO,
        name: SELECTED_TYPE_PERSON_FIELD,
        value: null,
        isValid: true,
        isLoading: false,
        isVisible: true,
    },
    [EMAIL_FIELD]: {
        validator: EMAIL,
        name: EMAIL_FIELD,
        value: null,
        isValid: true,
        isLoading: false,
        isVisible: true,
    },
    [PHONE_FIELD]: {
        validator: PHONE,
        name: PHONE_FIELD,
        value: null,
        isValid: true,
        isLoading: false,
        isVisible: true,
    },
    [INTERNATIONAL_PHONE_FIELD]: {
        validator: CHECKBOX_OPTIONAL,
        name: INTERNATIONAL_PHONE_FIELD,
        value: false,
        isValid: true,
        isLoading: false,
        isVisible: true,
    },
    [INTERNATIONAL_PHONE_COUNTRY_FIELD]: {
        validator: SELECT,
        name: INTERNATIONAL_PHONE_COUNTRY_FIELD,
        value: INTERNATIONAL_PHONE_COUNTRIES_VALUES[0].id,
        isValid: true,
        isLoading: false,
        isVisible: false,
    },
    [INTERNATIONAL_PHONE_COUNTRY_NUMBER_FIELD]: {
        validator: TEXT,
        name: INTERNATIONAL_PHONE_COUNTRY_NUMBER_FIELD,
        value: null,
        isValid: true,
        isLoading: false,
        isVisible: false,
        isMandatory: false,
    },
    [SELECTED_IDENTITY_FIELD]: {
        validator: RADIO,
        name: SELECTED_IDENTITY_FIELD,
        value: null,
        isValid: true,
        isLoading: false,
        isVisible: false,
    },
    [COMPANY_NAME_FIELD]: {
        validator: TEXT,
        name: COMPANY_NAME_FIELD,
        value: null,
        isValid: true,
        isLoading: false,
        isVisible: false,
    },
    [FINAL_AGREEMENT_FIELD]: {
        validator: CHECKBOX_MANDATORY,
        name: FINAL_AGREEMENT_FIELD,
        value: null,
        isValid: true,
        isLoading: false,
        isVisible: true,
    },
    [FINAL_AGREEMENT_2_FIELD]: {
        validator: CHECKBOX_MANDATORY,
        name: FINAL_AGREEMENT_2_FIELD,
        value: null,
        isValid: true,
        isLoading: false,
        isVisible: true,
    },
};

export const FIRST_NAME_FIELD = 'firstName';
export const LAST_NAME_FIELD = 'lastName';
export const CNP_FIELD = 'cnp';
export const CI_FIELD = 'ci';
export const EMITTED_BY_FIELD = 'emittedBy';
export const EMITTED_DATE_FIELD = 'emittedDate';
export const EXPIRE_DATE_FIELD = 'expireDate';
export const BIRTH_DATE_FIELD = 'birthDate';
export const BIRTH_PLACE_FIELD = 'birthPlace';
export const ADDRESS_FIELD = 'address';
export const COUNTY_FIELD = 'county';
export const CITY_FIELD = 'city';
export const COUNTRY_FIELD = 'country';
export const REGISTER_STEP_5_INITIAL_STATE = {
    [FIRST_NAME_FIELD]: {
        validator: TEXT,
        name: FIRST_NAME_FIELD,
        value: null,
        isValid: true,
        isLoading: false,
        isVisible: true,
    },
    [LAST_NAME_FIELD]: {
        validator: TEXT,
        name: LAST_NAME_FIELD,
        value: null,
        isValid: true,
        isLoading: false,
        isVisible: true,
    },
    [CNP_FIELD]: {
        validator: CNP,
        name: CNP_FIELD,
        value: null,
        isValid: true,
        isLoading: false,
        isVisible: true,
    },
    [CI_FIELD]: {
        validator: TEXT,
        name: CI_FIELD,
        value: null,
        isValid: true,
        isLoading: false,
        isVisible: true,
    },
    [EMITTED_BY_FIELD]: {
        validator: TEXT,
        name: EMITTED_BY_FIELD,
        value: null,
        isValid: true,
        isLoading: false,
        isVisible: true,
    },
    [EMITTED_DATE_FIELD]: {
        validator: EMITTED_DATE,
        name: EMITTED_DATE_FIELD,
        value: null,
        isValid: true,
        isLoading: false,
        isVisible: true,
    },
    [EXPIRE_DATE_FIELD]: {
        validator: EXPIRE_DATE,
        name: EXPIRE_DATE_FIELD,
        value: null,
        isValid: true,
        isLoading: false,
        isVisible: true,
    },
    [BIRTH_DATE_FIELD]: {
        validator: BIRTH_DATE,
        name: BIRTH_DATE_FIELD,
        value: null,
        isValid: true,
        isLoading: false,
        isVisible: true,
    },
    [BIRTH_PLACE_FIELD]: {
        validator: TEXT,
        name: BIRTH_PLACE_FIELD,
        value: null,
        isValid: true,
        isLoading: false,
        isVisible: true,
    },
    [ADDRESS_FIELD]: {
        validator: TEXT,
        name: ADDRESS_FIELD,
        value: null,
        isValid: true,
        isLoading: false,
        isVisible: true,
    },
    [COUNTY_FIELD]: {
        validator: TEXT,
        name: COUNTY_FIELD,
        value: null,
        isValid: true,
        isLoading: false,
        isVisible: true,
    },
    [CITY_FIELD]: {
        validator: TEXT,
        name: CITY_FIELD,
        value: null,
        isValid: true,
        isLoading: false,
        isVisible: true,
    },
    [COUNTRY_FIELD]: {
        validator: SELECT,
        name: COUNTRY_FIELD,
        value: NATIONALITY_VALUES[ROMANIA_COUNTRY_INDEX] && NATIONALITY_VALUES[ROMANIA_COUNTRY_INDEX].id,
        isValid: true,
        isLoading: false,
        isVisible: true,
    },
    newFlow: false,
};

export const CITIZENSHIP_FIELD = 'citizenship';
export const NATIONALITY_FIELD = 'nationality';
export const ORIGIN_FIELD = 'origin';
export const ADDRESS_DIFFER_FIELD = 'addressDiffer';
export const RESIDENCY_ADDRESS_FIELD = 'residencyAddress';
export const RESIDENCY_CITY_FIELD = 'residencyCity';
export const RESIDENCY_COUNTY_FIELD = 'residencyCounty';
export const RESIDENCY_COUNTRY_FIELD = 'residencyCountry';
export const RESIDENCY_NOTIFICATION_FIELD = 'residencyNotification';
export const REGISTER_STEP_6_INITIAL_STATE = {
    [CITIZENSHIP_FIELD]: {
        validator: SELECT,
        name: CITIZENSHIP_FIELD,
        value: CITIZENSHIP_VALUES[ROMANIA_COUNTRY_INDEX] && CITIZENSHIP_VALUES[ROMANIA_COUNTRY_INDEX].id,
        isValid: true,
        isLoading: false,
        isVisible: true,
    },
    [NATIONALITY_FIELD]: {
        validator: SELECT,
        name: NATIONALITY_FIELD,
        value: NATIONALITY_VALUES[ROMANIA_COUNTRY_INDEX] && NATIONALITY_VALUES[ROMANIA_COUNTRY_INDEX].id,
        isValid: true,
        isLoading: false,
        isVisible: true,
    },
    [ORIGIN_FIELD]: {
        validator: SELECT,
        name: ORIGIN_FIELD,
        value: ORIGIN_VALUES[ROMANIA_COUNTRY_INDEX] && ORIGIN_VALUES[ROMANIA_COUNTRY_INDEX].id,
        isValid: true,
        isLoading: false,
        isVisible: true,
    },
    [ADDRESS_DIFFER_FIELD]: {
        validator: RADIO,
        name: ADDRESS_DIFFER_FIELD,
        value: null,
        isValid: true,
        isLoading: false,
        isVisible: true,
    },
    [RESIDENCY_ADDRESS_FIELD]: {
        validator: TEXT,
        name: RESIDENCY_ADDRESS_FIELD,
        value: null,
        isValid: true,
        isLoading: false,
        isVisible: false,
    },
    [RESIDENCY_CITY_FIELD]: {
        validator: TEXT,
        name: RESIDENCY_CITY_FIELD,
        value: null,
        isValid: true,
        isLoading: false,
        isVisible: false,
    },
    [RESIDENCY_COUNTY_FIELD]: {
        validator: TEXT,
        name: RESIDENCY_COUNTY_FIELD,
        value: null,
        isValid: true,
        isLoading: false,
        isVisible: false,
    },
    [RESIDENCY_COUNTRY_FIELD]: {
        validator: SELECT,
        name: RESIDENCY_COUNTRY_FIELD,
        value: NATIONALITY_VALUES[ROMANIA_COUNTRY_INDEX] && NATIONALITY_VALUES[ROMANIA_COUNTRY_INDEX].id,
        isValid: true,
        isLoading: false,
        isVisible: false,
    },
    [RESIDENCY_NOTIFICATION_FIELD]: {
        validator: RADIO,
        name: RESIDENCY_NOTIFICATION_FIELD,
        value: null,
        isValid: true,
        isLoading: false,
        isVisible: false,
    },
};

export const BANK_NAME_FIELD = 'bankName';
export const CURRENCY_FIELD = 'currency';
export const IBAN_FIELD = 'iban';
export const BANK_FILE_FIELD = 'bankFile';
export const BANK_FILENAME = 'Extras_cont_bancar';
export const REGISTER_STEP_7_INITIAL_STATE = {
    [BANK_NAME_FIELD]: {
        validator: BANK,
        name: BANK_NAME_FIELD,
        value: null,
        isValid: true,
        isLoading: false,
        isVisible: true,
    },
    [CURRENCY_FIELD]: {
        validator: RADIO,
        name: CURRENCY_FIELD,
        value: null,
        isValid: true,
        isLoading: false,
        isVisible: true,
    },
    [IBAN_FIELD]: {
        validator: IBAN,
        name: IBAN_FIELD,
        value: null,
        isValid: true,
        isLoading: false,
        isVisible: true,
    },
    [BANK_FILE_FIELD]: {
        validator: FILE,
        name: BANK_FILE_FIELD,
        value: null,
        isValid: true,
        isLoading: false,
        isVisible: true,
        isMandatory: true,
    },
};

export const USERNAME_FIELD = 'username';
export const PASSWORD_FIELD = 'password';
export const REPETEAD_PASSWORD_FIELD = 'repeteadPassword';
export const REGISTER_STEP_8_INITIAL_STATE = {
    [USERNAME_FIELD]: {
        validator: TEXT,
        name: USERNAME_FIELD,
        value: null,
        isValid: true,
        isLoading: false,
        isVisible: true,
    },
    [PASSWORD_FIELD]: {
        validator: PASSWORD,
        name: PASSWORD_FIELD,
        value: null,
        isValid: true,
        isLoading: false,
        isVisible: true,
        errorMessage: null,
    },
    [REPETEAD_PASSWORD_FIELD]: {
        validator: PASSWORD,
        name: REPETEAD_PASSWORD_FIELD,
        value: null,
        isValid: true,
        isLoading: false,
        isVisible: true,
        errorMessage: null,
    },
};

export const PERSON_NONRESIDENT_FIELD = 'personNonresident';
export const PUBLIC_PERSON_FIELD = 'publicPersonField';
export const REAL_BENEFICIARY_DIFFER_FIELD = 'realBeneficiaryDiffer';
export const PUBLIC_FUNCTION_FIELD = 'publicFunction';
export const FUNDS_SOURCE_FIELD = 'fundsSource';
export const REAL_BENEFICIARY_FIELD = 'realBeneficiary';
export const REAL_BENEFICIARY_QUESTION_FIELD = 'realBeneficiaryQuestion';
export const REAL_BENEFICIARY_FUNDS_SOURCE_FIELD = 'realBeneficiaryFundsSource';
export const BENEFICIARY_AGREEMENT = 'beneficiaryAgreement';
export const PERSON_COMPENSABLE_FIELD = 'personCompensable';
export const REGISTER_STEP_9_INITIAL_STATE = {
    [PERSON_NONRESIDENT_FIELD]: {
        validator: RADIO,
        name: PERSON_NONRESIDENT_FIELD,
        value: null,
        isValid: true,
        isLoading: false,
        isVisible: true,
    },
    [PUBLIC_PERSON_FIELD]: {
        validator: RADIO,
        name: PUBLIC_PERSON_FIELD,
        value: null,
        isValid: true,
        isLoading: false,
        isVisible: true,
    },
    [REAL_BENEFICIARY_DIFFER_FIELD]: {
        validator: RADIO,
        name: REAL_BENEFICIARY_DIFFER_FIELD,
        value: null,
        isValid: true,
        isLoading: false,
        isVisible: true,
    },
    [PUBLIC_FUNCTION_FIELD]: {
        validator: TEXT,
        name: PUBLIC_FUNCTION_FIELD,
        value: null,
        isValid: true,
        isLoading: false,
        isVisible: false,
    },
    [FUNDS_SOURCE_FIELD]: {
        validator: TEXT,
        name: FUNDS_SOURCE_FIELD,
        value: null,
        isValid: true,
        isLoading: false,
        isVisible: false,
    },
    [REAL_BENEFICIARY_FIELD]: {
        validator: SELECT,
        name: REAL_BENEFICIARY_FIELD,
        value: null,
        isValid: true,
        isLoading: false,
        isVisible: false,
    },
    [REAL_BENEFICIARY_QUESTION_FIELD]: {
        validator: SELECT,
        name: REAL_BENEFICIARY_QUESTION_FIELD,
        value: null,
        isValid: true,
        isLoading: false,
        isVisible: false,
    },
    [REAL_BENEFICIARY_FUNDS_SOURCE_FIELD]: {
        validator: RADIO,
        name: REAL_BENEFICIARY_FUNDS_SOURCE_FIELD,
        value: FUNDS_SOURCE_VALUES[0].id,
        isValid: true,
        isLoading: false,
        isVisible: false,
    },
    [PERSON_COMPENSABLE_FIELD]: {
        validator: RADIO,
        name: PERSON_COMPENSABLE_FIELD,
        value: null,
        isValid: true,
        isLoading: false,
        isVisible: true,
    },
    [BENEFICIARY_AGREEMENT]: {
        validator: CHECKBOX_MANDATORY,
        name: BENEFICIARY_AGREEMENT,
        value: false,
        isValid: true,
        isLoading: false,
        isVisible: true,
    },
};

export const OCCUPATION_FIELD = 'occupation';
export const OWN_ACTIVITY_FIELD = 'ownActivity';
export const EMPLOYER_FIELD = 'employer';
export const DOMAIN_FIELD = 'domain';
export const REGISTER_STEP_10_INITIAL_STATE = {
    [OCCUPATION_FIELD]: {
        validator: SELECT,
        name: OCCUPATION_FIELD,
        value: OCCUPATION_VALUES[0].id,
        isValid: true,
        isLoading: false,
        isVisible: true,
    },
    [OWN_ACTIVITY_FIELD]: {
        validator: SELECT,
        name: OWN_ACTIVITY_FIELD,
        value: OWN_ACTIVITY_VALUES[0].id,
        isValid: true,
        isLoading: false,
        isVisible: false,
    },
    [EMPLOYER_FIELD]: {
        validator: TEXT,
        name: EMPLOYER_FIELD,
        value: null,
        isValid: true,
        isLoading: false,
        isVisible: true,
    },
    [DOMAIN_FIELD]: {
        validator: SELECT,
        name: DOMAIN_FIELD,
        value: DOMAIN_VALUES[0].id,
        isValid: true,
        isLoading: false,
        isVisible: true,
    },
};

export const SPECULATIVE_FIELD = 'speculative';
export const INVESTMENT_FIELD = 'investment';
export const EDUCATION_LEVEL_FIELD = 'educationLevel';
export const ANNUAL_INCOME_FIELD = 'annualIncome';
export const REGISTER_STEP_11_INITIAL_STATE = {
    [SPECULATIVE_FIELD]: {
        validator: CHECKBOX_INVESTMENTS_MANDATORY_GROUP,
        name: SPECULATIVE_FIELD,
        value: false,
        isValid: true,
        isLoading: false,
        isVisible: true,
    },
    [INVESTMENT_FIELD]: {
        validator: CHECKBOX_INVESTMENTS_MANDATORY_GROUP,
        name: INVESTMENT_FIELD,
        value: false,
        isValid: true,
        isLoading: false,
        isVisible: true,
    },
    [EDUCATION_LEVEL_FIELD]: {
        validator: SELECT,
        name: EDUCATION_LEVEL_FIELD,
        value: EDUCATION_LEVEL_VALUES[0].id,
        isValid: true,
        isLoading: false,
        isVisible: true,
    },
    [ANNUAL_INCOME_FIELD]: {
        validator: SELECT,
        name: ANNUAL_INCOME_FIELD,
        value: ANNUAL_INCOME_VALUES[0].id,
        isValid: true,
        isLoading: false,
        isVisible: true,
    },
    [FUNDS_SOURCE_FIELD]: {
        validator: SELECT,
        name: FUNDS_SOURCE_FIELD,
        value: FUNDS_SOURCE_VALUES[0].id,
        isValid: true,
        isLoading: false,
        isVisible: true,
    },
};

export const ESTIMATE_PERCENTAGE_FIELD = 'estimatePercentage';
export const EXPERIENCE_FIELD = 'experience';
export const RISK_FIELD = 'risk';
export const PRODUCT1_FIELD = 'product1';
export const PRODUCT2_FIELD = 'product2';
export const PRODUCT3_FIELD = 'product3';
export const PRODUCT4_FIELD = 'product4';
export const PRODUCT5_FIELD = 'product5';
export const PRODUCT6_FIELD = 'product6';
export const PRODUCT7_FIELD = 'product7';
export const REGISTER_STEP_12_INITIAL_STATE = {
    [ESTIMATE_PERCENTAGE_FIELD]: {
        validator: SELECT,
        name: ESTIMATE_PERCENTAGE_FIELD,
        value: ESTIMATE_VALUES[0].id,
        isValid: true,
        isLoading: false,
        isVisible: true,
    },
    [EXPERIENCE_FIELD]: {
        validator: SELECT,
        name: EXPERIENCE_FIELD,
        value: EXPERIENCE_VALUES[0].id,
        isValid: true,
        isLoading: false,
        isVisible: true,
    },
    [RISK_FIELD]: {
        validator: SELECT,
        name: RISK_FIELD,
        value: RISK_VALUES[0].id,
        isValid: true,
        isLoading: false,
        isVisible: true,
    },
    [INVESTMENT_FIELD]: {
        validator: SELECT,
        name: INVESTMENT_FIELD,
        value: INVESTMENT_VALUES[0].id,
        isValid: true,
        isLoading: false,
        isVisible: true,
    },
    [PRODUCT1_FIELD]: {
        validator: CHECKBOX_PRODUCTS_MANDATORY_GROUP,
        name: PRODUCT1_FIELD,
        value: false,
        isValid: true,
        isLoading: false,
        isVisible: true,
    },
    [PRODUCT2_FIELD]: {
        validator: CHECKBOX_PRODUCTS_MANDATORY_GROUP,
        name: PRODUCT2_FIELD,
        value: false,
        isValid: true,
        isLoading: false,
        isVisible: true,
    },
    [PRODUCT3_FIELD]: {
        validator: CHECKBOX_PRODUCTS_MANDATORY_GROUP,
        name: PRODUCT3_FIELD,
        value: false,
        isValid: true,
        isLoading: false,
        isVisible: true,
    },
    [PRODUCT4_FIELD]: {
        validator: CHECKBOX_PRODUCTS_MANDATORY_GROUP,
        name: PRODUCT4_FIELD,
        value: false,
        isValid: true,
        isLoading: false,
        isVisible: true,
    },
    [PRODUCT5_FIELD]: {
        validator: CHECKBOX_PRODUCTS_MANDATORY_GROUP,
        name: PRODUCT5_FIELD,
        value: false,
        isValid: true,
        isLoading: false,
        isVisible: true,
    },
    [PRODUCT6_FIELD]: {
        validator: CHECKBOX_PRODUCTS_MANDATORY_GROUP,
        name: PRODUCT6_FIELD,
        value: false,
        isValid: true,
        isLoading: false,
        isVisible: true,
    },
    [PRODUCT7_FIELD]: {
        validator: CHECKBOX_PRODUCTS_MANDATORY_GROUP,
        name: PRODUCT7_FIELD,
        value: false,
        isValid: true,
        isLoading: false,
        isVisible: true,
    },
};

export const FISCAL_CODE_FIELD = 'fiscalCode';
export const NEW_COUNTRY_FIELD = 'newCountry';
export const NEW_FISCAL_CODE_FIELD = 'newFiscalCode';
export const NEW_COUNTRY_2_FIELD = 'newCountry2';
export const NEW_FISCAL_CODE_2_FIELD = 'newFiscalCode2';
export const USA_CITIZEN_FIELD = 'usaCitizen';
export const REGISTER_STEP_13_INITIAL_STATE = {
    [COUNTRY_FIELD]: {
        validator: SELECT,
        name: COUNTRY_FIELD,
        value: CITIZENSHIP_VALUES[ROMANIA_COUNTRY_INDEX] && CITIZENSHIP_VALUES[ROMANIA_COUNTRY_INDEX].id,
        isValid: true,
        isLoading: false,
        isVisible: true,
    },
    [FISCAL_CODE_FIELD]: {
        validator: TEXT,
        name: FISCAL_CODE_FIELD,
        value: null,
        isValid: true,
        isLoading: false,
        isVisible: true,
    },
    [NEW_COUNTRY_FIELD]: {
        validator: SELECT,
        name: NEW_COUNTRY_FIELD,
        value: CITIZENSHIP_VALUES[ROMANIA_COUNTRY_INDEX] && CITIZENSHIP_VALUES[ROMANIA_COUNTRY_INDEX].id,
        isValid: true,
        isLoading: false,
        isVisible: false,
    },
    [NEW_FISCAL_CODE_FIELD]: {
        validator: TEXT,
        name: NEW_FISCAL_CODE_FIELD,
        value: null,
        isValid: true,
        isLoading: false,
        isVisible: false,
    },
    [NEW_COUNTRY_2_FIELD]: {
        validator: SELECT,
        name: NEW_COUNTRY_2_FIELD,
        value: CITIZENSHIP_VALUES[ROMANIA_COUNTRY_INDEX] && CITIZENSHIP_VALUES[ROMANIA_COUNTRY_INDEX].id,
        isValid: true,
        isLoading: false,
        isVisible: false,
    },
    [NEW_FISCAL_CODE_2_FIELD]: {
        validator: TEXT,
        name: NEW_FISCAL_CODE_2_FIELD,
        value: null,
        isValid: true,
        isLoading: false,
        isVisible: false,
    },
    [USA_CITIZEN_FIELD]: {
        validator: RADIO,
        name: USA_CITIZEN_FIELD,
        value: null,
        isValid: true,
        isLoading: false,
        isVisible: true,
    },
};

export const ID_FILE_FIELD = 'idFile';
export const ID_FILENAME = 'Act_Identitate';
export const SELFIE_FILE_FIELD = 'selfieFile';
export const SELFIE_FILENAME = 'Selfie';
export const OTHER_FILES_FIELD = 'otherFiles';
export const REGISTER_STEP_14_INITIAL_STATE = {
    [ID_FILE_FIELD]: {
        validator: FILE,
        name: ID_FILE_FIELD,
        value: null,
        isValid: true,
        isLoading: false,
        isVisible: true,
        isMandatory: !hasPassedProcess(),
    },
    [SELFIE_FILE_FIELD]: {
        validator: FILE,
        name: SELFIE_FILE_FIELD,
        value: null,
        isValid: true,
        isLoading: false,
        isVisible: true,
        isMandatory: !hasPassedProcess(),
    },
    [OTHER_FILES_FIELD]: {
        validator: FILES,
        name: OTHER_FILES_FIELD,
        value: null,
        isValid: true,
        isLoading: false,
        isVisible: true,
        isMandatory: false,
    },
};

export const LEGAL_FILES_FIELD = 'files';
export const REGISTER_STEP_16_INITIAL_STATE = {
    [LEGAL_FILES_FIELD]: {
        validator: FILES,
        name: LEGAL_FILES_FIELD,
        value: null,
        isValid: true,
        isLoading: false,
        isVisible: true,
        isMandatory: true,
    },
};

export const CUI_FILE_FIELD = 'cuiFile';
export const CUI_FILENAME = 'CopieCUI';
export const CONSTITUTIVE_FILE_FIELD = 'constitutiveFile';
export const CONSTITUTIVE_FILENAME = 'Copie_act_constitutiv';
export const BANK_JURIDIC_FILENAME = 'Extras_cont_bancarPJ';
export const ONRC_FILE_FIELD = 'onrcFile';
export const ONRC_FILENAME = 'Certificat_cons_onrc';
export const BENEFICIARY_FILE_FIELD = 'beneficiaryFile';
export const BENEFICIARY_FILENAME = 'Doc_repr_si_benef';
export const LEI_CODE_FILE_FIELD = 'leiCodeFile';
export const LEI_CODE_FILENAME = 'Cod_lei';
export const REGISTER_STEP_17_INITIAL_STATE = {
    [CUI_FILE_FIELD]: {
        validator: FILE,
        name: CUI_FILE_FIELD,
        value: null,
        isValid: true,
        isLoading: false,
        isVisible: true,
        isMandatory: true,
    },
    [BANK_FILE_FIELD]: {
        validator: FILE,
        name: BANK_FILE_FIELD,
        value: null,
        isValid: true,
        isLoading: false,
        isVisible: true,
        isMandatory: true,
    },
    [CONSTITUTIVE_FILE_FIELD]: {
        validator: FILE,
        name: CONSTITUTIVE_FILE_FIELD,
        value: null,
        isValid: true,
        isLoading: false,
        isVisible: true,
        isMandatory: true,
    },
    [ONRC_FILE_FIELD]: {
        validator: FILE,
        name: ONRC_FILE_FIELD,
        value: null,
        isValid: true,
        isLoading: false,
        isVisible: true,
        isMandatory: true,
    },
    [BENEFICIARY_FILE_FIELD]: {
        validator: FILE,
        name: BENEFICIARY_FILE_FIELD,
        value: null,
        isValid: true,
        isLoading: false,
        isVisible: true,
        isMandatory: true,
    },
    [LEI_CODE_FILE_FIELD]: {
        validator: FILE,
        name: LEI_CODE_FILE_FIELD,
        value: null,
        isValid: true,
        isLoading: false,
        isVisible: true,
        isMandatory: true,
    },
    [OTHER_FILES_FIELD]: {
        validator: FILES,
        name: OTHER_FILES_FIELD,
        value: null,
        isValid: true,
        isLoading: false,
        isVisible: true,
        isMandatory: false,
    },
};
