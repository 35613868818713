import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { ErrorBoundary } from 'react-error-boundary';

import WebSocket from '../websocket/components/WebSocket';
import I18n from '../hoc/I18n';
import Error from '../components/Error';
import reducer from '../reducers/index';
import router from '../routes';
import translations from '../i18n/translations';
import getQueryParam from '../utils/url';
import { resetAllData } from '../../security/utils/fields-storage';

const Root = () => {
    const store = getReduxStore();
    const I18nRouterProvider = I18n(RouterProvider, translations);

    useEffect(() => {
        const url = window.location.href;
        const guidUrlParam = getQueryParam(url, 'guid');
        if (guidUrlParam) {
            resetAllData();
        }
    }, []);

    const fallbackRender = ({ resetErrorBoundary }) => (
        <Error onRetry={() => { resetErrorBoundary(); window.location.reload(); }} />
    );

    return (
        <ErrorBoundary fallbackRender={fallbackRender} onReset={() => { window.location.reload(); }}>
            <Provider store={store}>
                <WebSocket />
                <I18nRouterProvider router={router}/>
            </Provider>
        </ErrorBoundary>
    );
};

const getReduxStore = () => {
    if (process.env.NODE_ENV === 'production') {
        return createStore(reducer, applyMiddleware(thunk));
    }

    return createStore(reducer, composeWithDevTools(applyMiddleware(thunk)));
};

export default Root;
