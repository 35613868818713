import React from 'react';
import { Form } from 'react-bootstrap';

import { checkboxContainer } from '../../../containers/RegisterPage.styles';

const Checkbox = ({ controlId, label, checked, isInvalid, onChange, customStyle = {} }) => (
    <div style={{ ...checkboxContainer, width: 'auto', ...customStyle }}>
        <Form.Group controlId={controlId}>
            <Form.Check
                type="checkbox"
                isInvalid={isInvalid}
                label={label}
                checked={checked}
                onChange={onChange}
            />
        </Form.Group>
    </div>
);

export default Checkbox;
