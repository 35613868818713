import React from 'react';
import { FormattedMessage } from 'react-intl';

import TextInput from './core/TextInput';
import PasswordInput from './core/PasswordInput';
import { setRegisterStepFieldValue } from '../../actions/register';
import { PASSWORD_FIELD, REPETEAD_PASSWORD_FIELD, USERNAME_FIELD } from '../../reducers/features/form-structures';
import { REGISTER_STEP_8 } from '../../utils/register-steps';

const RegisterAccessDataForm = ({ inputs, dispatch, onUsernameFinish }) => (
    <>
        <TextInput
            label={<FormattedMessage id='register_page_step_eight_username' />}
            controlId={inputs[USERNAME_FIELD].name}
            isInvalid={!inputs[USERNAME_FIELD].isValid}
            value={inputs[USERNAME_FIELD].value}
            onBlur={onUsernameFinish}
            onInputChange={(e) => (
                dispatch(setRegisterStepFieldValue(REGISTER_STEP_8, USERNAME_FIELD, removeDiacriticsAndSpecialChars(e.target.value)))
            )}
            isLoading={inputs[USERNAME_FIELD].isLoading}
        />
        <PasswordInput
            label={<FormattedMessage id='register_page_step_eight_password' />}
            controlId={inputs[PASSWORD_FIELD].name}
            isInvalid={!inputs[PASSWORD_FIELD].isValid}
            value={inputs[PASSWORD_FIELD].value}
            errorMessage={inputs[PASSWORD_FIELD].errorMessage}
            onInputChange={(e) => dispatch(setRegisterStepFieldValue(REGISTER_STEP_8, PASSWORD_FIELD, e.target.value))}
        />
        <PasswordInput
            label={<FormattedMessage id='register_page_step_eight_repetead_password' />}
            controlId={inputs[REPETEAD_PASSWORD_FIELD].name}
            isInvalid={!inputs[REPETEAD_PASSWORD_FIELD].isValid}
            value={inputs[REPETEAD_PASSWORD_FIELD].value}
            errorMessage={inputs[REPETEAD_PASSWORD_FIELD].errorMessage}
            onInputChange={(e) => dispatch(setRegisterStepFieldValue(REGISTER_STEP_8, REPETEAD_PASSWORD_FIELD, e.target.value))}
        />
    </>
);

const removeDiacriticsAndSpecialChars = (value) => {
    let normalized = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    normalized = normalized.replace(/[^a-zA-Z0-9\s]/g, '');
    return normalized;
};

export default RegisterAccessDataForm;
