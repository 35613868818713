import React from 'react';
import { Form } from 'react-bootstrap';

import { displayFlexRow, marginRightWithWidth, width } from '../../../../core/styles/generic.styles';
import { checkboxContainer } from '../../../containers/RegisterPage.styles';

const TwoCheckboxesRow = ({
    formControlId, label, firstInputLabel, firstInputValue, firstInputIsInvalid, firstInputControlId, onFirstInputChange,
    secondInputLabel, secondInputValue, secondInputIsInvalid, secondInputControlId, onSecondInputChange,
}) => (
    <Form.Group controlId={formControlId}>
        <p className="mb-1">{label}</p>
        <div style={displayFlexRow}>
            <span style={marginRightWithWidth(15, 50)}>
                <div style={{ ...checkboxContainer, width: 'auto', marginBottom: 10 }}>
                    <Form.Group controlId={firstInputControlId}>
                        <Form.Check
                            type="checkbox"
                            isInvalid={firstInputIsInvalid}
                            label={firstInputLabel}
                            checked={firstInputValue}
                            onChange={onFirstInputChange}
                        />
                    </Form.Group>
                </div>
            </span>
            <span style={width(50)}>
                <div style={{ ...checkboxContainer, width: 'auto', marginBottom: 10 }}>
                    <Form.Group controlId={secondInputControlId}>
                        <Form.Check
                            type="checkbox"
                            isInvalid={secondInputIsInvalid}
                            label={secondInputLabel}
                            checked={secondInputValue}
                            onChange={onSecondInputChange}
                        />
                    </Form.Group>
                </div>
            </span>
        </div>
    </Form.Group>
);

export default TwoCheckboxesRow;
