import { saveFlow } from '../utils/ocr-storage';
import { setFlow } from '../actions/ocr';

export const NEW_OCR_FLOW = 1;
export const OLD_OCR_FLOW = 2;

export const decideOcrFlowAndSave = (dispatch, newOcrFlowPercentage) => {
    const flow = getRandomFlow(newOcrFlowPercentage);
    saveFlow(flow);
    dispatch(setFlow(flow));
};

const getRandomFlow = (newOcrFlowPercentage) => {
    return OLD_OCR_FLOW;
    // eslint-disable-next-line no-unreachable
    const rnd = Math.random() * 100;
    if (rnd <= newOcrFlowPercentage) {
        return NEW_OCR_FLOW;
    }

    return OLD_OCR_FLOW;
};
