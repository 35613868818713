import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import RegisterStep from '../RegisterStep';
import RegisterFinancialForm from '../forms/RegisterFinancialForm';
import validateStep from '../../validators/step-validator';
import usePageTitle from '../../../core/hoc/use-page-title';
import { selectRegisterStepFormData } from '../../selectors/register';
import { resetRegisterStepValidity, setRegisterStepFields } from '../../actions/register';
import { sendMessage } from '../../../core/websocket/engine';
import { getCurrentStepCommand, getStep11Command } from '../../decorators/payloads';
import { getRegisterStepFields, saveCurrentStep, saveRegisterStepFields } from '../../utils/fields-storage';
import { getFlow } from '../../utils/ocr-storage';
import { REGISTER_STEP_11, REGISTER_STEP_11_NAME } from '../../utils/register-steps';
import { NEW_OCR_FLOW } from '../../services/ocr-flow-decider';

const RegisterStep11 = ({ goToNextStep, goToPrevStep }) => {
    const dispatch = useDispatch();
    const inputs = useSelector((state) => selectRegisterStepFormData(state, REGISTER_STEP_11));

    const onSubmit = () => {
        const isValid = validateStep(dispatch, REGISTER_STEP_11, inputs);
        if (isValid) {
            sendMessage(getStep11Command(Object.values(inputs)), dispatch);
            saveRegisterStepFields(inputs, REGISTER_STEP_11);
        }

        return isValid && goToNextStep();
    };

    const onBack = () => {
        dispatch(resetRegisterStepValidity(REGISTER_STEP_11));
        goToPrevStep();
    };

    const flow = getFlow();
    const version = flow === NEW_OCR_FLOW ? 'v2' : 'v1';
    usePageTitle(`Deschidere cont - Pas 11 | ${version}`);

    useEffect(() => {
        saveCurrentStep(REGISTER_STEP_11_NAME);
        sendMessage(getCurrentStepCommand(REGISTER_STEP_11_NAME), dispatch);
        dispatch(setRegisterStepFields(REGISTER_STEP_11, getRegisterStepFields(REGISTER_STEP_11)));
    }, []);

    return (
        <RegisterStep
            title={<FormattedMessage id='register_page_step_ten_title' />}
            formTitle={<FormattedMessage id='register_page_step_five_form_title' />}
            formSubtitle={<></>}
            prevBtnText={<FormattedMessage id='prev' />}
            nextBtnText={<FormattedMessage id='next' />}
            onGoNextClick={onSubmit}
            onGoPrevClick={onBack}
        >
            <>
                <p><FormattedMessage id='register_page_step_eleven_desc' /></p>
            </>
            <>
                <RegisterFinancialForm dispatch={dispatch} inputs={inputs} />
            </>
        </RegisterStep>
    );
};

export default RegisterStep11;
