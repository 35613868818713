import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import RegisterStep from '../RegisterStep';
import RegisterFullInfoForm from '../forms/RegisterFullInfoForm';
import validateStep from '../../validators/step-validator';
import usePageTitle from '../../../core/hoc/use-page-title';
import { resetRegisterStepValidity, setRegisterStepFields } from '../../actions/register';
import { selectRegisterStepFormData } from '../../selectors/register';
import { sendMessage } from '../../../core/websocket/engine';
import { getCurrentStepCommand, getStep5Command } from '../../decorators/payloads';
import { getRegisterStepFields, saveCurrentStep, saveRegisterStepFields } from '../../utils/fields-storage';
import { getFlow } from '../../utils/ocr-storage';
import { REGISTER_STEP_5_NAME, REGISTER_STEP_5, REGISTER_STEP_2 } from '../../utils/register-steps';
import { SELECTED_IDENTITY_FIELD } from '../../reducers/features/form-structures';
import { PASSPORT_IDENTITY_TYPE } from './RegisterStep2';
import { contentTitleFormat } from '../../containers/RegisterPage.styles';
import { NEW_OCR_FLOW } from '../../services/ocr-flow-decider';

const RegisterStep5 = ({ goToNextStep, goToPrevStep, goToStep }) => {
    const dispatch = useDispatch();
    const inputs = useSelector((state) => selectRegisterStepFormData(state, REGISTER_STEP_5));
    const inputsFromStep2 = useSelector((state) => selectRegisterStepFormData(state, REGISTER_STEP_2));
    const selectedIdentity = inputsFromStep2[SELECTED_IDENTITY_FIELD].value;
    const isPassportSelected = selectedIdentity === PASSPORT_IDENTITY_TYPE;

    const flow = getFlow();
    const version = flow === NEW_OCR_FLOW ? 'v2' : 'v1';
    usePageTitle(`Deschidere cont - Pas 5 | ${version}`);

    useEffect(() => {
        saveCurrentStep(REGISTER_STEP_5_NAME);
        sendMessage(getCurrentStepCommand(REGISTER_STEP_5_NAME), dispatch);
        dispatch(setRegisterStepFields(REGISTER_STEP_2, getRegisterStepFields(REGISTER_STEP_2)));
        dispatch(setRegisterStepFields(REGISTER_STEP_5, getRegisterStepFields(REGISTER_STEP_5)));
    }, []);

    const onGoNextClick = () => {
        const isValid = validateStep(dispatch, REGISTER_STEP_5, inputs, isPassportSelected);
        if (isValid) {
            sendMessage(getStep5Command(Object.values(inputs)), dispatch);
            saveRegisterStepFields(inputs, REGISTER_STEP_5);
        }
        return isValid && goToNextStep();
    };

    const onGoPrevClick = () => {
        dispatch(resetRegisterStepValidity(REGISTER_STEP_5));
        if (isPassportSelected) {
            goToStep(REGISTER_STEP_2)();
        } else {
            goToPrevStep();
        }
    };

    return (
        <RegisterStep
            title={<FormattedMessage id='register_page_step_five_title' />}
            formTitle={inputs.newFlow
                ? <FormattedMessage id='register_page_step_five_form_title_v2' />
                : <FormattedMessage id='register_page_step_five_form_title' />
            }
            formSubtitle={<></>}
            prevBtnText={<FormattedMessage id='prev' />}
            nextBtnText={<FormattedMessage id='next' />}
            onGoNextClick={onGoNextClick}
            onGoPrevClick={onGoPrevClick}
        >
            <>
                <p style={contentTitleFormat}><FormattedMessage id='register_page_step_five_subtitle' /></p>
                <p><FormattedMessage id='register_page_step_five_desc' /></p>
            </>
            <div>
                <RegisterFullInfoForm dispatch={dispatch} inputs={inputs} isPassportSelected={isPassportSelected} />
            </div>
        </RegisterStep>
    );
};

export default RegisterStep5;
