import { sendMessage } from '../../core/websocket/engine';
import { getAllRegisterStepsFields } from '../utils/fields-storage';
import {
    getStep2Command, getStep3Command, getStep5Command, getStep6Command, getStep7Command, getStep8Command, getStep9Command,
    getStep10Command, getStep11Command, getStep12Command, getStep13Command, getStep14Command,
} from '../decorators/payloads';
import {
    REGISTER_STEP_10, REGISTER_STEP_11, REGISTER_STEP_12, REGISTER_STEP_13, REGISTER_STEP_14, REGISTER_STEP_2,
    REGISTER_STEP_3, REGISTER_STEP_5, REGISTER_STEP_6, REGISTER_STEP_7, REGISTER_STEP_8, REGISTER_STEP_9,
} from '../utils/register-steps';

const valuesFallbackFactory = (dispatch) => {
    const registerStepsFields = getAllRegisterStepsFields();
    registerStepsFields.forEach((fields, step) => {
        if (Object.values(fields).length === 0) {
            return null;
        }

        const command = getCommandForStep(step + 1);
        if (!command) {
            return null;
        }

        return sendMessage(command(Object.values(fields)), dispatch);
    });
};

const getCommandForStep = (step) => {
    switch (step) {
        case REGISTER_STEP_2:
            return getStep2Command;
        case REGISTER_STEP_3:
            return getStep3Command;
        case REGISTER_STEP_5:
            return getStep5Command;
        case REGISTER_STEP_6:
            return getStep6Command;
        case REGISTER_STEP_7:
            return getStep7Command;
        case REGISTER_STEP_8:
            return getStep8Command;
        case REGISTER_STEP_9:
            return getStep9Command;
        case REGISTER_STEP_10:
            return getStep10Command;
        case REGISTER_STEP_11:
            return getStep11Command;
        case REGISTER_STEP_12:
            return getStep12Command;
        case REGISTER_STEP_13:
            return getStep13Command;
        case REGISTER_STEP_14:
            return getStep14Command;
        default:
            return null;
    }
};

export default valuesFallbackFactory;
