export const validateCheckbox = (value) => !!value;
export const validateRadio = (value) => value !== null;
export const validateSelect = (value) => value !== null && value !== '' && value !== 0 && value !== '0' && value !== 'blank';
export const validateText = (value) => value && value.length >= 2;
export const validateFile = (value) => value && typeof value === 'object';
export const validatePasswordMatch = (password, confirmPassword) => password === confirmPassword;
export const validateBankName = (value) => value && value.length >= 2;

export const validateEmail = (value) => {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isEmail = emailRegex.test(value);
    return value && isEmail;
};

export const validateDate = (value) => {
    const dateRegex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;
    const isDate = dateRegex.test(value);
    return value && isDate;
};

export const validatePhoneNumber = (value) => {
    const phoneRegex = /^07\d{8,8}$/;
    const isPhoneNumber = phoneRegex.test(value);
    return value && isPhoneNumber;
};

export const validatePassword = (value) => {
    const passwordRegex = /.{8,}/;
    const isCorrect = passwordRegex.test(value);
    return value && isCorrect;
};

export const validateBirthDate = (value) => {
    const birthDate = new Date(value);
    const currentDate = new Date();
    const age = currentDate.getFullYear() - birthDate.getFullYear();

    return !(age < 18 || age > 150);
};

export const validateCheckboxGroup = (values) => {
    let isValid = false;
    values.forEach((value) => {
        if (validateCheckbox(value) === true) {
            isValid = true;
        }
    });

    return isValid;
};

export const validateFiles = (values) => {
    if (!values) {
        return false;
    }

    let isValid = true;
    Object.values(values).forEach((value) => {
        if (validateFile(value) === false) {
            isValid = false;
        }
    });

    return isValid;
};

export const validateEmittedDate = (value) => {
    if (!value) {
        return false;
    }

    const date = new Date(value);
    return date.getTime() < Date.now();
};

export const validateExpireDate = (value) => {
    if (!value) {
        return false;
    }

    const date = new Date(value);
    return date.getTime() > (Date.now() + 1e7);
};

export const validateEmittedDateAndExpireDateCompatibility = (emittedDate, expireDate) => {
    if (!emittedDate || !expireDate) {
        return false;
    }

    const emittedDateObj = new Date(emittedDate);
    const expireDateObj = new Date(expireDate);
    return emittedDateObj.getTime() < expireDateObj.getTime();
};

export const validateCnp = (cnp) => {
    if (cnp && cnp.length !== 13) {
        return false;
    }
    if (!/^\d+$/.test(cnp)) {
        return false;
    }

    const month = parseInt(cnp.substr(3, 2), 10);
    const day = parseInt(cnp.substr(5, 2), 10);
    if (month < 1 || month > 12 || day < 1 || day > 31) {
        return false;
    }

    const firstDigit = parseInt(cnp.charAt(0), 10);
    if (![1, 2, 3, 4, 5, 6].includes(firstDigit)) {
        return false;
    }

    const weights = [2, 7, 9, 1, 4, 6, 3, 5, 8, 2, 7, 9];
    let sum = 0;
    for (let i = 0; i < 12; i += 1) {
        sum += parseInt(cnp.charAt(i), 10) * weights[i];
    }
    const remainder = sum % 11;
    const checksum = remainder < 10 ? remainder : 1;

    return checksum === parseInt(cnp.charAt(12), 10);
};

export const validateIban = (value) => {
    const ibanRegex = /^[A-Z]{2}\d{2}[A-Z0-9]{1,30}$/;
    if (!ibanRegex.test(value)) {
        return false;
    }

    const countryCode = value.slice(0, 2);
    // eslint-disable-next-line max-len
    const ibanLengths = { AD: 24, AE: 23, AL: 28, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29, BY: 28, CH: 21, CR: 22, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, ES: 24, FI: 18, FO: 18, FR: 27, GB: 22, GE: 22, GI: 23, GL: 18, GR: 27, GT: 28, HR: 21, HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28, LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19, MR: 27, MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29, RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26, VG: 24, XK: 20 };
    if (!ibanLengths[countryCode]) {
        return false;
    }

    return value.length === ibanLengths[countryCode];
};
