import React from 'react';

import Loader from '../components/Loader';
import Error from '../components/Error';

const withAsyncLifecycle = (WrappedComponent, props) => (nestedProps) => {
    const { isFetching, isError, onRetry, isDisabled } = props;
    const showLoader = typeof props.showLoader === 'undefined' ? true : props.showLoader;
    if (isDisabled === true) {
        return <WrappedComponent {...nestedProps} />;
    }

    if (isError === true) {
        return <div style={{ justifyContent: 'center', display: 'flex' }}><Error onRetry={onRetry} /></div>;
    }

    if (isFetching === true) {
        if (showLoader === true) {
            return <div style={{ justifyContent: 'center', display: 'flex' }}><Loader /></div>;
        }

        return null;
    }

    return <WrappedComponent {...nestedProps}/>;
};

export default withAsyncLifecycle;
