import React, { useState } from 'react';

import RegisterStepHeader from './RegisterStepHeader';
import RegisterStepContent from './RegisterStepContent';
import { displayFlexRow } from '../../core/styles/generic.styles';

const RegisterStep = ({
    title, formTitle, formSubtitle, children, prevBtnText, nextBtnText, nextBtnDisabled = false, onGoNextClick, onGoPrevClick,
}) => {
    const [showDesc, setShowDesc] = useState(false);

    return (
        <div className="register-step-container row" style={displayFlexRow}>
            <RegisterStepHeader title={title} content={children[0]} showDesc={showDesc} hideDesc={() => setShowDesc(false)} />
            <RegisterStepContent
                formTitle={formTitle} formSubtitle={formSubtitle} content={children[1]} prevBtnText={prevBtnText}
                nextBtnText={nextBtnText} onGoNextClick={onGoNextClick} onGoPrevClick={onGoPrevClick}
                showDesc={showDesc} setShowDesc={setShowDesc} nextBtnDisabled={nextBtnDisabled}
            />
        </div>
    );
};

export default RegisterStep;
