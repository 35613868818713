import React from 'react';
import { createBrowserRouter } from 'react-router-dom';

import RegisterPage from '../security/containers/RegisterPage';

const router = createBrowserRouter([
    {
        path: '/',
        element: <RegisterPage />,
    },
    {
        path: 'desccont/desccont.htm',
        element: <RegisterPage />,
    },
]);

export default router;
