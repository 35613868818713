import React from 'react';

import { titleFormat } from '../containers/RegisterPage.styles';

const RegisterStepHeader = ({ title, content, hideDesc, showDesc }) => (
    <div className="register-step-container__col1 col-md-6">
        <div className="sticky-md-top pt-5" >
            <p className="register-step-container__col1__title pt-5" style={titleFormat}>{title}</p>
            <div className={`register-step-container__col1__desc ${showDesc && 'show'}`}>
                <p onClick={hideDesc} className="d-md-none register-step-container__col1__desc__close close">&times;</p>
                {content}
            </div>
        </div>
    </div>
);

export default RegisterStepHeader;
