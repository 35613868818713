import React from 'react';
import { Form } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';

const TextInput = ({ disabled = false, controlId, label, isInvalid, value, onInputChange, onBlur, isLoading }) => (
    <Form.Group className="mb-3" controlId={controlId}>
        <Form.Label>{label}</Form.Label>
        <Form.Control
            disabled={disabled}
            isInvalid={isInvalid}
            type="text"
            value={value || ''}
            onBlur={onBlur}
            onInput={onInputChange}
        />
        {isLoading && <Spinner animation="border" role="status" size='sm' />}
    </Form.Group>
);

export default TextInput;
