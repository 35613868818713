import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Image, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import selectIsOverlayerOpened from '../../../core/selectors/overlayer';
import { sendMessage } from '../../../core/websocket/engine';
import { closeOverlayer, openOverlayer } from '../../../core/actions/overlayer';
import { getSendBlockedMailCommand } from '../../decorators/payloads';

import HELPER_IMAGE_SOURCE from '../../../core/assets/img/contact.gif';

export const REGISTER_CONTACT_US_OVERLAYER_NAME = 'REGISTER_CONTACT_US';

const RegisterContactUsOverlayer = () => {
    const dispatch = useDispatch();
    const isOpen = useSelector((state) => selectIsOverlayerOpened(state, REGISTER_CONTACT_US_OVERLAYER_NAME));
    const onClose = () => dispatch(closeOverlayer(REGISTER_CONTACT_US_OVERLAYER_NAME));

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            dispatch(openOverlayer(REGISTER_CONTACT_US_OVERLAYER_NAME));
            sendMessage(getSendBlockedMailCommand(), dispatch);
        }, 900000);

        return () => {
            clearTimeout(timeoutId);
        };
    }, []);

    return (
        <Modal show={isOpen} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title><FormattedMessage id='register_overlayer_problems_title' /></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className='text-center'><Image width={250} height={250} src={HELPER_IMAGE_SOURCE} /></p>
                <h4 className='text-center'><FormattedMessage id='register_overlayer_problems_desc_part_1' /></h4>
                <h6 className="text-center mb-3">
                    <FormattedMessage id="register_overlayer_problems_desc_part_2"/>
                    <a href="tel:0040213187555">
                        +40 21 318 75 55
                    </a>
                    <FormattedMessage id="register_overlayer_problems_desc_part_3"/>
                    <a href="mailto:help@tradeville.ro">help@tradeville.ro</a>
                </h6>
            </Modal.Body>
        </Modal>
    );
};

export default RegisterContactUsOverlayer;
