import useWebSocket from 'react-use-websocket';

import getQueryParam from '../utils/url';
import { handleMessage } from './message-handler';
import { addCommandToStack, websocketConnecting, websocketConnectionComplete, websocketConnectionError } from '../actions/websocket';

export const WEBSOCKET_TEST_BASE_URL = 'wss://dev-pf.tradeville.ro';
export const WEBSOCKET_PROD_BASE_URL = 'wss://contnou.tradeville.ro';
const getWebSocketUrl = () => {
    const url = window.location.href;
    const debug = getQueryParam(url, '__debug');
    return debug ? WEBSOCKET_TEST_BASE_URL : WEBSOCKET_PROD_BASE_URL;
};

export const PROTOCOLS = ['pf4'];

export const initWebSocket = (dispatch) => {
    dispatch(websocketConnecting());
    window.websocket = useWebSocket(getWebSocketUrl(), {
        onOpen: () => dispatch(websocketConnectionComplete()),
        onError: () => dispatch(websocketConnectionError()),
        onMessage: (message) => handleMessage(message, dispatch),
        protocols: PROTOCOLS,
        shouldReconnect: () => true,
    });
};

export const sendMessage = (message, dispatch) => {
    if (!window.websocket || typeof window.websocket !== 'object') {
        initWebSocket();
        setTimeout(() => sendMessage(message, dispatch), 1500);
        return;
    }

    const formattedMessage = JSON.parse(message);
    if (shouldAddCommandToStack(formattedMessage)) {
        dispatch(addCommandToStack(formattedMessage));
    }

    window.websocket.sendMessage(`${message}`);
};

const shouldAddCommandToStack = (message) => (
    message.prm && message.prm.json && !message.prm.json.includes('"pasnou":"?"') && !message.prm.json.includes('"pascurent":')
);
