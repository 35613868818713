import React from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import TwoTextInputsRow from './core/TwoTextInputsRow';
import TextInput from './core/TextInput';
import TwoDateInputsRow from './core/TwoDateInputsRow';
import DateInput from './core/DateInput';
import Select from './core/Select';
import { setRegisterStepFieldValue } from '../../actions/register';
import { extractBirthdateFromCNP } from '../../utils/helpers';
import { NATIONALITY_VALUES } from '../../utils/forms-options';
import { REGISTER_STEP_5 } from '../../utils/register-steps';
import {
    CI_FIELD, EMITTED_BY_FIELD, CNP_FIELD, FIRST_NAME_FIELD, LAST_NAME_FIELD, EMITTED_DATE_FIELD,
    EXPIRE_DATE_FIELD, BIRTH_DATE_FIELD, BIRTH_PLACE_FIELD, ADDRESS_FIELD, COUNTY_FIELD, COUNTRY_FIELD, CITY_FIELD,
} from '../../reducers/features/form-structures';

const RegisterFullInfoForm = ({ dispatch, inputs, isPassportSelected }) => (
    <div>
        <Form>
            <TwoTextInputsRow
                firstInputDisabled={isInputDisabled(inputs, FIRST_NAME_FIELD)}
                controlId='formNames' firstInputLabel={<FormattedMessage id='register_page_step_five_first_name' />}
                firstInputInvalid={!inputs[FIRST_NAME_FIELD].isValid}
                firstInputValue={inputs[FIRST_NAME_FIELD].value}
                onFirstInputChange={(e) => (
                    dispatch(setRegisterStepFieldValue(REGISTER_STEP_5, FIRST_NAME_FIELD, e.target.value))
                )}
                secondInputDisabled={isInputDisabled(inputs, LAST_NAME_FIELD)}
                secondInputLabel={<FormattedMessage id='register_page_step_five_last_name' />}
                secondInputInvalid={!inputs[LAST_NAME_FIELD].isValid}
                secondInputValue={inputs[LAST_NAME_FIELD].value}
                onSecondInputChange={(e) => (
                    dispatch(setRegisterStepFieldValue(REGISTER_STEP_5, LAST_NAME_FIELD, e.target.value))
                )}
            />
            <TwoTextInputsRow
                firstInputDisabled={isInputDisabled(inputs, CNP_FIELD)}
                controlId='formNames' firstInputLabel={<FormattedMessage id='register_page_step_five_cnp' />}
                firstInputInvalid={!inputs[CNP_FIELD].isValid}
                firstInputValue={inputs[CNP_FIELD].value}
                onFirstInputChange={(e) => dispatch(setRegisterStepFieldValue(REGISTER_STEP_5, CNP_FIELD, e.target.value))}
                onFirstInputBlur={() => (
                    dispatch(
                        setRegisterStepFieldValue(REGISTER_STEP_5, BIRTH_DATE_FIELD, extractBirthdateFromCNP(inputs[CNP_FIELD].value)),
                    )
                )}
                secondInputDisabled={isInputDisabled(inputs, CI_FIELD)}
                secondInputLabel={isPassportSelected ? <FormattedMessage id='register_page_step_five_number' /> : <FormattedMessage id='register_page_step_five_series' />}
                secondInputInvalid={!inputs[CI_FIELD].isValid}
                secondInputValue={inputs[CI_FIELD].value}
                onSecondInputChange={(e) => dispatch(setRegisterStepFieldValue(REGISTER_STEP_5, CI_FIELD, e.target.value))}
            />
            <TextInput
                disabled={isInputDisabled(inputs, EMITTED_BY_FIELD)}
                label={isPassportSelected ? <FormattedMessage id='register_page_step_five_emitted_by_passport' /> : <FormattedMessage id='register_page_step_five_emitted_by' />}
                controlId={inputs[EMITTED_BY_FIELD].name}
                isInvalid={!inputs[EMITTED_BY_FIELD].isValid}
                value={inputs[EMITTED_BY_FIELD].value}
                onInputChange={(e) => (
                    dispatch(setRegisterStepFieldValue(REGISTER_STEP_5, EMITTED_BY_FIELD, e.target.value))
                )}
            />
            <TwoDateInputsRow
                firstInputDisabled={inputs.newFlow && inputs[EMITTED_DATE_FIELD].isValid}
                controlId='formDates' firstInputLabel={<FormattedMessage id='register_page_step_five_emitted_date' />}
                firstInputInvalid={!inputs[EMITTED_DATE_FIELD].isValid}
                firstInputValue={inputs[EMITTED_DATE_FIELD].value}
                onFirstInputChange={(e) => dispatch(setRegisterStepFieldValue(REGISTER_STEP_5, EMITTED_DATE_FIELD, e.target.value))}
                secondInputDisabled={inputs.newFlow && inputs[EXPIRE_DATE_FIELD].isValid}
                secondInputLabel={<FormattedMessage id='register_page_step_five_expire_date' />}
                secondInputInvalid={!inputs[EXPIRE_DATE_FIELD].isValid}
                secondInputValue={inputs[EXPIRE_DATE_FIELD].value}
                onSecondInputChange={(e) => dispatch(setRegisterStepFieldValue(REGISTER_STEP_5, EXPIRE_DATE_FIELD, e.target.value))}
            />
            <DateInput
                disabled={inputs.newFlow && inputs[BIRTH_DATE_FIELD].isValid}
                controlId={inputs[BIRTH_DATE_FIELD].name}
                label={<FormattedMessage id='register_page_step_five_birth_day' />}
                isInvalid={!inputs[BIRTH_DATE_FIELD].isValid}
                value={inputs[BIRTH_DATE_FIELD].value}
                onChange={(e) => dispatch(setRegisterStepFieldValue(REGISTER_STEP_5, BIRTH_DATE_FIELD, e.target.value))}
            />
            <TextInput
                disabled={isInputDisabled(inputs, BIRTH_PLACE_FIELD)}
                label={<FormattedMessage id='register_page_step_five_birth_place' />}
                controlId={inputs[BIRTH_PLACE_FIELD].name}
                isInvalid={!inputs[BIRTH_PLACE_FIELD].isValid}
                value={inputs[BIRTH_PLACE_FIELD].value}
                onInputChange={(e) => dispatch(setRegisterStepFieldValue(REGISTER_STEP_5, BIRTH_PLACE_FIELD, e.target.value))}
            />
            <TextInput
                disabled={isInputDisabled(inputs, ADDRESS_FIELD)}
                label={<FormattedMessage id='register_page_step_five_address' />}
                controlId={inputs[ADDRESS_FIELD].name}
                isInvalid={!inputs[ADDRESS_FIELD].isValid}
                value={inputs[ADDRESS_FIELD].value}
                onInputChange={(e) => dispatch(setRegisterStepFieldValue(REGISTER_STEP_5, ADDRESS_FIELD, e.target.value))}
            />
            <TwoTextInputsRow
                firstInputDisabled={isInputDisabled(inputs, COUNTY_FIELD)}
                controlId='formLocations' firstInputLabel={<FormattedMessage id='register_page_step_five_county' />}
                firstInputInvalid={!inputs[COUNTY_FIELD].isValid}
                firstInputValue={inputs[COUNTY_FIELD].value}
                onFirstInputChange={(e) => dispatch(setRegisterStepFieldValue(REGISTER_STEP_5, COUNTY_FIELD, e.target.value))}
                secondInputDisabled={isInputDisabled(inputs, CITY_FIELD)}
                secondInputLabel={<FormattedMessage id='register_page_step_five_city' />}
                secondInputInvalid={!inputs[CITY_FIELD].isValid}
                secondInputValue={inputs[CITY_FIELD].value}
                onSecondInputChange={(e) => dispatch(setRegisterStepFieldValue(REGISTER_STEP_5, CITY_FIELD, e.target.value))}
            />
            <Select
                disabled={inputs.newFlow && inputs[COUNTRY_FIELD].isValid}
                formControlId={inputs[COUNTRY_FIELD].name}
                formLabel={<FormattedMessage id='register_page_step_five_country' />}
                isInvalid={!inputs[COUNTRY_FIELD].isValid}
                selectedValue={inputs[COUNTRY_FIELD].value}
                options={NATIONALITY_VALUES}
                onSelectedValueChange={(e) => (
                    dispatch(setRegisterStepFieldValue(REGISTER_STEP_5, COUNTRY_FIELD, e.target.selectedOptions[0].id))
                )}
            />
        </Form>
    </div>
);

const isInputDisabled = (inputs, inputName) => (
    inputs.newFlow && inputs[inputName].isValid && inputs[inputName].value && inputs[inputName].value.length >= 3
);

export default RegisterFullInfoForm;
