import { fetchFiles, fetchOldFiles } from '../api/ocr';
import { uploadFile } from '../actions/register';
import { base64ToFile } from '../utils/helpers';
import { getIdFile, OLD_OCR_TYPE, removeIdFile, removeProcessPassed, saveIdFile } from '../utils/ocr-storage';

const ID_CARD_FILENAME = 'IdCard';
const LIVENESS_FILENAME = 'Liveness';
const SELFIE_FILENAME = 'Selfie';
const handleOcrFiles = async (token, formattedResponse, dispatch) => {
    if (formattedResponse.Proof_Images) {
        const images = formattedResponse.Proof_Images;
        if (images.IdCard) {
            constructAndUploadFile(token, images, ID_CARD_FILENAME, dispatch);
        }
        if (images.Liveness) {
            constructAndUploadFile(token, images, LIVENESS_FILENAME, dispatch);
        }
        if (images.Selfie) {
            constructAndUploadFile(token, images, SELFIE_FILENAME, dispatch);
        }
    }
};

const constructAndUploadFile = async (token, images, fileType, dispatch) => {
    const filesResponse = await fetchFiles(token, images[fileType]);
    const formattedFilesResponse = await filesResponse.json();
    // eslint-disable-next-line no-undef
    const file = base64ToFile(formattedFilesResponse.FileContent, formattedFilesResponse.Extension, `${fileType}.${formattedFilesResponse.Extension}`);
    dispatch(uploadFile(file));
};

export const handleOldOcrFilesForLiveness = async (token, formattedResponse, dispatch) => {
    const idFileId = getIdFile();
    if (!idFileId) {
        removeProcessPassed(OLD_OCR_TYPE);
    } else {
        const idFileResponse = await fetchOldFiles(token, idFileId);
        const formattedIdFileResponse = await idFileResponse.json();
        const file = base64ToFile(formattedIdFileResponse.FileContent, formattedIdFileResponse.Extension, `${ID_CARD_FILENAME}.${formattedIdFileResponse.Extension}`);
        dispatch(uploadFile(file));
        removeIdFile();
    }

    const selfieFileId = extractLivenessFileIdFromOldOcrResponse(formattedResponse);
    if (!selfieFileId) {
        removeProcessPassed(OLD_OCR_TYPE);
    } else {
        const selfieFileResponse = await fetchOldFiles(token, selfieFileId);
        const formattedSelfieFileResponse = await selfieFileResponse.json();
        const selfieFile = base64ToFile(formattedSelfieFileResponse.FileContent, formattedSelfieFileResponse.Extension, `${SELFIE_FILENAME}.${formattedSelfieFileResponse.Extension}`);
        dispatch(uploadFile(selfieFile));
    }
};

export const handleOldOcrFilesForId = async (token, formattedResponse) => {
    const fileId = extractFileIdFromOldOcrResponse(formattedResponse);
    saveIdFile(fileId);
};

const extractFileIdFromOldOcrResponse = (response) => {
    const values = JSON.parse(response[0].Details);
    const { IdCardFull } = values;
    return IdCardFull;
};

const extractLivenessFileIdFromOldOcrResponse = (response) => {
    const values = JSON.parse(response[0].Details);
    const { AdjustedPhotosIds } = values;
    return AdjustedPhotosIds[0];
};

export default handleOcrFiles;
