import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from 'react-bootstrap';

import RegisterNotWorkingOverlayer, { REGISTER_NOT_WORKING_OVERLAYER_NAME } from '../overlayers/RegisterNotWorkingOverlayer';
import RegisterContactUsOverlayer from '../overlayers/RegisterContactUsOverlayer';
import OcrIframe from '../OcrIframe';
import OldOcrIframe from '../OldOcrIframe';
import withAsyncLifecycle from '../../../core/hoc/async-lifecycle';
import usePageTitle from '../../../core/hoc/use-page-title';
import { getOldToken, getToken } from '../../actions/ocr';
import { selectOcrFlow, selectOcrInstanceId } from '../../selectors/ocr';
import { openOverlayer } from '../../../core/actions/overlayer';
import { selectSmsMessage } from '../../selectors/register';
import { getGuid, getRegisterStepField, getRegisterStepFields, saveCurrentStep } from '../../utils/fields-storage';
import { setRegisterStepFields } from '../../actions/register';
import { sendMessage } from '../../../core/websocket/engine';
import { getRegisterCommand } from '../../../core/websocket/commands';
import { getCurrentStepCommand } from '../../decorators/payloads';
import { getFlow, getInstanceInfo, removeInstanceInfo } from '../../utils/ocr-storage';
import { isMobile } from '../../utils/helpers';
import { fullPageContentContainer, titleFormat } from '../../containers/RegisterPage.styles';
import { textMainColor } from '../../../core/styles/generic.styles';
import { PHONE_FIELD } from '../../reducers/features/form-structures';
import { REGISTER_STEP_2, REGISTER_STEP_4_NAME, REGISTER_STEP_7 } from '../../utils/register-steps';
import { NEW_OCR_FLOW } from '../../services/ocr-flow-decider';

const RegisterStep4 = ({ goToNextStep, goToPrevStep, goToStep, liveness = false }) => {
    const [instanceUpdated, setInstanceUpdated] = useState(false);
    const { isFetching, isError } = useSelector((state) => state.ui.http.ocr);
    const dispatch = useDispatch();
    const isNewFlow = useSelector(selectOcrFlow) === NEW_OCR_FLOW;

    const flow = getFlow();
    const version = flow === NEW_OCR_FLOW ? 'v2' : 'v1';
    usePageTitle(`Deschidere cont - Pas 4 | ${version}`);

    useEffect(() => {
        saveCurrentStep(REGISTER_STEP_4_NAME);
        sendMessage(getCurrentStepCommand(REGISTER_STEP_4_NAME), dispatch);
        if (isNewFlow) {
            dispatch(setRegisterStepFields(REGISTER_STEP_2, getRegisterStepFields(REGISTER_STEP_2)));
        }
    }, []);

    useEffect(() => {
        if (!isNewFlow) {
            dispatch(getOldToken());
        } else {
            dispatch(getToken());
        }
    }, []);

    const instanceId = extractInstanceId();
    if (!instanceId) {
        removeInstanceInfo();
        if (!instanceUpdated) {
            setInstanceUpdated(true);
        }
    }

    const AsyncContentComponent = withAsyncLifecycle(ContentComponent, { isFetching, isError });

    return (
        <div>
            <div style={{ marginTop: 90, width: '100%' }}>
                <p className="register-step-container__col1__title" style={titleFormat}><FormattedMessage id='register_page_step_four_title' /></p>
                <p><FormattedMessage id='register_page_step_four_subtitle_1' /></p>
                <p><FormattedMessage id='register_page_step_four_subtitle_2' /></p>
                <Button
                    variant='outline-primary'
                    onClick={() => dispatch(openOverlayer(REGISTER_NOT_WORKING_OVERLAYER_NAME))}
                >
                    <FormattedMessage id='not_working' />
                </Button>
            </div>
            <AsyncContentComponent
                dispatch={dispatch} isNewFlow={isNewFlow} instanceId={instanceId} goToStep={goToStep}
                goToNextStep={goToNextStep} goToPrevStep={goToPrevStep} liveness={liveness}
            />
            <RegisterNotWorkingOverlayer />
            <RegisterContactUsOverlayer />
        </div>
    );
};

const ContentComponent = ({ liveness, isNewFlow, instanceId, dispatch, goToNextStep, goToPrevStep, goToStep }) => {
    const smsMessage = useSelector(selectSmsMessage);
    const intl = useIntl();

    const customGoToPrevHandler = () => {
        // eslint-disable-next-line no-alert
        const isAccepted = window.confirm(intl.formatMessage({ id: 'are_you_sure' }));
        if (isAccepted) {
            goToPrevStep();
        }
    };

    const onMobileClick = () => {
        const phoneField = getRegisterStepField(REGISTER_STEP_2, PHONE_FIELD);
        const phoneNumber = phoneField ? phoneField.value : '';
        const guid = getGuid();
        sendMessage(getRegisterCommand({ sms2mobil: phoneNumber, guid }), dispatch);
    };

    return (
        <div style={{ ...fullPageContentContainer, paddingLeft: 0, paddingRight: 0 }}>
            {!isMobile()
                && <>
                    {smsMessage && <span>{smsMessage}</span>}
                    <Button className='continua-mobil-top' variant='outline-primary' style={{ paddingTop: 10 }} onClick={onMobileClick}>
                        <FormattedMessage id='register_page_step_four_continue_on_mobile' />
                    </Button>
                </>
            }
            {!isNewFlow
                && <Button variant='link' onClick={() => (liveness ? goToStep(REGISTER_STEP_7)() : goToNextStep())}>
                    <FormattedMessage id='register_page_step_four_decline_webcam' />
                </Button>
            }
            <br />
            {!isNewFlow ? <OldOcrIframe liveness={liveness} /> : <OcrIframe goToNextStep={goToNextStep} instanceId={instanceId} />}
            <span style={{ ...textMainColor, marginTop: 15 }} onClick={customGoToPrevHandler}>
                <FormattedMessage id='prev' />
            </span>
        </div>
    );
};

const extractInstanceId = () => {
    const stateInstanceId = useSelector(selectOcrInstanceId);
    if (!stateInstanceId) {
        const { instanceId } = getInstanceInfo();
        return instanceId;
    }

    return stateInstanceId;
};

export default RegisterStep4;
