import { SET_LOCALE, SET_DEFAULT_LOCALE, DEFAULT_LOCALE } from '../actions/i18n';

export const INITIAL_STATE = {
    locale: DEFAULT_LOCALE,
};

const i18nReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_LOCALE:
            return { ...state, locale: action.payload };
        case SET_DEFAULT_LOCALE:
            return { ...state, locale: DEFAULT_LOCALE };
        default:
            return state;
    }
};

export default i18nReducer;
