import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FaInfoCircle } from 'react-icons/fa';

import Checkbox from './core/Checkbox';
import RadioWithTwoOptions from './core/RadioWithTwoOptions';
import TextInput from './core/TextInput';
import Select from './core/Select';
import RegisterCompensableInfoOverlayer, { REGISTER_COMPENSABLE_INFO_OVERLAYER_NAME } from '../overlayers/RegisterCompensableInfoOverlayer';
import { setRegisterStepFieldValue, setRegisterStepFieldsVisibility, setRegisterStepFieldVisibility } from '../../actions/register';
import { openOverlayer } from '../../../core/actions/overlayer';
import { FUNDS_SOURCE_VALUES } from '../../utils/forms-options';
import { REGISTER_STEP_9 } from '../../utils/register-steps';
import {
    PERSON_NONRESIDENT_FIELD, PUBLIC_PERSON_FIELD, REAL_BENEFICIARY_DIFFER_FIELD, BENEFICIARY_AGREEMENT,
    PUBLIC_FUNCTION_FIELD, FUNDS_SOURCE_FIELD, REAL_BENEFICIARY_FIELD, REAL_BENEFICIARY_FUNDS_SOURCE_FIELD,
    REAL_BENEFICIARY_QUESTION_FIELD, PERSON_COMPENSABLE_FIELD,
} from '../../reducers/features/form-structures';

const RegisterLegalForm = ({ dispatch, inputs }) => (
    <>
        <RadioWithTwoOptions
            formLabel={<FormattedMessage id='register_page_step_nine_first_question' />}
            formInvalid={!inputs[PERSON_NONRESIDENT_FIELD].isValid}
            checkedOption={inputs[PERSON_NONRESIDENT_FIELD].value}
            formName={inputs[PERSON_NONRESIDENT_FIELD].name}
            firstOptionValue={false} firstOptionId='no_1' firstOptionLabel={<FormattedMessage id='no' />}
            onFirstOptionChange={() => (
                dispatch(setRegisterStepFieldValue(REGISTER_STEP_9, PERSON_NONRESIDENT_FIELD, false))
            )}
            secondOptionValue={true} secondOptionId='yes_1' secondOptionLabel={<FormattedMessage id='yes' />}
            onSecondOptionChange={() => (
                dispatch(setRegisterStepFieldValue(REGISTER_STEP_9, PERSON_NONRESIDENT_FIELD, true))
            )}
        />
        <RadioWithTwoOptions
            formLabel={<FormattedMessage id='register_page_step_nine_second_question' />}
            formInvalid={!inputs[PUBLIC_PERSON_FIELD].isValid}
            checkedOption={inputs[PUBLIC_PERSON_FIELD].value}
            formName={inputs[PUBLIC_PERSON_FIELD].name}
            firstOptionValue={false} firstOptionId='no_2' firstOptionLabel={<FormattedMessage id='no' />}
            onFirstOptionChange={() => {
                dispatch(setRegisterStepFieldValue(REGISTER_STEP_9, PUBLIC_PERSON_FIELD, false));
                dispatch(
                    setRegisterStepFieldsVisibility(REGISTER_STEP_9, [PUBLIC_FUNCTION_FIELD, FUNDS_SOURCE_FIELD], false),
                );
                dispatch(setRegisterStepFieldValue(REGISTER_STEP_9, PUBLIC_FUNCTION_FIELD, null));
                dispatch(setRegisterStepFieldValue(REGISTER_STEP_9, FUNDS_SOURCE_FIELD, null));
            }}
            secondOptionValue={true} secondOptionId='yes_2' secondOptionLabel={<FormattedMessage id='yes' />}
            onSecondOptionChange={() => {
                dispatch(setRegisterStepFieldValue(REGISTER_STEP_9, PUBLIC_PERSON_FIELD, true));
                dispatch(
                    setRegisterStepFieldsVisibility(REGISTER_STEP_9, [PUBLIC_FUNCTION_FIELD, FUNDS_SOURCE_FIELD], true),
                );
            }}
        />
        {inputs[PUBLIC_FUNCTION_FIELD].isVisible
            && <>
                <TextInput
                    label={<FormattedMessage id='register_page_step_nine_function'/>}
                    controlId={inputs[PUBLIC_FUNCTION_FIELD].name}
                    isInvalid={!inputs[PUBLIC_FUNCTION_FIELD].isValid}
                    value={inputs[PUBLIC_FUNCTION_FIELD].value}
                    onInputChange={(e) => (
                        dispatch(setRegisterStepFieldValue(REGISTER_STEP_9, PUBLIC_FUNCTION_FIELD, e.target.value))
                    )}
                />
                <Select
                    formLabel={<FormattedMessage id='register_page_step_nine_funds'/>}
                    formControlId={inputs[FUNDS_SOURCE_FIELD].name}
                    selectedValue={inputs[FUNDS_SOURCE_FIELD].value}
                    isInvalid={!inputs[FUNDS_SOURCE_FIELD].isValid}
                    options={FUNDS_SOURCE_VALUES}
                    onSelectedValueChange={(e) => (
                        dispatch(setRegisterStepFieldValue(REGISTER_STEP_9, FUNDS_SOURCE_FIELD, e.target.selectedOptions[0].value))
                    )}
                />
            </>
        }
        <RadioWithTwoOptions
            formLabel={<FormattedMessage id='register_page_step_nine_third_question' />}
            formInvalid={!inputs[REAL_BENEFICIARY_DIFFER_FIELD].isValid}
            checkedOption={inputs[REAL_BENEFICIARY_DIFFER_FIELD].value}
            formName={inputs[REAL_BENEFICIARY_DIFFER_FIELD].name}
            firstOptionValue={false} firstOptionId='no_3' firstOptionLabel={<FormattedMessage id='no' />}
            onFirstOptionChange={() => {
                dispatch(setRegisterStepFieldValue(REGISTER_STEP_9, REAL_BENEFICIARY_DIFFER_FIELD, false));
                dispatch(
                    setRegisterStepFieldsVisibility(REGISTER_STEP_9, [REAL_BENEFICIARY_FIELD, REAL_BENEFICIARY_FUNDS_SOURCE_FIELD], false),
                );
                dispatch(setRegisterStepFieldValue(REGISTER_STEP_9, REAL_BENEFICIARY_FIELD, null));
                dispatch(setRegisterStepFieldValue(REGISTER_STEP_9, REAL_BENEFICIARY_FUNDS_SOURCE_FIELD, null));
            }}
            secondOptionValue={true} secondOptionId='yes_3' secondOptionLabel={<FormattedMessage id='yes' />}
            onSecondOptionChange={() => {
                dispatch(setRegisterStepFieldValue(REGISTER_STEP_9, REAL_BENEFICIARY_DIFFER_FIELD, true));
                dispatch(
                    setRegisterStepFieldsVisibility(REGISTER_STEP_9, [REAL_BENEFICIARY_FIELD, REAL_BENEFICIARY_FUNDS_SOURCE_FIELD], true),
                );
            }}
        />
        {inputs[REAL_BENEFICIARY_FIELD].isVisible
            && <>
                <TextInput
                    label={<FormattedMessage id='register_page_step_nine_real_ben' />}
                    controlId={inputs[REAL_BENEFICIARY_FIELD].name}
                    isInvalid={!inputs[REAL_BENEFICIARY_FIELD].isValid}
                    value={inputs[REAL_BENEFICIARY_FIELD].value}
                    onInputChange={(e) => (
                        dispatch(setRegisterStepFieldValue(REGISTER_STEP_9, REAL_BENEFICIARY_FIELD, e.target.value))
                    )}
                />
                <RadioWithTwoOptions
                    formLabel={<FormattedMessage id='register_page_step_nine_real_ben_question_1' />}
                    formName={inputs[REAL_BENEFICIARY_QUESTION_FIELD].name}
                    formInvalid={!inputs[REAL_BENEFICIARY_QUESTION_FIELD].isValid}
                    checkedOption={inputs[REAL_BENEFICIARY_QUESTION_FIELD].value}
                    firstOptionValue={false} firstOptionId='no_4' firstOptionLabel={<FormattedMessage id='no' />}
                    onFirstOptionChange={() => {
                        dispatch(
                            setRegisterStepFieldValue(REGISTER_STEP_9, REAL_BENEFICIARY_QUESTION_FIELD, false),
                        );
                        dispatch(
                            setRegisterStepFieldVisibility(REGISTER_STEP_9, REAL_BENEFICIARY_FUNDS_SOURCE_FIELD, false),
                        );
                    }}
                    secondOptionValue={true} secondOptionLabel={<FormattedMessage id='yes' />} secondOptionId='yes_4'
                    onSecondOptionChange={() => {
                        dispatch(setRegisterStepFieldValue(REGISTER_STEP_9, REAL_BENEFICIARY_QUESTION_FIELD, true));
                        dispatch(
                            setRegisterStepFieldVisibility(REGISTER_STEP_9, REAL_BENEFICIARY_FUNDS_SOURCE_FIELD, true),
                        );
                    }}
                />
                {inputs[REAL_BENEFICIARY_FUNDS_SOURCE_FIELD].isVisible
                    && <Select
                        formControlId={inputs[REAL_BENEFICIARY_FUNDS_SOURCE_FIELD].name}
                        formLabel={<FormattedMessage id='register_page_step_nine_real_ben_select' />}
                        selectedValue={inputs[REAL_BENEFICIARY_FUNDS_SOURCE_FIELD].value}
                        isInvalid={!inputs[REAL_BENEFICIARY_FUNDS_SOURCE_FIELD].isValid}
                        options={FUNDS_SOURCE_VALUES}
                        onSelectedValueChange={(e) => (
                            dispatch(
                                setRegisterStepFieldValue(
                                    REGISTER_STEP_9,
                                    REAL_BENEFICIARY_FUNDS_SOURCE_FIELD,
                                    e.target.selectedOptions[0].value,
                                ),
                            ))
                        }
                    />
                }
            </>
        }
        <RadioWithTwoOptions
            formLabel={<>
                <FormattedMessage id='register_page_step_nine_compensable_question' />
                <FaInfoCircle className="info-icon" onClick={() => dispatch(openOverlayer(REGISTER_COMPENSABLE_INFO_OVERLAYER_NAME))} />
            </>}
            formInvalid={!inputs[PERSON_COMPENSABLE_FIELD].isValid}
            checkedOption={inputs[PERSON_COMPENSABLE_FIELD].value}
            formName={inputs[PERSON_COMPENSABLE_FIELD].name}
            firstOptionValue={true} firstOptionId='yes_111' firstOptionLabel={<FormattedMessage id='no' />}
            onFirstOptionChange={() => (
                dispatch(setRegisterStepFieldValue(REGISTER_STEP_9, PERSON_COMPENSABLE_FIELD, true))
            )}
            secondOptionValue={false} secondOptionId='no_111' secondOptionLabel={<FormattedMessage id='yes' />}
            onSecondOptionChange={() => (
                dispatch(setRegisterStepFieldValue(REGISTER_STEP_9, PERSON_COMPENSABLE_FIELD, false))
            )}
        />
        <Checkbox
            isInvalid={!inputs[BENEFICIARY_AGREEMENT].isValid}
            controlId={inputs[BENEFICIARY_AGREEMENT].name}
            checked={inputs[BENEFICIARY_AGREEMENT].value}
            label={getCheckboxLabel()}
            onChange={() => (
                dispatch(
                    setRegisterStepFieldValue(REGISTER_STEP_9, BENEFICIARY_AGREEMENT, !inputs[BENEFICIARY_AGREEMENT].value),
                )
            )}
        />
        <RegisterCompensableInfoOverlayer />
    </>
);

const getCheckboxLabel = () => (
    <>
        <FormattedMessage id='register_page_step_nine_final_agreement_1' />
        <a href='https://mbl.tradeville.ro/Documents/Home/DeschidereCont-IncadrariLegale.pdf' target='_blank' rel="noreferrer">
            <FormattedMessage id='register_page_step_nine_final_agreement_2' />
        </a>
    </>
);

export default RegisterLegalForm;
