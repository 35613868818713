import React from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

const PasswordInput = ({ controlId, label, isInvalid, value, onInputChange, errorMessage }) => (
    <Form.Group className="mb-3" controlId={controlId}>
        <Form.Label>{label}</Form.Label>
        <Form.Control
            isInvalid={isInvalid}
            type="password"
            value={value}
            onInput={onInputChange}
        />
        {isInvalid && errorMessage && <Form.Control.Feedback type="invalid"><FormattedMessage id={errorMessage} /></Form.Control.Feedback>}
    </Form.Group>
);

export default PasswordInput;
