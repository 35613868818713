import { MAIN_MENU_COLOR, RED } from '../utils/colors';

export const displayFlexAlignCenter = { alignItems: 'center', display: 'flex' };
export const displayFlexJustifyCenter = { justifyContent: 'center', display: 'flex' };
export const displayFlexRow = { flexDirection: 'row', display: 'flex' };
export const boldFont = { fontWeight: 500 };
export const textRedColor = ({ color: RED });
export const textMainColor = { color: MAIN_MENU_COLOR, cursor: 'pointer' };

export const marginRight = (value) => ({ marginRight: value });
export const marginLeft = (value) => ({ marginLeft: value });
export const marginRightWithWidth = (margin, width) => ({ marginRight: margin, width: `${width}%` });
export const width = (value) => ({ width: `${value}%` });
export const fontSize = (size) => ({ fontSize: size });
