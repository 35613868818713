import React from 'react';
import { FormattedMessage } from 'react-intl';

import TextInput from './core/TextInput';
import Select from './core/Select';
import { doesOccupationHaveOptions, isOccupationOwnActivity } from '../../utils/helpers';
import { setRegisterStepFieldValue, setRegisterStepFieldsVisibility, setRegisterStepFieldVisibility } from '../../actions/register';
import { OCCUPATION_VALUES, DOMAIN_VALUES, OWN_ACTIVITY_VALUES } from '../../utils/forms-options';
import { DOMAIN_FIELD, EMPLOYER_FIELD, OCCUPATION_FIELD, OWN_ACTIVITY_FIELD } from '../../reducers/features/form-structures';
import { REGISTER_STEP_10 } from '../../utils/register-steps';

const RegisterOccupationForm = ({ dispatch, inputs }) => {
    const occupation = inputs[OCCUPATION_FIELD].value;

    const onOccupationSelect = (e) => {
        const selectedOccupation = e.target.selectedOptions[0].id;
        dispatch(setRegisterStepFieldValue(REGISTER_STEP_10, OCCUPATION_FIELD, e.target.selectedOptions[0].value));

        if (doesOccupationHaveOptions(Number(selectedOccupation))) {
            if (isOccupationOwnActivity(Number(selectedOccupation))) {
                dispatch(setRegisterStepFieldsVisibility(REGISTER_STEP_10, [OWN_ACTIVITY_FIELD, DOMAIN_FIELD], true));
                dispatch(setRegisterStepFieldVisibility(REGISTER_STEP_10, EMPLOYER_FIELD, false));
                dispatch(setRegisterStepFieldValue(REGISTER_STEP_10, EMPLOYER_FIELD, null));
            } else {
                dispatch(setRegisterStepFieldsVisibility(REGISTER_STEP_10, [DOMAIN_FIELD, EMPLOYER_FIELD], true));
                dispatch(setRegisterStepFieldVisibility(REGISTER_STEP_10, OWN_ACTIVITY_FIELD, false));
                dispatch(setRegisterStepFieldValue(REGISTER_STEP_10, OWN_ACTIVITY_FIELD, null));
            }
        } else {
            dispatch(
                setRegisterStepFieldsVisibility(REGISTER_STEP_10, [OWN_ACTIVITY_FIELD, EMPLOYER_FIELD, DOMAIN_FIELD], false),
            );
            dispatch(setRegisterStepFieldValue(REGISTER_STEP_10, OWN_ACTIVITY_FIELD, null));
            dispatch(setRegisterStepFieldValue(REGISTER_STEP_10, DOMAIN_FIELD, null));
            dispatch(setRegisterStepFieldValue(REGISTER_STEP_10, EMPLOYER_FIELD, null));
        }
    };

    return (
        <>
            <Select
                formLabel={<FormattedMessage id='register_page_step_ten_occupation' />}
                options={OCCUPATION_VALUES}
                formControlId={inputs[OCCUPATION_FIELD].name}
                isInvalid={!inputs[OCCUPATION_FIELD].isValid}
                selectedValue={occupation}
                onSelectedValueChange={onOccupationSelect}
            />

            {inputs[OWN_ACTIVITY_FIELD].isVisible
                && <Select
                    formLabel={<FormattedMessage id='register_page_step_ten_own_activity' />}
                    formControlId={inputs[OWN_ACTIVITY_FIELD].name}
                    options={OWN_ACTIVITY_VALUES}
                    isInvalid={!inputs[OWN_ACTIVITY_FIELD].isValid}
                    selectedValue={inputs[OWN_ACTIVITY_FIELD].value}
                    onSelectedValueChange={(e) => (
                        dispatch(setRegisterStepFieldValue(REGISTER_STEP_10, OWN_ACTIVITY_FIELD, e.target.selectedOptions[0].value))
                    )}
                />
            }
            {inputs[EMPLOYER_FIELD].isVisible
                && <TextInput
                    label={<FormattedMessage id='register_page_step_ten_employer' />}
                    value={inputs[EMPLOYER_FIELD].value}
                    controlId={inputs[EMPLOYER_FIELD].name}
                    isInvalid={!inputs[EMPLOYER_FIELD].isValid}
                    onInputChange={(e) => dispatch(setRegisterStepFieldValue(REGISTER_STEP_10, EMPLOYER_FIELD, e.target.value))}
                />
            }
            {inputs[DOMAIN_FIELD].isVisible
                && <Select
                    formLabel={<FormattedMessage id='register_page_step_ten_domain' />}
                    formControlId={inputs[DOMAIN_FIELD].name} options={DOMAIN_VALUES}
                    selectedValue={inputs[DOMAIN_FIELD].value}
                    isInvalid={!inputs[DOMAIN_FIELD].isValid}
                    onSelectedValueChange={(e) => (
                        dispatch(setRegisterStepFieldValue(REGISTER_STEP_10, DOMAIN_FIELD, e.target.selectedOptions[0].value))
                    )}
                />
            }
        </>
    );
};

export default RegisterOccupationForm;
