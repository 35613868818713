import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Select from './core/Select';
import TextInput from './core/TextInput';
import RadioWithTwoOptions from './core/RadioWithTwoOptions';
import RegisterCountriesWithTaxObligationsOverlayer, { REGISTER_COUNTRIES_TAX_OVERLAYER_NAME } from '../overlayers/RegisterCountriesWIthTaxObligationsOverlayer';
import RegisterTaxDeclarationOverlayer, { REGISTER_TAX_DECLARATION_OVERLAYER_NAME } from '../overlayers/RegisterTaxDeclarationOverlayer';
import { setRegisterStepFieldValue, setRegisterStepFieldsVisibility } from '../../actions/register';
import { getRegisterStepField } from '../../utils/fields-storage';
import { openOverlayer } from '../../../core/actions/overlayer';
import { CITIZENSHIP_VALUES } from '../../utils/forms-options';
import {
    CNP_FIELD, COUNTRY_FIELD, FISCAL_CODE_FIELD, NEW_COUNTRY_2_FIELD, NEW_COUNTRY_FIELD, NEW_FISCAL_CODE_2_FIELD,
    NEW_FISCAL_CODE_FIELD, USA_CITIZEN_FIELD,
} from '../../reducers/features/form-structures';
import { REGISTER_STEP_13, REGISTER_STEP_5 } from '../../utils/register-steps';
import { boldFont, textMainColor, textRedColor } from '../../../core/styles/generic.styles';

const USA_CITIZEN_YES = 1;
const USA_CITIZEN_NO = 2;

const RegisterTaxForm = ({ setShouldShowExtendedDesc, dispatch, inputs }) => {
    const [addNewCountry, setAddNewCountry] = useState(false);
    const [addNewCountry2, setAddNewCountry2] = useState(false);
    const cnpField = getRegisterStepField(REGISTER_STEP_5, CNP_FIELD);

    useEffect(() => (
        (inputs[COUNTRY_FIELD].value !== 'RO' || (inputs[NEW_COUNTRY_FIELD].value && inputs[NEW_COUNTRY_FIELD].value !== 'RO'))
            ? setShouldShowExtendedDesc(true) : setShouldShowExtendedDesc(false)
    ), [inputs[COUNTRY_FIELD].value, inputs[NEW_COUNTRY_FIELD].value]);

    useEffect(() => {
        if (inputs[COUNTRY_FIELD].value === 'RO') {
            if (cnpField && cnpField.value) {
                dispatch(setRegisterStepFieldValue(REGISTER_STEP_13, FISCAL_CODE_FIELD, cnpField.value));
            }
        } else {
            dispatch(setRegisterStepFieldValue(REGISTER_STEP_13, FISCAL_CODE_FIELD, null));
        }
    }, [inputs[COUNTRY_FIELD].value]);

    useEffect(() => {
        if (inputs[NEW_COUNTRY_FIELD].value === 'RO') {
            if (cnpField && cnpField.value) {
                dispatch(setRegisterStepFieldValue(REGISTER_STEP_13, NEW_FISCAL_CODE_FIELD, cnpField.value));
            }
        } else {
            dispatch(setRegisterStepFieldValue(REGISTER_STEP_13, NEW_FISCAL_CODE_FIELD, null));
        }
    }, [inputs[NEW_COUNTRY_FIELD].value]);

    useEffect(() => {
        if (inputs[NEW_COUNTRY_2_FIELD].value === 'RO') {
            if (cnpField && cnpField.value) {
                dispatch(setRegisterStepFieldValue(REGISTER_STEP_13, NEW_FISCAL_CODE_2_FIELD, cnpField.value));
            }
        } else {
            dispatch(setRegisterStepFieldValue(REGISTER_STEP_13, NEW_FISCAL_CODE_2_FIELD, null));
        }
    }, [inputs[NEW_COUNTRY_2_FIELD].value]);

    const onAddNewCountry = () => {
        setAddNewCountry(!addNewCountry);
        dispatch(setRegisterStepFieldValue(REGISTER_STEP_13, NEW_COUNTRY_FIELD, null));
        dispatch(setRegisterStepFieldValue(REGISTER_STEP_13, NEW_FISCAL_CODE_FIELD, null));
        if (!addNewCountry) {
            dispatch(setRegisterStepFieldsVisibility(REGISTER_STEP_13, [NEW_COUNTRY_FIELD, NEW_FISCAL_CODE_FIELD], true));
        } else {
            dispatch(setRegisterStepFieldsVisibility(
                REGISTER_STEP_13,
                [NEW_COUNTRY_FIELD, NEW_FISCAL_CODE_FIELD, NEW_COUNTRY_2_FIELD, NEW_FISCAL_CODE_2_FIELD],
                false,
            ));
        }
    };

    const onAddNewCountry2 = () => {
        setAddNewCountry2(!addNewCountry2);
        dispatch(setRegisterStepFieldValue(REGISTER_STEP_13, NEW_COUNTRY_2_FIELD, null));
        dispatch(setRegisterStepFieldValue(REGISTER_STEP_13, NEW_FISCAL_CODE_2_FIELD, null));
        if (!addNewCountry2) {
            dispatch(setRegisterStepFieldsVisibility(REGISTER_STEP_13, [NEW_COUNTRY_2_FIELD, NEW_FISCAL_CODE_2_FIELD], true));
        } else {
            dispatch(setRegisterStepFieldsVisibility(REGISTER_STEP_13, [NEW_COUNTRY_2_FIELD, NEW_FISCAL_CODE_2_FIELD], false));
        }
    };

    return (
        <>
            <p style={textMainColor} onClick={() => dispatch(openOverlayer(REGISTER_COUNTRIES_TAX_OVERLAYER_NAME))}>
                <FormattedMessage id='register_page_step_thirteen_countries' />
            </p>
            <Select
                formLabel={<FormattedMessage id='register_page_step_five_country' />}
                formControlId={inputs[COUNTRY_FIELD].name}
                isInvalid={!inputs[COUNTRY_FIELD].isValid}
                selectedValue={inputs[COUNTRY_FIELD].value}
                options={CITIZENSHIP_VALUES}
                onSelectedValueChange={(e) => (
                    dispatch(setRegisterStepFieldValue(REGISTER_STEP_13, COUNTRY_FIELD, e.target.selectedOptions[0].id))
                )}
            />
            <TextInput
                label={<FormattedMessage id='register_page_step_thirteen_fiscal_code' />}
                controlId={inputs[FISCAL_CODE_FIELD].name}
                isInvalid={!inputs[FISCAL_CODE_FIELD].isValid}
                value={inputs[FISCAL_CODE_FIELD].value}
                onInputChange={(e) => dispatch(setRegisterStepFieldValue(REGISTER_STEP_13, FISCAL_CODE_FIELD, e.target.value))}
            />
            <p style={{ ...textMainColor, marginLeft: 10 }} onClick={onAddNewCountry}>
                {!addNewCountry
                    ? <FormattedMessage id='register_page_step_thirteen_new_country' />
                    : <FormattedMessage id='register_page_step_thirteen_remove_country' />
                }
            </p>
            {inputs[NEW_COUNTRY_FIELD].isVisible
                && <Select
                    formLabel={<FormattedMessage id='register_page_step_five_country' />}
                    formControlId={inputs[NEW_COUNTRY_FIELD].name}
                    isInvalid={!inputs[NEW_COUNTRY_FIELD].isValid}
                    selectedValue={inputs[NEW_COUNTRY_FIELD].value}
                    options={[{ id: 'blank', label: ' ' }, ...CITIZENSHIP_VALUES]}
                    onSelectedValueChange={(e) => (
                        dispatch(setRegisterStepFieldValue(REGISTER_STEP_13, NEW_COUNTRY_FIELD, e.target.selectedOptions[0].id))
                    )}
                />
            }
            {inputs[NEW_FISCAL_CODE_FIELD].isVisible
               && <TextInput
                   label={<FormattedMessage id='register_page_step_thirteen_fiscal_code' />}
                   controlId={inputs[NEW_FISCAL_CODE_FIELD].name}
                   isInvalid={!inputs[NEW_FISCAL_CODE_FIELD].isValid}
                   value={inputs[NEW_FISCAL_CODE_FIELD].value}
                   onInputChange={(e) => (
                       dispatch(setRegisterStepFieldValue(REGISTER_STEP_13, NEW_FISCAL_CODE_FIELD, e.target.value))
                   )}
               />
            }
            {addNewCountry
                && <p style={{ ...textMainColor, marginLeft: 10 }} onClick={onAddNewCountry2}>
                    {!addNewCountry2
                        ? <FormattedMessage id='register_page_step_thirteen_new_country' />
                        : <FormattedMessage id='register_page_step_thirteen_remove_country' />
                    }
                </p>
            }
            {inputs[NEW_COUNTRY_2_FIELD].isVisible
                && <Select
                    formLabel={<FormattedMessage id='register_page_step_five_country' />}
                    formControlId={inputs[NEW_COUNTRY_2_FIELD].name}
                    isInvalid={!inputs[NEW_COUNTRY_2_FIELD].isValid}
                    selectedValue={inputs[NEW_COUNTRY_2_FIELD].value}
                    options={[{ id: 'blank', label: ' ' }, ...CITIZENSHIP_VALUES]}
                    onSelectedValueChange={(e) => (
                        dispatch(setRegisterStepFieldValue(REGISTER_STEP_13, NEW_COUNTRY_2_FIELD, e.target.selectedOptions[0].id))
                    )}
                />
            }
            {inputs[NEW_FISCAL_CODE_2_FIELD].isVisible
                && <TextInput
                    label={<FormattedMessage id='register_page_step_thirteen_fiscal_code' />}
                    controlId={inputs[NEW_FISCAL_CODE_2_FIELD].name}
                    isInvalid={!inputs[NEW_FISCAL_CODE_2_FIELD].isValid}
                    value={inputs[NEW_FISCAL_CODE_2_FIELD].value}
                    onInputChange={(e) => (
                        dispatch(setRegisterStepFieldValue(REGISTER_STEP_13, NEW_FISCAL_CODE_2_FIELD, e.target.value))
                    )}
                />
            }
            <RadioWithTwoOptions
                formLabel={<FormattedMessage id='register_page_step_thirteen_usa' />}
                checkedOption={inputs[USA_CITIZEN_FIELD].value}
                formInvalid={!inputs[USA_CITIZEN_FIELD].isValid}
                formName={inputs[USA_CITIZEN_FIELD].name}
                firstOptionId={USA_CITIZEN_YES} firstOptionValue={USA_CITIZEN_YES}
                firstOptionLabel={<FormattedMessage id='register_page_step_thirteen_usa_yes' />}
                onFirstOptionChange={() => (
                    dispatch(setRegisterStepFieldValue(REGISTER_STEP_13, USA_CITIZEN_FIELD, USA_CITIZEN_YES))
                )}
                secondOptionId={USA_CITIZEN_NO} secondOptionValue={USA_CITIZEN_NO}
                secondOptionLabel={<FormattedMessage id='register_page_step_thirteen_usa_no' />}
                onSecondOptionChange={() => (
                    dispatch(setRegisterStepFieldValue(REGISTER_STEP_13, USA_CITIZEN_FIELD, USA_CITIZEN_NO))
                )}
            />
            <small><i><FormattedMessage id='register_page_step_thirteen_usa_disclaimer' /></i></small>
            <br/><br/>
            <small>
                <FormattedMessage id='register_page_step_thirteen_footer' />
                <span style={textMainColor} onClick={() => dispatch(openOverlayer(REGISTER_TAX_DECLARATION_OVERLAYER_NAME))}>
                    <FormattedMessage id='register_page_step_thirteen_footer_action' />
                </span>
                {inputs[USA_CITIZEN_FIELD].value === USA_CITIZEN_YES
                    && <>
                        <br/><br/>
                        <span style={{ ...textRedColor, ...boldFont }}>
                            <FormattedMessage id='register_page_step_thirteen_footer_action_usa' />
                        </span>
                    </>
                }
            </small>
            <RegisterCountriesWithTaxObligationsOverlayer />
            <RegisterTaxDeclarationOverlayer />
        </>
    );
};

export default RegisterTaxForm;
