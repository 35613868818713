import { forEach } from 'lodash';
import { getCountries } from './fields-storage';

const COUNTRIES_PHONE_PREFIXES = [
    { Afganistan: '93' },
    { 'Africa de Sud': '27' },
    { Albania: '355' },
    { Algeria: '213' },
    { Andorra: '376' },
    { Angola: '244' },
    { Anguilla: '1-264' },
    { Antarctica: '672' },
    { 'Antigua si Barbuda': '1-268' },
    { 'Antilele Olandeze': '599' },
    { 'Arabia Saudita': '966' },
    { Argentina: '54' },
    { Armenia: '374' },
    { Aruba: '297' },
    { Australia: '61' },
    { Austria: '43' },
    { Azerbaidjan: '994' },
    { Bahamas: '1-242' },
    { Bahrain: '973' },
    { Bangladesh: '880' },
    { Barbados: '1-246' },
    { Belarus: '375' },
    { Belgia: '32' },
    { Belize: '501' },
    { Benin: '229' },
    { Bhutan: '975' },
    { Bolivia: '591' },
    { 'Bonaire, Sint Eustatius si Saba': '599' },
    { 'Bosnia si Hertegovina': '387' },
    { Botswana: '267' },
    { Brazilia: '55' },
    { Brunei: '673' },
    { Bulgaria: '359' },
    { 'Burkina Faso': '226' },
    { Burundi: '257' },
    { Cambodgia: '855' },
    { Camerun: '237' },
    { Canada: '1' },
    { 'Capul Verde': '238' },
    { Cehia: '420' },
    { Chile: '56' },
    { China: '86' },
    { Ciad: '235' },
    { Cipru: '357' },
    { 'Coasta de Fildes': '225' },
    { Columbia: '57' },
    { Comore: '269' },
    { Congo: '242' },
    { 'Coreea de Nord': '850' },
    { 'Coreea de Sud': '82' },
    { 'Costa Rica': '506' },
    { Croatia: '385' },
    { Cuba: '53' },
    { Curacao: '599' },
    { Danemarca: '45' },
    { Djibouti: '253' },
    { Dominica: '1-767' },
    { Ecuador: '593' },
    { Egipt: '20' },
    { 'El Salvador': '503' },
    { Elvetia: '41' },
    { 'Emiratele Arabe Unite': '971' },
    { Eritreea: '291' },
    { Estonia: '372' },
    { Etiopia: '251' },
    { Fiji: '679' },
    { Filipine: '63' },
    { Finlanda: '358' },
    { Franta: '33' },
    { Gabon: '241' },
    { Gambia: '220' },
    { Georgia: '995' },
    { 'Georgia de Sud si Insulele Sandwich de Sud': '500' },
    { Germania: '49' },
    { Ghana: '233' },
    { Gibraltar: '350' },
    { Grecia: '30' },
    { Grenada: '1-473' },
    { Groenlanda: '299' },
    { Guadelupa: '590' },
    { Guam: '1-671' },
    { Guatemala: '502' },
    { Guernsey: '44-1481' },
    { 'Guiana Franceza': '594' },
    { Guineea: '224' },
    { 'Guineea Ecuatoriala': '240' },
    { 'Guineea-Bissau': '245' },
    { Guyana: '592' },
    { Haiti: '509' },
    { Honduras: '504' },
    { 'Hong Kong': '852' },
    { India: '91' },
    { Indonezia: '62' },
    { 'Insula Bouvet': '47' },
    { 'Insula Craciunului': '61' },
    { 'Insula Heard si Insulele McDonald': '672' },
    { 'Insula Jersey': '44-1534' },
    { 'Insula Man': '44-1624' },
    { 'Insula Norfolk': '672' },
    { 'Insulele Aland': '358' },
    { 'Insulele Bermude': '1-441' },
    { 'Insulele Cayman': '1-345' },
    { 'Insulele Cocos (Keeling)': '61' },
    { 'Insulele Cook': '682' },
    { 'Insulele Falkland': '500' },
    { 'Insulele Feroe': '298' },
    { 'Insulele Mariane de Nord': '1-670' },
    { 'Insulele Marshall': '692' },
    { 'Insulele Minore Indepartate ale Statelor Unite': '1' },
    { 'Insulele Solomon': '677' },
    { 'Insulele Turks si Caicos': '1-649' },
    { 'Insulele Virgine Americane': '1-340' },
    { 'Insulele Virgine Britanice': '1-284' },
    { Iordania: '962' },
    { Irak: '964' },
    { Iran: '98' },
    { Irlanda: '353' },
    { Islanda: '354' },
    { Israel: '972' },
    { Italia: '39' },
    { Jamaica: '1-876' },
    { Japonia: '81' },
    { Kazahstan: '7' },
    { Kenya: '254' },
    { Kiribati: '686' },
    { Kuweit: '965' },
    { Kyrgyzstan: '996' },
    { Laos: '856' },
    { Lesotho: '266' },
    { Letonia: '371' },
    { Liban: '961' },
    { Liberia: '231' },
    { Libia: '218' },
    { Liechtenstein: '423' },
    { Lituania: '370' },
    { Luxemburg: '352' },
    { Macao: '853' },
    { Macedonia: '389' },
    { Madagascar: '261' },
    { Malaezia: '60' },
    { Malawi: '265' },
    { Maldive: '960' },
    { Mali: '223' },
    { Malta: '356' },
    { 'Marea Britanie': '44' },
    { Maroc: '212' },
    { Martinica: '596' },
    { Mauritania: '222' },
    { Mauritius: '230' },
    { Mayotte: '262' },
    { Mexic: '52' },
    { Micronezia: '691' },
    { Monaco: '377' },
    { Mongolia: '976' },
    { Montserrat: '1-664' },
    { Mozambic: '258' },
    { Muntenegru: '382' },
    { Myanmar: '95' },
    { Namibia: '264' },
    { Nauru: '674' },
    { Nepal: '977' },
    { Nicaragua: '505' },
    { Niger: '227' },
    { Nigeria: '234' },
    { Niue: '683' },
    { Norvegia: '47' },
    { 'Noua Caledonie': '687' },
    { 'Noua Zeelanda': '64' },
    { Olanda: '31' },
    { Oman: '968' },
    { Pakistan: '92' },
    { Palau: '680' },
    { Palestina: '970' },
    { Panama: '507' },
    { 'Papua Noua Guinee': '675' },
    { Paraguay: '595' },
    { Peru: '51' },
    { Pitcairn: '64' },
    { 'Polinezia Franceza': '689' },
    { Polonia: '48' },
    { Portugalia: '351' },
    { 'Puerto Rico': '1-787' },
    { Qatar: '974' },
    { 'Republica Centrafricana': '236' },
    { 'Republica Democrata Congo': '243' },
    { 'Republica Dominicana': '1-809' },
    { 'Republica Moldova': '373' },
    { Reunion: '262' },
    { Romania: '40' },
    { Rusia: '7' },
    { Rwanda: '250' },
    { 'Sahara de Vest': '212' },
    { 'Saint Barthelemy': '590' },
    { 'Saint Martin (French part)': '590' },
    { Samoa: '685' },
    { 'Samoa americana': '1-684' },
    { 'San Marino': '378' },
    { 'Sao Tome si Principe': '239' },
    { Senegal: '221' },
    { Serbia: '381' },
    { Seychelles: '248' },
    { 'Sfanta Elena': '290' },
    { 'Sfanta Lucia': '1-758' },
    { 'Sfantul Kitts si Nevis': '1-869' },
    { 'Sfantul Pierre si Miquelon': '508' },
    { 'Sfantul Vincent si Grenadine': '1-784' },
    { 'Sierra Leone': '232' },
    { Singapore: '65' },
    { 'Sint Maarten (Teritoriul Olandez)': '1-721' },
    { Siria: '963' },
    { Slovacia: '421' },
    { Slovenia: '386' },
    { Somalia: '252' },
    { Spania: '34' },
    { 'Sri Lanka': '94' },
    { 'Statele Unite ale Americii': '1' },
    { Sudan: '249' },
    { 'Sudanul de Sud}, Republica': '211' },
    { Suedia: '46' },
    { Surinam: '597' },
    { 'Svalbard & Jan Mayan': '47' },
    { Swaziland: '268' },
    { Tadjikistan: '992' },
    { Tailanda: '66' },
    { Taiwan: '886' },
    { Tanzania: '255' },
    { 'Teritoriile australe si antarctice franceze': '262' },
    { 'Teritoriul Britanic din Oceanul Indian': '246' },
    { 'Timorul de Est': '670' },
    { Togo: '228' },
    { Tokelau: '690' },
    { Tonga: '676' },
    { 'Trinidad si Tobago': '1-868' },
    { Tunisia: '216' },
    { Turcia: '90' },
    { Turkmenistan: '993' },
    { Tuvalu: '688' },
    { Ucraina: '380' },
    { Uganda: '256' },
    { Ungaria: '36' },
    { URSS: '7' },
    { Uruguay: '598' },
    { Uzbekistan: '998' },
    { Vanuatu: '678' },
    { Vatican: '379' },
    { Venezuela: '58' },
    { Vietnam: '84' },
    { 'Wallis si Futuna': '681' },
    { Yemen: '967' },
    { Zambia: '260' },
    { Zimbabwe: '263' },
];
export const ROMANIA_COUNTRY_INDEX = 189;
export const CITIZENSHIP_VALUES = getCountries();
export const NATIONALITY_VALUES = getCountries();
export const ORIGIN_VALUES = getCountries();

const fundSourceValuesList = [];
for (let i = 1; i <= 12; i += 1) {
    fundSourceValuesList.push({
        id: i,
        label: `funds_${i}`,
    });
}
export const FUNDS_SOURCE_VALUES = [
    { id: 0, label: 'select', disabled: true, selected: true },
    ...fundSourceValuesList,
];

const occupationValuesList = [];
for (let i = 1; i <= 30; i += 1) {
    occupationValuesList.push({
        id: i,
        label: `profession_${i}`,
    });
}
export const OCCUPATION_VALUES = [
    { id: 0, label: 'select', disabled: true, selected: true },
    ...occupationValuesList,
];

const domainValuesList = [];
for (let i = 1; i <= 28; i += 1) {
    domainValuesList.push({
        id: i,
        label: `domain_${i}`,
    });
}
export const DOMAIN_VALUES = [
    { id: 0, label: 'select', disabled: true, selected: true },
    ...domainValuesList,
];

export const OWN_ACTIVITY_VALUES = [
    { id: 0, label: 'select', disabled: true, selected: true },
    { id: 1, label: 'own_activity_1' },
    { id: 2, label: 'own_activity_2' },
    { id: 3, label: 'own_activity_3' },
    { id: 4, label: 'own_activity_4' },
];

export const EDUCATION_LEVEL_VALUES = [
    { id: 0, label: 'select', disabled: true, selected: true },
    { id: 1, label: 'education_level_1' },
    { id: 2, label: 'education_level_2' },
    { id: 3, label: 'education_level_3' },
    { id: 4, label: 'education_level_4' },
    { id: 5, label: 'education_level_5' },
];

export const ANNUAL_INCOME_VALUES = [
    { id: 0, label: 'select', disabled: true, selected: true },
    { id: 1, label: 'annual_income_1' },
    { id: 2, label: 'annual_income_2' },
    { id: 3, label: 'annual_income_3' },
    { id: 4, label: 'annual_income_4' },
    { id: 5, label: 'annual_income_5' },
    { id: 6, label: 'annual_income_6' },
];

export const ESTIMATE_VALUES = [
    { id: 0, label: 'select', disabled: true, selected: true },
    { id: 1, label: 'estimate_1' },
    { id: 2, label: 'estimate_2' },
    { id: 3, label: 'estimate_3' },
    { id: 4, label: 'estimate_4' },
    { id: 5, label: 'estimate_5' },
    { id: 6, label: 'estimate_6' },
];

export const EXPERIENCE_VALUES = [
    { id: 0, label: 'select', disabled: true, selected: true },
    { id: 1, label: 'experience_1' },
    { id: 2, label: 'experience_2' },
    { id: 3, label: 'experience_3' },
    { id: 4, label: 'experience_4' },
    { id: 5, label: 'experience_5' },
];

export const INVESTMENT_VALUES = [
    { id: 0, label: 'select', disabled: true, selected: true },
    { id: 1, label: 'investment_1' },
    { id: 2, label: 'investment_2' },
    { id: 3, label: 'investment_3' },
    { id: 4, label: 'investment_4' },
];

export const RISK_VALUES = [
    { id: 0, label: 'select', disabled: true, selected: true },
    { id: 1, label: 'risk_1' },
    { id: 2, label: 'risk_2' },
    { id: 3, label: 'risk_3' },
];

export const getPhoneCodeByCountryName = (name) => {
    const foundCountry = COUNTRIES_PHONE_PREFIXES.find((country) => Object.keys(country)[0] === name);
    return (foundCountry && Object.values(foundCountry)[0]) || '';
};

export const getCountryNameByCountryCode = (code) => getCountries().find((country) => country.id === code).label;
export const getPhoneCodeByCountryCode = (code) => {
    const name = getCountryNameByCountryCode(code);
    return getPhoneCodeByCountryName(name);
};

const internationalValuesList = [];
forEach(getCountries(), (country) => {
    internationalValuesList.push({
        id: country.id,
        label: `${country.label} (${getPhoneCodeByCountryName(country.label)})`,
    });
});

export const INTERNATIONAL_PHONE_COUNTRIES_VALUES = [
    { id: 0, label: 'select', disabled: true, selected: true },
    ...internationalValuesList,
];

export const ACCOUNTS_IN_RON = 'BRD, BT, BCR, ING, LIBRA';
export const ACCOUNTS_IN_EUR = 'BRD, BT, ING, LIBRA';
export const ACCOUNT_IN_USD = 'BT, BRD, ING, LIBRA';
