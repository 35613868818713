const getQueryParam = (url, param) => {
    const queryString = url.split('?')[1];
    if (!queryString) {
        return null;
    }

    const queries = queryString.split('&');
    for (let query = 0; query < queries.length; query += 1) {
        const pair = queries[query].split('=');
        if (pair[0] === param) {
            return pair[1] === undefined ? true : decodeURIComponent(pair[1]);
        }
    }

    return null;
};

export const appendQueryParam = (key, value) => {
    const url = new URL(window.location);
    url.searchParams.append(key, value);
    window.history.pushState({}, '', url);
};

export const removeQueryParam = (parameter) => {
    const url = new URL(window.location);
    url.searchParams.delete(parameter);
    window.history.pushState({}, '', url.toString());
};

export const generateRandomString = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i += 1) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
};

export const cleanUpUrlParameters = (url) => {
    const urlObj = new URL(url);
    const params = urlObj.searchParams;

    const seenParams = {};
    const paramsForReconstruction = [];

    params.forEach((value, key) => {
        if (!seenParams[key]) {
            seenParams[key] = true;
            paramsForReconstruction.push({ key, value });
        }
    });

    urlObj.search = new URLSearchParams();

    paramsForReconstruction.forEach((param) => {
        urlObj.searchParams.append(param.key, param.value);
    });

    return urlObj.toString();
};

export default getQueryParam;
