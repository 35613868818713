import { doUploadFile, fetchUploadedFiles } from '../api/register';

export const SET_REGISTER_STEP = 'SET_REGISTER_STEP';
export const setRegisterStep = (step) => ({ type: SET_REGISTER_STEP, payload: step });

export const SET_SMS_MESSAGE = 'SET_SMS_MESSAGE';
export const setSmsMessage = (message) => ({ type: SET_SMS_MESSAGE, payload: message });

export const SET_UPLOADED_FILES = 'SET_UPLOADED_FILES';
export const setUploadedFiles = (files) => ({ type: SET_UPLOADED_FILES, payload: files });

export const SET_REGISTER_STEP_FIELD_VALUE = 'SET_REGISTER_STEP_FIELD_VALUE';
export const setRegisterStepFieldValue = (step, field, value) => ({
    type: SET_REGISTER_STEP_FIELD_VALUE,
    step,
    field,
    value,
});

export const SET_REGISTER_STEP_FILE_VALUE = 'SET_REGISTER_STEP_FILE_VALUE';
export const setRegisterStepFileValue = (step, field, value) => ({
    type: SET_REGISTER_STEP_FILE_VALUE,
    step,
    field,
    value,
});

export const SET_REGISTER_STEP_FIELDS = 'SET_REGISTER_STEP_FIELDS';
export const setRegisterStepFields = (step, fields) => ({
    type: SET_REGISTER_STEP_FIELDS,
    step,
    fields,
});

export const SET_REGISTER_STEP_FIELDS_FROM_OCR = 'SET_REGISTER_STEP_FIELDS_FROM_OCR';
export const setRegisterStepFieldsFromOcr = (step, fields) => ({
    type: SET_REGISTER_STEP_FIELDS_FROM_OCR,
    step,
    payload: fields,
});

export const SET_REGISTER_STEP_FIELD_VALIDITY = 'SET_REGISTER_STEP_FIELD_VALIDITY';
export const setRegisterStepFieldValidity = (step, field, validity) => ({
    type: SET_REGISTER_STEP_FIELD_VALIDITY,
    step,
    field,
    validity,
});

export const SET_REGISTER_STEP_FIELD_VISIBILITY = 'SET_REGISTER_STEP_FIELD_VISIBILITY';
export const setRegisterStepFieldVisibility = (step, field, visibility) => ({
    type: SET_REGISTER_STEP_FIELD_VISIBILITY,
    step,
    field,
    visibility,
});

export const SET_REGISTER_STEP_FIELD_LOADING = 'SET_REGISTER_STEP_FIELD_LOADING';
export const setRegisterStepFieldLoading = (step, field, loading) => ({
    type: SET_REGISTER_STEP_FIELD_LOADING,
    step,
    field,
    loading,
});

export const SET_REGISTER_STEP_FIELD_ERROR_MESSAGE = 'SET_REGISTER_STEP_FIELD_ERROR_MESSAGE';
export const setRegisterStepFieldErrorMessage = (step, field, errorMessage) => ({
    type: SET_REGISTER_STEP_FIELD_ERROR_MESSAGE,
    step,
    field,
    errorMessage,
});

export const RESET_REGISTER_STEP_VALIDITY = 'RESET_REGISTER_STEP_VALIDITY';
export const resetRegisterStepValidity = (step) => ({
    type: RESET_REGISTER_STEP_VALIDITY,
    step,
});

export const setRegisterStepFieldsVisibility = (step, fields, visibility) => (dispatch) => (
    fields.map((field) => dispatch(setRegisterStepFieldVisibility(step, field, visibility)))
);

export const setRegisterStepFieldsValidity = (step, fields, validity) => (dispatch) => (
    fields.map((field) => dispatch(setRegisterStepFieldValidity(step, field, validity)))
);

export const getUploadedFiles = () => (dispatch) => {
    fetchUploadedFiles((response) => dispatch(setUploadedFiles(response)));
};

export const uploadFile = (file, setErrorMessage, setLoader, validator, fileName, onSuccessCallback, dispatch) => () => {
    doUploadFile(file, fileName, setErrorMessage, setLoader, validator, onSuccessCallback, dispatch);
};
