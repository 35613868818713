import genericCatalogue from './catalogues/generic-catalogue';
import registerPageTranslations from './catalogues/register-page-catalogue';
import countriesCatalogue from './catalogues/countries-catalogue';

const translations = {
    en: {
        ...genericCatalogue.en,
        ...registerPageTranslations.en,
        ...countriesCatalogue.en,
    },
    ro: {
        ...genericCatalogue.ro,
        ...registerPageTranslations.ro,
        ...countriesCatalogue.ro,
    },

};

export default translations;
