import React from 'react';
import { FormattedMessage } from 'react-intl';

import { GREEN_FOOTER_COLOR, MAIN_MENU_COLOR } from '../utils/colors';

const Footer = () => (
    <div className="footer">
        <div className="container">
            <hr />
            <a className="footer__title" target='_blank' href='https://tradeville.ro/landing/decetradeville' rel="noreferrer">
                <span style={{ color: GREEN_FOOTER_COLOR }}><FormattedMessage id='footer_title' /></span>
                <span style={{ color: MAIN_MENU_COLOR }}>TRADE</span>
                <span style={{ color: GREEN_FOOTER_COLOR }}>VILLE</span>
            </a>
            <small className="footer__desc">
                <FormattedMessage id='footer_desc_part_1' />&nbsp;
                <a target='_blank' href='https://tradeville.ro/landing/disclaimer/ipo' rel="noreferrer">
                    (<FormattedMessage id='read' />)
                </a>.
            </small>
            <small className="footer__desc">
                <FormattedMessage id='footer_desc_part_2' />&nbsp;
                <a target='_blank' href='https://tradeville.ro/landing/disclaimer/ipo' rel="noreferrer">
                    (<FormattedMessage id='read' />)
                </a>
            </small>
            <small className="footer__desc">
                <FormattedMessage id='footer_desc_part_3' />&nbsp;
                <a target='_blank' href='https://tradeville.ro/landing/disclaimer/ipo' rel="noreferrer">
                    (<FormattedMessage id='read' />)
                </a>
            </small>
            <small className="footer__desc">
                <FormattedMessage id='footer_desc_part_4' />
            </small>
            <small className="footer__desc">
                <FormattedMessage id='footer_desc_part_5' />&nbsp;
                <a target='_blank' href='https://asfromania.ro/ro/a/2018/contact' rel="noreferrer">
                    ASF.
                </a>
            </small>
            <br/>
        </div>
    </div>
);

export default Footer;
