import React from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';

import { width } from '../../../../core/styles/generic.styles';

const Select = ({ disabled = false, formControlId, formLabel, onSelectedValueChange, selectedValue, isInvalid, options }) => {
    const intl = useIntl();
    const isDefault = isDefaultSelected(options, selectedValue, intl);

    return (
        <Form.Group style={width(100)} className="mb-3" controlId={formControlId}>
            <Form.Label>{formLabel}</Form.Label>
            <Form.Select disabled={disabled} onChange={onSelectedValueChange} isInvalid={isInvalid}>
                {options.map((option) => (
                    <option
                        key={option.id} id={option.id} disabled={option.disabled || false} value={option.value}
                        selected={isDefault
                            ? option.selected
                            : (selectedValue === intl.formatMessage({ id: option.label })
                                || Number(selectedValue) === Number(option.id) || selectedValue === option.id)
                        }
                    >
                        <FormattedMessage id={option.label} defaultMessage={option.label} />
                    </option>
                ))}
            </Form.Select>
        </Form.Group>
    );
};

const isDefaultSelected = (options, selectedValue, intl) => {
    let foundSelected = false;
    options.forEach((option) => {
        if (option && option.id !== 0 && (Number(option.id) === Number(selectedValue) || option.id === selectedValue
            || selectedValue === intl.formatMessage({ id: option.label, defaultMessage: option.label }))) {
            foundSelected = true;
        }
    });

    return !foundSelected;
};

export default Select;
