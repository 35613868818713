import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Image, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import selectIsOverlayerOpened from '../../../core/selectors/overlayer';
import { closeOverlayer } from '../../../core/actions/overlayer';

import HELPER_IMAGE_SOURCE from '../../../core/assets/img/selfie.jpg';

export const REGISTER_SELFIE_OVERLAYER_NAME = 'REGISTER_SELFIE';

const RegisterSelfieOverlayer = () => {
    const dispatch = useDispatch();
    const isOpen = useSelector((state) => selectIsOverlayerOpened(state, REGISTER_SELFIE_OVERLAYER_NAME));
    const onClose = () => dispatch(closeOverlayer(REGISTER_SELFIE_OVERLAYER_NAME));

    return (
        <Modal show={isOpen} onHide={onClose} size={'lg'}>
            <Modal.Header closeButton>
                <Modal.Title><FormattedMessage id='register_overlayer_selfie_title' /></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Image height='100%' width='100%' src={HELPER_IMAGE_SOURCE} />
            </Modal.Body>
        </Modal>
    );
};

export default RegisterSelfieOverlayer;
