export const STEP_ENTRY_NAME = 'F3AlteleText';
export const SELECTED_TYPE_PERSON_ENTRY_NAME = 'tippers';
export const EMAIL_ENTRY_NAME = 'email';
export const PHONE_ENTRY_NAME = 'tel';
export const INTERNATIONAL_PHONE_COUNTRY_ENTRY_NAME = 'prefint';
export const COMPANY_NAME_ENTRY_NAME = 'numePJ';
export const SELECTED_IDENTITY_ENTRY_NAME = 'tipbi';
export const RO_CI_IDENTITY_TYPE_ENTRY_NAME = 'CI';
export const PASSPORT_IDENTITY_TYPE_ENTRY_NAME = 'Pasaport';
export const FINAL_AGREEMENT_ENTRY_NAME = 'exprimConsimtamantul';
export const FINAL_AGREEMENT_2_ENTRY_NAME = 'acordSemnare';
export const LAST_NAME_ENTRY_NAME = 'nume';
export const FIRST_NAME_ENTRY_NAME = 'prenume';
export const CNP_ENTRY_NAME = 'cnp';
export const CI_ENTRY_NAME = 'bi';
export const EMITTED_BY_ENTRY_NAME = 'eliberatbi';
export const EMMITED_DATE_ENTRY_NAME = 'eliberatdata';
export const EXPIRE_DATE_ENTRY_NAME = 'valabildata';
export const BIRTH_DATE_ENTRY_NAME = 'datan';
export const BIRTH_PLACE_ENTRY_NAME = 'loculn';
export const ADDRESS_ENTRY_NAME = 'adresad';
export const COUNTY_ENTRY_NAME = 'judetd';
export const CITY_ENTRY_NAME = 'orasd';
export const COUNTRY_ENTRY_NAME = 'codTarad';
export const CITIZENSHIP_ENTRY_NAME = 'codCetatenie';
export const NATIONALITY_ENTRY_NAME = 'codNationalitate';
export const ORIGIN_ENTRY_NAME = 'codTaraOrigine';
export const ADDRESS_DIFFER_ENTRY_NAME = 'cuadresar';
export const RESIDENCY_ADDRESS_ENTRY_NAME = 'AdresaResed';
export const RESIDENCY_CITY_ENTRY_NAME = 'OrasResed';
export const RESIDENCY_COUNTY_ENTRY_NAME = 'JudetResed';
export const RESIDENCY_COUNTRY_ENTRY_NAME = 'CodTaraResed';
export const RESIDENCY_NOTIFICATION_ENTRY_NAME = 'CorespondentaLa';
export const BANK_NAME_ENTRY_NAME = 'banca';
export const CURRENCY_ENTRY_NAME = 'Moneda';
export const IBAN_ENTRY_NAME = 'contb';
export const USERNAME_ENTRY_NAME = 'cod';
export const PASSWORD_ENTRY_NAME = 'pw';
export const REPETEAD_PASSWORD_ENTRY_NAME = 'pwconf';
export const PERSON_NONRESIDENT_ENTRY_NAME = 'Rezident';
export const PUBLIC_PERSON_ENTRY_NAME = 'expol';
export const PUBLIC_FUNCTION_ENTRY_NAME = 'FunctiePublica';
export const FUNDS_SOURCE_ENTRY_NAME = 'SursaAvere_Tit';
export const REAL_BENEFICIARY_DIFFER_ENTRY_NAME = 'altbenef';
export const REAL_BENEFICIARY_ENTRY_NAME = 'beneficiar_real';
export const PERSON_COMPENSABLE_ENTRY_NAME = 'compensabilFCI';
export const REAL_BENEFICIARY_QUESTION_ENTRY_NAME = 'expol_br';
export const REAL_BENEFICIARY_FUNDS_SOURCE_ENTRY_NAME = 'SursaAvere_BR';
export const BENEFICIARY_AGREEMENT_ENTRY_NAME = 'amcititsiinteles';
export const DMONITOR_ENTRY_NAME = 'dmonitorpers';
export const OCCUPATION_ENTRY_NAME = 'profesia';
export const EMPLOYER_ENTRY_NAME = 'angajator';
export const DOMAIN_ENTRY_NAME = 'domactiv';
export const EDUCATION_LEVEL_ENTRY_NAME = 'studii';
export const ANNUAL_INCOME_ENTRY_NAME = 'venit';
export const FUNDS_SOURCE_TIT_ENTRY_NAME = 'SursaFonduri_Tit';
export const TRANSACTION_SCOPE_ENTRY_NAME = 'ScopulTranzactiilor';
export const ESTIMATE_PERCENTAGE_ENTRY_NAME = 'procinvest';
export const EXPERIENCE_ENTRY_NAME = 'estiminvest';
export const RISK_ENTRY_NAME = 'riscaccept';
export const INVESTMENT_ENTRY_NAME = 'expinvest';
export const LAST_STEP_ENTRY_NAME = 'descont_step';
export const CURRENT_STEP_ENTRY_NAME = 'pascurent';
export const NEXT_STEP_ENTRY_NAME = 'pasnou';
export const GET_NEW_GUID_ENTRY_NAME = 'getNewGuid';
export const IS_FIRST_TIME_ENTRY_NAME = 'primaoara';
export const ACCEPT_MAILS_ENTRY_NAME = 'vmail';
export const USERNAME_CHECK_ENTRY_NAME = 'uservalid';
export const ACCOUNT_TYPE_ENTRY_NAME = 'tipcont';
export const PRODUCT_INVEST_ENTRY_NAME = 'produsinvest';
export const FINAL_EMAIL_ENTRY_NAME = 'emailfinal';
export const FILES_ENTRY_NAME = 'cefisiere';
export const FISCAL_COUNTRY_1_ENTRY_NAME = 'TaraOblFisc';
export const FISCAL_COUNTRY_2_ENTRY_NAME = 'TaraOblFisc2';
export const FISCAL_COUNTRY_3_ENTRY_NAME = 'TaraOblFisc3';
export const FISCAL_CODE_1_ENTRY_NAME = 'FiscID';
export const FISCAL_CODE_2_ENTRY_NAME = 'FiscID2';
export const FISCAL_CODE_3_ENTRY_NAME = 'FiscID3';
export const USA_CITIZEN_ENTRY_NAME = 'persSua';
export const FISCAL_COUNTRY_CODE_ENTRY_NAME = 'codRezidFisc';
export const ANAF_ENTRY_NAME = 'NrAnaf';
export const COUNTRIES_ENTRY_NAME = 'tarile';
