export const REGISTER_STEP_1 = 1;
export const REGISTER_STEP_1_NAME = 'start';

export const REGISTER_STEP_2 = 2;
export const REGISTER_STEP_2_NAME = 'nume';

export const REGISTER_STEP_3 = 3;
export const REGISTER_STEP_3_NAME = 'abonare';

export const REGISTER_STEP_4 = 4;
export const REGISTER_STEP_4_NAME = 'ocr';

export const REGISTER_STEP_5 = 5;
export const REGISTER_STEP_5_NAME = 'identif';

export const REGISTER_STEP_6 = 6;
export const REGISTER_STEP_6_NAME = 'identif2';

export const REGISTER_STEP_LIVENESS = 20;
export const REGISTER_STEP_LIVENESS_NAME = 'liveness';

export const REGISTER_STEP_7 = 7;
export const REGISTER_STEP_7_NAME = 'banca';

export const REGISTER_STEP_8 = 8;
export const REGISTER_STEP_8_NAME = 'user';

export const REGISTER_STEP_9 = 9;
export const REGISTER_STEP_9_NAME = 'legal';

export const REGISTER_STEP_10 = 10;
export const REGISTER_STEP_10_NAME = 'detaliiAngajator';

export const REGISTER_STEP_11 = 11;
export const REGISTER_STEP_11_NAME = 'informatiiFinanciare';

export const REGISTER_STEP_12 = 12;
export const REGISTER_STEP_12_NAME = 'profil';

export const REGISTER_STEP_13 = 13;
export const REGISTER_STEP_13_NAME = 'fisc';

export const REGISTER_STEP_14 = 14;
export const REGISTER_STEP_14_NAME = 'fisiere';

export const REGISTER_STEP_15 = 15;
export const REGISTER_STEP_15_NAME = 'gata';

export const REGISTER_STEP_16 = 16;
export const REGISTER_STEP_16_NAME = 'fisierePJ1';

export const REGISTER_STEP_17 = 17;
export const REGISTER_STEP_17_NAME = 'fisierePJ2';

export const REGISTER_STEP_FILES = 18;
export const REGISTER_STEP_FILES_NAME = 'fisiere2';

export const REGISTER_STEPS = 15;

export const getStepFromName = (name) => {
    switch (name) {
        case REGISTER_STEP_2_NAME:
            return REGISTER_STEP_2;
        case REGISTER_STEP_3_NAME:
            return REGISTER_STEP_3;
        case REGISTER_STEP_4_NAME:
            return REGISTER_STEP_4;
        case REGISTER_STEP_5_NAME:
            return REGISTER_STEP_5;
        case REGISTER_STEP_6_NAME:
            return REGISTER_STEP_6;
        case REGISTER_STEP_7_NAME:
            return REGISTER_STEP_7;
        case REGISTER_STEP_8_NAME:
            return REGISTER_STEP_8;
        case REGISTER_STEP_9_NAME:
            return REGISTER_STEP_9;
        case REGISTER_STEP_10_NAME:
            return REGISTER_STEP_10;
        case REGISTER_STEP_11_NAME:
            return REGISTER_STEP_11;
        case REGISTER_STEP_12_NAME:
            return REGISTER_STEP_12;
        case REGISTER_STEP_13_NAME:
            return REGISTER_STEP_13;
        case REGISTER_STEP_14_NAME:
            return REGISTER_STEP_14;
        case REGISTER_STEP_15_NAME:
            return REGISTER_STEP_15;
        case REGISTER_STEP_16_NAME:
            return REGISTER_STEP_16;
        case REGISTER_STEP_17_NAME:
            return REGISTER_STEP_17;
        case REGISTER_STEP_FILES_NAME:
            return REGISTER_STEP_FILES;
        default:
            return REGISTER_STEP_1;
    }
};
