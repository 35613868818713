import React from 'react';
import { Image } from 'react-bootstrap';

import { EN_LOCALE, RO_LOCALE } from '../actions/i18n';

import LOGO_SOURCE from '../assets/webp/logo.webp';

const UnloggedMenuBar = ({ setLocale }) => (
    <div className="menu-bar-container">
        <div className="container">
            <a href='https://www.tradeville.ro'>
                <Image src={LOGO_SOURCE} width={104} height={28} style={{ position: 'absolute', top: 15 }} />
            </a>
            <div className="menu-items-container">
                <div className='menu-item-container' onClick={() => setLocale(RO_LOCALE)}>
                    Romana
                </div>
                <div className='menu-item-container' onClick={() => setLocale(EN_LOCALE)}>
                    English
                </div>
            </div>
        </div>
    </div>
);

export default UnloggedMenuBar;
