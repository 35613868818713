import { getCountries } from '../../../security/utils/fields-storage';

const countries = {
    en: {},
    ro: {},
};

getCountries().forEach((country) => {
    countries.en[country.label] = country.label;
    countries.ro[country.label] = country.label;
});

export default countries;
