import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import RegisterStep from '../RegisterStep';
import RegisterLocationForm from '../forms/RegisterLocationForm';
import validateStep from '../../validators/step-validator';
import usePageTitle from '../../../core/hoc/use-page-title';
import { selectRegisterStepFormData } from '../../selectors/register';
import { resetRegisterStepValidity, setRegisterStep, setRegisterStepFields, setRegisterStepFieldValue } from '../../actions/register';
import { sendMessage } from '../../../core/websocket/engine';
import { getCurrentStepCommand, getStep6Command } from '../../decorators/payloads';
import { isAddressInRomania } from '../../utils/helpers';
import { getRegisterStepField, getRegisterStepFields, saveCurrentStep, saveRegisterStepFields } from '../../utils/fields-storage';
import { getWorkflowVariables } from '../../actions/ocr';
import { getFlow, hasPassedProcess, NEW_OCR_TYPE } from '../../utils/ocr-storage';
import { selectOcrFlow } from '../../selectors/ocr';
import {
    REGISTER_STEP_6_NAME, REGISTER_STEP_6, REGISTER_STEP_5, REGISTER_STEP_2, REGISTER_STEP_LIVENESS,
} from '../../utils/register-steps';
import { BIRTH_PLACE_FIELD, ORIGIN_FIELD, SELECTED_IDENTITY_FIELD } from '../../reducers/features/form-structures';
import { NEW_OCR_FLOW } from '../../services/ocr-flow-decider';
import { PASSPORT_IDENTITY_TYPE } from './RegisterStep2';
import { contentTitleFormat } from '../../containers/RegisterPage.styles';

const RegisterStep6 = ({ goToNextStep, goToPrevStep }) => {
    const dispatch = useDispatch();
    const inputs = useSelector((state) => selectRegisterStepFormData(state, REGISTER_STEP_6));
    const inputsFromStep2 = useSelector((state) => selectRegisterStepFormData(state, REGISTER_STEP_2));
    const selectedIdentity = inputsFromStep2[SELECTED_IDENTITY_FIELD].value;
    const isPassportSelected = selectedIdentity === PASSPORT_IDENTITY_TYPE;
    const isNewFlow = useSelector(selectOcrFlow) === NEW_OCR_FLOW;

    const flow = getFlow();
    const version = flow === NEW_OCR_FLOW ? 'v2' : 'v1';
    usePageTitle(`Deschidere cont - Pas 6 | ${version}`);

    useEffect(() => {
        saveCurrentStep(REGISTER_STEP_6_NAME);
        sendMessage(getCurrentStepCommand(REGISTER_STEP_6_NAME), dispatch);
        dispatch(setRegisterStepFields(REGISTER_STEP_6, getRegisterStepFields(REGISTER_STEP_6)));
        if (hasPassedProcess(NEW_OCR_TYPE)) {
            dispatch(getWorkflowVariables(false, true));
        }
    }, []);

    useEffect(() => {
        const birthPlace = getRegisterStepField(REGISTER_STEP_5, BIRTH_PLACE_FIELD);
        if (birthPlace && birthPlace.value) {
            if (!isAddressInRomania(birthPlace.value)) {
                setRegisterStepFieldValue(REGISTER_STEP_6, ORIGIN_FIELD, null);
            }
        }
    }, []);

    const onSubmit = () => {
        const isValid = validateStep(dispatch, REGISTER_STEP_6, inputs);
        if (isValid) {
            sendMessage(getStep6Command(Object.values(inputs)), dispatch);
            saveRegisterStepFields(inputs, REGISTER_STEP_6);
        }

        if (isValid && !isNewFlow && !isPassportSelected) {
            return dispatch(setRegisterStep(REGISTER_STEP_LIVENESS));
        }

        return isValid && goToNextStep();
    };

    const onBack = () => {
        dispatch(resetRegisterStepValidity(REGISTER_STEP_6));
        goToPrevStep();
    };

    return (
        <RegisterStep
            title={<FormattedMessage id='register_page_step_five_title' />}
            formTitle={<FormattedMessage id='register_page_step_five_form_title' />}
            formSubtitle={<></>}
            prevBtnText={<FormattedMessage id='prev' />}
            nextBtnText={<FormattedMessage id='next' />}
            onGoNextClick={onSubmit}
            onGoPrevClick={onBack}
        >
            <>
                <p style={contentTitleFormat}><FormattedMessage id='register_page_step_five_subtitle' /></p>
                <p><FormattedMessage id='register_page_step_six_desc' /></p>
            </>
            <div>
                <RegisterLocationForm dispatch={dispatch} inputs={inputs} />
            </div>
        </RegisterStep>
    );
};

export default RegisterStep6;
