import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'react-bootstrap';

const Error = ({ onRetry }) => (
    <div>
        <h3><FormattedMessage id="error" /></h3>
        {renderRetry(onRetry)}
    </div>
);

const renderRetry = (onRetryCallback) => {
    const onClickCallback = typeof (onRetryCallback) !== 'function' ? defaultRetryCallback : onRetryCallback;

    return (
        <Button variant='primary' onClick={onClickCallback}>
            <FormattedMessage id="try_again" />
        </Button>
    );
};

const defaultRetryCallback = () => {
    window.location.reload();
};

export default Error;
