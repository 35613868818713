import React from 'react';
import { Form } from 'react-bootstrap';

const SimpleCheckbox = ({ label, checked, onChange }) => (
    <Form.Check
        className="mt-2"
        type="checkbox"
        label={label}
        checked={checked}
        onChange={onChange}
    />
);

export default SimpleCheckbox;
