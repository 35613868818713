import React from 'react';
import { Form } from 'react-bootstrap';

import { width } from '../../../../core/styles/generic.styles';
import { checkboxContainer } from '../../../containers/RegisterPage.styles';

const RadioWithTwoOptions = ({
    formLabel, formName, checkedOption, formInvalid,
    firstOptionLabel, firstOptionId, firstOptionValue, onFirstOptionChange,
    secondOptionLabel, secondOptionId, secondOptionValue, onSecondOptionChange,
}) => (
    <>
        {formLabel}
        <div className='d-flex mt-1'>
            <div style={{ ...checkboxContainer, ...width(50), marginRight: 10 }} onClick={onFirstOptionChange}>
                <Form.Check
                    type="radio"
                    id={firstOptionId}
                    isInvalid={formInvalid}
                    label={firstOptionLabel}
                    name={formName}
                    value={firstOptionValue}
                    checked={checkedOption === firstOptionValue}
                    onChange={onFirstOptionChange}
                    style={checkedOption === firstOptionValue ? { color: '#0060df' } : { color: '#212529' }}
                />
            </div>
            <div style={{ ...checkboxContainer, ...width(50) }} onClick={onSecondOptionChange}>
                <Form.Check
                    type="radio"
                    id={secondOptionId}
                    isInvalid={formInvalid}
                    label={secondOptionLabel}
                    name={formName}
                    value={secondOptionValue}
                    checked={checkedOption === secondOptionValue}
                    onChange={onSecondOptionChange}
                    style={checkedOption === secondOptionValue ? { color: '#0060df' } : { color: '#212529' }}
                />
            </div>
        </div>
    </>
);

export default RadioWithTwoOptions;
