import { combineReducers } from 'redux';

import createNamedWrapperReducer from './named-wrapper-factory';
import httpReducer from './http';
import i18nReducer from './i18n';
import overlayerReducer from './overlayer';
import { websocketUIReducer, websocketDataReducer } from './websocket';
import { registerDataReducer, registerUIReducer } from '../../security/reducers/register';
import { ocrDataReducer, ocrUiReducer } from '../../security/reducers/ocr';
import { OCR_REQUEST_NAME } from '../../security/api/ocr';
import { REGISTER_CONTACT_US_OVERLAYER_NAME } from '../../security/components/overlayers/RegisterContactUsOverlayer';
import { REGISTER_NOT_WORKING_OVERLAYER_NAME } from '../../security/components/overlayers/RegisterNotWorkingOverlayer';
import { REGISTER_BANK_STATEMENT_OVERLAYER_NAME } from '../../security/components/overlayers/RegisterBankStatementOverlayer';
import { REGISTER_SELFIE_OVERLAYER_NAME } from '../../security/components/overlayers/RegisterSelfieOverlayer';
import { REGISTER_COUNTRIES_TAX_OVERLAYER_NAME } from '../../security/components/overlayers/RegisterCountriesWIthTaxObligationsOverlayer';
import { REGISTER_TAX_DECLARATION_OVERLAYER_NAME } from '../../security/components/overlayers/RegisterTaxDeclarationOverlayer';
import { REGISTER_DOCUMENTS_OVERLAYER_NAME } from '../../security/components/overlayers/RegisterDocumentsOverlayer';
import { REGISTER_HELP_OVERLAYER_NAME } from '../../security/components/overlayers/RegisterHelpOverlayer';
import { REGISTER_COMPENSABLE_INFO_OVERLAYER_NAME } from '../../security/components/overlayers/RegisterCompensableInfoOverlayer';

const UIReducers = combineReducers({
    websocket: websocketUIReducer,
    register: registerUIReducer,
    ocr: ocrUiReducer,
    http: combineReducers({
        ocr: createNamedWrapperReducer(httpReducer, OCR_REQUEST_NAME),
    }),
    overlayer: combineReducers({
        [REGISTER_CONTACT_US_OVERLAYER_NAME]: createNamedWrapperReducer(overlayerReducer, REGISTER_CONTACT_US_OVERLAYER_NAME),
        [REGISTER_NOT_WORKING_OVERLAYER_NAME]: createNamedWrapperReducer(overlayerReducer, REGISTER_NOT_WORKING_OVERLAYER_NAME),
        [REGISTER_BANK_STATEMENT_OVERLAYER_NAME]: createNamedWrapperReducer(overlayerReducer, REGISTER_BANK_STATEMENT_OVERLAYER_NAME),
        [REGISTER_SELFIE_OVERLAYER_NAME]: createNamedWrapperReducer(overlayerReducer, REGISTER_SELFIE_OVERLAYER_NAME),
        [REGISTER_COUNTRIES_TAX_OVERLAYER_NAME]: createNamedWrapperReducer(overlayerReducer, REGISTER_COUNTRIES_TAX_OVERLAYER_NAME),
        [REGISTER_TAX_DECLARATION_OVERLAYER_NAME]: createNamedWrapperReducer(overlayerReducer, REGISTER_TAX_DECLARATION_OVERLAYER_NAME),
        [REGISTER_DOCUMENTS_OVERLAYER_NAME]: createNamedWrapperReducer(overlayerReducer, REGISTER_DOCUMENTS_OVERLAYER_NAME),
        [REGISTER_COMPENSABLE_INFO_OVERLAYER_NAME]: createNamedWrapperReducer(overlayerReducer, REGISTER_COMPENSABLE_INFO_OVERLAYER_NAME),
        [REGISTER_HELP_OVERLAYER_NAME]: createNamedWrapperReducer(overlayerReducer, REGISTER_HELP_OVERLAYER_NAME),
    }),
});

const dataReducers = combineReducers({
    websocket: websocketDataReducer,
    register: registerDataReducer,
    ocr: ocrDataReducer,
    i18n: i18nReducer,
});

const rootReducer = combineReducers({
    data: dataReducers,
    ui: UIReducers,
});

export default rootReducer;
