window.onload = () => {
    document.AcceptDefaultCookieValability = 1000 * 60 * 60 * 24 * 730;
    document.AcceptCustomCookieValability = 1000 * 60 * 60 * 24 * 7;
    checkCookies();
};

const checkCookies = () => {
    if (document.cookie.indexOf('TDV_CookiesAcc=') < 0 && window === window.parent) {
        insertAcceptCookiesTab();
    }
};

const insertAcceptCookiesTab = () => {
    const AcceptCookiesTab = document.createElement('DIV');
    AcceptCookiesTab.classList.add('cookie-container');

    // eslint-disable-next-line no-multi-str
    AcceptCookiesTab.innerHTML = '<div id="AccCooTab" style="float: none; height:100vh; width:100vw; background-color: rgba(0, 0, 0, 0.6); position: fixed; top: 0; z-index: 9999;">\
        <div class="ac--popup-icon"></div>\
        <div id="ac--popup-main">\
        <div id="ac--popup-head">\
            <h4>Consimtamant de utilizare cookies</h4>\
        </div>\
        <div class="row">\
        <div class="col-md-9 col-sm-12 col-12">\
        <p>Colectam anumite date in timp ce utilizezi website-ul Tradeville, pentru a-i asigura functionarea corecta, pentru a-ti asigura siguranta in timpul navigarii si pentru a respecta reglementarile in vigoare.</p>\
        <p>De asemenea, avem nevoie de acordul tau in timp ce navighezi pe tradeville.ro pentru a:</p>\
        <ul>\
            <li class="fw-bold mb-3">studia modul în care utilizezi site-ul nostru si alte servicii pentru a le putea imbunatati</li>\
            <li class="fw-bold mb-3">stabili care dintre produsele, serviciile si ofertele noastre pot fi relevante pentru tine</li>\
            <li class="fw-bold mb-3">adapta mesajele de marketing pe care le vei vedea pe retelele sociale, in aplicatii si pe alte site-uri web</li>\
        </ul>\
        <p>Selecteaza “Sunt de acord” daca este in regula sa folosim aceste cookies.</p>\
        <p>Selecteaza “Setari cookies” daca preferi sa alegi tipurile de module cookie pe care le folosim.</p>\
        <p>Iti poti modifica preferintele în orice moment. Trebuie doar sa stergi cookie-urile existente din browser, apoi sa reiei din nou selectia.</p>\
        </div>\
        <div class="col-md-3 col-sm-12 col-12">\
        <div id="ac--foot">\
            <input type="button" id="ac--one" value="Setari cookies" class="mb-3">\
            <input type="button" id="ac--two" value="Sunt de acord">\
        </div>\
        </div>\
        </div>\
        </div>\
    </div>';

    document.body.appendChild(AcceptCookiesTab);
    document.getElementById('ac--one').onclick = insertCookieSettings;
    document.getElementById('ac--two').onclick = acceptDefaultCookies;
};

const acceptDefaultCookies = () => {
    const CookieValab = new Date();
    CookieValab.setTime(CookieValab.getTime() + document.AcceptDefaultCookieValability);
    document.cookie = `TDV_CookiesAcc=functionale,Analiza,Publicitate; expires=${CookieValab.toUTCString()}; path=/;domain=.${window.location.host};`;
    window.dataLayer.push({ event: 'Accepted_Cookies', Analiza: true, Publicitate: true });
    document.getElementById('AccCooTab').remove();
};

const insertCookieSettings = () => {
    const CookieSettingsTab = document.createElement('DIV');

    // eslint-disable-next-line no-multi-str
    CookieSettingsTab.innerHTML = '<div id="CooSetTab" style="float: none; height:100vh; width:100vw; background-color: rgba(0, 0, 0, 0.6); position: fixed; top: 0; z-index: 9999;">\
        <div class="ac--popup-icon"></div>\
        <div id="ac--popup-main">\
        <div id="ac--popup-head">\
            <h4>Setari politica cookie</h4>\
            <button type="button" class="btn-close" id="ac--popup-one" value="Inchide"></button>\
        </div>\
        <p><strong>Personalizați preferințele de consimțământ</strong></p>\
        <p class="mb-3">Modulele de cookie sunt fisiere de text care se stocheaza pe calculatorul, telefonul, tableta sau dispozitivul tau mobil. Acestea sunt stocate de catre site-urile web pe care le accesezi (cum este si site-ul nostru), si contin informatii cu privire la activitatea ta pe respectivele site-uri. Unele cookie-uri sunt necesare, de exemplu pentru a permite accesul la platforma de tranzactionare, in timp ce altele fac site-ul mult mai personal si relevant pentru tine. Afla mai multe <a href="https://tradeville.ro/despre_cookies" style="color:#62a70f;" target="_blank">aici</a>.</p>\
        <p>Modulele de cookie ne ajuta sa furnizam, sa protejam si sa imbunatatim serviciile site-ului nostru. Poti decide ce cookie-uri vrei sa fie active.</p>\
        <p>Avem trei setari pentru cookie-uri:</p>\
        <div class="row">\
        <div class="col-xl-5 col-lg-7 col-md-8 col-sm-12 col-12">\
        <ul class="list-unstyled ac-popup-checks">\
            <li>\
            <div class="form-check form-switch form-check-reverse">\
            <input class="form-check-input" type="checkbox" role="switch" id="cookies" disabled="disabled" checked>\
            <label class="form-check-label" for="cookies">Cookies de functionalitate (sunt obligatorii, nu se pot dezactiva)</label>\
            </div>\
            </li>\
            <li>\
            <div class="form-check form-switch form-check-reverse">\
            <input class="form-check-input" type="checkbox" role="switch" id="AccCoo_ChkB_Analiza" >\
            <label class="form-check-label" for="AccCoo_ChkB_Analiza">Cookies de analiza (exemplu: Google Analytics, Mixpanel, Hotjar etc.)</label>\
            </div>\
            </li>\
            <li>\
            <div class="form-check form-switch form-check-reverse">\
            <input class="form-check-input" type="checkbox" role="switch" id="AccCoo_ChkB_Publicitate" >\
            <label class="form-check-label" for="AccCoo_ChkB_Publicitate">Cookies de publicitate (exemplu: AdWords, YouTube, Facebook etc.)</label>\
            </div>\
            </li>\
        </ul>\
        </div>\
        </div>\
        <div id="ac--popup-foot">\
            <input type="button" id="ac--popup-two" value="Salveaza setari cookie" style="float:none;">\
        </div>\
      </div>\
    </div>';

    document.body.appendChild(CookieSettingsTab);
    document.getElementById('ac--popup-one').onclick = closeCookieSettings;
    document.getElementById('ac--popup-two').onclick = acceptCustomCookies;
    document.getElementById('AccCooTab').remove();
};

const closeCookieSettings = () => {
    document.getElementById('CooSetTab').remove();
    checkCookies();
};

const acceptCustomCookies = () => {
    const CookieValab = new Date();
    let CustomCookieText = 'functionale';

    if (document.getElementById('AccCoo_ChkB_Analiza').checked === true && document.getElementById('AccCoo_ChkB_Publicitate').checked === true) {
        CookieValab.setTime(CookieValab.getTime() + document.AcceptDefaultCookieValability);
    } else {
        CookieValab.setTime(CookieValab.getTime() + document.AcceptCustomCookieValability);
    }

    let CookiesAnaliza = false; let CookiesPublicitate = false;
    if (document.getElementById('AccCoo_ChkB_Analiza').checked === true) { CookiesAnaliza = true; CustomCookieText += ',Analiza'; }
    if (document.getElementById('AccCoo_ChkB_Publicitate').checked === true) { CookiesPublicitate = true; CustomCookieText += ',Publicitate'; }

    document.cookie = `TDV_CookiesAcc=${CustomCookieText}; expires=${CookieValab.toUTCString()}; path=/;domain=.${window.location.host};`;
    window.dataLayer.push({ event: 'Accepted_Cookies', Analiza: CookiesAnaliza, Publicitate: CookiesPublicitate });
    document.getElementById('CooSetTab').remove();
};
