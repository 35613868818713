import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import selectIsOverlayerOpened from '../../../core/selectors/overlayer';
import { closeOverlayer } from '../../../core/actions/overlayer';

export const REGISTER_DOCUMENTS_OVERLAYER_NAME = 'REGISTER_DOCUMENTS';

const RegisterDocumentsOverlayer = () => {
    const dispatch = useDispatch();
    const isOpen = useSelector((state) => selectIsOverlayerOpened(state, REGISTER_DOCUMENTS_OVERLAYER_NAME));
    const onClose = () => dispatch(closeOverlayer(REGISTER_DOCUMENTS_OVERLAYER_NAME));

    return (
        <Modal show={isOpen} onHide={onClose} size={'lg'}>
            <Modal.Header closeButton />
            <Modal.Body>
                <ul>
                    <li><FormattedMessage id='register_page_step_seventeen_desc_2' /></li>
                    <li><FormattedMessage id='register_page_step_seventeen_desc_3' /></li>
                    <li><FormattedMessage id='register_page_step_seventeen_desc_4' /></li>
                    <li><FormattedMessage id='register_page_step_seventeen_desc_5' /></li>
                    <li><FormattedMessage id='register_page_step_seventeen_desc_6' /></li>
                    <li><FormattedMessage id='register_page_step_seventeen_desc_7' /></li>
                </ul>
                <FormattedMessage id='register_page_step_seventeen_desc_8' />
                <ul className='mt-3'>
                    <li><FormattedMessage id='register_page_step_seventeen_desc_9' /></li>
                    <li><FormattedMessage id='register_page_step_seventeen_desc_10' /></li>
                </ul>
            </Modal.Body>
        </Modal>
    );
};
export default RegisterDocumentsOverlayer;
