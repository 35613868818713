import { COMMAND_NAMES } from './constants';

export const getRegisterCommand = (json, messageNumber = 0, timeoutId = 0) => {
    const command = {
        cmd: COMMAND_NAMES.register,
        prm: {
            json: JSON.stringify(json),
        },
        lagata: messageNumber,
        timeoutID: timeoutId,
    };

    return JSON.stringify(command);
};

export const getPingCommand = (messageNumber = 0, timeoutId = 0) => {
    const command = {
        cmd: COMMAND_NAMES.ping,
        lagata: messageNumber,
        timeoutID: timeoutId,
    };

    return JSON.stringify(command);
};

export default getRegisterCommand;
