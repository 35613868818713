import retry from 'async-retry';

const DEFAULT_RETRY_OPTIONS = {
    retries: 3,
    maxTimeout: 60000,
};

class HttpClient {
    static async executeWithRetry(url, method = 'GET', headers = {}, data = {}, retryOptions = DEFAULT_RETRY_OPTIONS) {
        if (method === 'GET') {
            return this.doExecuteWithRetry({
                url,
                method,
                headers,
            }, retryOptions);
        }

        return this.doExecuteWithRetry({
            url,
            method,
            body: JSON.stringify(data),
            headers,
        }, retryOptions);
    }

    static async execute(url, method = 'GET', headers = {}, data = {}) {
        if (method === 'GET') {
            return this.doExecute({
                url,
                method,
                headers,
            });
        }

        return this.doExecute({
            url,
            method,
            body: JSON.stringify(data),
            headers,
        });
    }

    static async doExecuteWithRetry(request, retryOptions = DEFAULT_RETRY_OPTIONS) {
        return retry(async (bail) => {
            try {
                return await this.doExecute(request);
            } catch (httpError) {
                if (httpError.status && httpError.status >= 500) {
                    throw httpError;
                }

                bail(httpError);
                return httpError;
            }
        }, retryOptions);
    }

    static async doExecute(request) {
        try {
            return await fetch(request.url, request);
        } catch (e) {
            throw this.createHttpError(request, e);
        }
    }

    static createHttpError(request, e) {
        const error = new Error(`Error while executing request endpoint=${request.url}`);
        error.status = e.status;
        error.statusMessage = e.statusMessage;
        error.responseText = e.responseText;
        error.responseJSON = e.responseJSON || '';
        error.request = request;

        return error;
    }
}

export default HttpClient;
