import getRegisterCommand from '../../core/websocket/commands';
import handleOcrFiles from '../services/ocr-file-handler';
import { requestComplete, requestError, requestStarted } from '../../core/actions/http';
import { selectRegisterStepFormData } from '../selectors/register';
import { selectOcrInstanceId, selectOcrToken } from '../selectors/ocr';
import { sendMessage } from '../../core/websocket/engine';
import {
    handleOcrResponse, handleOcrSessionStatusResponse, handleOldOcrResponse, hasProcessExpired, isProcessCompleted, hasProcessFailed,
} from '../services/ocr-handler';
import {
    fetchOldToken, fetchSessionResult, fetchSessionStatus, fetchToken, fetchWorkflowVariables, initOldSession, startMessageEvent,
    OCR_REQUEST_NAME,
} from '../api/ocr';
import {
    getInstanceInfo, getOcrOldTokenInfo, getOcrTokenInfo, hasInstanceInfo, hasOcrTokenExpired, removeOcrOldTokenInfo,
    removeOcrTokenInfo, saveInstanceInfo, saveOcrOldTokenInfo, saveOcrTokenInfo, saveRequestsIds,
    getInstanceIdForFileUpload, saveInstanceIdForFileUpload, removeAllOcrData,
} from '../utils/ocr-storage';
import { getRegisterStepFields } from '../utils/fields-storage';
import { getPhoneCodeByCountryCode } from '../utils/forms-options';
import { delay } from '../utils/helpers';
import { REGISTER_STEP_2 } from '../utils/register-steps';
import { EMAIL_FIELD, INTERNATIONAL_PHONE_COUNTRY_FIELD, PHONE_FIELD } from '../reducers/features/form-structures';

export const SET_FLOW = 'SET_FLOW';
export const setFlow = (flow) => ({
    type: SET_FLOW,
    flow,
});

export const SET_TOKEN_INFO = 'SET_TOKEN_INFO';
export const setTokenInfo = (token, refreshToken, expireDate) => ({
    type: SET_TOKEN_INFO,
    token,
    refreshToken,
    expireDate,
});

export const SET_INSTANCE_INFO = 'SET_INSTANCE_INFO';
export const setInstanceInfo = (instanceId, workflowId, uniqueGUID, businessKey, correlationKeys) => ({
    type: SET_INSTANCE_INFO,
    instanceId,
    workflowId,
    uniqueGUID,
    businessKey,
    correlationKeys,
});

export const SET_OLD_SESSION_INFO = 'SET_OLD_SESSION_INFO';
export const setOldSessionInfo = (
    hasAutomatedSignature,
    idExternalRequest,
    idExternalUser,
    idInternalRequest,
    idInternalUser,
    idRequest,
    idStep,
    idWFInstance,
    pageNumberSignature,
) => ({
    type: SET_OLD_SESSION_INFO,
    hasAutomatedSignature,
    idExternalRequest,
    idExternalUser,
    idStep,
    idInternalRequest,
    idInternalUser,
    idRequest,
    idWFInstance,
    pageNumberSignature,
});

export const SET_WORKFLOW_RESULTS = 'SET_WORKFLOW_RESULTS';
export const setWorkflowResults = (
    instanceResult,
    consentsResults,
    selfieResult,
    livenessResult,
    idCardResult,
    otpResult,
) => ({
    type: SET_WORKFLOW_RESULTS,
    instanceResult,
    consentsResults,
    selfieResult,
    livenessResult,
    idCardResult,
    otpResult,
});

export const getToken = () => async (dispatch) => {
    try {
        dispatch(requestStarted(OCR_REQUEST_NAME));
        const { ocrToken, ocrRefreshToken, ocrTokenExpireDate } = getOcrTokenInfo();
        if (ocrToken && ocrRefreshToken && ocrTokenExpireDate) {
            const hasTokenExpired = hasOcrTokenExpired(ocrTokenExpireDate);
            if (hasTokenExpired) {
                removeOcrTokenInfo();
            } else {
                dispatch(setTokenInfo(ocrToken, ocrRefreshToken, ocrTokenExpireDate));
                dispatch(dispatchMessageEvent());
                return;
            }
        }

        const response = await fetchToken();
        const formattedResponse = await response.json();
        const { token, refreshToken } = formattedResponse;
        const expireDate = formattedResponse.ValidTo;
        dispatch(setTokenInfo(token, refreshToken, expireDate));
        saveOcrTokenInfo(token, refreshToken, expireDate);
        dispatch(dispatchMessageEvent());
    } catch (e) {
        dispatch(requestError(OCR_REQUEST_NAME));
    } finally {
        dispatch(requestComplete(OCR_REQUEST_NAME));
    }
};

export const dispatchMessageEvent = () => async (dispatch, getState) => {
    try {
        dispatch(requestStarted(OCR_REQUEST_NAME));
        const token = extractToken(getState());
        if (!token) {
            dispatch(getToken());
            return;
        }
        if (hasInstanceInfo()) {
            const { instanceId, workflowId, uniqueGUID, businessKey, correlationKeys } = getInstanceInfo();
            dispatch(setInstanceInfo(instanceId, workflowId, uniqueGUID, businessKey, correlationKeys));
            return;
        }

        const { phone, email } = await extractPhoneAndEmail(getState);
        if (!phone || !email) {
            dispatch(getToken());
            return;
        }

        const response = await startMessageEvent(token, email.value, phone.value);
        const formattedResponse = await response.json();
        const currentResponse = formattedResponse[0];
        dispatch(setInstanceInfo(
            currentResponse.InstanceId,
            currentResponse.WorkflowId,
            currentResponse.UniqueGUID,
            currentResponse.BusinessKey,
            currentResponse.CorrelationKeys,
        ));
        saveInstanceInfo(
            currentResponse.InstanceId,
            currentResponse.WorkflowId,
            currentResponse.UniqueGUID,
            currentResponse.BusinessKey,
            currentResponse.CorrelationKeys,
        );
        saveInstanceIdForFileUpload(currentResponse.InstanceId);
    } catch (e) {
        dispatch(requestError(OCR_REQUEST_NAME));
    } finally {
        dispatch(requestComplete(OCR_REQUEST_NAME));
    }
};

export const getWorkflowVariables = (handleResponse = true, isFinished = false) => async (dispatch, getState) => {
    try {
        const token = extractToken(getState());
        if (!token) {
            dispatch(getToken());
            return;
        }

        const instanceId = extractInstanceId(getState());
        let fallbackInstanceId = null;
        if (!instanceId && !handleResponse) {
            fallbackInstanceId = getInstanceIdForFileUpload();
        }

        const response = await fetchWorkflowVariables(token, instanceId || fallbackInstanceId, 'wf_WfInstance_Results');
        const formattedResponse = await response.json();
        dispatch(setWorkflowResults(
            formattedResponse.Workflow_Instance_Result,
            formattedResponse.Workflow_Consents_Result,
            formattedResponse.Workflow_Selfie_Result,
            formattedResponse.Workflow_Liveness_Result,
            formattedResponse.Workflow_IdCard_Result,
            formattedResponse.Workflow_OTP_Result,
        ));

        if (hasProcessExpired(formattedResponse.Workflow_Instance_Result, formattedResponse.Workflow_Storyline.Instance_Workflow)
            || hasProcessFailed(formattedResponse.Workflow_Instance_Result, formattedResponse.Workflow_Storyline.Instance_Workflow)) {
            removeAllOcrData();
            dispatch(getToken());
            return;
        }

        if (isProcessCompleted(formattedResponse.Workflow_Instance_Result, formattedResponse.Workflow_Storyline.Instance_Workflow)
            || isFinished) {
            await handleOcrFiles(token, formattedResponse, dispatch);
            sendMessage(getRegisterCommand({ ocrrez: JSON.stringify(formattedResponse) }), dispatch);
            if (handleResponse) {
                handleOcrResponse(formattedResponse, dispatch);
            }
        }
    } catch (e) {
        dispatch(requestError(OCR_REQUEST_NAME));
    }
};

const extractToken = (state) => {
    const token = selectOcrToken(state);
    if (!token) {
        const { ocrToken } = getOcrTokenInfo();
        return ocrToken;
    }

    return token;
};

const extractOldToken = (state) => {
    const token = selectOcrToken(state);
    if (!token) {
        const { ocrToken } = getOcrOldTokenInfo();
        return ocrToken;
    }

    return token;
};

const extractInstanceId = (state) => {
    const stateInstanceId = selectOcrInstanceId(state);
    if (!stateInstanceId) {
        const { instanceId } = getInstanceInfo();
        return instanceId;
    }

    return stateInstanceId;
};

const extractPhoneAndEmail = async (getState) => {
    let internationalPhoneCode = null;
    let phone = null;
    let email = null;

    const inputs = selectRegisterStepFormData(getState(), REGISTER_STEP_2);
    internationalPhoneCode = inputs[INTERNATIONAL_PHONE_COUNTRY_FIELD];
    phone = inputs[PHONE_FIELD];
    email = inputs[EMAIL_FIELD];

    if (!phone || !phone.value || !email || !email.value) {
        const inputsFromStorage = getRegisterStepFields(REGISTER_STEP_2);
        internationalPhoneCode = inputsFromStorage[INTERNATIONAL_PHONE_COUNTRY_FIELD];
        email = inputsFromStorage[EMAIL_FIELD];
        phone = inputsFromStorage[PHONE_FIELD];
    }
    if (!phone || !phone.value || !email || !email.value) {
        await delay(1500);
        const inputsFromStorage = getRegisterStepFields(REGISTER_STEP_2);
        internationalPhoneCode = inputsFromStorage[INTERNATIONAL_PHONE_COUNTRY_FIELD];
        email = inputsFromStorage[EMAIL_FIELD];
        phone = inputsFromStorage[PHONE_FIELD];
    }
    if (internationalPhoneCode && internationalPhoneCode.value && internationalPhoneCode.value !== 0) {
        const internationalPhoneCodeValue = getPhoneCodeByCountryCode(internationalPhoneCode.value);
        return { phone: { value: `${internationalPhoneCodeValue}${phone.value}` }, email };
    }

    return { phone, email };
};

export const getOldToken = () => async (dispatch) => {
    try {
        dispatch(requestStarted(OCR_REQUEST_NAME));
        const { ocrToken, ocrRefreshToken, ocrTokenExpireDate } = getOcrOldTokenInfo();
        if (ocrToken && ocrRefreshToken && ocrTokenExpireDate) {
            const hasTokenExpired = hasOcrTokenExpired(ocrTokenExpireDate);
            if (hasTokenExpired) {
                removeOcrOldTokenInfo();
            } else {
                dispatch(setTokenInfo(ocrToken, ocrRefreshToken, ocrTokenExpireDate));
                dispatch(dispatchInitSession());
                return;
            }
        }

        const response = await fetchOldToken();
        const formattedResponse = await response.json();
        const { token, refreshToken } = formattedResponse;
        const expireDate = formattedResponse.ValidTo;
        dispatch(setTokenInfo(token, refreshToken, expireDate));
        saveOcrOldTokenInfo(token, refreshToken, expireDate);
        dispatch(dispatchInitSession());
    } catch (e) {
        dispatch(requestError(OCR_REQUEST_NAME));
    } finally {
        dispatch(requestComplete(OCR_REQUEST_NAME));
    }
};

export const dispatchInitSession = () => async (dispatch, getState) => {
    try {
        dispatch(requestStarted(OCR_REQUEST_NAME));
        const token = extractToken(getState());
        if (!token) {
            dispatch(getOldToken());
            return;
        }

        const { phone, email } = await extractPhoneAndEmail(getState);
        const response = await initOldSession(token, email.value, phone.value);
        const formattedResponse = await response.json();
        dispatch(setOldSessionInfo(
            formattedResponse.HasAutomatedSignature,
            formattedResponse.IdExternalRequest,
            formattedResponse.IdExternalUser,
            formattedResponse.IdInternalRequest,
            formattedResponse.IdInternalUser,
            formattedResponse.IdRequest,
            formattedResponse.IdStep,
            formattedResponse.IdWFInstance,
            formattedResponse.PageNumberSignature,
        ));
        saveRequestsIds(formattedResponse.IdExternalRequest, formattedResponse.IdInternalRequest);
    } catch (e) {
        dispatch(requestError(OCR_REQUEST_NAME));
    } finally {
        dispatch(requestComplete(OCR_REQUEST_NAME));
    }
};

export const getSessionStatus = (type) => async (dispatch, getState) => {
    try {
        const token = extractOldToken(getState());
        if (!token) {
            dispatch(getOldToken());
            return;
        }

        const response = await fetchSessionStatus(token);
        const formattedResponse = await response.json();
        handleOcrSessionStatusResponse(formattedResponse, dispatch, type);
    } catch (e) { /* empty */ }
};

export const getSessionResult = (type) => async (dispatch, getState) => {
    try {
        const token = extractOldToken(getState());
        if (!token) {
            dispatch(getOldToken());
            return;
        }

        const response = await fetchSessionResult(token);
        const formattedResponse = await response.json();
        sendMessage(getRegisterCommand({ ocrrez: JSON.stringify(formattedResponse) }), dispatch);
        handleOldOcrResponse(token, formattedResponse, dispatch, type);
    } catch (e) {
        dispatch(requestError(OCR_REQUEST_NAME));
    }
};
