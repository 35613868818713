export const doesOccupationHaveOptions = (occupation) => !(occupation === 27 || occupation === 29 || occupation === 30);
export const isOccupationOwnActivity = (occupation) => occupation === 28;

export const isLessThanHoursApart = (date1, date2, hours) => {
    const time1 = date1;
    const time2 = date2.getTime();
    const differenceInMilliseconds = Math.abs(time1 - time2);
    const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60);

    return differenceInHours < hours;
};

export const isLessThanMinutesApart = (date1, date2, minutes) => {
    const timeDifference = Math.abs(date1 - date2);
    const minutesDifference = timeDifference / (1000 * 60);
    return minutesDifference < minutes;
};

export const getStringBetweenParentheses = (inputString) => {
    const regex = /\(([^)]+)\)/;
    const matches = inputString.match(regex);
    return matches ? matches[1] : null;
};

export const extractBirthdateFromCNP = (cnp) => {
    if (!cnp || cnp.length !== 13) {
        return null;
    }

    const year = parseInt(cnp.substr(1, 2), 10);
    const month = parseInt(cnp.substr(3, 2), 10);
    const day = parseInt(cnp.substr(5, 2), 10);

    let centuryPrefix = '';
    const firstDigit = parseInt(cnp.charAt(0), 10);
    if (firstDigit === 1 || firstDigit === 2) {
        centuryPrefix = '19';
    } else if (firstDigit === 5 || firstDigit === 6) {
        centuryPrefix = '20';
    }

    return `${centuryPrefix + year.toString().padStart(2, '0')}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
};

export const isAddressInRomania = (value) => {
    const romanianKeywords = ['judet', 'municipiu', 'oras', 'Romania', 'România'];
    const lowerCaseAddress = value.toLowerCase();
    return romanianKeywords.some((keyword) => lowerCaseAddress.includes(keyword.toLowerCase()));
};

export const isMobile = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile|android|iphone|ipad|phone/i.test(userAgent);
};

// eslint-disable-next-line no-promise-executor-return
export const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export const base64ToFile = (base64Data, contentType, filename) => {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i += 1) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });

    // eslint-disable-next-line no-undef
    return new File([blob], filename, { type: contentType });
};

// eslint-disable-next-line no-bitwise
export const getFileExtension = (filename) => filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2);
