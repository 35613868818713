import React from 'react';
import { Form } from 'react-bootstrap';

import { displayFlexRow, marginRightWithWidth, width } from '../../../../core/styles/generic.styles';

const TwoTextInputsRow = ({
    controlId, firstInputLabel, firstInputValue, firstInputInvalid, onFirstInputChange, onFirstInputBlur, firstInputDisabled = false,
    secondInputLabel, secondInputValue, secondInputInvalid, onSecondInputChange, secondInputDisabled = false,
}) => (
    <Form.Group className="mb-3" controlId={controlId}>
        <div style={displayFlexRow}>
            <span style={marginRightWithWidth(15, 50)}>
                <Form.Label>{firstInputLabel}</Form.Label>
                <Form.Control
                    type="text" value={firstInputValue} onBlur={onFirstInputBlur} disabled={firstInputDisabled}
                    onInput={onFirstInputChange} isInvalid={firstInputInvalid}
                />
            </span>
            <span style={width(50)}>
                <Form.Label>{secondInputLabel}</Form.Label>
                <Form.Control disabled={secondInputDisabled} type="text" value={secondInputValue} onInput={onSecondInputChange} isInvalid={secondInputInvalid} />
            </span>
        </div>
    </Form.Group>
);

export default TwoTextInputsRow;
