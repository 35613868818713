import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import RegisterStep from '../RegisterStep';
import RegisterAccessDataForm from '../forms/RegisterAccessDataForm';
import validateStep from '../../validators/step-validator';
import usePageTitle from '../../../core/hoc/use-page-title';
import { selectRegisterStepFormData } from '../../selectors/register';
import { resetRegisterStepValidity, setRegisterStepFieldLoading, setRegisterStepFields } from '../../actions/register';
import { sendMessage } from '../../../core/websocket/engine';
import { getCurrentStepCommand, getStep8Command, getUsernameCheckCommand } from '../../decorators/payloads';
import { getRegisterStepFields, saveCurrentStep, saveRegisterStepFields } from '../../utils/fields-storage';
import { getFlow } from '../../utils/ocr-storage';
import { REGISTER_STEP_8_NAME, REGISTER_STEP_8 } from '../../utils/register-steps';
import { USERNAME_FIELD } from '../../reducers/features/form-structures';
import { NEW_OCR_FLOW } from '../../services/ocr-flow-decider';

const RegisterStep8 = ({ goToNextStep, goToPrevStep }) => {
    const dispatch = useDispatch();
    const inputs = useSelector((state) => selectRegisterStepFormData(state, REGISTER_STEP_8));

    const onUsernameFinish = () => {
        dispatch(setRegisterStepFieldLoading(REGISTER_STEP_8, USERNAME_FIELD, true));
        sendMessage(getUsernameCheckCommand(inputs.username.value), dispatch);
    };

    const onSubmit = () => {
        const isValid = validateStep(dispatch, REGISTER_STEP_8, inputs);
        if (isValid) {
            sendMessage(getStep8Command(Object.values(inputs)), dispatch);
            saveRegisterStepFields(inputs, REGISTER_STEP_8);
        }
        return isValid && goToNextStep();
    };

    const flow = getFlow();
    const version = flow === NEW_OCR_FLOW ? 'v2' : 'v1';
    usePageTitle(`Deschidere cont - Pas 8 | ${version}`);

    useEffect(() => {
        saveCurrentStep(REGISTER_STEP_8_NAME);
        sendMessage(getCurrentStepCommand(REGISTER_STEP_8_NAME), dispatch);
        dispatch(setRegisterStepFields(REGISTER_STEP_8, getRegisterStepFields(REGISTER_STEP_8)));
    }, []);

    const onBack = () => {
        dispatch(resetRegisterStepValidity(REGISTER_STEP_8));
        goToPrevStep();
    };

    return (
        <RegisterStep
            title={<FormattedMessage id='register_page_step_eight_title' />}
            formTitle={<FormattedMessage id='register_page_step_eight_form_title' />}
            formSubtitle={<></>}
            prevBtnText={<FormattedMessage id='prev' />}
            nextBtnText={<FormattedMessage id='next' />}
            nextBtnDisabled={inputs[USERNAME_FIELD].isLoading}
            onGoNextClick={onSubmit}
            onGoPrevClick={onBack}
        >
            <>
                <p><FormattedMessage id='register_page_step_eight_desc_1' /></p>
                <p><FormattedMessage id='register_page_step_seven_desc_2' /></p>
                <p><FormattedMessage id='register_page_step_seven_desc_3' /></p>
            </>
            <>
                <RegisterAccessDataForm inputs={inputs} dispatch={dispatch} onUsernameFinish={onUsernameFinish} />
            </>
        </RegisterStep>
    );
};

export default RegisterStep8;
