import React from 'react';
import { FormattedMessage } from 'react-intl';

import RadioWithTwoOptions from './core/RadioWithTwoOptions';
import TextInput from './core/TextInput';
import NumberInput from './core/NumberInput';
import SimpleCheckbox from './core/SimpleCheckbox';
import DisabledInput from './core/DisabledInput';
import Checkbox from './core/Checkbox';
import Select from './core/Select';
import { LEGAL_PERSON, NATURAL_PERSON, RO_CI_IDENTITY_TYPE, PASSPORT_IDENTITY_TYPE } from '../steps/RegisterStep2';
import {
    COMPANY_NAME_FIELD, EMAIL_FIELD, FINAL_AGREEMENT_FIELD, FINAL_AGREEMENT_2_FIELD, INTERNATIONAL_PHONE_COUNTRY_FIELD,
    INTERNATIONAL_PHONE_FIELD, PHONE_FIELD, SELECTED_IDENTITY_FIELD, SELECTED_TYPE_PERSON_FIELD, INTERNATIONAL_PHONE_COUNTRY_NUMBER_FIELD,
} from '../../reducers/features/form-structures';
import { INTERNATIONAL_PHONE_COUNTRIES_VALUES } from '../../utils/forms-options';

const RegisterBasicInfoForm = ({
    inputs, handleSelectedPerson, setEmail, setPhone, onInternationalPhoneCheck, handleIdentityType,
    setCompanyName, onFinalAgreementClick, onFinalAgreement2Click, onSelectCountry,
}) => {
    const selectedTypePerson = inputs[SELECTED_TYPE_PERSON_FIELD].value;
    const finalAgreement = inputs[FINAL_AGREEMENT_FIELD].value;
    const finalAgreement2 = inputs[FINAL_AGREEMENT_2_FIELD].value;

    return (
        <>
            <RadioWithTwoOptions
                formLabel={<></>} formName='options'
                formInvalid={!inputs[SELECTED_TYPE_PERSON_FIELD].isValid}
                checkedOption={selectedTypePerson}
                firstOptionLabel={<FormattedMessage id='register_page_step_two_form_checkbox_1' />}
                firstOptionId={NATURAL_PERSON}
                firstOptionValue={NATURAL_PERSON}
                onFirstOptionChange={() => handleSelectedPerson(NATURAL_PERSON)}
                secondOptionLabel={<FormattedMessage id='register_page_step_two_form_checkbox_2' />}
                secondOptionId={LEGAL_PERSON}
                secondOptionValue={LEGAL_PERSON}
                onSecondOptionChange={() => handleSelectedPerson(LEGAL_PERSON)}
            />
            <TextInput
                isInvalid={!inputs[EMAIL_FIELD].isValid}
                value={inputs[EMAIL_FIELD].value}
                label='E-mail'
                controlId={EMAIL_FIELD}
                onInputChange={(e) => setEmail(e.target.value)}
                isLoading={inputs[EMAIL_FIELD].isLoading}
            />
            {inputs[INTERNATIONAL_PHONE_COUNTRY_FIELD].isVisible
                ? <>
                    <Select
                        formLabel={<FormattedMessage id='phone' />}
                        formControlId={inputs[INTERNATIONAL_PHONE_COUNTRY_FIELD].name}
                        isInvalid={!inputs[INTERNATIONAL_PHONE_COUNTRY_FIELD].isValid}
                        selectedValue={inputs[INTERNATIONAL_PHONE_COUNTRY_FIELD].value}
                        options={INTERNATIONAL_PHONE_COUNTRIES_VALUES}
                        onSelectedValueChange={onSelectCountry}
                    />
                    <div className='d-flex w-100' style={{ marginTop: '-2rem' }}>
                        <DisabledInput
                            style={{ width: 100 }}
                            label={<></>}
                            value={inputs[INTERNATIONAL_PHONE_COUNTRY_NUMBER_FIELD].value}
                        />
                        <NumberInput
                            isInvalid={!inputs[PHONE_FIELD].isValid}
                            value={inputs[PHONE_FIELD].value}
                            label={<></>} controlId={PHONE_FIELD} isLoading={inputs[PHONE_FIELD].isLoading}
                            onInputChange={(e) => setPhone(e.target.value)} style={{ flexGrow: 1 }}
                        />
                    </div>
                </>
                : <NumberInput
                    isInvalid={!inputs[PHONE_FIELD].isValid}
                    value={inputs[PHONE_FIELD].value} isLoading={inputs[PHONE_FIELD].isLoading}
                    label={<FormattedMessage id='phone' />} controlId={PHONE_FIELD}
                    onInputChange={(e) => setPhone(e.target.value)}
                />
            }
            <SimpleCheckbox
                checked={inputs[INTERNATIONAL_PHONE_FIELD].value}
                label={<FormattedMessage id='register_page_step_two_international_phone' />}
                onChange={onInternationalPhoneCheck}
            />
            <br />
            {inputs[SELECTED_IDENTITY_FIELD].isVisible
                && <RadioWithTwoOptions
                    formLabel={<></>} formName='identity'
                    formInvalid={!inputs[SELECTED_IDENTITY_FIELD].isValid}
                    checkedOption={inputs[SELECTED_IDENTITY_FIELD].value}
                    firstOptionId={RO_CI_IDENTITY_TYPE}
                    firstOptionValue={RO_CI_IDENTITY_TYPE}
                    firstOptionLabel={<FormattedMessage id='register_page_step_two_documents_option_1' />}
                    onFirstOptionChange={() => handleIdentityType(RO_CI_IDENTITY_TYPE)}
                    secondOptionId={PASSPORT_IDENTITY_TYPE}
                    secondOptionValue={PASSPORT_IDENTITY_TYPE}
                    secondOptionLabel={<FormattedMessage id='register_page_step_two_documents_option_2' />}
                    onSecondOptionChange={() => handleIdentityType(PASSPORT_IDENTITY_TYPE)}
                />
            }
            {inputs[COMPANY_NAME_FIELD].isVisible
                && <TextInput
                    controlId={COMPANY_NAME_FIELD}
                    label={<FormattedMessage id='register_page_step_two_company_name' />}
                    isInvalid={!inputs[COMPANY_NAME_FIELD].isValid}
                    value={inputs[COMPANY_NAME_FIELD].value}
                    onInputChange={(e) => setCompanyName(e.target.value)}
                />
            }
            <Checkbox
                label={<FormattedMessage id='register_page_step_two_checkbox' />}
                controlId={FINAL_AGREEMENT_FIELD}
                checked={finalAgreement}
                isInvalid={!inputs[FINAL_AGREEMENT_FIELD].isValid}
                onChange={() => onFinalAgreementClick(!finalAgreement)}
            />
            <Checkbox
                label={getCheckbockText()}
                controlId={FINAL_AGREEMENT_2_FIELD}
                checked={finalAgreement2}
                isInvalid={!inputs[FINAL_AGREEMENT_2_FIELD].isValid}
                onChange={() => onFinalAgreement2Click(!finalAgreement2)}
            />
        </>
    );
};

const getCheckbockText = () => (
    <>
        <FormattedMessage id='register_page_step_two_checkbox_2_part_1' />
        <a target='_blank' href='https://public.tradeville.ro/Documents/Home/Contracte/SE_Contract_TaxeRO&INTL.pdf' rel="noreferrer">
            <FormattedMessage id='register_page_step_two_checkbox_2_part_2' />
        </a>
        <FormattedMessage id='register_page_step_two_checkbox_2_part_3' />
    </>
);

export default RegisterBasicInfoForm;
