export const WEBSOCKET_CONNECTING = 'WEBSOCKET_CONNECTING';
export const websocketConnecting = () => ({ type: WEBSOCKET_CONNECTING });

export const WEBSOCKET_CONNECTION_ERROR = 'WEBSOCKET_CONNECTION_ERROR';
export const websocketConnectionError = () => ({ type: WEBSOCKET_CONNECTION_ERROR });

export const WEBSOCKET_CONNECTION_COMPLETED = 'WEBSOCKET_CONNECTION_COMPLETED';
export const websocketConnectionComplete = () => ({ type: WEBSOCKET_CONNECTION_COMPLETED });

export const ADD_RESPONSE_TO_STACK = 'ADD_RESPONSE_TO_STACK';
export const addResponseToStack = (response) => ({ type: ADD_RESPONSE_TO_STACK, payload: response });

export const ADD_COMMAND_TO_STACK = 'ADD_COMMAND_TO_STACK';
export const addCommandToStack = (command) => ({ type: ADD_COMMAND_TO_STACK, payload: command });

export const SET_LAST_RESPONSE = 'SET_LAST_RESPONSE';
export const setLastResponse = (response) => ({ type: SET_LAST_RESPONSE, payload: response });
