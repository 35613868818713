import React from 'react';
import { Form } from 'react-bootstrap';

const DisabledInput = ({ label, value, style = {} }) => (
    <Form.Group className="mb-3" style={style}>
        <Form.Label>{label}</Form.Label>
        <Form.Control
            disabled
            type="text"
            value={value}
        />
    </Form.Group>
);

export default DisabledInput;
