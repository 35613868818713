import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import RegisterStep from '../RegisterStep';
import RegisterSelfieOverlayer from '../overlayers/RegisterSelfieOverlayer';
import RegisterBankStatementOverlayer from '../overlayers/RegisterBankStatementOverlayer';
import FileUpload from '../forms/core/FileUpload';
import validateStep from '../../validators/step-validator';
import usePageTitle from '../../../core/hoc/use-page-title';
import { selectRegisterStepFormData, selectUploadedFiles } from '../../selectors/register';
import { getFlow, hasPassedProcess, NEW_OCR_TYPE } from '../../utils/ocr-storage';
import { sendMessage } from '../../../core/websocket/engine';
import { getCurrentStepCommand, getStep14Command } from '../../decorators/payloads';
import { saveCurrentStep } from '../../utils/fields-storage';
import { getWorkflowVariables } from '../../actions/ocr';
import {
    getUploadedFiles, resetRegisterStepValidity, setRegisterStepFieldValidity, setRegisterStepFileValue, setUploadedFiles, uploadFile,
} from '../../actions/register';
import { REGISTER_STEP_14_NAME, REGISTER_STEP_14 } from '../../utils/register-steps';
import { ID_FILE_FIELD, ID_FILENAME, OTHER_FILES_FIELD, SELFIE_FILE_FIELD, SELFIE_FILENAME } from '../../reducers/features/form-structures';
import { NEW_OCR_FLOW } from '../../services/ocr-flow-decider';
import { boldFont } from '../../../core/styles/generic.styles';

import ID_SVG from '../../../core/assets/svg/id.svg';
import SELFIE_SVG from '../../../core/assets/svg/selfie.svg';
import DOCUMENT_SVG from '../../../core/assets/svg/document.svg';

const RegisterStep14 = ({ goToNextStep, goToPrevStep }) => {
    const [errorMessage1, setErrorMessage1] = useState(null);
    const [errorMessage3, setErrorMessage3] = useState(null);
    const [errorMessage4, setErrorMessage4] = useState(null);
    const [loader1, setLoader1] = useState(false);
    const [loader3, setLoader3] = useState(false);
    const [loader4, setLoader4] = useState(false);

    const dispatch = useDispatch();
    const inputs = useSelector((state) => selectRegisterStepFormData(state, REGISTER_STEP_14));
    const uploadedFiles = useSelector(selectUploadedFiles);
    const hasPassedOcr = hasPassedProcess(NEW_OCR_TYPE);
    const hasPassedAnyOcr = hasPassedProcess();

    const flow = getFlow();
    const version = flow === NEW_OCR_FLOW ? 'v2' : 'v1';
    usePageTitle(`Deschidere cont - Pas 14 | ${version}`);

    useEffect(() => {
        saveCurrentStep(REGISTER_STEP_14_NAME);
        sendMessage(getCurrentStepCommand(REGISTER_STEP_14_NAME), dispatch);
        dispatch(getUploadedFiles());
        if (hasPassedOcr) {
            dispatch(getWorkflowVariables(false, true));
        }
    }, []);

    const onSubmit = () => {
        if (loader1) {
            setErrorMessage1('Te rugam asteapta pana se incarca documentele.');
            return;
        }
        if (loader3) {
            setErrorMessage3('Te rugam asteapta pana se incarca documentele.');
            return;
        }
        if (loader4) {
            setErrorMessage4('Te rugam asteapta pana se incarca documentele.');
            return;
        }

        const hasAlreadyAllFiles = () => (
            uploadedFiles.some((file) => file.includes(ID_FILENAME)) && uploadedFiles.some((file) => file.includes(SELFIE_FILENAME))
        );

        const hasNoErrorMessages = () => (
            !errorMessage1 && !errorMessage3 && !errorMessage4
        );

        const isValid = validateStep(dispatch, REGISTER_STEP_14, inputs);
        if ((isValid || hasAlreadyAllFiles()) && hasNoErrorMessages()) {
            sendMessage(getStep14Command(), dispatch);
            goToNextStep();
        }
    };

    const onBack = () => {
        dispatch(resetRegisterStepValidity(REGISTER_STEP_14));
        goToPrevStep();
    };

    const validatorCallback = (fileField) => (isValid) => (
        dispatch(setRegisterStepFieldValidity(REGISTER_STEP_14, fileField, isValid))
    );

    const onFileUpload = (file, setError, setLoader, validator, fileName = null) => {
        if (file) {
            const onSuccessCallback = () => dispatch(setUploadedFiles([...uploadedFiles, fileName]));
            dispatch(uploadFile(file, setError, setLoader, validator, fileName, onSuccessCallback, dispatch));
        }
    };

    const onFilesUpload = (files, setError, setLoader, validator, fileName = null) => {
        [...files].forEach((file) => {
            dispatch(uploadFile(file, setError, setLoader, validator, fileName, () => {}, dispatch));
        });
    };

    return (
        <RegisterStep
            title={<FormattedMessage id='register_page_step_fourteen_title' />}
            formTitle={<FormattedMessage id='register_page_step_fourteen_form_title' />}
            formSubtitle={<></>}
            prevBtnText={<FormattedMessage id='prev' />}
            nextBtnText={<FormattedMessage id='finish' />}
            nextBtnDisabled={loader1 || loader3 || loader4}
            onGoNextClick={onSubmit}
            onGoPrevClick={onBack}
        >
            <>
                <p style={boldFont}><FormattedMessage id='register_page_step_fourteen_desc_1' /></p>
                <p><FormattedMessage id='register_page_step_fourteen_desc_3' /></p>
            </>
            <>
                {(!hasPassedAnyOcr || !uploadedFiles.some((file) => file.includes(ID_FILENAME)))
                    && <FileUpload
                        imgSrc={ID_SVG}
                        titleLabel={<FormattedMessage id='register_page_step_fourteen_id' />}
                        isInvalid={!uploadedFiles.some((file) => file.includes(ID_FILENAME)) && !inputs[ID_FILE_FIELD].isValid}
                        errorMessage={errorMessage1}
                        successMessage={uploadedFiles.some((file) => file.includes(ID_FILENAME)) && ID_FILENAME}
                        loader={loader1}
                        handleFileChange={(e) => {
                            dispatch(setRegisterStepFileValue(REGISTER_STEP_14, ID_FILE_FIELD, e.target.files[0]));
                            onFileUpload(e.target.files[0], setErrorMessage1, setLoader1, validatorCallback(ID_FILE_FIELD), ID_FILENAME);
                        }}
                    />}
                {(!hasPassedAnyOcr || !uploadedFiles.some((file) => file.includes('Selfie')))
                    && <FileUpload
                        imgSrc={SELFIE_SVG}
                        titleLabel={<FormattedMessage id='register_page_step_fourteen_selfie' />}
                        isInvalid={!uploadedFiles.some((file) => file.includes('Selfie')) && !inputs[SELFIE_FILE_FIELD].isValid}
                        errorMessage={errorMessage3}
                        successMessage={uploadedFiles.some((file) => file.includes('Selfie')) && SELFIE_FILENAME}
                        loader={loader3}
                        handleFileChange={(e) => {
                            dispatch(setRegisterStepFileValue(REGISTER_STEP_14, SELFIE_FILE_FIELD, e.target.files[0]));
                            onFileUpload(
                                e.target.files[0],
                                setErrorMessage3,
                                setLoader3,
                                validatorCallback(SELFIE_FILE_FIELD),
                                SELFIE_FILENAME,
                            );
                        }}
                    />
                }
                <FileUpload
                    imgSrc={DOCUMENT_SVG}
                    titleLabel={<FormattedMessage id='register_page_step_fourteen_other' />}
                    isInvalid={!inputs[OTHER_FILES_FIELD].isValid}
                    errorMessage={errorMessage4}
                    loader={loader4}
                    handleFileChange={(e) => {
                        dispatch(setRegisterStepFileValue(REGISTER_STEP_14, OTHER_FILES_FIELD, e.target.files));
                        onFilesUpload(e.target.files, setErrorMessage4, setLoader4, validatorCallback(OTHER_FILES_FIELD));
                    }}
                    multiple={true}
                />
                <RegisterSelfieOverlayer />
                <RegisterBankStatementOverlayer />
            </>
        </RegisterStep>
    );
};

export default RegisterStep14;
