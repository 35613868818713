import HttpClient from '../../core/services/http-client';

export const TOKEN = 'eyJhbGciOiJIUzI1NiJ9.eyJjbGllbnRJZCI6NDYsImVtYWlsIjoicmFkdS5zaXRhcnVAdHJhZGV2aWxsZS5ybyJ9.niI4xMViRRXX52Ghe1AdEb3s_KhUvnXK9RVOZxqD0Po';
export const API_URL = 'https://app.dmonitor.tech/api/search/v2';

export const getDmonitorData = async (firstName, lastName) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${TOKEN}`,
    };
    const requestBody = {
        entity: 'Person',
        search: `${lastName} ${firstName}`,
    };

    return HttpClient.execute(API_URL, 'POST', headers, requestBody);
};
